import axios from 'axios'
import i18n from '@src/plugins/vue-i18n'
import { sortBy, orderBy, debounce } from 'lodash'

import { app } from '@src/main'

import {
  FolderType,
  ScormFileAvailability,
  PermissionRoleType,
  LicenseType,
  DEFAULT_ADMIN_SELECTED_COMPANY_ID,
  MEGA_ADMIN_EMAILS,
  DEMO_MOCKUP_DSK_ACCOUNT,
  MenuCategoryUuid,
  TrainingSessionStatus,
} from '@enums'

import $router from '@router'

import store from '@state/store'

import BrandSetting from '@state/models/brand-setting'
import Company from '@state/models/company'
import CompanyRole from '@state/models/company-role'
import CompanyRoleScormStatus from '@state/models/company-role-scorm-status'
import { Document } from '@state/models/document'
import { DocumentVersion } from '@state/models/document-version'
import DocumentBlueprint from '@state/models/document-blueprint'
import DocsAuditProject from '@state/models/audit/docs-audit-project'
import File from '@state/models/file'
import Folder from '@state/models/folder'
import FolderLink from '@state/models/folder-link'
import License from '@state/models/license'
import Order from '@state/models/order'
import PermissionRole from '@state/models/permission-role'
import PermissionRoleCompanyRoleAssociation from '@state/models/permission-role-company-role-association'
import PermissionRoleUserAssociation from '@state/models/permission-role-user-association'
import PermissionRoleCompanyAssociation from '@state/models/permission-role-company-association'
import ScormFile from '@state/models/scorm-file'
import ScormFileCompanyAssociation from '@state/models/scorm-file-company-association'
import TableColumn from '@state/models/table-column'

import setThemeColors from '@utils/theme'
import ScormFileCompanyBooking from '@state/models/scorm-file-company-booking'
import ScormFileCategory from '@state/models/scorm-file-category'
import MenuFolderSetting from '@state/models/menu-folder-setting'

import global404Handler from '@utils/global-404-handler'

export const state = {
  navbarLoading: true,
  contentLoading: true,
  navTreeLoading: false,

  navTreeItems: [],
  navTreeOpen: [],

  navTreeActive: [],

  selectedApp: null,
  selectedCompany: null,

  licensePermissionRole: null,
  menuFoldersPermissionRole: null,
  dpdTopLevelFolders: null,

  currentRoute: null, // "helper" because $router is not reactive, only this.$route is, which is not accesible here
  showNavigationDrawer: true,
  showChecklistDrawer: false,
  showApprovalDrawer: false,
  showGuideChecklistDrawer: false,

  skipSetNavTreeOpenInAfterEach: false,
  navTreePromise: null,

  showCompanySelectFilter: false,
  adminSelectedCompanyLicenseFilterValues: [],

  hasSmallScreen: false,
  hasVerySmallScreen: false,

  initialFooterHeight: 32,
  initialNavbarHeight: 64,

  adminCompaniesLoaded: false,
  setSelectedCompanyPromise: null,

  navbarInitPromise: null,
  trainingInitPromise: null,

  isPartnerOfCompaniesIds: [],

  currentWindowWidth: null,
  contentLanguages: null,

  currentCompanyTreeLimit: 20,
}

const findCompanyItem = (companyId, _state) => {
  const findCompanyItemRecursive = (item) => {
    if (item.company && item.company.id === companyId) {
      return item
    }

    if (item?.children?.length) {
      let _item
      item.children.some((childItem) => {
        _item = findCompanyItemRecursive(childItem)
        return !!_item
      })
      return _item
    }
  }
  let companyItem
  _state.navTreeItems.some((i) => {
    companyItem = findCompanyItemRecursive(i)
    return !!companyItem
  })
  return companyItem
}

const findParentItemRecursive = (stateItem, parentItem) => {
  if (stateItem.nameId === parentItem.nameId) {
    return stateItem
  }

  if (stateItem.children && stateItem.children.length) {
    let _item = false
    stateItem.children.some((_i) => {
      _item = findParentItemRecursive(_i, parentItem)
      return !!_item
    })
    return _item
  }
}

export const getGroupedScormFiles = ({ scormFileCategories, scormFiles }) => {
  return orderBy(
    scormFileCategories.map((scormFileCategory) => {
      return {
        scormFileCategory,
        scormFiles: scormFiles.filter(
          (sf) =>
            (!sf.scorm_file_category_associations[0] && scormFileCategory.id === null) ||
            sf.scorm_file_category_associations[0]?.scorm_file_category_id === scormFileCategory.id
        ),
      }
    }),
    (obj) => ScormFileCategory.getSortAttrs()(obj.scormFileCategory)
  )
}

export const mutations = {
  RESET_NAVTREE_ITEMS(state) {
    state.navTreeItems = []
  },
  SET_NAVTREE_ITEMS(state, { navTreeItems, navTree = 'dpd', title }) {
    state.navTreeItems.push({
      name: navTree,
      items: navTreeItems,
      title,
    })
  },
  NAVTREE_ADD_OR_REPLACE(state, { item, parentItem, navTree = 'dpd' }) {
    const foundNavTree = state.navTreeItems.find((nt) => nt.name === navTree)
    const treeItems = foundNavTree.items

    let parentChildren
    if (parentItem?.nameId) {
      let stateParentItem = null
      treeItems.some((i) => {
        stateParentItem = findParentItemRecursive(i, parentItem)
        return stateParentItem || false
      })

      parentChildren = stateParentItem.children
    } else {
      parentChildren = treeItems
    }

    const existingItem = parentChildren.find((i) => i.nameId === item.nameId)
    if (existingItem) {
      parentChildren[parentChildren.indexOf(existingItem)] = item
    } else {
      parentChildren.push(item)
    }
  },
  SET_SELECTED_APP(state, { selectedApp }) {
    state.selectedApp = selectedApp
  },
  SET_NAVTREE_OPEN(state, { navTreeOpen }) {
    state.navTreeOpen = navTreeOpen
  },
  SET_NAVTREE_ACTIVE(state, { navTreeActive }) {
    state.navTreeActive = navTreeActive
  },
  SET_LICENSE_PERMISSION_ROLE(state, { licensePermissionRole }) {
    state.licensePermissionRole = licensePermissionRole
  },
  SET_MENU_FOLDERS_PERMISSION_ROLE(state, { menuFoldersPermissionRole }) {
    state.menuFoldersPermissionRole = menuFoldersPermissionRole
  },
  SET_DPD_TOP_LEVEL_FOLDERS(state, { dpdTopLevelFolders }) {
    state.dpdTopLevelFolders = dpdTopLevelFolders
  },
  SET_NAVBAR_LOADING(state, value) {
    state.navbarLoading = value
  },
  SET_NAV_TREE_LOADING(state, value) {
    state.navTreeLoading = value
  },
  SET_CONTENT_LOADING(state, value) {
    state.contentLoading = value
  },
  SET_CURRENT_ROUTE(state, currentRoute) {
    state.currentRoute = currentRoute
  },
  SET_SELECTED_COMPANY(state, selectedCompany) {
    state.selectedCompany = selectedCompany
  },
  SET_SHOW_NAVIGATION_DRAWER(state, showNavigationDrawer) {
    state.showNavigationDrawer = showNavigationDrawer
  },
  SET_SKIP_SET_NAVTREE_OPEN_IN_AFTER_EACH(state, skipSetNavTreeOpenInAfterEach) {
    state.skipSetNavTreeOpenInAfterEach = skipSetNavTreeOpenInAfterEach
  },
  SET_NAVTREE_PROMISE(state, navTreePromise) {
    state.navTreePromise = navTreePromise
  },
  SET_SHOW_COMPANY_SELECT_FILTER(state, showCompanySelectFilter) {
    state.showCompanySelectFilter = showCompanySelectFilter
  },
  SET_ADMIN_SELECTED_COMPANY_LICENSE_FILTER_VALUES(state, adminSelectedCompanyLicenseFilterValues) {
    state.adminSelectedCompanyLicenseFilterValues = adminSelectedCompanyLicenseFilterValues
  },
  SET_HAS_SMALL_SCREEN(state, hasSmallScreen) {
    state.hasSmallScreen = hasSmallScreen
  },
  SET_HAS_VERY_SMALL_SCREEN(state, hasVerySmallScreen) {
    state.hasVerySmallScreen = hasVerySmallScreen
  },
  SET_SHOW_APPROVAL_DRAWER(state, showApprovalDrawer) {
    state.showApprovalDrawer = showApprovalDrawer
  },
  SET_SHOW_GUIDE_CHECKLIST_DRAWER(state, showGuideChecklistDrawer) {
    state.showGuideChecklistDrawer = showGuideChecklistDrawer
  },
  SET_SHOW_CHECKLIST_DRAWER(state, showChecklistDrawer) {
    state.showChecklistDrawer = showChecklistDrawer
  },
  SET_INITIAL_NAVBAR_HEIGHT(state, navbarHeight) {
    state.initialNavbarHeight = navbarHeight
  },
  SET_INITIAL_FOOTER_HEIGHT(state, footerHeight) {
    state.initialFooterHeight = footerHeight
  },
  SET_ADMIN_COMPANIES_LOADED(state, adminCompaniesLoaded) {
    state.adminCompaniesLoaded = adminCompaniesLoaded
  },
  SET_SELECTED_COMPANY_PROMISE(state, setSelectedCompanyPromise) {
    state.setSelectedCompanyPromise = setSelectedCompanyPromise
  },
  SET_NAVBAR_INIT_PROMISE(state, navbarInitPromise) {
    state.navbarInitPromise = navbarInitPromise
  },
  SET_IS_PARTNER_OF_COMPANIES_IDS(state, isPartnerOfCompaniesIds) {
    state.isPartnerOfCompaniesIds = isPartnerOfCompaniesIds
  },
  SET_TRAINING_INIT_PROMISE(state, trainingInitPromise) {
    state.trainingInitPromise = trainingInitPromise
  },
  SET_CURRENT_WINDOW_WIDTH(state, currentWindowWidth) {
    state.currentWindowWidth = currentWindowWidth
  },
  SET_CONTENT_LANGUAGES(state, contentLanguages) {
    state.contentLanguages = contentLanguages
  },
  SET_CURRENT_COMPANY_TREE_LIMIT(state, currentCompanyTreeLimit) {
    state.currentCompanyTreeLimit = currentCompanyTreeLimit
  },
}

export const getters = {
  currentUser() {
    return store.getters['auth/currentUser']
  },
  currentLocale() {
    return store.getters['auth/currentLocale']
  },
  isAdmin() {
    return store.getters['auth/isAdmin']
  },
  isManager(state, getters) {
    return getters.currentUser?.is_manager
  },
  isMegaAdmin(state, getters) {
    const emailWhitelist = MEGA_ADMIN_EMAILS
    return (
      !!getters.currentUser &&
      getters.isAdmin &&
      emailWhitelist.indexOf(getters.currentUser.email) !== -1
    )
  },
  isDSKDemoAccount(state, getters) {
    const emailWhitelist = DEMO_MOCKUP_DSK_ACCOUNT
    return !!getters.currentUser && emailWhitelist.indexOf(getters.currentUser.email) !== -1
  },
  $t: (state) => {
    return i18n.global.t
  },
  companies(state, getters) {
    const mappedCompanies = orderBy(
      CompanyRole.query()
        .where((cr) => cr.user_id === getters.currentUser.id)
        .with('company')
        .with('company.child_companies')
        .all(),
      [(cr) => -cr.permission_role_types?.length, (cr) => cr.company?.name.toLowerCase()]
    )
      .filter((cr) => !!cr.company) // during some reloads, company is not available yet
      .map((cr) => {
        return cr.company
      })

    return mappedCompanies
  },
  companiesWithAdditionalParents(state, getters) {
    let companies = getters.allCompanies.filter((c) => !c.demo_import_loading)

    if (getters.showCompanySelectFilter) {
      companies = companies.filter(
        (c) => getters.adminSelectedCompanyLicenseFilterValues.indexOf(c.license_type) !== -1
      )
    }

    if (store.getters['auth/adminShowSelectedCompanyOnly'] && !!getters.selectedCompany) {
      companies = companies.filter((c) => c.id === getters.selectedCompany.id)
    }

    const companiesWithAdditionalParents = [...companies]
    const addParentCompaniesRecursive = (c) => {
      if (
        c.parent_company_id &&
        companiesWithAdditionalParents.map((_c) => _c.id).indexOf(c.parent_company_id) === -1
      ) {
        const parentCompany = Company.query().whereId(c.parent_company_id).first()

        if (parentCompany) {
          companiesWithAdditionalParents.push(parentCompany)
          addParentCompaniesRecursive(parentCompany)
        }
      }
    }

    companies.forEach(addParentCompaniesRecursive)

    return companiesWithAdditionalParents
  },
  companyTreeItems(state, getters) {
    const companiesWithAdditionalParents = getters.companiesWithAdditionalParents

    const mapChildCompanies = (parentCompany) => {
      return sortBy(
        companiesWithAdditionalParents
          .filter((c) => c.parent_company_id === parentCompany.id)
          .map(mapCompanyItem),
        'name'
      )
    }

    const mapCompanyItem = (company) => {
      const disabled =
        !getters.isAdmin &&
        !CompanyRole.allFast().find(
          (cr) => cr.company_id === company.id && cr.user_id === getters.currentUser.id
        )

      return {
        id: company.id,
        name: company.name,
        type: 'company',
        company,
        children: mapChildCompanies(company),
        disabled,
      }
    }

    const sortedTree = sortBy(
      companiesWithAdditionalParents.filter((c) => !c.parent_company_id).map(mapCompanyItem),
      'name'
    )

    return sortedTree /* .slice(0, state.currentCompanyTreeLimit) */
  },
  companyTreeOpen(state, getters) {
    const openItems = []

    const addOpenItemsRecursive = (items, parentItem) => {
      items.some((item) => {

        if (item.children?.length > 0 && item.disabled) {
          openItems.push(item.id)
        }

        if (parentItem && item.id === getters.selectedCompany.id) {
          openItems.push(parentItem.id)
          return false
        }

        const ret = addOpenItemsRecursive(item.children, item)
        if (ret === false) {
          return false
        }
      })

      return true
    }

    if (getters.selectedCompany) {
      addOpenItemsRecursive(getters.companyTreeItems)
    }

    return openItems
  },
  isPartnerOfCompaniesItems(state, getters) {
    return state.isPartnerOfCompaniesIds.map((companyId) => {
      const company = Company.query().whereId(companyId).first()
      return {
        company,
        isPartnerOfCompany: true,
        children: [],
      }
    })
  },
  myCompanyItems(state, getters) {
    const companyItems = []
    const mapCompanyItem = (companyItem) => {
      const company = companyItem.company
      return {
        text: company.name,
        value: company.id,
        company,
        isPartnerOfCompany: companyItem.isPartnerOfCompany || false,
      }
    }

    const addCompanyItem = (companyItem) => {
      companyItems.push(mapCompanyItem(companyItem))

      companyItem.children.forEach(addCompanyItem)
    }

    getters.companyTreeItems.forEach(addCompanyItem)

    if (getters.isPartnerOfCompaniesItems) {
      getters.isPartnerOfCompaniesItems.forEach(addCompanyItem)
    }

    const ret = companyItems.filter((ci) => ci.value !== getters.selectedCompany.id)
    return ret.filter((ci) => !!ci.company)
  },
  allCompanies(state, getters) {
    if (getters.isAdmin) {
      const sortedCompanies = sortBy(Company.query().all(), (c) =>
        DEFAULT_ADMIN_SELECTED_COMPANY_ID
          ? DEFAULT_ADMIN_SELECTED_COMPANY_ID === c.id
            ? -1
            : 0
          : 1
      )
      return sortedCompanies
    } else {
      return getters.companies
    }
  },
  navTreeItems(state) {
    return state.navTreeItems
  },
  navTreeOpen(state) {
    return state.navTreeOpen
  },
  navTreeActive(state) {
    return state.navTreeActive
  },
  getNavTreeCompanyItem(state) {
    return (companyId) => {
      return findCompanyItem(companyId, state)
    }
  },
  selectedCompany(state) {
    return state.selectedCompany
  },
  myCompanyRole(state, getters) {
    if (getters.selectedCompany) {
      return CompanyRole.query()
        .where(
          (cr) =>
            cr.user_id === getters.currentUser.id && cr.company_id === getters.selectedCompany.id
        )
        .first()
    }
  },
  selectedCompanyWithPermissionHelpers(state, getters) {
    const company = getters.selectedCompany
    let myCompanyRole

    if (company) {
      myCompanyRole = getters.myCompanyRole
    }

    return {
      company,
      myCompanyRole,
      meCanAccessProcessingDirectories:
        getters.isAdmin ||
        myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.DOCUMENTS) !== -1,
      meCanAccessScormFiles:
        getters.isAdmin ||
        myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.SCORM_FILES) !== -1,
      meCanAccessRollouts:
        getters.isAdmin ||
        myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.ROLLOUTS) !== -1 ||
        true,
      isCompanyAdmin:
        getters.isAdmin ||
        myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.COMPANY_ADMIN) !== -1,
      notSelectedChildren: [],
    }
  },
  selectedCompanyMeCanEdit(state, getters) {
    if (!!getters.selectedCompany && getters.currentUser.id) {
      if (getters.isAdmin) {
        return true
      }

      return (
        getters.myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.COMPANY_ADMIN) !==
        -1
      )
    }

    return false
  },
  brandSetting() {
    return BrandSetting.query().first()
  },
  appSelectItems(state, getters) {
    const items = []

    items.push({
      value: 'profile',
      text: getters.$t('profile.dashboard_text'),
      icon: 'fas fa-fw fa-user',
      to: {
        name: 'profile.dashboard',
      },
      hideInProfile: true,
    })

    let company = getters.selectedCompany

    if (!company) {
      if (getters.isAdmin) {
        company = getters.allCompanies[0]
      } else {
        company = getters.companies[0]
      }
    }

    if (company) {
      if (!getters.brandSetting || getters.brandSetting.enable_dpd) {
        const dpdItem = {
          value: 'dataProtectionDocumentation',
          text: getters.$t('generic.processing_directories'),
          icon: 'fas fa-fw fa-book',
          to: {
            name: 'documents.dashboard',
            params: {
              companyId: company.id,
            },
          },
          bgUrl: require('@/public/profile/dpd.webp'),
          disabled: !getters.selectedCompanyWithPermissionHelpers.meCanAccessProcessingDirectories,
        }

        items.push(dpdItem)
      }

      if (!getters.brandSetting || getters.brandSetting.enable_trainings) {
        const trainingItem = {
          value: 'training',
          text: getters.$t('admin.scorm_files.scorm_files'),
          icon: 'fas fa-fw fa-chalkboard-teacher',
          bgUrl: require('@/public/profile/training_scaled.webp'),
          to: {
            name: 'company.training',
            params: {
              companyId: company.id,
            },
          },
          disabled: !getters.selectedCompanyWithPermissionHelpers.meCanAccessScormFiles,
        }

        items.push(trainingItem)
      }

      const rolloutTo = {}

      if (
        getters.myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.ROLLOUTS) !== -1 ||
        getters.isAdmin
      ) {
        rolloutTo.name = 'rollouts.manager'
        rolloutTo.params = {
          companyId: company.id,
        }
      } else {
        rolloutTo.name = 'rollouts.assigned_rollouts'
        rolloutTo.params = {
          companyId: company.id,
        }
      }
      if (!getters.brandSetting || getters.brandSetting.enable_rollouts) {
        items.push({
          value: 'rollouts',
          text: getters.$t('rollouts.title'),
          icon: 'far fa-fw fa-mail-bulk',
          bgUrl: require('@/public/profile/rollout-mail.jpg'),
          to: rolloutTo,
          disabled: !getters.selectedCompanyWithPermissionHelpers.meCanAccessRollouts,
          nameId: 'rollouts.manager',
        })
      }
    }

    if (!getters.brandSetting || getters.brandSetting.enable_info_center) {
      items.push({
        value: 'infoCenter',
        text: getters.$t('info_center.info_center'),
        to: {
          name: 'infoCenter.external',
          params: {
            slug: 'home',
          },
        },
        exact: true,
        icon: 'fas fa-fw fa-info',
        // bgUrl: 'https://prima.planit.legal/icp/wp-content/uploads/2022/02/glossar-1.jpg',
        bgUrl: require('@/public/profile/info-center_scaled.jpg'),
      })
    }

    if (getters.isAdmin) {
      items.push({
        value: 'superadmin',
        text: getters.$t('admin.dashboard.dashboard'),
        to: { name: 'admin.dashboard' },
        icon: 'far fa-fw fa-wand-magic',
        bgUrl: require('@/public/profile/manager.jpg'),
        textClass: 'text-error',
        iconColor: 'error',
      })
    }

    if (!getters.isAdmin && getters.isManager) {
      items.push({
        value: 'manager',
        text: getters.$t('manager.manager_text'),
        to: { name: 'manager.dashboard' },
        icon: 'fas fa-fw fa-user-tie',
        bgUrl: require('@/public/profile/manager.jpg'),
        textClass: 'text-success',
        iconColor: 'success',
      })
    }

    if (company && getters.selectedCompanyMeCanEdit) {
      const companyItem = {
        value: 'company',
        text: company.name,
        icon: 'fas fa-fw fa-landmark',
        to: {
          name: 'company.dashboard',
          params: {
            companyId: company.id,
          },
        },
        hideInProfile: true,
        // disabled: !getters.selectedCompanyMeCanEdit,
      }

      items.push(companyItem)
    }

    items.forEach((item) => {
      if (item.bgUrl) {
        item.backgroundImageStyle =
          'radial-gradient(#ffffff00, #00000055 150%),' + 'url(' + item.bgUrl + ')'
      }
    })

    return items
  },
  rightAppSelectItems(state, getters) {
    const items = []

    items.push({
      value: 'gdpr',
      text: getters.$t('info_center.laws'),
      fn: () => {
        store.dispatch('global/setShowInfoCenter', !store.getters['global/showInfoCenter'])
      },
      icon: 'fas fa-fw fa-books',
      iconColor: 'primary',
    })

    if (true) {
      items.push({
        value: 'checklist',
        text: getters.$t('admin.guides.roadmap'),
        icon: 'fas fa-fw fa-clipboard-list-check',
        iconColor: 'primary',
        fn: () => {
          store.dispatch('navbar/setShowChecklistDrawer', !getters.showChecklistDrawer)
        },
      })
    }

    if (store.getters['currentDocument/document']) {
      items.push({
        value: 'document',
        text: getters.$t('components.review.assignments'),
        icon: 'fas fa-fw fa-tasks',
        iconColor: 'primary',
        fn: () => {
          const show = !store.getters['navbar/showApprovalDrawer']
          const documentId = store.getters['currentDocument/document']?.id
          if (documentId) {
            let rightDrawerClosed =
              JSON.parse(window.localStorage.getItem('rightDrawerClosed')) || {}
            if (show) {
              delete rightDrawerClosed[documentId]
            } else {
              rightDrawerClosed[documentId] = true
            }
            window.localStorage.setItem('rightDrawerClosed', JSON.stringify(rightDrawerClosed))
          }
          store.dispatch('navbar/setShowApprovalDrawer', show)
        },
      })
    }

    if (getters.hasGuideChecklist) {
      items.push({
        value: 'folder',
        text: getters.$t('admin.guides.guide_requests'),
        icon: 'fas fa-fw fa-mailbox',
        iconColor: 'primary',
        fn: () => {
          const show = !store.getters['navbar/showGuideChecklistDrawer']
          const folderId = store.getters['files/currentFolder'].id
          if (folderId) {
            let rightDrawerClosed =
              JSON.parse(window.localStorage.getItem('rightDrawerClosed')) || {}
            if (show) {
              delete rightDrawerClosed[folderId]
            } else {
              rightDrawerClosed[folderId] = true
            }
            window.localStorage.setItem('rightDrawerClosed', JSON.stringify(rightDrawerClosed))
          }
          store.dispatch('navbar/setShowGuideChecklistDrawer', show)
        },
      })
    }

    return items
  },
  hasGuideChecklist() {
    return (
      store.getters['files/topLevelFolder']?.menu_category_uuid === MenuCategoryUuid.REQUESTS &&
      store.getters['currentDocument/document'] &&
      store.getters['files/currentFolder'].checklist_step_folder_statuses.length > 0
    )
  },
  rightAppActive(state, getters) {
    if (store.getters['global/showInfoCenter']) {
      return 'gdpr'
    }

    if (store.getters['currentDocument/document'] && store.getters['navbar/showApprovalDrawer']) {
      return 'document'
    }

    if (store.getters['navbar/showChecklistDrawer']) {
      return 'checklist'
    }

    if (store.getters['navbar/showGuideChecklistDrawer']) {
      return 'folder'
    }
  },
  selectedApp(state) {
    return state.selectedApp
  },
  selectedAppItem(state, getters) {
    const ret =
      getters.selectedApp && getters.appSelectItems.find((i) => i.value === getters.selectedApp)
    return ret
  },
  instanceMode() {
    return process.env.INSTANCE_MODE || window.instance_mode || process.env.NODE_ENV
  },
  futureNavTreeOpen(state, getters) {
    const $route = state.currentRoute

    if ($route.name === null) {
      return []
    }

    const openCompanies = []
    const scormFileNames = []

    if (getters.selectedApp === 'training') {
      const companyId = parseInt($route.params.companyId)
      scormFileNames.push('scormFileList-' + companyId)

      if ($route.name.includes('marketplace')) {
        scormFileNames.push('company.marketplace')
      }
    }

    if (
      $route.name.includes('companyScormFile') ||
      $route.name.includes('companyScormParticipants')
    ) {
      const companyId = parseInt($route.params.companyId)
      scormFileNames.push('scormFiles-' + companyId)

      getters.scormFileCompanyAssociations.forEach((scormFileCompanyAssociation) => {
        scormFileNames.push(
          'scormFile-' + scormFileCompanyAssociation.scorm_file.id + '-' + companyId
        )
        if (!openCompanies.includes(parseInt(companyId))) {
          openCompanies.push(parseInt(companyId))
        }
      })
    }

    const additionalNames = []

    if ($route.name.includes('infoCenter')) {
      additionalNames.push('infoCenter.external.home')

      if ($route.params.slug) {
        const slugSplit = $route.params.slug.split('/')
        if (slugSplit[1]) {
          const previousSlugs = []
          slugSplit.forEach((slug) => {
            previousSlugs.push(slug)
            additionalNames.push('infoCenter.external.' + previousSlugs.join('.'))
          })
        } else {
          additionalNames.push('infoCenter.external.' + slugSplit[0])
        }
      }
    }

    if ($route.name.includes('admin.scormFile')) {
      additionalNames.push('admin.scormFiles')
    }

    const openItems = openCompanies.concat(scormFileNames).concat(additionalNames)

    // set existing items that are not in new + new
    return state.navTreeOpen.filter((i) => openItems.indexOf(i) === -1).concat(openItems)
  },
  scormFileCompanyAssociations(state, getters) {
    return ScormFileCompanyAssociation.allFast()
      .filter((sfca) => sfca.company_id === getters.selectedCompany.id)
      .map((sfca) => {
        return ScormFileCompanyAssociation.query()
          .whereId(sfca.id)
          .with('scorm_file')
          .with('scorm_file.scorm_file_category_associations')
          .with('scorm_file.scorm_file_category_associations.scorm_file_category')
          .first()
      })
  },
  scormFileCompanyBookings(state, getters) {
    return ScormFileCompanyBooking.query()
      .where((sfcb) => sfcb.company_id === getters.selectedCompany.id)
      .all()
  },
  marketplaceAvailableScormFiles(state, getters) {
    const perCompanyScormFiles = getters.scormFileCompanyAssociations.map((sfca) => sfca.scorm_file)
    const previewScormFileIds = perCompanyScormFiles
      .filter((sf) => !!sf.preview_scorm_file_id)
      .map((sf) => sf.preview_scorm_file_id)

    const globalScormFiles = ScormFile.query()
      .where((sf) => sf.availability === ScormFileAvailability.GLOBAL)
      .with('scorm_file_category_associations')
      .with('scorm_file_category_associations.scorm_file_category')
      .all()
    const filteredGlobalScormFiles = globalScormFiles.filter((sf) => {
      return previewScormFileIds.indexOf(sf.id) === -1
    })

    return filteredGlobalScormFiles.concat(perCompanyScormFiles)
  },
  scormFileCategories(state, getters) {
    return orderBy(ScormFileCategory.query().all(), 'position')
  },
  groupedAvailableScormFiles(state, getters) {
    return getGroupedScormFiles({
      scormFileCategories: getters.scormFileCategories,
      scormFiles: getters.marketplaceAvailableScormFiles,
    })
  },
  inScormSessionScormFiles(state, getters) {
    const trainingSessions = store.getters['trainingSession/trainingSessions']
    const trainingSessionScormFileIds = new Set(
      trainingSessions.flatMap((session) =>
        session.session_scorm_links.map((link) => link.scorm_file_id)
      )
    )

    const availableScormFiles = store.getters['scormFile/availableScormFiles']
    const filteredScormFiles = availableScormFiles.filter((scormFile) =>
      trainingSessionScormFileIds.has(scormFile.id)
    )

    const combinedScormFiles = [...filteredScormFiles]
    const uniqueScormFilesMap = new Map(combinedScormFiles.map((file) => [file.id, file]))
    const uniqueScormFiles = Array.from(uniqueScormFilesMap.values())
    if (!uniqueScormFiles.length && getters.myCompanyRole) {
      const crss = CompanyRoleScormStatus.query()
        .where('company_role_id', getters.myCompanyRole.id)
        .with('scorm_file.scorm_file_category_associations.scorm_file_category')
        .all()

      const scormFiles = crss.map((crss) => crss.scorm_file)

      return scormFiles.length ? scormFiles : []
    }

    return uniqueScormFiles
  },
  enabledAndInTrainingSessionScormFiles(state, getters) {
    const trainingSessions = store.getters['trainingSession/trainingSessions']
    const trainingSessionScormFileIds = new Set(
      trainingSessions
        .filter(
          (session) =>
            session.status === TrainingSessionStatus.ACTIVE &&
            session.training_session_company_role_associations.some((tscra) => {
              if (!getters.myCompanyRole) return false
              return tscra.company_role_id === getters.myCompanyRole.id
            })
        )
        .flatMap((session) => session.session_scorm_links.map((link) => link.scorm_file_id))
    )

    const availableScormFiles = store.getters['scormFile/availableScormFiles']
    const filteredScormFiles = availableScormFiles.filter((scormFile) =>
      trainingSessionScormFileIds.has(scormFile.id)
    )

    const combinedScormFiles = [...filteredScormFiles]
    const uniqueScormFilesMap = new Map(combinedScormFiles.map((file) => [file.id, file]))
    const uniqueScormFiles = Array.from(uniqueScormFilesMap.values())
    if (!uniqueScormFiles.length && getters.myCompanyRole) {
      const crss = CompanyRoleScormStatus.query()
        .where('company_role_id', getters.myCompanyRole.id)
        .with('scorm_file.scorm_file_category_associations.scorm_file_category')
        .all()

      const scormFiles = crss.map((crss) => crss.scorm_file)

      return scormFiles.length ? scormFiles : []
    }

    return uniqueScormFiles
  },
  groupedEnabledCompanyScormFiles(state, getters) {
    return getGroupedScormFiles({
      scormFileCategories: getters.scormFileCategories,
      scormFiles: getters.enabledAndInTrainingSessionScormFiles,
    })
  },
  dpdTopLevelFolders(state, getters) {
    return state.dpdTopLevelFolders
  },
  showCompanySelect(state, getters) {
    return getters.allCompanies.length
  },
  navbarPxHeight(state, getters) {
    return state.initialNavbarHeight
  },
  navbarHeight(state, getters) {
    return getters.navbarPxHeight + 'px'
  },
  navDrawerWidth() {
    return '23rem'
  },
  rightDrawerWidth(state, getters) {
    if (
      (store.getters['checklist/hasActiveListChecklist'] && !state.showApprovalDrawer) ||
      state.showGuideChecklistDrawer
    ) {
      return 'max(32rem, 30%)'
    } else {
      return 'max(32rem, 25%)'
    }
  },
  rightAppPanelWidth(state, getters) {
    return 54
  },
  footerPxHeight(state) {
    return store.getters['debug/hideDebugFooter'] || !store.getters['debug/showDebugInfo']
      ? state.initialFooterHeight
      : state.initialFooterHeight + 64
  },
  footerHeight(_, getters) {
    return getters.footerPxHeight && getters.footerPxHeight + 'px'
  },
  licensePermissionRole(state) {
    return state.licensePermissionRole
  },
  menuFoldersPermissionRole(state) {
    return state.menuFoldersPermissionRole
  },
  navbarLoading(state) {
    return state.navbarLoading
  },
  navTreeLoading(state) {
    return state.navTreeLoading
  },
  contentLoading(state) {
    return state.contentLoading
  },
  showProfile(state) {
    return state.currentRoute.matched.some((r) => r.meta?.belongsToApp === 'profile')
  },
  companyAppSelected(state, getters) {
    if (!getters.selectedApp) {
      return false
    }

    return (
      ['training', 'dataProtectionDocumentation', 'manager'].indexOf(getters.selectedApp) !== -1
    )
  },
  showNavigationDrawer(state, getters) {
    if (state.currentRoute?.name === 'demo') {
      return false
    } else if (state.currentRoute?.matched.some((r) => !!r.meta?.hideNavBar)) {
      return false
    } else if (getters.currentUser && !getters.currentUser.verified) {
      return false
    } else if (!getters.currentUser) {
      return false
    }

    return state.showNavigationDrawer
  },
  skipSetNavTreeOpenInAfterEach(state) {
    return state.skipSetNavTreeOpenInAfterEach
  },
  currentRoute(state) {
    return state.currentRoute
  },
  navTreePromise(state) {
    return state.navTreePromise
  },
  showApprovalDrawer(state) {
    if (state.currentRoute?.matched.some((r) => !!r.meta?.hideNavBar)) {
      return false
    }

    return state.showApprovalDrawer
  },
  showChecklistDrawer(state) {
    if (state.currentRoute?.matched.some((r) => !!r.meta?.hideNavBar)) {
      return false
    }

    return state.showChecklistDrawer
  },
  showGuideChecklistDrawer(state) {
    if (state.currentRoute?.matched.some((r) => !!r.meta?.hideNavBar)) {
      return false
    }
    return state.showGuideChecklistDrawer
  },
  showCompanySelectFilter(state) {
    return state.showCompanySelectFilter
  },
  adminSelectedCompanyLicenseFilterValues(state) {
    return state.adminSelectedCompanyLicenseFilterValues
  },
  hasSmallScreen(state) {
    return state.hasSmallScreen
  },
  hasVerySmallScreen(state) {
    return state.hasVerySmallScreen
  },
  dpdReadOnly(state, getters) {
    const ret =
      !!getters.selectedCompany &&
      store.getters['auth/activeLicenseType'] === LicenseType.FREE &&
      getters.selectedAppItem?.value === 'dataProtectionDocumentation'

    return ret
  },
  navbarInitPromise(state) {
    return state.navbarInitPromise
  },
  trainingInitPromise(state) {
    return state.trainingInitPromise
  },
  setSelectedCompanyPromise(state) {
    return state.setSelectedCompanyPromise
  },
  showRightDrawer(_, getters) {
    return (
      getters.showApprovalDrawer ||
      getters.showChecklistDrawer ||
      store.getters['global/showInfoCenter'] ||
      getters.showGuideChecklistDrawer
    )
  },
  contentLanguages(state) {
    return state.contentLanguages
  },
}

export const actions = {
  async init({ state, commit, dispatch, getters }) {
    if (window.maintenance === true) {
      commit('SET_NAVBAR_LOADING', false)
    }

    const savedShowNavigationDrawer = window.localStorage.getItem('showNavigationDrawer')
    if (savedShowNavigationDrawer !== null) {
      commit('SET_SHOW_NAVIGATION_DRAWER', JSON.parse(savedShowNavigationDrawer))
    }

    commit('SET_CURRENT_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener(
      'resize',
      debounce((v) => {
        if (v.currentTarget.innerWidth !== state.currentWindowWidth) {
          commit('SET_CURRENT_WINDOW_WIDTH', v.currentTarget.innerWidth)
          dispatch('setSmallScreenVariables')
        }
      }, 500)
    )
  },

  async reloadNavBar({ state, dispatch, getters, commit }) {},

  async setNavTreeItems(
    { state, dispatch, getters, commit },
    { skipSetContentLoading, skipSetContentLoadingFalse } = {}
  ) {
    /*
     * skipSetContentLoadingFalse is used to prevent content from loading e.g. while company
     * is changing but company page is open. company/index.vue created would trigger reload
     * with wrong company_id
     */

    commit('SET_NAV_TREE_LOADING', true)
    commit('RESET_NAVTREE_ITEMS')
    dispatch('setNavTreeOpen', { navTreeOpen: [] })

    const deletePromises = [
      FolderLink.deleteAll(),
      Document.deleteAll(),
      DocumentVersion.deleteAll(),
      File.deleteAll(),
      DocumentBlueprint.deleteAll(),
      ScormFile.deleteAll(),
      ScormFileCompanyAssociation.deleteAll(),
      ScormFileCompanyBooking.deleteAll(),
      CompanyRoleScormStatus.deleteAll(),
      TableColumn.deleteAll(),
    ]

    //ich weiß nicht wie ich die Folder daten sonst zum richtigen zeitpunkt laden soll,
    // wenn sie hier immer gelöscht werden.
    if (!state.showChecklistDrawer) {
      deletePromises.push(Folder.deleteAll())
    }

    await Promise.all(deletePromises)

    // Profile (+ no-app pages that have menu)
    if (getters.showProfile) {
      const profileItems = []

      profileItems.push({
        name: getters.$t('profile.dashboard_text'),
        nameId: 'profile.dashboard',
        to: { name: 'profile.dashboard' },
        icon: 'far fa-chart-line',
        color: 'text-grey-darken-4',
      })

      profileItems.push({
        name: getters.$t('profile.edit_profile'),
        nameId: 'profile.edit',
        to: { name: 'profile.edit' },
        icon: 'far fa-user-edit',
        color: 'text-grey-darken-4',
      })

      if (Order.allFast().some((o) => o.user_id === getters.currentUser.id)) {
        profileItems.push({
          name: getters.$t('components.nav_bar.payments'),
          nameId: 'profile.payments',
          to: { name: 'profile.payments' },
          icon: 'far fa-credit-card',
          color: 'text-grey-darken-4',
        })
      }

      commit('SET_NAVTREE_ITEMS', { navTreeItems: profileItems, navTree: 'profile' })

      const profileLicensesItems = []

      const activeLicensePermissionRoles = store.getters['auth/activeUserLicensePermissionRoles']

      if (activeLicensePermissionRoles) {
        activeLicensePermissionRoles.forEach((permissionRole) => {
          const license = permissionRole.licenses.find((l) => l.is_active)
          let licenseName = null

          const prca = PermissionRoleCompanyAssociation.allFast().find(
            (_prca) => _prca.permission_role_id === permissionRole.id
          )
          const firstCompanyId = prca?.company_id

          const firstCompanyName =
            firstCompanyId && !permissionRole.name_translations[getters.currentLocale]
              ? Company.find(firstCompanyId).name
              : null

          if (permissionRole.name_translations[getters.currentLocale]) {
            licenseName = permissionRole.name_translations[getters.currentLocale]
          }
          const routeName = getters.isAdmin ? 'admin.licenses' : 'manager.licenses'

          const routeTo =
            getters.isAdmin ||
            !!PermissionRoleUserAssociation.allFast().find(
              (prua) =>
                prua.user_id === getters.currentUser.id &&
                prua.permission_role_id === permissionRole.id
            )
              ? {
                  name: routeName,
                  state: {
                    search: firstCompanyName,
                    licenseType: license.license_type,
                    licenseId: license.id,
                  },
                }
              : {}

          profileLicensesItems.push({
            name: licenseName,
            description: firstCompanyName,
            nameId: routeName + '.' + license.id,
            to: routeTo,
            color: 'text-grey-darken-4',
            licenseType: license.license_type,
          })
        })
      }

      if (!getters.brandSetting || getters.brandSetting.enable_changelog) {
        const changelogProfileItems = []
        changelogProfileItems.push({
          name: getters.$t('changelog.name'),
          nameId: 'changelog',
          to: { name: 'changelog.list' },
          icon: 'far fa-newspaper',
          color: 'text-grey-darken-4',
        })

        if (getters.isAdmin) {
          changelogProfileItems.push({
            name: getters.$t('enums.changelog_type.blog'),
            nameId: 'blog',
            to: { name: 'admin.blog' },
            icon: 'far fa-blog',
            color: 'text-grey-darken-4',
          })
        }

        commit('SET_NAVTREE_ITEMS', {
          navTreeItems: changelogProfileItems,
          navTree: 'profileChangelog',
        })
      }
    }

    const items = []
    const company = getters.selectedCompany

    // Admin
    if (state.selectedApp === 'superadmin') {
      const adminItems = [
        {
          name: getters.$t('components.nav_bar.dashboard'),
          nameId: 'admin.dashboard',
          to: { name: 'admin.dashboard' },
          icon: 'fas fa-chart-line',
          iconColor: 'error',
        },
        {
          name: getters.$t('components.nav_bar.users'),
          nameId: 'admin.users',
          to: { name: 'admin.users' },
          icon: 'fas fa-users',
          iconColor: 'error',
        },
        {
          name: getters.$t('components.nav_bar.companies'),
          nameId: 'admin.companies',
          to: { name: 'admin.companies' },
          icon: 'fas fa-building',
          iconColor: 'error',
        },
      ]

      if (!getters.brandSetting || getters.brandSetting.enable_dpd) {
        adminItems.push(
          ...[
            {
              name: getters.$t('components.nav_bar.document_blueprints'),
              nameId: 'admin.documentBlueprints',
              to: { name: 'admin.documentBlueprints' },
              icon: 'fas fa-file-alt',
              iconColor: 'error',
            },
            {
              name: getters.$t('components.nav_bar.repositories'),
              nameId: 'admin.repositories',
              to: { name: 'admin.repositories' },
              icon: 'fas fa-album-collection',
              iconColor: 'error',
            },
            {
              name: getters.$t('components.nav_bar.menu_folders'),
              nameId: 'admin.folders',
              to: { name: 'admin.folders' },
              icon: 'fas fa-folder',
              iconColor: 'error',
            },
            {
              name: getters.$t('components.nav_bar.templates'),
              nameId: 'admin.prima.folder.templates',
              to: { name: 'admin.prima.folder.templates' },
              icon: 'fas fa-folders',
              iconColor: 'error',
            },
          ]
        )
      }

      if (!getters.brandSetting || getters.brandSetting.enable_trainings) {
        adminItems.push(
          ...[
            {
              name: getters.$t('admin.scorm_files.scorm_files'),
              nameId: 'admin.scormFiles',
              to: { name: 'admin.scormFiles' },
              icon: 'fas fa-graduation-cap',
              iconColor: 'error',
            },
          ]
        )
      }

      adminItems.push(
        ...[
          {
            name: getters.$t('admin.settings.settings_text'),
            nameId: 'admin.settings',
            to: { name: 'admin.settings' },
            icon: 'fas fa-sliders-h',
            iconColor: 'error',
          },
          {
            name: getters.$t('admin.licenses.licenses_text'),
            nameId: 'admin.licenses',
            to: { name: 'admin.licenses' },
            icon: 'fas fa-file-contract',
            iconColor: 'error',
          },
          {
            name: getters.$t('admin.permission_roles.permission_role_text'),
            nameId: 'admin.permissionRoles',
            to: { name: 'admin.permissionRoles' },
            icon: 'fas fa-user-tag',
            iconColor: 'error',
          },
          {
            name: 'Guides',
            nameId: 'admin.guides',
            to: { name: 'admin.guides' },
            icon: 'fas fa-clipboard-list-check',
            iconColor: 'error',
          },
        ]
      )

      items.push(...adminItems)

      commit('SET_NAVTREE_ITEMS', { navTreeItems: items, navTree: 'admin' })

      if (window.primary_instance) {
        const primaryInstanceItems = []

        primaryInstanceItems.push({
          name: getters.$t('admin.promotions.promotions_text'),
          nameId: 'admin.promotions',
          to: { name: 'admin.promotions' },
          icon: 'fas fa-gift',
          iconColor: 'error',
        })

        primaryInstanceItems.push({
          name: getters.$t('components.nav_bar.reminders'),
          nameId: 'admin.reminders',
          to: { name: 'admin.reminders' },
          icon: 'fas fa-alarm-clock',
          iconColor: 'error',
        })

        primaryInstanceItems.push({
          name: getters.$t('components.nav_bar.payments'),
          nameId: 'admin.payments',
          to: { name: 'admin.payments' },
          icon: 'fas fa-credit-card',
          iconColor: 'error',
        })

        primaryInstanceItems.push({
          name: 'Info-Center Admin',
          nameId: 'infoCenterAdmin',
          to: window.primaConfig.infoCenterUrl + '/wp-admin/',
          icon: 'fas fa-books',
          iconColor: 'error',
          external: true,
        })

        primaryInstanceItems.push({
          name: 'UI Translations',
          nameId: 'weblate',
          to: 'https://translate.prima.planit.legal/',
          icon: 'fas fa-language',
          iconColor: 'error',
          external: true,
        })

        if (window.audit_integration) {
          primaryInstanceItems.push({
            name: getters.$t('components.nav_bar.audit_projects'),
            nameId: 'admin.auditProjects',
            to: { name: 'admin.auditProjects' },
            icon: 'fas fa-file-alt',
            iconColor: 'error',
          })
          primaryInstanceItems.push({
            name: 'Audit Tool',
            nameId: 'adminAuditTool',
            to: { name: 'adminAuditTool' },
            icon: 'fas fa-eye',
            iconColor: 'error',
          })
        }

        commit('SET_NAVTREE_ITEMS', {
          navTreeItems: primaryInstanceItems,
          navTree: 'primaryInstance',
          title: getters.$t('admin.primary_instance_admin'),
        })

        if (getters.instanceMode !== 'demo') {
          const styleguideItems = [
            {
              name: 'Dashboard',
              nameId: 'styleguide.dashboard',
              to: { name: 'styleguide.dashboard' },
              icon: 'fas fa-window-alt',
              iconColor: 'error',
            },
            {
              name: 'Typical Page',
              nameId: 'styleguide.page',
              to: { name: 'styleguide.page' },
              icon: 'fas fa-file',
              iconColor: 'error',
            },
            {
              name: 'Typography',
              nameId: 'styleguide.typography',
              to: { name: 'styleguide.typography' },
              icon: 'fas fa-file',
              iconColor: 'error',
            },
            {
              name: 'Resources',
              nameId: 'styleguide.resources',
              to: { name: 'styleguide.resources' },
              icon: 'fas fa-link',
              iconColor: 'error',
            },
          ]

          commit('SET_NAVTREE_ITEMS', {
            navTreeItems: styleguideItems,
            navTree: 'styleguide',
            title: 'Styleguide',
          })
        }

        if (getters.isMegaAdmin) {
          const megaAdminItems = []
          megaAdminItems.push({
            name: 'Mega Admin',
            nameId: 'megaAdmin',
            to: { name: 'admin.megaAdmin' },
            icon: 'fas fa-hat-wizard',
            iconColor: 'pink',
          })

          megaAdminItems.push({
            name: getters.$t('components.nav_bar.prima_instances'),
            nameId: 'primaInstances',
            to: { name: 'admin.instances' },
            icon: 'fas fa-server',
            iconColor: 'pink',
          })

          megaAdminItems.push({
            name: getters.$t('admin.events.events_title'),
            nameId: 'events',
            to: { name: 'admin.events' },
            icon: 'fas fa-calendar-alt',
            iconColor: 'pink',
          })

          megaAdminItems.push({
            name: getters.$t('admin.table_columns.table_columns_text'),
            nameId: 'tableColumns',
            to: { name: 'admin.tableColumns' },
            icon: 'fas fa-columns',
            iconColor: 'error',
          })

          megaAdminItems.push({
            name: getters.$t('admin.user_reminders.user_reminders_title'),
            nameId: 'userReminders',
            to: { name: 'admin.userReminders' },
            icon: 'fas fa-alarm-clock',
            iconColor: 'pink',
          })

          megaAdminItems.push({
            name: 'Blueprint Debug',
            nameId: 'debugBlueprints',
            to: { name: 'admin.debugBlueprints' },
            icon: 'fas fa-books',
            iconColor: 'pink',
          })

          megaAdminItems.push({
            name: 'Download Resources',
            nameId: 'downloadResources',
            to: { name: 'admin.downloadResources' },
            icon: 'fas fa-file-download',
            iconColor: 'pink',
          })

          commit('SET_NAVTREE_ITEMS', {
            navTreeItems: megaAdminItems,
            navTree: 'megaAdmin',
            title: getters.$t('admin.mega_admin'),
          })
        }
      }
    } else if (state.selectedApp === 'manager') {
      const permissionRoleResult = await getters.currentUser.refreshSubResource(
        PermissionRoleUserAssociation
      )
      const pruas = permissionRoleResult.response.data

      const managerItems = []

      managerItems.push({
        name: getters.$t('components.nav_bar.dashboard'),
        nameId: 'manager.dashboard',
        to: { name: 'manager.dashboard' },
        icon: 'fas fa-chart-line',
        iconColor: 'success',
      })

      if (
        pruas.find(
          (prua) => prua.permission_role.permission_role_type === PermissionRoleType.LICENSE
        )
      ) {
        managerItems.push({
          name: getters.$t('admin.licenses.licenses_text'),
          nameId: 'manager.licenses',
          to: { name: 'manager.licenses' },
          icon: 'fas fa-file-contract',
          iconColor: 'success',
        })

        managerItems.push({
          name: getters.$t('admin.permission_roles.permission_role_text'),
          nameId: 'manager.permissionRoles',
          to: { name: 'manager.permissionRoles' },
          icon: 'fas fa-user-tag',
          iconColor: 'success',
        })
      }

      // Pro
      let hasProOrAboveLicense = false
      if (
        [LicenseType.PRO, LicenseType.RESELLER, LicenseType.PROFESSIONAL].indexOf(
          store.getters['auth/activeLicenseType']
        ) !== -1
      ) {
        hasProOrAboveLicense = true
      } else if (
        pruas.find(
          (prua) =>
            prua.permission_role.permission_role_type === PermissionRoleType.LICENSE &&
            !!prua.permission_role.licenses.find(
              (l) =>
                l.is_active &&
                [LicenseType.PRO, LicenseType.RESELLER, LicenseType.PROFESSIONAL].indexOf(
                  l.license_type
                ) !== -1
            )
        )
      ) {
        hasProOrAboveLicense = true
      }

      let hasBlueprintEditorPermission = true
      let hasMenuEditorPermission = true
      if (hasProOrAboveLicense) {
        // only check in this case, otherwise its already disabled
        hasBlueprintEditorPermission = pruas.some((prua) => {
          return (
            prua.permission_role.permission_role_type === PermissionRoleType.DOCUMENT_BLUEPRINTS
          )
        })

        hasMenuEditorPermission = pruas.some((prua) => {
          return prua.permission_role.permission_role_type === PermissionRoleType.MENU_FOLDERS
        })
      }

      managerItems.push({
        name: getters.$t('components.nav_bar.document_blueprints'),
        nameId: 'admin.documentBlueprints',
        to: { name: 'admin.documentBlueprints' },
        icon: 'fas fa-file-alt',
        iconColor: 'success',
        disabled: !hasProOrAboveLicense || !hasBlueprintEditorPermission,
        disabledLicenseTypeRequired: LicenseType.PRO,
      })

      managerItems.push({
        name: getters.$t('components.nav_bar.menu_folders'),
        nameId: 'admin.folders',
        to: { name: 'admin.folders' },
        icon: 'fas fa-folder',
        iconColor: 'success',
        disabled: !hasProOrAboveLicense || !hasMenuEditorPermission,
        disabledLicenseTypeRequired: LicenseType.PRO,
      })

      items.push(...managerItems)

      commit('SET_NAVTREE_ITEMS', { navTreeItems: items, navTree: 'manager' })
    } else if (state.selectedApp === 'infoCenter') {
      commit('SET_NAVTREE_ITEMS', { navTreeItems: [], navTree: 'infoCenter' })
      await dispatch('loadNavTreeChildren', { item: { type: 'infoCenter' } })
    } else if (state.selectedApp === 'company') {
      await dispatch('loadNavTreeChildren', { item: { type: 'company' } })
    } else if (
      state.selectedApp === 'training' ||
      state.selectedApp === 'dataProtectionDocumentation' ||
      state.selectedApp === 'rollouts'
    ) {
      if (!company && getters.allCompanies?.length) {
        await dispatch('setSelectedCompany', { selectedCompany: getters.allCompanies[0] })
        company = getters.selectedCompany
      }

      const myCompanyRole = CompanyRole.query()
        .where((cr) => cr.user_id === getters.currentUser.id && cr.company_id === company.id)
        .with('permission_role_company_role_associations')
        .with('permission_role_company_role_associations.permission_role')
        .with(
          'permission_role_company_role_associations.permission_role.permission_role_model_associations'
        )
        .with(
          'permission_role_company_role_associations.permission_role.permission_role_model_associations.document'
        )
        .with(
          'permission_role_company_role_associations.permission_role.permission_role_model_associations.file'
        )
        .with(
          'permission_role_company_role_associations.permission_role.permission_role_model_associations.folder'
        )
        .with(
          'permission_role_company_role_associations.permission_role.permission_role_model_associations.scorm_file'
        )
        .first()
      const meIsCompanyAdmin = getters.isAdmin || (myCompanyRole && myCompanyRole.is_company_admin)
      const meIsTrainingAdmin =
        getters.isAdmin || (myCompanyRole && myCompanyRole.is_training_admin)
      const meIsDocumentsAdmin =
        getters.isAdmin || (myCompanyRole && myCompanyRole.is_documents_admin)

      if (state.selectedApp === 'training') {
        // Scorm Files
        const trainingPromises = []

        trainingPromises.push(store.dispatch('scormFile/refreshAvailableScormFiles', { company }))

        trainingPromises.push(ScormFileCategory.$all())
        trainingPromises.push(company.refreshScormFileCompanyAssociations())
        trainingPromises.push(
          store.dispatch('trainingSession/initTrainingSessions', {
            company_id: company.id,
            company_role_id: myCompanyRole?.id,
          })
        )
        // if (myCompanyRole) {
        //   trainingPromises.push(myCompanyRole.refreshCompanyRoleScormStatus())
        // }

        if (meIsTrainingAdmin) {
          trainingPromises.push(company.refreshSubResource(ScormFileCompanyBooking))
        }

        const trainingInitPromise = Promise.allSettled(trainingPromises)
        commit('SET_TRAINING_INIT_PROMISE', trainingInitPromise)
        await trainingInitPromise

        if (
          getters.selectedCompanyWithPermissionHelpers.meCanAccessScormFiles &&
          getters.marketplaceAvailableScormFiles.length
        ) {
          // Set available categories as childs to marketplace
          const categoryItems = []

          getters.groupedAvailableScormFiles.forEach((scormFileGroup) => {
            if (scormFileGroup.scormFiles.length) {
              categoryItems.push({
                name: scormFileGroup.scormFileCategory.name,
                nameId:
                  'scormFileCategory-' + scormFileGroup.scormFileCategory.name + '-' + company.id,
                to: {
                  name: 'company.marketplace',
                  params: {
                    companyId: company.id,
                  },
                  query: {
                    category: scormFileGroup.scormFileCategory.name,
                  },
                },
                icon: scormFileGroup.scormFileCategory.icon
                  ? 'fas ' + scormFileGroup.scormFileCategory.icon
                  : 'fas fa-layer-group',
              })
            }
          })

          const trainingItems = []

          trainingItems.push({
            name: getters.$t('generic.overview'),
            nameId: 'company.training',
            to: { name: 'company.training', params: { companyId: company.id } },
            icon: 'fas fa-chart-line',
          })
          const scormFiles = getters.marketplaceAvailableScormFiles

          if (meIsTrainingAdmin) {
            if (categoryItems && !!scormFiles.some((sf) => sf.has_to_be_booked)) {
              trainingItems.push({
                name: getters.$t('generic.marketplace'),
                nameId: 'company.marketplace',
                to: { name: 'company.marketplace', params: { companyId: company.id } },
                icon: 'fas fa-store',
                children: categoryItems,
              })
            }

            trainingItems.push({
              name: getters.$t('components.nav_bar.participants'),
              nameId: 'scormParticipants-' + company.id,
              to: { name: 'companyScormParticipants', params: { companyId: company.id } },
              icon: 'fas fa-users',
            })
            trainingItems.push({
              name: getters.$t('company.scorm_file.training_planner'),
              nameId: 'training.trainingManager',
              newFeatureRibbon: true,
              to: { name: 'training.trainingManager', params: { companyId: company.id } },
              icon: 'fas fa-user-cog',
            })
          }
          trainingItems.push({
            name: getters.$t('components.nav_bar.my_training_session'),
            nameId: 'training.myTrainingSession',
            newFeatureRibbon: true,
            to: { name: 'training.myTrainingSession', params: { companyId: company.id } },
            icon: 'fas fa-calendar-alt',
          })

          commit('SET_NAVTREE_ITEMS', { navTreeItems: trainingItems, navTree: 'training' })
        }
      } else if (state.selectedApp === 'dataProtectionDocumentation') {
        const menuItems = []

        menuItems.push({
          name: getters.$t('components.nav_bar.dashboard'),
          nameId: 'documents.dashboard',
          to: { name: 'documents.dashboard', params: { companyId: company.id } },
          icon: 'fas fa-chart-line',
          iconColor: 'primary',
        })

        menuItems.push({
          name: getters.$t('prima_folder_templates.title'),
          nameId: 'documents.prima.folder.templates',
          to: { name: 'documents.prima.folder.templates', params: { companyId: company.id } },
          icon: 'fas fa-folders',
          iconColor: 'primary',
        })

        if (meIsDocumentsAdmin) {
          menuItems.push({
            name: getters.$t('components.nav_bar.repositories'),
            nameId: 'documents.repositories',
            to: { name: 'documents.repositories', params: { companyId: company.id } },
            icon: 'fas fa-album-collection',
            iconColor: 'primary',
          })
        }

        commit('SET_NAVTREE_ITEMS', { navTreeItems: menuItems, navTree: 'dpd' })

        await dispatch('loadNavTreeChildren', { item: { type: 'dataProtectionDocumentation' } })
      } else if (state.selectedApp === 'rollouts') {
        const menuItems = []

        if (
          getters.myCompanyRole?.permission_role_types.indexOf(PermissionRoleType.ROLLOUTS) !==
            -1 ||
          getters.isAdmin
        ) {
          menuItems.push({
            name: getters.$t('rollouts.manager'),
            nameId: 'rollouts.manager',
            to: { name: 'rollouts.manager', params: { companyId: company.id } },
            icon: 'far fa-mail-bulk',
            iconColor: 'primary',
          })
        }

        menuItems.push({
          name: getters.$t('rollouts.assigned_rollouts'),
          nameId: 'rollouts.assigned_rollouts',
          to: { name: 'rollouts.assigned_rollouts', params: { companyId: company.id } },
          icon: 'far fa-inbox-in',
          iconColor: 'primary',
        })

        commit('SET_NAVTREE_ITEMS', { navTreeItems: menuItems, navTree: 'rollouts' })
        await dispatch('loadNavTreeChildren', { item: { type: 'rollouts' } })
      }
    }

    // This cannot happen before loadNavTreeCompanies, otherwise vuetify thinks the open items don't exist
    // and overwrite navTreeOpenComputed with an empty list...
    // this.setNavTreeOpen()
    dispatch('setNavTreeOpen')
    dispatch('setNavTreeActive')

    commit('SET_NAV_TREE_LOADING', false)
  },

  navTreeAddOrReplace({ commit }, { item, parentItem, navTree }) {
    commit('NAVTREE_ADD_OR_REPLACE', { item, parentItem, navTree })
  },

  setNavTreeOpen({ commit, getters }, { navTreeOpen } = {}) {
    if (navTreeOpen === undefined) {
      navTreeOpen = getters.futureNavTreeOpen
    }

    commit('SET_NAVTREE_OPEN', { navTreeOpen })
  },

  insertFolder({ commit }, { folder, parentFolderId }) {
    commit('INSERT_FOLDER', { folder, parentFolderId })
  },
  async setSelectedApp({ commit, dispatch, getters }, { selectedApp, skipRedirect }) {
    const selectedAppChanged = selectedApp !== getters.selectedApp
    commit('SET_SELECTED_APP', { selectedApp })

    if (selectedApp) {
      // localStorage.setItem('selectedApp', selectedApp)

      const selectedAppItem = getters.appSelectItems.find((asi) => asi.value === selectedApp)

      const willRedirect =
        !skipRedirect &&
        selectedAppChanged &&
        selectedAppItem?.to &&
        selectedAppItem.to.name !== state.currentRoute.name

      if (
        selectedAppItem?.to?.params?.companyId &&
        (!getters.selectedCompany ||
          getters.selectedCompany.id !== selectedAppItem.to?.params?.companyId)
      ) {
        await dispatch('setSelectedCompany', {
          selectedCompany: getters.companies.find(
            (c) => c.id === selectedAppItem.to.params.companyId
          ),
          dontRedirect: !willRedirect,
          skipSetNavTreeItems: true,
        })
      }

      if (willRedirect) {
        await $router.push(selectedAppItem.to)
      }
    } else {
      localStorage.removeItem('selectedApp')
    }

    await dispatch('setNavTreeItems')

    commit('SET_NAVBAR_LOADING', false)
    commit('SET_CONTENT_LOADING', false)
  },
  async setSelectedCompany(
    { state, getters, commit, dispatch },
    { selectedCompany, dontRedirect, skipSetNavTreeItems, skipSetLoading }
  ) {
    if (state.setSelectedCompanyPromise) {
      return state.setSelectedCompanyPromise
    }

    const setSelectedCompanyAsync = async (resolve) => {
      // this might be called after/during checkSelectedApp/setNavTreeItems
      if (!skipSetNavTreeItems) {
        commit('SET_CONTENT_LOADING', true)
        commit('SET_NAV_TREE_LOADING', true)
      }

      const $route = state.currentRoute

      dontRedirect = dontRedirect || false
      skipSetNavTreeItems = skipSetNavTreeItems || false

      let licensePermissionRole = null
      let menuFoldersPermissionRole = null

      if (selectedCompany) {
        commit('SET_SELECTED_COMPANY', selectedCompany)

        selectedCompany.setThemeColor()

        localStorage.setItem('selectedCompanyId', selectedCompany.id)

        const selectedCompanyWithPermissionHelpers = getters.selectedCompanyWithPermissionHelpers
        const myCompanyRole = selectedCompanyWithPermissionHelpers.myCompanyRole
        const meCanAccessAuditProjects =
          selectedCompanyWithPermissionHelpers.meCanAccessAuditProjects
        const meCanAccessScormFiles = selectedCompanyWithPermissionHelpers.meCanAccessScormFiles

        // Load stuff and permissions
        const promises = []

        if (myCompanyRole && myCompanyRole.permission_role_types.length) {
          promises.push(myCompanyRole.refreshSubResource(PermissionRoleCompanyRoleAssociation))
        }

        promises.push(
          selectedCompany.refreshLicensePermissionRole().catch((error) => {
            console.error(error)
          })
        )

        if (window.audit_integration && meCanAccessAuditProjects) {
          promises.push(selectedCompany.refreshAuditProjects())
        }

        // moved to training
        /* if (meCanAccessScormFiles) {
          promises.push(ScormFile.$all())
          promises.push(selectedCompany.refreshScormFileCompanyAssociations())
          if (myCompanyRole) {
            promises.push(myCompanyRole.refreshCompanyRoleScormStatus())
          }
        } */

        promises.push(store.dispatch('checklist/refreshActiveChecklist'))

        if (selectedCompany.content_locales) {
          promises.push(dispatch('getContentLanguages'))
        }

        await Promise.all(promises)

        if (store.getters['auth/activeLicenseType'] === LicenseType.RESELLER) {
          const isPartnerOfCompaniesIds = []
          store.getters['auth/activeLicensePermissionRole'].licenses.forEach((license) => {
            const licenseWithResoldData = License.query().whereId(license.id).first()
            isPartnerOfCompaniesIds.push(
              ...licenseWithResoldData.resold_licenses.flatMap((resoldLicense) =>
                resoldLicense.permission_role.permission_role_company_associations.map(
                  (permissionRoleCompanyAssociation) => permissionRoleCompanyAssociation.company.id
                )
              )
            )
          })

          commit('SET_IS_PARTNER_OF_COMPANIES_IDS', isPartnerOfCompaniesIds)
        }

        // show checklist
        if (store.getters['checklist/hasActiveListChecklist'] && !state.hasVerySmallScreen) {
          dispatch('setShowChecklistDrawer', true)
        }

        // save license + permissionRole stuff to state
        licensePermissionRole = selectedCompany.getLicensePermissionRole()
        if (licensePermissionRole) {
          menuFoldersPermissionRole = PermissionRole.allFast().find(
            (pr) =>
              pr.license_permission_role_id === licensePermissionRole.id &&
              pr.permission_role_type === PermissionRoleType.MENU_FOLDERS
          )
        }
      } else {
        commit('SET_SELECTED_COMPANY', null)
        localStorage.setItem('selectedCompanyId', null)
      }

      if (
        !selectedCompany ||
        !(selectedCompany.custom_color_enabled || selectedCompany.custom_color_accent_enabled)
      ) {
        if (getters.brandSetting) {
          getters.brandSetting.setThemeColors()
        } else {
          setThemeColors()
        }
      }

      commit('SET_LICENSE_PERMISSION_ROLE', { licensePermissionRole })
      commit('SET_MENU_FOLDERS_PERMISSION_ROLE', { menuFoldersPermissionRole })

      // TODO: only needed for adminSelectedCompanies
      /* const notSelectedUpdatedCompanies = await Company.update({
        where: (company) => {
          return selectedCompanyIds.indexOf(company.id) === -1
        },
        data: { selected: false },
      })

      notSelectedUpdatedCompanies.forEach((c) => {
        c.syncToLocalStorage(['selected'])
      }) */

      if (!skipSetNavTreeItems) {
        await dispatch('setNavTreeItems', { skipSetContentLoadingFalse: true })
      }

      const replaceCurrentRouteCompanyId = async () => {
        await $router.push({
          ...getters.currentRoute,
          params: {
            ...getters.currentRoute.params,
            companyId: selectedCompany?.id,
          },
        })
      }

      if (!dontRedirect) {
        if (!selectedCompany && $route.name !== 'profile.dashboard') {
          await $router.push({ name: 'profile.dashboard' })
        } else if ($route.params.documentId || $route.params.folderId) {
          let companyId
          if ($route.params.documentId) {
            companyId = Document.find(parseInt($route.params.documentId))?.company_id
          } else if ($route.params.folderI) {
            companyId = Folder.find(parseInt($route.params.folderId))?.company_id
          }

          // if company isnt selected anymore
          if (selectedCompany.id !== companyId) {
            const selectedAppItem = getters.appSelectItems.find(
              (i) => i.value === state.selectedApp
            )
            if (selectedAppItem?.to) {
              await $router.push(selectedAppItem.to)
            } else {
              await $router.push({ name: 'profile.dashboard' })
            }
          }
        } else if ($route.params.companyId) {
          const companyId = parseInt($route.params.companyId)
          if (selectedCompany.id !== companyId) {
            if (getters.selectedCompanyWithPermissionHelpers.myCompanyRole) {
              await $router.push(
                getters.selectedCompanyWithPermissionHelpers.myCompanyRole.getPrimaryCompanyRoute()
              )
            } else {
              await replaceCurrentRouteCompanyId()
            }
          }
        }
      } else if (
        selectedCompany &&
        getters.currentRoute.params?.companyId !== undefined &&
        getters.currentRoute.params.companyId != selectedCompany.id
      ) {
        await replaceCurrentRouteCompanyId()
      }

      if (!skipSetNavTreeItems && !skipSetLoading) {
        commit('SET_NAV_TREE_LOADING', false)
        commit('SET_CONTENT_LOADING', false)
      }
    }

    const promise = setSelectedCompanyAsync()
    commit('SET_SELECTED_COMPANY_PROMISE', promise)

    const result = await promise
    commit('SET_SELECTED_COMPANY_PROMISE', null)

    return result
  },
  documentInNavTree({ state }, { documentId }) {
    const findItemByDocumentIdRecursive = (item) => {
      if (item.documentId === documentId) {
        return true
      }

      if (item.children) {
        return item.children.some(findItemByDocumentIdRecursive)
      }
    }

    const dpdNavTree = state.navTreeItems.find((nt) => nt.name === 'dpd')
    return dpdNavTree?.items.some(findItemByDocumentIdRecursive) || false
  },
  loadNavTreeChildren({ state, dispatch, getters, commit }, { item }) {
    commit('SET_SKIP_SET_NAVTREE_OPEN_IN_AFTER_EACH', true)

    const navTreePromise = (async () => {
      const company = getters.selectedCompany

      // static company items
      if (item.type === 'company' && getters.selectedCompanyMeCanEdit) {
        const staticCompanyItems = []
        if (!company.is_company_group) {
          staticCompanyItems.push(
            ...[
              {
                name: getters.$t('components.nav_bar.dashboard'),
                nameId: 'company.dashboard',
                to: { name: 'company.dashboard', params: { companyId: company.id } },
                icon: 'fas fa-chart-line',
              },
              {
                // name: getters.$t('com.nav_bar.users'),
                name: getters.$t('admin.company.people'),
                nameId: 'company.people',
                to: { name: 'company.people', params: { companyId: company.id } },
                icon: 'fas fa-users',
              },
            ]
          )
        }

        staticCompanyItems.push({
          name: getters.$t('company.company_data.company_data'),
          nameId: 'company.settings.company_data',
          to: { name: 'company.settings.company_data', params: { companyId: company.id } },
          icon: 'fas fa-database',
          color: 'text-grey-darken-4',
        })

        if (!company.is_company_group) {
          if (!getters.brandSetting || getters.brandSetting.enable_dpd) {
            staticCompanyItems.push(
              ...[
                {
                  name: getters.$t('company.organization.organization_text'),
                  nameId: 'company.settings.organization',
                  to: { name: 'company.settings.organization', params: { companyId: company.id } },
                  icon: 'fas fa-sitemap',
                  color: 'text-grey-darken-4',
                },
              ]
            )
          }

          staticCompanyItems.push(
            ...[
              {
                name: getters.$t('generic.settings'),
                nameId: 'company.settings',
                to: { name: 'company.settings.general', params: { companyId: company.id } },
                icon: 'fas fa-cog',
              },
            ]
          )
        }

        if (getters.isAdmin) {
          staticCompanyItems.push({
            name: getters.$t('generic.admin') + ' ' + getters.$t('generic.settings'),
            nameId: 'company.settings.adminSettings',
            to: { name: 'company.settings.adminSettings', params: { companyId: company.id } },
            icon: 'fas fa-wand-magic',
            iconColor: 'error',
          })
        }

        commit('SET_NAVTREE_ITEMS', { navTreeItems: staticCompanyItems, navTree: 'company' })
      } else if (item.type === 'infoCenter') {
        let infoCenterPages

        const iconRegex = /fas fa-[a-z-]+/g

        if (!item.hasChildren) {
          await store.dispatch('infoCenter/resolvePages')
          // infoCenterPages = cloneDeep(this.extInfoCenterPages)
          infoCenterPages = store.getters['infoCenter/pages']
        } else {
          infoCenterPages = item.childPages
        }

        if (infoCenterPages) {
          const childInfoCenterPromises = []
          infoCenterPages.forEach((current) => {
            if (!current.slug.includes('home')) {
              let nameId
              if (!current.fullSlug) {
                nameId = 'infoCenter.external.' + current.slug
              } else {
                nameId = 'infoCenter.external.' + current.fullSlug.split('/').join('.')
              }

              const childInfoCenterItem = {
                type: 'infoCenter',
                name: current.title.rendered,
                nameId,
                icon:
                  typeof current.page_icon !== 'undefined' && current.page_icon.match(iconRegex)
                    ? current.page_icon
                    : 'fas fa-info-square',
                openOnClick: true,
              }

              if (current.children) {
                childInfoCenterItem.children = []
                childInfoCenterItem.hasChildren = true
                childInfoCenterItem.childPages = current.children
              }

              if (current.meta._links_to != null && current.meta._links_to !== '') {
                childInfoCenterItem.external = true
                childInfoCenterItem.to = current.meta._links_to
              } else {
                childInfoCenterItem.to = '/info-center/' + (current.fullSlug || current.slug)
              }

              store.dispatch('navbar/navTreeAddOrReplace', {
                item: childInfoCenterItem,
                parentItem: item,
                navTree: 'infoCenter',
              })

              if (current.children != null) {
                childInfoCenterPromises.push(
                  store.dispatch('navbar/loadNavTreeChildren', {
                    item: childInfoCenterItem,
                    parentItem: item,
                  })
                )
              }
            }
          })

          await Promise.all(childInfoCenterPromises)
        }
      } else if (item.type === 'dataProtectionDocumentation') {
        await dispatch('setDpdTopLevelFolders')

        const $route = state.currentRoute

        let notExistingFolderId
        if ($route.params.folderId) {
          const existingFolder = Folder.find($route.params.folderId)
          if (
            !existingFolder ||
            (existingFolder?.parent_folder_id && !Folder.find(existingFolder.parent_folder_id))
          ) {
            notExistingFolderId = $route.params.folderId
          }
        } else if ($route.params.documentId) {
          let existingDocument = Document.find($route.params.documentId)

          if (!existingDocument || !existingDocument.primary_folder_id) {
            const existingDocumentResult = await Document.$find($route.params.documentId).catch(
              global404Handler
            )

            if (existingDocumentResult) {
              existingDocument = existingDocumentResult.response.data
            }
          }

          if (existingDocument && !Folder.find(existingDocument.primary_folder_id)) {
            notExistingFolderId = existingDocument.primary_folder_id
          }
        }

        if (notExistingFolderId) {
          await Folder.$find(notExistingFolderId, {
            params: {
              include_parent_folders: true,
              company_id: getters.selectedCompany.id,
            },
          })
        }
      }
    })()

    if (!state.navTreePromise) {
      commit('SET_NAVTREE_PROMISE', navTreePromise)

      navTreePromise.then(() => {
        commit('SET_NAVTREE_PROMISE', null)
      })
    }

    return navTreePromise
  },
  setNavTreeActive({ state, commit }) {
    const $route = state.currentRoute
    let activeItems = []

    const addFolder = (folderId, list) => {
      list.push('dbp-folder-' + $route.params.companyId + '-' + folderId)
    }

    if ($route.name.includes('documents.detail')) {
      const documentId = parseInt($route.params.documentId)
      const documentsMap = new Map(Document.allFast().map((d) => [d.id, d]))
      const initialFoldersMap = new Map(Folder.allFast().map((f) => [f.id, f]))

      // Nur Ordner mit ParentFolder MENU_GROUP
      const foldersMap = new Map(
        Array.from(initialFoldersMap.values())
          .filter((folder) => {
            const parentFolderType = initialFoldersMap.get(folder.parent_folder_id)?.folder_type
            return parentFolderType === FolderType.MENU_GROUP
          })
          .map((folder) => [folder.id, folder])
      )

      const document = documentsMap.get(documentId)
      let currentFolder = initialFoldersMap.get(document?.primary_folder_id)

      let blueprintMenuFolder = null
      //soweit hoch gehen bis currentFolder in der map ist.
      while (currentFolder && currentFolder.folder_type !== FolderType.MENU_GROUP) {
        if (currentFolder.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE) {
          if (foldersMap.has(currentFolder.id)) {
            blueprintMenuFolder = currentFolder
            break
          }
        }
        currentFolder = initialFoldersMap.get(currentFolder.parent_folder_id)
      }

      const folderNameIds = []
      if (blueprintMenuFolder) {
        addFolder(blueprintMenuFolder.id, folderNameIds)
      }
      activeItems = folderNameIds
    } else if ($route.name === 'documents.exports') {
      activeItems = ['exports' + $route.params.documentBlueprintId + '-' + $route.params.companyId]
    } else if ($route.name === 'company.folders') {
      let folderName = null

      const topLevelFolder = store.getters['files/topLevelFolder']

      if (topLevelFolder) {
        folderName = 'dbp-folder-' + $route.params.companyId + '-' + topLevelFolder.id
      }

      if (folderName) {
        activeItems = [folderName]
      }
    } else if ($route.name === 'documents.dashboard') {
      activeItems = ['documents.dashboard']
    } else if ($route.name === 'documents.overview') {
      activeItems = ['dbp-' + $route.params.documentBlueprintId + '-' + $route.params.companyId]
    } else if ($route.name === 'documents.templates') {
      activeItems = ['Templates' + $route.params.companyId]
    } else if ($route.name.includes('documents.repositories')) {
      activeItems = ['documents.repositories']
    } else if ($route.name === 'auditProject') {
      const auditProject = DocsAuditProject.query()
        .whereId(parseInt($route.params.docsAuditProjectId))
        .first()

      activeItems = ['audit-' + auditProject.id + '-' + auditProject.company_id]
    } else if (
      $route.name === 'companyScormFile.detail' ||
      $route.name === 'companyScormFile.settings'
    ) {
      activeItems = ['scormFile-' + $route.params.scormFileId + '-' + $route.params.companyId]
    } else if ($route.name.indexOf('company.marketplace') !== -1) {
      if (!!$route.query.category) {
        activeItems = ['scormFileCategory-' + $route.query.category + '-' + $route.params.companyId]
      } else {
        activeItems = ['company.marketplace']
      }
    } else if ($route.name.indexOf('company.') !== -1) {
      activeItems = [$route.name]

      if ($route.name.indexOf('data-sources') !== -1) {
        activeItems.push('company.data-sources')
      }

      if ($route.name.indexOf('settings.general') !== -1) {
        activeItems.push('company.settings')
      }
    } else if ($route.name === 'companyScormParticipants') {
      activeItems = ['scormParticipants-' + $route.params.companyId]
    } else if ($route.name.indexOf('infoCenter') !== -1 && !!$route.params.slug) {
      activeItems = []
      const slugSplit = $route.params.slug.split('/')

      if (slugSplit[1]) {
        activeItems.push('infoCenter.external.' + slugSplit.join('.'))
      } else {
        activeItems.push('infoCenter.external.' + slugSplit[0])
      }
    } else if ($route.name.indexOf('changelog') !== -1) {
      activeItems = ['changelog']
    } else if ($route.name.indexOf('blog') !== -1) {
      activeItems = ['blog']
    } else if ($route.name.indexOf('admin.') !== -1) {
      if ($route.name.indexOf('documentBlueprint') !== -1) {
        activeItems = ['admin.documentBlueprints']
      } else if ($route.name.includes('repositories')) {
        activeItems = ['admin.repositories']
      } else {
        // activeItems = [$route.name]
        activeItems = $route.matched.map((r) => r.name).filter(Boolean)
      }

      // parent exceptions where parent is not actually parent in routes
      if ($route.name.indexOf('admin.user') !== -1) {
        activeItems.push('admin.users')
      }

      if ($route.name.indexOf('admin.prima.folder.template') !== -1) {
        activeItems.push('admin.prima.folder.templates')
      }
    } else {
      // activeItems = [$route.name]
      activeItems = $route.matched.map((r) => r.name).filter(Boolean)
    }

    commit('SET_NAVTREE_ACTIVE', { navTreeActive: activeItems })
  },
  async setDpdTopLevelFolders({ state, getters, commit }) {
    const company = getters.selectedCompany

    // const dpdTopLevelFolders = []
    const menuFoldersPermissionRole = state.menuFoldersPermissionRole

    const folderRequestArgs = {
      blueprint_menu_structure: true,
      company_id: company.id,
      include_folder_links_with_uuids: true,
    }

    if (menuFoldersPermissionRole) {
      folderRequestArgs.permission_role_id = menuFoldersPermissionRole.id
    }

    const promises = [
      Folder.$all(folderRequestArgs),
      // company.resolveSubResource(Folder),
    ]
    if (menuFoldersPermissionRole) {
      const reQueriedMenuFoldersPermissionRole = PermissionRole.find(menuFoldersPermissionRole.id)
      promises.push(reQueriedMenuFoldersPermissionRole.refreshSubResource(MenuFolderSetting))
    }
    const results = await Promise.all(promises)

    let menuFolderSettings = []
    if (menuFoldersPermissionRole) {
      menuFolderSettings = results[1].response.data
    }

    const groupMenuFolders = results[0].response.data
      .filter((f) => {
        const corMenuFolderSetting = menuFolderSettings.find((mfs) => mfs.folder_id === f.id)
        return (
          !(corMenuFolderSetting?.hidden || f.hidden) &&
          !(corMenuFolderSetting?.parent_menu_folder_setting_id || f.parent_folder_id) &&
          f.folder_type === FolderType.MENU_GROUP
        )
      })
      .map((f) => {
        return results[0].entities.folders.find((_f) => _f.id === f.id)
      })

    groupMenuFolders.sort((a, b) => {
      if (menuFoldersPermissionRole) {
        const aSetting = menuFolderSettings.find((mfs) => mfs.folder_id === a.id)
        const bSetting = menuFolderSettings.find((mfs) => mfs.folder_id === b.id)

        return (
          (aSetting?.menu_position || a.menu_position) -
          (bSetting?.menu_position || b.menu_position)
        )
      } else {
        return a.menu_position - b.menu_position
      }
    })
    groupMenuFolders.forEach((menuFolder, index) => {
      const topLevelFolders = results[0].response.data
        .filter((f) => {
          if (menuFoldersPermissionRole) {
            const corMenuFolderSetting = menuFolderSettings.find((mfs) => mfs.folder_id === f.id)
            const corTopLevelMenuFolderSetting = menuFolderSettings.find(
              (mfs) => mfs.folder_id === menuFolder.id
            )

            const parentId =
              corMenuFolderSetting?.parent_menu_folder_setting_id || f.parent_folder_id
            const corMenuFolderId = corTopLevelMenuFolderSetting?.id || menuFolder.id
            return !(corMenuFolderSetting?.hidden || f.hidden) && parentId === corMenuFolderId
          } else {
            return !f.hidden && f.parent_folder_id === menuFolder.id
          }
        })
        .map((f) => {
          return results[0].entities.folders.find((_f) => _f.id === f.id)
        })

      const dpdTopLevelFolders = []
      orderBy(topLevelFolders, Folder.getSortAttrs()).forEach((folder) => {
        if (
          !getters.selectedCompany.is_eu_rep &&
          (folder.name === 'EU-Vertreter' || folder.name === 'EU Representative')
        ) {
          return false
        }

        if (!getters.selectedCompany.is_data_processor && folder.show_only_for_data_processors) {
          return false
        }

        if (!getters.selectedCompany.has_works_council && folder.show_only_for_works_council) {
          return false
        }

        dpdTopLevelFolders.push(folder.getMenuItem(company))
      })

      commit('SET_NAVTREE_ITEMS', {
        navTreeItems: dpdTopLevelFolders,
        navTree: 'menuGroup' + index,
      })
    })
  },
  setNavbarLoading({ commit }, value) {
    commit('SET_NAVBAR_LOADING', value)
  },
  setNavTreeLoading({ commit }, value) {
    commit('SET_NAV_TREE_LOADING', value)
  },
  setCurrentRoute({ commit }, currentRoute) {
    commit('SET_CURRENT_ROUTE', currentRoute)
  },
  addToNavTreeOpen({ commit, getters, dispatch }, { item }) {
    if (getters.navTreeOpen.indexOf(item.nameId) === -1) {
      const v = [...getters.navTreeOpen]
      v.push(item.nameId)
      dispatch('setNavTreeOpen', { navTreeOpen: v })
    }
  },
  checkSelectedApp({ state, getters, commit }) {
    let toBeSelectedApp = undefined

    let matchedBelongsToApps = []
    // Check whether selected app should be set or is correctly set
    // manager and admin might be matched at the same time, custom logic for that case
    if (state.currentRoute) {
      const reversedMatchedRoutes = [...state.currentRoute.matched].reverse()
      matchedBelongsToApps =
        reversedMatchedRoutes.find((r) => r.meta?.belongsToApp)?.meta.belongsToApp || []

      if (typeof matchedBelongsToApps === 'string') {
        matchedBelongsToApps = [matchedBelongsToApps]
      }
    }

    let matchedBelongsToApp
    if (matchedBelongsToApps.length === 1) {
      matchedBelongsToApp = matchedBelongsToApps[0]
    } else if (
      matchedBelongsToApps.indexOf('superadmin') !== -1 &&
      matchedBelongsToApps.indexOf('manager') !== -1
    ) {
      if (getters.isAdmin) {
        matchedBelongsToApp = 'superadmin'
      } else if (getters.currentUser.is_manager) {
        matchedBelongsToApp = 'manager'
      }
    }

    const shouldBeSelectedAppItem = store.getters['navbar/appSelectItems'].find(
      (i) => matchedBelongsToApp === i.value || i.to?.name === state.currentRoute?.name
    )

    // profile is not in appSelectItems
    if (matchedBelongsToApp === 'profile') {
      toBeSelectedApp = 'profile'
    } else if (matchedBelongsToApp === 'empty') {
      toBeSelectedApp = null
    } else if (
      shouldBeSelectedAppItem &&
      shouldBeSelectedAppItem.value !== store.getters['navbar/selectedApp']
    ) {
      toBeSelectedApp = shouldBeSelectedAppItem.value
    }

    if (toBeSelectedApp === undefined) {
      if (!store.getters['navbar/selectedApp'] && getters.selectedCompany) {
        toBeSelectedApp = getters.appSelectItems[0].value
      }
    }

    if (toBeSelectedApp !== undefined && toBeSelectedApp !== store.getters['navbar/selectedApp']) {
      store.dispatch('navbar/setSelectedApp', {
        selectedApp: toBeSelectedApp,
        skipRedirect: true,
      })
    } else {
      commit('SET_CONTENT_LOADING', false)
    }
  },
  async setShowNavigationDrawer({ commit, state }, showNavigationDrawer) {
    window.localStorage.setItem('showNavigationDrawer', showNavigationDrawer)

    if (
      state.hasVerySmallScreen &&
      (state.showChecklistDrawer || state.showApprovalDrawer || state.showGuideChecklistDrawer)
    ) {
      commit('SET_SHOW_APPROVAL_DRAWER', false)
      commit('SET_SHOW_CHECKLIST_DRAWER', false)
      commit('SET_SHOW_GUIDE_CHECKLIST_DRAWER', false)
      await app.$nextTick()
    }

    commit('SET_SHOW_NAVIGATION_DRAWER', showNavigationDrawer)
  },
  async initNavbar({ commit, dispatch, getters, state }, { skipCheckSelectedApp = false } = {}) {
    // load selected company from localStorage
    let localStorageSelectedCompanyId = localStorage.getItem('selectedCompanyId')
    // reset if not in available companies
    if (
      localStorageSelectedCompanyId &&
      getters.allCompanies.map((c) => c.id).indexOf(parseInt(localStorageSelectedCompanyId)) === -1
    ) {
      localStorageSelectedCompanyId = null
      localStorage.removeItem('selectedCompanyId')
    }

    // auto-select company if companyId in params
    // or only one company exists
    let toBeSelectedCompany = null

    if (state.currentRoute.params.companyId) {
      const companyId = parseInt(state.currentRoute.params.companyId)
      toBeSelectedCompany = Company.find(companyId)
      if (
        !toBeSelectedCompany ||
        (getters.currentUser &&
          !CompanyRole.allFast().find(
            (cr) => cr.company_id === companyId && cr.user_id === getters.currentUser.id
          ))
      ) {
        const result = await Company.$find(companyId)
        toBeSelectedCompany = result.entities.companies.find(
          (c) => c.id === result.response.data.id
        )
      }
    } else if (localStorageSelectedCompanyId) {
      const companyId = parseInt(localStorageSelectedCompanyId)
      toBeSelectedCompany = await Company.find(companyId)
      if (!toBeSelectedCompany) {
        const result = Company.$find(companyId)
        toBeSelectedCompany = result.entities.companies.find(
          (c) => c.id === result.response.data.id
        )
      }
    } else if (!getters.isAdmin && !getters.selectedCompany && getters.allCompanies.length > 0) {
      toBeSelectedCompany = getters.allCompanies[0]
    } else if (getters.isAdmin && getters.allCompanies.length === 0) {
      // admin has no company-roles. refresh companies to show dropdown
      await dispatch('adminRefreshCompanies')
    }

    // might already be set by beforeEach in router/index.js
    await dispatch('setSelectedCompany', {
      selectedCompany: toBeSelectedCompany,
      skipSetNavTreeItems: true,
      dontRedirect: true,
    })

    if (!skipCheckSelectedApp) {
      await dispatch('checkSelectedApp')
    }
  },
  adminRefreshCompanies({ state, commit }) {
    if (state.adminCompaniesLoaded) {
      return Promise.resolve()
    }

    return Company.$all({ include_license_type: true }).then((result) => {
      if (result.entities.companies) {
        result.entities.companies.forEach((c) => {
          c.fillFromLocalStorage()
        })
      }
      commit('SET_ADMIN_COMPANIES_LOADED', true)
    })
  },
  setSmallScreenVariables({ commit, state, getters, dispatch }) {
    const navbarEl = document.getElementById('planit-header')
    const footerEl = document.getElementById('planit-footer')

    if (navbarEl) {
      // when logging in via saml, navbarHeight is weird
      commit('SET_INITIAL_NAVBAR_HEIGHT', Math.max(navbarEl.getBoundingClientRect().height, 64))
    }

    if (footerEl) {
      commit('SET_INITIAL_FOOTER_HEIGHT', footerEl.getBoundingClientRect().height)
    } else {
      commit('SET_INITIAL_FOOTER_HEIGHT', 0)
    }

    const SMALL_SCREEN_BREAKPOINT = getters.showRightDrawer ? 1280 : 960
    const VERY_SMALL_SCREEN_BREAKPOINT = getters.showRightDrawer ? 960 : 768
    const hasSmallScreen = window.innerWidth <= SMALL_SCREEN_BREAKPOINT
    const hasVerySmallScreen = window.innerWidth <= VERY_SMALL_SCREEN_BREAKPOINT

    if (state.showNavigationDrawer && !state.hasSmallScreen && hasSmallScreen) {
      commit('SET_SHOW_NAVIGATION_DRAWER', false)
    } else if (!state.showNavigationDrawer && state.hasSmallScreen && !hasSmallScreen) {
      commit('SET_SHOW_NAVIGATION_DRAWER', true)
    }

    if (
      (getters.showChecklistDrawer ||
        getters.showApprovalDrawer ||
        getters.showGuideChecklistDrawer) &&
      !state.hasVerySmallScreen &&
      hasVerySmallScreen
    ) {
      /* commit('SET_SHOW_APPROVAL_DRAWER', false)
      commit('SET_SHOW_CHECKLIST_DRAWER', false)
      commit('SET_SHOW_GUIDE_CHECKLIST_DRAWER', false) */
      dispatch('closeAllRightDrawers')
    } else if (!getters.showChecklistDrawer && state.hasVerySmallScreen && !hasVerySmallScreen) {
      commit('SET_SHOW_CHECKLIST_DRAWER', !!store.getters['checklist/hasActiveListChecklist'])
    }

    commit('SET_HAS_SMALL_SCREEN', hasSmallScreen)
    commit('SET_HAS_VERY_SMALL_SCREEN', hasVerySmallScreen)
  },
  closeAllRightDrawers({ commit }) {
    if (state.hasSmallScreen) {
      if (state.showNavigationDrawer) {
        commit('SET_SHOW_NAVIGATION_DRAWER', false)
      }
    }

    if (state.showChecklistDrawer) {
      commit('SET_SHOW_CHECKLIST_DRAWER', false)
    }

    if (state.showApprovalDrawer) {
      commit('SET_SHOW_APPROVAL_DRAWER', false)
    }
    if (state.showGuideChecklistDrawer) {
      commit('SET_SHOW_GUIDE_CHECKLIST_DRAWER', false)
    }

    if (store.getters['global/showInfoCenter']) {
      store.commit('global/SET_INFO_CENTER_SEARCH_STRING', null)
      store.commit('global/SET_SHOW_INFO_CENTER', false)
    }
  },
  async setShowChecklistDrawer({ state, commit, dispatch }, value) {
    if (value !== state.showChecklistDrawer) {
      dispatch('closeAllRightDrawers')

      if (!store.getters['checklist/activeChecklist'] && !!value) {
        await store.dispatch('checklist/refreshChecklists')
        const checklist = store.getters['checklist/checklists'].find(
          (c) => c.uuid === '00a96971-2ffe-48bf-b0a8-51115e7a5eec'
        )
        if (checklist) {
          await checklist.start()
        }
      }

      commit('SET_SHOW_CHECKLIST_DRAWER', value)
      dispatch('setSmallScreenVariables')
    }
  },
  setShowApprovalDrawer({ state, commit, dispatch }, value) {
    if (value !== state.showApprovalDrawer) {
      dispatch('closeAllRightDrawers')

      commit('SET_SHOW_APPROVAL_DRAWER', value)
      dispatch('setSmallScreenVariables')
    }
  },
  setShowGuideChecklistDrawer({ state, commit, dispatch }, value) {
    if (value !== state.showGuideChecklistDrawer) {
      dispatch('closeAllRightDrawers')

      commit('SET_SHOW_GUIDE_CHECKLIST_DRAWER', value)
      dispatch('setSmallScreenVariables')
    }
  },
  async getContentLanguages({ commit }) {
    const contentLanguagesResponse = await axios.get('/api/available-languages')
    commit('SET_CONTENT_LANGUAGES', contentLanguagesResponse.data)

    return contentLanguagesResponse.data
  },
  loadMoreCompanyTreeItems({ state, commit, getters }) {
    const newLoadedCount = getters.companyTreeItems.length - state.currentCompanyTreeLimit

    commit('SET_CURRENT_COMPANY_TREE_LIMIT', state.currentCompanyTreeLimit + 20)

    return newLoadedCount
  },
}
