<script>
import PrimaInfoCenterTextHighlight from '@components/info-center/prima-info-center-text-highlight.vue'

export default {
  name: 'PrimaInfoCenterArticleCard',
  components: { PrimaInfoCenterTextHighlight },
  props: {
    article: {
      type: Object,
    },
    highlightQuery: {
      type: String,
    },
  },
  emits: ['click'],
}
</script>

<template>
  <div
    class="bg-white card-box-shadow border-radius pa-3 cursor-pointer"
    v-on:click="$emit('click', $event)"
  >
    <div class="text-primary">
      <span>{{ $t('info_center.art') }}. {{ article.attributes.no }}</span>
      <span> - </span>
      <prima-info-center-text-highlight
        :text="article.attributes.name"
        :query="highlightQuery"
        class="d-inline"
      />
    </div>

    <slot></slot>
  </div>
</template>
