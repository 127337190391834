import PlanitModel from './planit-model'
import File from './file'


export default class DownloadResource extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name_translations: this.attr(),
      slug_translations: this.attr(),
      image_translations: this.attr(),
      description_translations: this.attr(),
      brevo_link_translations: this.attr(),

      position: this.attr(),

      files: this.hasMany(File, 'download_resource_id'),

    }
  }

  uploadFile(formData, requestConfig) {
    requestConfig = requestConfig || {}

    return File.api().request({
      method: 'post',
      url: this.url() + File.$url(),
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      ...requestConfig,
    })
  }

  getDownloadUrlForFile(file, locale) {
    if (!this.slug_translations[locale]) {
      return ''
    }

    return (locale === 'en' ? '/en' : '') +
      ('/downloads/' + this.slug_translations[locale] + '/' + file.id)

  }

}

DownloadResource.entity = 'download-resources'
