import { orderBy } from 'lodash'

import store from '@state/store'

import PlanitModel from './planit-model'
import Section from './section'
import {
  DocumentBlueprintVersion,
  // SectionDocumentBlueprintAssociation,
} from './document-blueprint-version'

export default class Condition extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      viid: this.attr(),

      section_id: this.attr(),
      section: this.belongsTo(Section, 'section_id'),

      section_viid: this.attr(),

      condition_type: this.attr(),

      value: this.attr(),
      data: this.attr(),

      is_pending: this.attr(),

      target1_type: this.attr(),
      target2_type: this.attr(),
      inverted: this.attr(),

      section1_id: this.attr(),
      section1: this.belongsTo(Section, 'section1_id'),

      section1_viid: this.attr(),

      section2_id: this.attr(),
      section2: this.belongsTo(Section, 'section2_id'),

      section2_viid: this.attr(),

      condition1_id: this.attr(),
      condition2_id: this.attr(),

      condition1_viid: this.attr(),
      condition2_viid: this.attr(),

    }
  }

  static async update(config) {
    const newCondition = await super.update(config)

    await store.dispatch('blueprint/updateConditionInAllVersions', { condition: newCondition })

    return newCondition

  }

  async delete() {
    const corBooleanCondition1 = Condition.allFast().find((c) => c.condition1_viid === this.viid)
    if (corBooleanCondition1) {
      await Promise.all(
        Condition.allFast().filter(c => c.condition1_viid === corBooleanCondition1.viid).map(
          c => Condition.update({ where: c.id, data: { condition1_viid: null }})
        )
      )
      await Promise.all(
        Condition.allFast().filter(c => c.condition2_viid === corBooleanCondition1.viid).map(
          c => Condition.update({ where: c.id, data: { condition2_viid: null }})
        )
      )
      await Condition.delete(corBooleanCondition1.id)
    }

    const corBooleanCondition2 = Condition.allFast().find((c) => c.condition2_viid === this.viid)
    if (corBooleanCondition2) {
      await Promise.all(
        Condition.allFast().filter(c => c.condition1_viid === corBooleanCondition2.viid).map(
          c => Condition.update({ where: c.id, data: { condition1_viid: null }})
        )
      )
      await Promise.all(
        Condition.allFast().filter(c => c.condition2_viid === corBooleanCondition2.viid).map(
          c => Condition.update({ where: c.id, data: { condition2_viid: null }})
        )
      )
      await Condition.delete(corBooleanCondition2.id)
    }

    return super.delete()

  }
}

Condition.entity = 'conditions'
