import PlanitModel from './planit-model'
import ScormFile from './scorm-file'
import ScormFileCategory from '@state/models/scorm-file-category'


export default class ScormFileCategoryAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      scorm_file_category_id: this.attr(),
      scorm_file_category: this.belongsTo(ScormFileCategory, 'scorm_file_category_id')
    }
  }
}

ScormFileCategoryAssociation.entity = 'scorm-file-category-associations'
