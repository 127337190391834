import PlanitModel from './planit-model'
import Rollout from './rollout'
import User from './user'
export default class Recipient extends PlanitModel {
    static fields() {
      return {
        ...super.fields(),
        user_id: this.attr(),
        user: this.belongsTo(User, 'user_id'),
        email: this.attr(),
        rollout_id: this.attr(),
        rollout: this.belongsTo(Rollout, 'rollout_id'),
        rollout_code: this.attr(),
        has_confirmed: this.attr(),
        confirmation_date: this.attr(),
      }
    }
    static confirm(code) {
      return this.api().patch(this.entity + '/code/' + code + '/confirm')
    }

    static getByCode(code, email_as_password) {
      if (email_as_password) {
        const params = {
          email_as_password: email_as_password
        }
        return this.api().get(this.entity + '/code/' + code, { params })
      } else {
        return this.api().get(this.entity + '/code/' + code)
      }
    }
}

Recipient.entity = 'recipients'