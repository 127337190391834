import PlanitModel from './planit-model'
import Company from './company'
import PermissionRole from './permission-role'

export default class PermissionRoleCompanyAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),
    }
  }
}

PermissionRoleCompanyAssociation.entity = 'permission-role-company-associations'
