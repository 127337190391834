const MAX_SECTIONS_PER_LEVEL = 1000
const DIGITS_PER_LEVEL = Math.ceil(Math.log10(MAX_SECTIONS_PER_LEVEL))

export default function getSectionPositionForSorting(fullPosition) {
  const positionPerLevel = fullPosition.split('.')
  const outPositionsPerLevel = []

  positionPerLevel.forEach((pos) => {
    let position = ''
    // fill leading zeroes
    for (let i = 0; i < DIGITS_PER_LEVEL - pos.length; i++) {
      position += '0'
    }

    position += pos
    outPositionsPerLevel.push(position)
  })

  return outPositionsPerLevel.join('.')
}
