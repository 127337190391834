import axios from 'axios'

import store from '@state/store'
import PlanitModel from './planit-model'
import DocumentBlueprint from './document-blueprint'
import Company from './company'
import TableColumnSettingAssociation from './table-column-setting-association'
import User from './user'


export default class TableColumn extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name_translations: this.attr(),
      name: this.attr(),
      description: this.attr(),
      column_type: this.attr(),

      blueprint_uuid: this.attr(),
      blueprint: this.belongsTo(DocumentBlueprint, 'blueprint_uuid', 'uuid'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      section_viid: this.attr(),
      section_text: this.attr(),

      table_column_setting_associations: this.hasMany(TableColumnSettingAssociation, 'table_column_id'),

      is_sortable: this.attr(),

      // locale fields
      current_locale: this.attr(),
    }
  }
  async getTableSettingsWhereColumnIsReferred() {
    return await axios.get('/api' + this.url() + `/table_settings`)
  }

  async getSection() {
    return await axios.get('/api' + this.url() + '/section')
  }
}

TableColumn.entity = 'table-columns'
