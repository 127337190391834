<script>
import PlanitInput from '@components/form/input'

import CompanyPersonType from '@state/models/company-person-type'
import store from '@state/store'


export default {
  name: 'AddressEditModal',
  components: { PlanitInput },
  props: {
    modelValue: { type: Object, required: true },
    open: { type: Boolean, required: false, default: true },
    title: { type: String, required: false, default: null },
    companyPerson: { type: Object, required: false, default: null },
    disableEditCompanyPersonType: { type: Boolean, required: false, default: false },
  },
  emits: ['close'],
  data: function () {
    return {}
  },
  computed: {
    titleComputed() {
      return this.title || this.$t('generic.address')
    },
    openComputed: {
      get() {
        return this.open
      },
      set() {
        this.afterSave()
      },
    },
    companyPersonTypeSelectItems() {
      return CompanyPersonType.all().map((cpt) => {
        return {
          text: cpt.name || this.$t('enums.company_person_type_type.' + cpt.type),
          value: cpt.id,
        }
      })
    },
  },
  created() {},
  methods: {
    async afterSave() {
      await store.dispatch('syncQueue/handleQueueNow')

      this.$emit('close')
    },
  },
}
</script>

<template>
  <div>
    <PlanitDialog
      v-model="openComputed"
      width="50rem"
    >
      <div
        v-if="modelValue"
        class="pa-6 bg-white border-radius"
      >
        <div class="d-flex align-center justify-space-between mb-6">
          <h1>{{ titleComputed }}</h1>

          <PlanitButton
            variant="text"
            class="icon-btn"
            @click="afterSave"
          >
            <PlanitIcon icon="fal fa-times" />

          </PlanitButton>
        </div>

        <v-row v-if="companyPerson">
          <v-col sm="12" md="6">
            <PlanitInput
              v-if="companyPerson.company_role && companyPerson.company_role.user_id"
              :model="companyPerson.company_role.user"
              attr="name"
              :label="$t('generic.name')"
              highlight
              class="mb-4"
              disabled
            ></PlanitInput>

            <PlanitInput
              v-else
              :model="companyPerson"
              attr="text"
              :label="$t('generic.name')"
              highlight
              class="mb-4"
            ></PlanitInput>
          </v-col>
          <v-col sm="12" md="6">
            <PlanitInput
              :model="companyPerson"
              attr="company_person_type_id"
              :label="$t('company.organization.company_person_type')"
              type="select"
              :select-items="companyPersonTypeSelectItems"
              highlight
              class="mb-4"
              :disabled="disableEditCompanyPersonType"
            ></PlanitInput>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col sm="12" md="6">
            <PlanitInput
              :model="modelValue"
              attr="street_line_1"
              :label="$t('generic.street_line', { n: 1 })"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="street_line_2"
              :label="$t('generic.street_line', { n: 2 })"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="postal_code"
              :label="$t('generic.postal_code')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="city"
              :label="$t('generic.city')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="state"
              :label="$t('generic.state')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="country"
              :label="$t('generic.country')"
              highlight
              class="mb-4"
            ></PlanitInput>
          </v-col>
          <v-col sm="12" md="6">
            <PlanitInput
              :model="modelValue"
              attr="phone"
              :label="$t('generic.phone')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="mobile"
              :label="$t('generic.mobile')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="email"
              :label="$t('generic.email')"
              highlight
              class="mb-4"
            ></PlanitInput>

            <PlanitInput
              :model="modelValue"
              attr="additional"
              :label="$t('generic.additional_data')"
              highlight
              class="mb-4"
            ></PlanitInput>
          </v-col>
        </v-row>

        <div class="d-flex justify-end">
          <PlanitButton
            class="mr-4"
            color="primary"
            @click="afterSave"
          >
            <span>{{ $t('generic.save') }}</span>
          </PlanitButton>
        </div>
      </div>
    </PlanitDialog>
  </div>
</template>
