import PlanitModel from './planit-model'
import Folder from './folder'
import Tag from './tag'

export default class FolderTagAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      tag_id: this.attr(),
      tag: this.belongsTo(Tag, 'tag_id'),

    }
  }

  static createFolderTagAssociation(data) {
    return FolderTagAssociation.api().post('folder-tag-associations', { folder_id: data.folder_id, tag_id: data.tag_id })
  }
}

FolderTagAssociation.entity = 'folder-tag-associations'