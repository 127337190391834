<script>
import PrimaInfoCenterArticleCard from '@components/info-center/prima-info-center-article-card.vue'
import PrimaInfoCenterTextHighlight from '@components/info-center/prima-info-center-text-highlight.vue'

export default {
  name: 'PrimaInfoCenterChapterPanel',
  components: { PrimaInfoCenterTextHighlight, PrimaInfoCenterArticleCard },
  props: {
    chapter: {
      type: Object,
    },
    highlightQuery: {
      type: String,
    },
  },
  emits: ['article-click'],
  computed: {
    minArticleNo() {
      const articles = this.chapter.attributes.articles.data
      if (articles.length) {
        return articles[0].attributes.no
      }

      const subchapters = this.chapter.attributes.subchapters.data
      if (subchapters.length) {
        const articles = subchapters[0].attributes.articles.data
        if (articles.length) {
          return articles[0].attributes.no
        }
      }

      return 0
    },
    maxArticleNo() {
      const articles = this.chapter.attributes.articles.data
      if (articles.length) {
        return articles[articles.length - 1].attributes.no
      }

      const subchapters = this.chapter.attributes.subchapters.data
      if (subchapters.length) {
        const articles = subchapters[subchapters.length - 1].attributes.articles.data
        if (articles.length) {
          return articles[articles.length - 1].attributes.no
        }
      }

      return 0
    },
  },
}
</script>

<template>
  <v-expansion-panel static class="background-none d-flex flex-column ga-1">
    <v-expansion-panel-title class="bg-white card-box-shadow border-radius pa-3">
      <div class="text-primary">
        <div class="mb-1">
          <span>{{ $t('info_center.chapter') }} {{ chapter.attributes.no }}</span>
          <span>&nbsp;</span>
          <span>({{ $t('info_center.art') }}. {{ minArticleNo }} &ndash; {{ maxArticleNo }})</span>
        </div>
        <prima-info-center-text-highlight
          :text="chapter.attributes.name"
          :query="highlightQuery"
          class="d-inline font-weight-bold"
        />
      </div>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <div class="d-flex flex-column ga-1">
        <prima-info-center-article-card
          v-for="article in chapter.attributes.articles.data"
          :key="article.id"
          :article="article"
          @click="$emit('article-click', article)"
        />

        <div
          v-for="subchapter in chapter.attributes.subchapters.data"
          :key="subchapter.id"
          class="d-flex flex-column ga-1"
        >
          <div class="bg-white card-box-shadow border-radius pa-3">
            {{ subchapter.attributes.name }}
          </div>

          <div
            v-for="article, index in subchapter.attributes.articles.data"
            :key="'article-' + index"
          >
            <prima-info-center-article-card
              class="ml-2"
              :article="article"
              @click="$emit('article-click', article)"
            />
          </div>
        </div>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>
