<script>

import { offset } from '@floating-ui/dom'

import 'shepherd.js/dist/css/shepherd.css'
import Shepherd from 'shepherd.js'

import ChecklistStep from '@components/checklist-step'
import PlanitProgress from '@components/planit-progress'

import {
  ChecklistMode,
} from '@enums'

import store from '@state/store'
import ChecklistStepModel from '@state/models/checklist-step'
import User from '@state/models/user'
import { method } from 'lodash'


export default {
  name: 'Checklist',
  props: {
    checklist: { type: Object, required: true },
  },
  components: {
    ChecklistStep,
    PlanitProgress,
  },
  data() {
    return {
      ChecklistMode,

      loading: true,

      tour: null,
    }
  },
  computed: {
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    currentUser() {
      return User.find(store.getters['auth/currentUser']?.id)
    },
    sortedToplevelSteps() {
      return this.checklist.getSortedToplevelSteps()
    },
    showNavigationDrawer() {
      return store.getters['navbar/showNavigationDrawer']
    },
    targetDomLoaded() {
      return store.getters['checklist/targetDomLoaded']
    },
    company() {
      return store.getters['navbar/selectedCompany']
    },
  },
  async mounted() {
    await this.handleCompanyContext()
    await this.handleChecklistSteps()
    await this.handleChecklistStart()
    this.loading = false
    this.setupTour()
  },
  methods: {
    async handleCompanyContext() {
      if (this.checklist.needs_company_context && !this.company) {
        await this.checklist.start()
      }
    },

    async handleChecklistSteps() {
      if (this.checklist.checklist_steps.length === 0) {
        const params = {}
        if (this.checklist.needs_company_context) {
          params.company_id = this.company.id
        }
        await this.checklist.refreshSubResource(ChecklistStepModel, { params })
      }
    },

    async handleChecklistStart() {
      if (!this.checklist.checklist_steps.some(cs => cs.status)) {
        await this.checklist.start()
      }
    },

    setupTour() {
      const TOUR_ENABLED = true
      if (TOUR_ENABLED && this.checklist.checklist_mode === ChecklistMode.TOUR) {
        const steps = this.sortedToplevelSteps
        this.$nextTick(() => {
          this.initializeTour()
          this.addTourSteps(steps)
          this.tour.start()
        })
      }
    },

    initializeTour() {
      this.tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          cancelIcon: {
            enabled: true,
            exitOnEsc: true,
          },
          classes: 'shephard-planit-tour',
          scrollTo: { behavior: 'smooth', block: 'nearest' },
          floatingUIOptions: {
            middleware: [offset(12)],
          },
        }
      })

      Shepherd.on('complete', async () => {
        if (this.checklist.id === this.currentUser.active_checklist_id) {
          await this.currentUser.updateAttr({ active_checklist_id: null })
          await this.currentUser.sync('active_checklist_id')
        }
      })
    },

    addTourSteps(steps) {
      const addStepFromChecklistStep = (checklistStep) => {
        const index = steps.indexOf(checklistStep)
        const buttons = this.createStepButtons(index, steps.length)
        const stepOptions = this.createStepOptions(checklistStep, buttons)

        this.tour.addStep(stepOptions)

        const childSteps = checklistStep.getSortedChildChecklistSteps()
        if (childSteps) {
          childSteps.forEach(addStepFromChecklistStep)
        }
      }

      steps.forEach(addStepFromChecklistStep)
    },

    createStepButtons(index, stepsLength) {
      const $t = this.$t.bind(this)
      const buttons = []

      if (index > 0) {
        buttons.push({
          action() {
            return this.back()
          },
          classes: 'v-btn v-btn--density-default v-btn--size-small v-theme--light v-btn--variant-outlined text-primary bg-transparent',
          text: $t('generic.back'),
        })
      }

      buttons.push({
        async action() {
          return this.next()
        },
        classes: 'v-btn v-theme--light v-btn--density-default v-btn--size-small bg-primary',
        text: index < stepsLength - 1 ? $t('generic.next') : $t('components.checklist.finish_and_hide'),
      })

      return buttons
    },

    createStepOptions(checklistStep, buttons) {
      const _this = this

      const stepOptions = {
        title: checklistStep.getName(this.currentLocale),
        text: checklistStep.getDescription(this.currentLocale),
        buttons,
        id: 'step-' + checklistStep.id,
        beforeShowPromise() {
          return new Promise((resolve) => {
            const checklistStepRouterLinkTo = checklistStep.getRouterLinkTo()
            checklistStep.checkLinkStep({ includeCompanyContext: _this.checklist.needs_company_context }).then(() => {
              _this.waitForTargetDomLoaded(_this, resolve, checklistStepRouterLinkTo)
            })
          })
        },
        when: {
          show() {
            _this.handleTourStepShow(_this)
          },
          cancel() {
            store.dispatch('checklist/cancelActiveChecklist')
          },
        },
        ...(checklistStep.data?.tour_params || {}),
      }

      if (Object.keys(checklistStep.description_translations).length) {
        stepOptions.text = checklistStep.getDescription(this.currentLocale)
      }

      return stepOptions
    },

    waitForTargetDomLoaded(_this, resolve, checklistStepRouterLinkTo) {
      const _router = this.$router

      const waitForTargetDomLoaded = (_resolve) => {
       // store.commit('checklist/SET_TARGET_DOM_LOADED', false)

        const interval = setInterval(() => {
          if (_this.targetDomLoaded) {
            _resolve()
            clearInterval(interval)
          }
        }, 200)
      }

      if (checklistStepRouterLinkTo) {
        if (checklistStepRouterLinkTo.name !== _router.currentRoute.name) {
          store.commit('checklist/SET_TARGET_DOM_LOADED', false)
          _router.push(checklistStepRouterLinkTo).then(() => {
            const currentRoute = _router.currentRoute.value // apparently currentRoute is a RefImpl wrapper??
            if (currentRoute.meta?.waitForTargetDomLoaded) {
              waitForTargetDomLoaded(resolve)
            } else {
              resolve()
            }
          })

        } else {
          const currentRoute = _router.currentRoute.value
          if (currentRoute.meta?.waitForTargetDomLoaded) {
            waitForTargetDomLoaded(resolve)
          } else {
            resolve()
          }

        }

      } else {
        resolve()
      }
    },

    handleTourStepShow(_this) {
      const currentStep = Shepherd.activeTour?.getCurrentStep()
      const currentStepElement = currentStep?.getElement()
      const currentStepTarget = currentStep?.target?.id
      const footer = currentStepElement?.querySelector('.shepherd-footer')
      const progress = document.createElement('span')

      if (currentStepTarget === 'navDrawer' && !_this.showNavigationDrawer) {
        store.dispatch('navbar/setShowNavigationDrawer', true)
      }

      progress.className = 'shepherd-progress'
      progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`
      footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'))
    },
  }
}

</script>

<template>
  <div class="fill-height">
    <div v-if="loading">
      <v-progress-linear
        color="primary"
        indeterminate
      ></v-progress-linear>
    </div>
    <div
      v-if="!loading"
      class="fill-height"
    >
      <div v-if="checklist.checklist_mode === ChecklistMode.LIST" class="d-flex flex-column fill-height">
        <div class="py-4 px-2 d-flex align-center">
          <h2 class="px-2 d-flex align-center">
            <PlanitIcon
              color="primary"
              class="mr-2"
              :icon="checklist.icon || 'fal fa-clipboard-list-check'"
            >
            </PlanitIcon>
            <div
              class="html-container"
              v-html="checklist.getName(currentLocale)"
            ></div>
          </h2>

          <PlanitProgress
            :progress="Math.ceil(checklist.progress)"
            include-percent
            size="48"
            class="ml-4"
          ></PlanitProgress>

          <PlanitButton
            variant="text"
            class="icon-btn pa-2 ml-auto"
            @click="checklist.pause()"
          >
            <PlanitIcon
              size="small"
              color="grey-darken-1"
              icon="fas fa-chevron-double-right"
            >
            </PlanitIcon>
          </PlanitButton>
        </div>

        <div>
          <div
            v-for="step in sortedToplevelSteps"
            :key="step.id"
            class="py-1 px-2"
          >
            <ChecklistStep
              :step="step"
              :checklist="checklist"
            ></ChecklistStep>
          </div>
        </div>

        <div class="mt-auto d-flex justify-end pa-2">
          <PlanitButton
            variant="text"
            class="icon-btn"
            @click="checklist.pause()"
          >
            <PlanitIcon v-if="checklist.progress === 100" class="mr-1" size="small" icon="fal fa-arrow-alt-from-left"></PlanitIcon>

            <PlanitIcon v-else class="mr-1" size="small" icon="fal fa-pause"></PlanitIcon>


            <span v-if="checklist.progress === 100">
              {{ $t('components.checklist.finish_and_hide') }}
            </span>
            <span v-else>
              {{ $t('components.checklist.pause') }}
            </span>
          </PlanitButton>
        </div>

      </div>
      <div
        v-if="checklist.checklist_mode === ChecklistMode.TASKS"
        class="dashboard-section"
      >

        <PlanitProgress
          :progress="Math.ceil(checklist.progress)"
          color="primary"
          size="4"
          linear
          border-radius-top
        ></PlanitProgress>

        <div class="pa-4">

          <div class="mb-4 d-flex align-center">
            <h2 class="d-flex align-center">
              <PlanitIcon
                color="primary"
                class="mr-2"
                :icon="checklist.icon || 'fal fa-clipboard-list-check'"
              >
              </PlanitIcon>
              {{ checklist.getName(currentLocale) }}

            </h2>

            <div class="ml-auto">
              <PlanitButton
                variant="text"
                size="small"
                class="icon-btn"
                @click="checklist.pause()"
              >
                <PlanitIcon v-if="checklist.progress === 100" class="mr-1" size="small" icon="fal fa-stop-circle"></PlanitIcon>

                <PlanitIcon v-else class="mr-1" size="small" icon="fal fa-eye-slash"></PlanitIcon>

                <span v-if="checklist.progress === 100">
                  {{ $t('components.checklist.finish_and_hide') }}
                </span>
                <span v-else>
                  {{ $t('components.checklist.hide') }}
                </span>
              </PlanitButton>
            </div>
          </div>

          <div
            v-for="step, index in sortedToplevelSteps"
            :key="step.id"
          >
            <div :class="index < sortedToplevelSteps.length - 1 ? 'mb-2 pb-2 border-bottom' : ''">
              <ChecklistStep
                :step="step"
                :checklist="checklist"
              ></ChecklistStep>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
