import { InviteTargetType } from '@enums'

import PlanitModel from './planit-model'
import ScormFile from './scorm-file'
import Company from './company'
import Invite from './invite'

export default class ScormFileCompanyAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      invite_code: this.attr(),

      invites: this.hasMany(Invite, 'scorm_file_company_association_id'),
    }
  }

  createInvite(args) {
    const data = {
      scorm_file_company_association_id: this.id,
      invite_target_type: InviteTargetType.SCORM_FILE_COMPANY_ASSOCIATION,
    }

    if (args.user) {
      data.user_id = args.user.id
    } else if (args.email) {
      data.email = args.email
    }

    if (args.invite_data) {
      data.invite_data = args.invite_data
    }

    return Invite.$new(data)
  }

  getId() {
    return this.id
  }
}

ScormFileCompanyAssociation.entity = 'scorm-file-company-associations'
