import axios from 'axios'

import {
  ScormStatus,
  ScormFileCompletionMetric,
} from '@enums'

import { app } from '@src/main'

import store from '@state/store'

import PlanitModel from './planit-model'
import Company from './company'
import ScormFileCompanyAssociation from './scorm-file-company-association'
import ScormFileCategoryAssociation from '@state/models/scorm-file-category-association'

import defaultThemes from '@plugins/default-themes'
import { rgbaColor } from '@/src/utils/rgbaColor'

export default class ScormFile extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_translations: this.attr(),
      folder_name_translations: this.attr(),
      scorm_index_path_translations: this.attr(),

      total_slides: this.attr(),

      availability: this.attr(),
      completion_metric: this.attr(),

      data: this.attr(),

      scorm_file_company_associations: this.hasMany(ScormFileCompanyAssociation, 'scorm_file_id'),
      scorm_file_category_associations: this.hasMany(ScormFileCategoryAssociation, 'scorm_file_id'),
      preview_scorm_file_id: this.attr(),

      private_name: this.attr(),
      private_name_translations: this.attr(),

      scorm_meta_data: this.attr(),
      scorm_meta_data_translations: this.attr(),

      has_to_be_booked: this.boolean(),

      current_version_uuid: this.attr(),

      minimum_license_type: this.attr(),

      // local attrs
      current_locale: this.attr(),
    }
  }

  static beforeCreate(model) {
    // set to currentLocale or first available
    if (model && model.name_translations) {
      const currentLocale = store.getters['auth/currentLocale']
      model.current_locale = model.name_translations[currentLocale]
        ? currentLocale
        : Object.keys(model.name_translations)[0]
    }
  }

  static getPieChartData(apiData) {
    const scormStatus = Object.keys(apiData.count_per_status);
    const countPerStatus = Object.values(apiData.count_per_status);

    const $t = store.getters['navbar/$t'];

    const data = {
      labels: scormStatus.map(ss => $t('enums.scorm_status.' + ss)),
      datasets: [{
        label: $t('company.dashboard.training_participants'),
        data: countPerStatus,
        backgroundColor: scormStatus.map((ss) => {
          if (ss === ScormStatus.INVITED) {
            return rgbaColor('#ccc', 0.5)

          } else if (ss === ScormStatus.NOT_STARTED) {
            return rgbaColor('#999', 0.5)

          } else if (ss === ScormStatus.STARTED) {
            return rgbaColor(defaultThemes.light.colors.primary, 0.5)

          } else if (ss === ScormStatus.FINISHED) {
            return rgbaColor(defaultThemes.light.colors.warning, 0.5)

          } else if (ss === ScormStatus.FINISHED_SUCCESS) {
            return rgbaColor(defaultThemes.light.colors.success, 0.5)

          } else if (ss === ScormStatus.EXPIRED) {
            return rgbaColor(defaultThemes.light.colors.error, 0.5)
          } else {
            return rgbaColor('#000', 0.5)
          }
        }),
      }]
    };
    return data
  }


  static getSortAttrs() {
    return (scormFile) => {
      if (scormFile.name) {
        return scormFile.name.toLowerCase()
      }
      return scormFile.name_translations[store.getters['auth/currentLocale']].toLowerCase()
    }
  }

  static getPrimaryScormFiles(params) {
    params = params || {}
    return axios.get('/api/primary-scorm-files', { params })
  }

  static importPrimaryScormFile(scormFile) {
    const params = { import: true }
    return axios.post('/api/primary-scorm-files/' + scormFile.current_version_uuid, { ...params })
  }

  addCompany(company) {
    const data = { company_id: company.id }
    return this.createSubResource(ScormFileCompanyAssociation, data, {
      save: false,
    }).then((result) => {
      result.response.data.scorm_file_id = this.id

      result.save()

      return result
    })
  }

  getIconFaOnly() {
    if (this.scorm_file_category_associations[0]?.scorm_file_category?.icon) {
      return this.scorm_file_category_associations[0].scorm_file_category.icon
    }

    if (this.completion_metric === ScormFileCompletionMetric.SLIDES_COMPLETED) {
      return 'fa-presentation'

    } else if (this.completion_metric === ScormFileCompletionMetric.SCORE) {
      return 'fa-clipboard-list-check'

    } else {
      return 'fa-graduation-cap'

    }
  }

  getIcon() {
    return 'fas ' + this.getIconFaOnly()
  }

  getRouterTo(companyId) {
    return {
      name: 'companyScormFile.detail',
      params: {
        scormFileId: this.id,
        companyId: companyId,
      },
    }
  }

  getImageSource() {
    if (!this.name_translations.de) {
      return require(`@/public/scorm-images/default.jpg`)
    }

    const imageName = this.name_translations.de
      .toLowerCase()
      .replaceAll(' ', '_')
      .replaceAll('[', '')
      .replaceAll(']', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(',', '')
      .replaceAll(':', '')
      .replaceAll('/', '')

    try {
      const url = require(`@/public/scorm-images/${imageName}.jpg`)
      return url

    } catch (e) {
      return require(`@/public/scorm-images/default.jpg`)

    }
  }

  async resolveCsvStats(companyId) {
    const el = document.createElement('a')
    el.href = '/api' + this.url() + '/csv-stats?company_id=' + companyId
    el.target = '_blank'
    el.click()
  }
}

ScormFile.entity = 'scorm-files'
