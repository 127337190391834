import PlanitModel from './planit-model'
import User from './user'
import Company from './company'


export default class Event extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      event_type: this.attr(),
      data: this.attr(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

    }
  }

  /* static getDataSortAttrs() {
    return [
      (d) => {
        if ()
      }
    ]
  } */
}

Event.entity = 'events'
