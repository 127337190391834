<script>

import axios from 'axios'

import PlanitAlert from './planit-alert.vue'

import store from '@state/store'


export default {
  name: 'UserVerifyAlert',
  components: {
    PlanitAlert,
  },
  props: {
    email: { type: String, required: false, default: null },
    hideLoginDifferently: { type: Boolean, required: false, default: false },
  },
  computed: {
    currentUser() {
      return store.getters['auth/currentUser']
    },
    debugVerificationLink() {
      return store.getters['auth/debugVerificationLink']
    },
  },
  data: function () {
    return {
      verificationResent: false,
    }
  },
  methods: {
    async resendVerification() {
      if (this.currentUser) {
        await axios.post('/auth/user/send_verification_email')

      } else {
        await axios.post('/auth/user/forgot-password', { email: this.email })

      }

      this.verificationResent = true

    },
    debugVerify() {
      window.location.href = this.debugVerificationLink
    },
  }
}
</script>

<template>
  <div>
    <PlanitAlert
      v-if="(currentUser && !currentUser.verified) || !currentUser"
      color="info"
      wrap
      class="my-6 text-center"
      :icon="null"
    >
      <div>
        <div class="mb-4">
          <i18n-t keypath="generic.unverified">
            <template #email>
              <strong>{{ email || (currentUser && currentUser.email) || '' }}</strong>
            </template>
          </i18n-t>
        </div>

        <div class="py-2">
          <span>{{ $t('generic.resend_verification_mail_info_text') }}</span>
          <br>
          <span>
            <a
              class="mt-2 mx-auto font-weight-medium"
              :color="verificationResent ? 'success' : 'info'"
              :disabled="!!verificationResent"
              @click="resendVerification"
            >
              <span v-if="verificationResent">
                <PlanitIcon :color="verificationResent ? 'success' : 'info'" icon="far fa-check"></PlanitIcon>

              </span>
              <span v-else>{{ $t('generic.resend_verification_mail') }}</span>
            </a>
          </span>
        </div>

        <div v-if="!hideLoginDifferently" class="py-2">
          <span>{{ $t('generic.unverified_login_differently_info_text') }}</span>
          <br>
          <router-link
            :to="{ name: 'logout' }"
            color="info"
            class="mt-2 mx-auto font-weight-medium"
          >
            <span>{{ $t('generic.unverified_login_differently') }}</span>
          </router-link>
        </div>

        <PlanitButton
          v-if="!!debugVerificationLink"
          color="error"
          @click="debugVerify"
        >Verify</PlanitButton>
      </div>
    </PlanitAlert>
  </div>
</template>
