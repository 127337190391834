<script>
import BaseCard from '@components/card/base-card'
import Checklist from '@components/checklist'
import { TaskType } from '@enums'

import store from '@state/store'
import { approvalGetters } from '@state/helpers'
import { markRaw, defineAsyncComponent } from 'vue'

export default {
  name: 'ReviewMain',
  components: {
    Checklist,
    BaseCard,
  },
  props: {},
  emits: ['setShowRightDrawer'],
  data: function () {
    return {
      TaskType,
      activeTabId: 'assignments',
      tabs: [
        {
          id: 'assignments',
          label: this.$t('components.review.assignments'),
          component: markRaw(defineAsyncComponent(() => import( '@components/review-system/review-assignments'))),
        },
        {
          id: 'comments',
          label: this.$t('components.review.comments'),
          component: markRaw(defineAsyncComponent(() => import('@components/review-system/review-comments'))) ,
        },
        {
          id: 'settings',
          label: this.$t('components.review.settings'),
          component: markRaw(defineAsyncComponent(() => import('@components/review-system/review-settings'))),
        },
      ],
      dialog: false,

      status: null,
      neededApproves: 0,
      loading: true,
    }
  },
  computed: {
    ...approvalGetters,
    documentId() {
      return store.getters['currentDocument/document']?.id
    },
    currentCompanyRole() {
      return store.getters['navbar/myCompanyRole']
    },
    blueprintId() {
      return null
    },
    isOwner() {
      if (store.getters['auth/isAdmin']) {
        return true
      }
      if (!this.currentCompanyRole) {
        return false
      }
      if(!this.ownerTask){
        return false
      }
      return this.ownerTask.task_company_role_associations.find(
        (tcra) => tcra.company_role.id === this.currentCompanyRole.id
      )
    },
    hasSmallScreen() {
      return store.getters['navbar/hasSmallScreen']
    },
    navbarHeight() {
      return store.getters['navbar/navbarPxHeight'] + 'px'
    },
    navDrawerStyles() {
      const styles = {}

      if (this.hasSmallScreen) {
        styles['padding-top'] = this.navbarHeight
      } else {
        styles.top = this.navbarHeight + ' !important'
      }
      return styles
    },
    filteredTabs() {
      return this.tabs.filter(tab => this.isOwner || tab.id !== 'settings')
    },
    activeTab() {
      return this.tabs.find(tab => tab.id === this.activeTabId)
    },
  },
  async created() {
    if (this.approval && this.approval.document_id == this.documentId) {
      await this.refreshApprovalContent()
      return
    }

    if (this.documentId) {
      await this.retrieveApproval()
    }

    if (!this.approval) {
      await this.createApproval()
    }

    await this.refreshApprovalContent()
    this.loading = false
  },
  methods: {
    async retrieveApproval() {
      await this.$store.dispatch('approval/getApprovalByDocumentId', this.documentId)
    },
    async createApproval() {
      await this.$store.dispatch('approval/createApproval', { document_id: this.documentId } )
    },
    async refreshApprovalContent() {
      if (this.approval) {
        await this.approval.refreshContent({ updateTasks: true })
      }

    },
    openDialog() {
      this.dialog = true
    },
    async setShowReviewDrawer(value) {
      await this.$store.dispatch('navbar/setShowApprovalDrawer', value)
    },
  },
}
</script>

<template>
  <div class="px-4">
    <template v-if="loading">
      <v-card-title>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card-title>
    </template>
    <div
      v-else
    >
      <div class="d-flex justify-end mb-2">
        <PlanitButton
          variant="text"
          class="icon-btn"
          @click="$emit('setShowRightDrawer')"
        >
          <PlanitIcon
            size="small"
            color="grey-darken-1"
           icon="fas fa-chevron-double-right
          "/>
        </PlanitButton>
      </div>

      <v-tabs
        v-if="approval"
        v-model="activeTabId"
        color="primary"
        hide-arrows
        class="mb-4"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.id"
          :value="tab.id"
          :href="`#${tab.id}`"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <BaseCard
        id="tab.id"
        no-padding
      >
        <component
          v-if="activeTab"
          :is="activeTab.component"
          :document-id="documentId"
          @openDialog="openDialog"
        />
      </BaseCard>
    </div>
  </div>
</template>
