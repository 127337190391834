<script>
import { orderBy } from 'lodash'

import PlanitInput from '@components/form/input'
import DateTimePicker from '@components/form/date-time-picker'

import { RolloutState } from '@enums'

import {
  tomorrowMidnightISO,
  dateToISOString,
} from '@utils/date-helpers'


export default {
  name: 'RolloutGeneral',
  components: {
    PlanitInput,
    DateTimePicker,
  },
  props: {
    rollout: { type: Object, required: true },
    stateIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      orderBy,
      documentSearch: '',
      RolloutState,
      dateFilter: tomorrowMidnightISO(),
      reminderDate: null
    }
  },
  computed: {
    nextReminderDate() {
      const filtered = this.rollout.reminder.timers.filter((t) => !t.last_triggered)
      if (filtered.length === 0) {
        return ''
      }
      return orderBy(filtered, (t) => t.start_timestamp)[0].start_timestamp
    },
  },
  methods: {
    async addReminder(datetime) {
      this.reminderDate = datetime
      await this.rollout.sync(null, { reminder_date: dateToISOString(datetime, { withTime: false }) })
      this.reminderDate = null
    },
    confirmedCount(recipients) {
      return recipients.filter((recipient) => recipient.has_confirmed).length
    },
    removeTimer(timer) {
      timer.delete()
    },
  },
}
</script>

<template>
  <div>
    <v-row class="pt-2 mb-8">
      <v-col cols="6">
        <PlanitInput
          :model="rollout"
          :disabled="rollout.state === RolloutState.COMPLETED"
          attr="name"
          :label="$t('generic.name')"
          required
          density="compact"
          highlight
          class="mb-8"
        >
        </PlanitInput>
        <PlanitInput
          :model="rollout"
          attr="description"
          :disabled="rollout.state === RolloutState.COMPLETED"
          :label="$t('generic.description')"
          class="mb-8"
          type="textarea"
          :rows="2"
          density="compact"
          markdown
          highlight
          hide-details
        ></PlanitInput>
        {{ $t('rollouts.with_confirmation_info') }}
        <PlanitInput
          :model="rollout"
          attr="with_confirmation"
          :label="$t('rollouts.with_confirmation')"
          :disabled="rollout.state !== RolloutState.DRAFT"
          class="mb-4"
          type="checkbox"
          highlight
          hide-details
        >
        </PlanitInput>
        {{ $t('rollouts.password_info') }}
        <PlanitInput
          :model="rollout"
          attr="email_as_password"
          :label="$t('rollouts.email_as_password')"
          :disabled="rollout.state !== RolloutState.DRAFT"
          class="mb-4"
          type="checkbox"
          highlight
          hide-details
        >
        </PlanitInput>
      </v-col>
      <v-col cols="6">
        <DateTimePicker
          type="date"
          v-model:value="reminderDate"
          :label="$t('rollouts.add_reminder')"
          :min="dateFilter"
          :disabled="rollout.state === RolloutState.COMPLETED"
          class="mb-2"
          @update:value="addReminder"
        ></DateTimePicker>
        <template
          v-for="timer, index in orderBy(rollout.reminder.timers, (t) => t.start_timestamp)"
          :key="'timer-' + index"
        >
          <PlanitChip
            :disabled="rollout.state === RolloutState.COMPLETED"
            closable
            class="mr-2 mb-2"
            :color="timer.last_triggered ? 'success' : ''"
            @click:close="removeTimer(timer)"
          >
            <PlanitIcon
              start
              size="small"
             :icon="timer.last_triggered ? 'fal fa-envelope' : 'fal fa-clock' "></PlanitIcon>

            {{ $filters.formatDate( timer.start_timestamp ) }}
          </PlanitChip>
        </template>
      </v-col>
    </v-row>
  </div>
</template>
