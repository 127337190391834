import PlanitModel from './planit-model'
import { Document } from './document'
import File from './file'
import DocumentBlueprint from './document-blueprint'
import ScormFile from './scorm-file'
import Folder from './folder'
import Answer from './answer'

export default class ModelSearchResult extends PlanitModel {
  /*
   * Meta model that groups search results
   */
  static fields() {
    return {
      ...super.fields(),

      document_ids: this.attr([]),
      documents: this.hasManyBy(Document, 'document_ids'),

      answer_ids: this.attr([]),
      answer_content: this.hasManyBy(Answer, 'answer_ids'),

      file_ids: this.attr([]),
      files: this.hasManyBy(File, 'file_ids'),

      document_blueprint_ids: this.attr([]),
      document_blueprints: this.hasManyBy(DocumentBlueprint, 'document_blueprint_ids'),

      scorm_file_ids: this.attr([]),
      scorm_files: this.hasManyBy(ScormFile, 'scorm_file_ids'),

      folder_ids: this.attr([]),
      folders: this.hasManyBy(Folder, 'folder_ids'),
    }
  }
}

ModelSearchResult.entity = 'model-search-results'
