
import store from '@state/store'

const replaceBrand = (value) => {
  return value
    ? value
      .replaceAll('{{ productName }}', store.getters['global/productName'])
      .replaceAll('{{ brandName }}', store.getters['global/brandName'])
      .replaceAll('{{ supportEmail }}', store.getters['global/supportEmail'])
    : null
}

export default replaceBrand
