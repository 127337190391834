import axios from 'axios'
// import objectDiff from '@utils/object-diff';

export const state = {
  cached: [],
  users: [],
}

export const getters = {
  USER_LIST(state) {
    return state.users
  },
  USER(state) {
    return function (id) {
      return state.cached.find((u) => u.id === id)
    }
  },
}

export const mutations = {
  CACHE_USER(state, newUser) {
    state.cached.push(newUser)
  },
  SET_USER_LIST(state, users) {
    state.users = users
  },
  SET_USER_CHANGES(state, { userId, data }) {
    const user = state.users.find((u) => u.id === userId)
    user.changes = user.changes || {}
    Object.assign(user.changes, data)
  },
}

export const actions = {
  fetchUser({ commit, state, rootState }, { userId }) {
    // 1. Check if we already have the user as a current user.
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.id === userId) {
      return Promise.resolve(currentUser)
    }

    // 2. Check if we've already fetched and cached the user.
    const matchedUser = state.cached.find((user) => user.id === userId)
    if (matchedUser) {
      return Promise.resolve(currentUser)
    }

    // 3. Fetch the user from the API and cache it in case
    //    we need it again in the future.
    return axios.get(`/api/users/${userId}`).then((response) => {
      const user = response.data
      commit('CACHE_USER', user)
      return user
    })
  },

  async GET_USER_LIST({ commit, state }) {
    const { data } = await axios.get('/api/users')
    commit('SET_USER_LIST', data)
  },

  GET_USER({ commit, state }, { userId }) {
    return axios.get('/api/users/' + userId).then((response) => {
      commit('CACHE_USER', response.data)
      return response.data
    })
  },

  PATCH_USER({ commit, state }, { userId }) {
    const storedUser = state.cached.find((u) => u.id === userId)
    // const diff = objectDiff(storedUser, data)

    return axios.patch('/api/users/' + userId, { data: storedUser.changes }).then((response) => {
      commit('CACHE_USER', response.data)
      return response.data
    })
  },
}
