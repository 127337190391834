<script>

import { LicenseType } from '@enums'

import store from '@state/store'
import BrandSetting from '@state/models/brand-setting'


export default {
  name: 'PlanitLicenseExpirationNotice',
  props: {
  },
  computed: {
    isDemoUser() {
      return store.getters['auth/currentUser'].is_demo_user
    },
    brandSetting() {
      return BrandSetting.query().first()
    },
  },
}

</script>

<template>
  <div
    class="border-bottom bg-error-lighten-4"
  >
    <div class="pa-4">
      <h2 class="d-flex align-center mb-2">
        <PlanitIcon class="mr-1" color="error" icon="fal fa-exclamation-triangle"></PlanitIcon>

        <span>{{ (brandSetting && brandSetting.name) || 'PRIMA' }} {{ $t('components.nav_bar.license_expired') }}</span>
      </h2>
      <div class="d-flex justify-end">
        <PlanitButton
          :href="
            isDemoUser ?
              $t('admin.licenses.upgrade_request_href') :
              $t('admin.licenses.renewal_request_href')
            "
          variant="outlined"
          size="small"
        >
          {{
            isDemoUser ?
              $t('admin.licenses.upgrade_request_text') :
              $t('admin.licenses.renewal_request_text')
          }}
        </PlanitButton>
      </div>
    </div>
  </div>
</template>
