import { Chart } from 'chart.js'

import { before, sortBy } from 'lodash'

import store from '@state/store'

import PlanitModel from './planit-model'
import { rgbaColor } from '@/src/utils/rgbaColor'

/* export class SelectGroupDocumentBlueprintVersionAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_blueprint_version_id: this.attr(),
      document_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'document_blueprint_version_id'
      ),

      select_group_id: this.attr(),
      select_group: this.belongsTo(SelectGroup, 'select_group_id'),

      commit_id: this.attr(),
    }
  }
}

SelectGroupDocumentBlueprintVersionAssociation.entity =
  'select-group-document-blueprint-version-associations' */

export class SelectGroup extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      viid: this.attr(),

      // translated attributes
      name: this.attr(),
      name_translations: this.attr({}),

      is_pending: this.attr(),

      latest_select_group_id: this.attr(),
      latest_select_group: this.belongsTo(SelectGroup, 'latest_select_group_id'),

      // select_group_options: this.hasMany(SelectGroupOption, 'select_group_id'),

      /* select_group_document_blueprint_version_associations: this.hasMany(
        SelectGroupDocumentBlueprintVersionAssociation,
        'select_group_id'
      ), */
      // sections: this.hasMany(Section, 'select_group_id'),

      // commit_id: this.attr(),
    }
  }

  /* getSelectGroupOptionsByBlueprintVersion(blueprintVersion) {
    const thisSgdbva = SelectGroupDocumentBlueprintVersionAssociation.allFast().find(
      (sgdbva) =>
        sgdbva.select_group_id === this.id &&
        sgdbva.document_blueprint_version_id === blueprintVersion.id
    )

    if (!thisSgdbva) {
      console.log(this.id, 'thisSgdbva missing', thisSgdbva, 'blueprintVersion', blueprintVersion)
      return []
    }

    return sortBy(
      SelectGroupOption.query()
        .where(
          (sgo) =>
            !!SelectGroupOptionDocumentBlueprintVersionAssociation.allFast().find((sgodbva) => {
              return (
                sgodbva.document_blueprint_version_id === blueprintVersion.id &&
                sgodbva.select_group_option_id === sgo.id &&
                sgodbva.select_group_document_blueprint_version_association_id === thisSgdbva.id
              )
            })
        )
        .all(),
      () => {}
    )
  } */

  addSelectOption(data, blueprintVersion, beforeSelectGroupOption) {
    data.select_group_viid = this.viid
    data.before_select_group_option_viid = beforeSelectGroupOption?.viid
    return SelectGroupOption.api()
      .post(blueprintVersion.url() + SelectGroupOption.$url(), data)
  }

  /* async sync(attr, data, config, callback) {
    debugger
    const thisId = this.id
    config.save = false
    return super.sync(attr, data, config, callback).then((r) => {
      // VIID_TODO
      /* if (r.response.data.id !== thisId) {
        await Promise.all([
          SelectGroupDocumentBlueprintVersionAssociation.delete((sgdbva) => {
            return sgdbva.select_group_id === thisId
          }).then((_r) => {}),
          SelectGroup.delete(thisId),
        ])
      }
      r.save()

      return r
    })
  } */

  async delete(blueprintVersion) {
    const result = await super.delete()
    store.commit('blueprint/DELETE_SELECT_GROUP', {
      selectGroup: this,
      blueprintVersion,
    })

    return result

  }

}

SelectGroup.entity = 'select-groups'

/* export class SelectGroupOptionDocumentBlueprintVersionAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_blueprint_version_id: this.attr(),
      document_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'document_blueprint_version_id'
      ),

      select_group_option_id: this.attr(),
      select_group_option: this.belongsTo(SelectGroupOption, 'select_group_option_id'),

      select_group_document_blueprint_version_association_id: this.attr(),

      commit_id: this.attr(),
    }
  }
}

SelectGroupOptionDocumentBlueprintVersionAssociation.entity =
  'select-group-option-document-blueprint-version-associations' */

export class SelectGroupOption extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      viid: this.attr(),

      // translated attributes
      name: this.attr(),
      name_translations: this.attr({}),
      color: this.attr(),

      is_pending: this.attr(),

      latest_select_group_option_id: this.attr(),
      latest_select_group_option: this.belongsTo(
        SelectGroupOption,
        'latest_select_group_option_id'
      ),

      select_group_viid: this.attr(),
      before_select_group_option_viid: this.attr(),

      /* select_group_id: this.attr(),
      select_group: this.belongsTo(SelectGroup, 'select_group_id'), */

      /* select_group_option_document_blueprint_version_associations: this.hasMany(
        SelectGroupOptionDocumentBlueprintVersionAssociation,
        'select_group_option_id'
      ), */

      // commit_id: this.attr(),
    }
  }

  getSectionSelectGroupOptionsResultsBySection(sectionViid, blueprintVersion) {
    const ssgors = store.getters['blueprint/sectionSelectGroupOptionResultsBlueprintVersion'](blueprintVersion)
    return sortBy(
      ssgors.filter(
        ssgor => ssgor.section_viid === sectionViid && ssgor.select_group_option_viid === this.viid
      ),
      (ssgor) => ssgor.getSortValue(),
    )

    /* const thisSgodbva = SelectGroupOptionDocumentBlueprintVersionAssociation.allFast().find(
      (sgdbva) =>
        sgdbva.select_group_option_id === this.id &&
        sgdbva.document_blueprint_version_id === blueprintVersionId
    )

    if (!thisSgodbva) {
      console.log(
        this.id,
        'thisSgodbva missing',
        thisSgodbva,
        'blueprintVersionId',
        blueprintVersionId
      )
      return []
    }

    return sortBy(
      SectionSelectGroupOptionResultDocumentBlueprintVersionAssociation.allFast()
        .filter((ssgordbva) => {
          return (
            ssgordbva.document_blueprint_version_id === blueprintVersionId &&
            ssgordbva.select_group_option_document_blueprint_version_association_id ===
              thisSgodbva.id &&
            (!sdbaId || sdbaId === ssgordbva.section_document_blueprint_association_id)
          )
        })
        .map((ssgordbva) => {
          return SectionSelectGroupOptionResult.find(
            ssgordbva.section_select_group_option_result_id
          )
        }),
      (ssgor) => ssgor.getSortValue() // must always be sorted
    ) */
  }

/*   async sync(attr, data, config, callback) {
    const thisId = this.id
    config.save = false
    return super.sync(attr, data, config, callback)  .then((r) => {
      // VIID_TODO
      if (r.response.data.id !== thisId) {
        //console.log('deleting old select group option with different id', r)
        await Promise.all([
          SelectGroupOptionDocumentBlueprintVersionAssociation.delete((sgodbva) => {
            return sgodbva.select_group_option_id === thisId
          }),
          SelectGroupOption.delete(thisId),
        ])

      }
      r.save()

      return r
    })
  } */

    getChartColor(alpha = 1) {
      const color = this.color;
      if (color === 'success') {
        return rgbaColor('#67b346', alpha);
      } else if (color === 'warning') {
        return rgbaColor('#EE984B', alpha);
      } else if (color === 'error') {
        return rgbaColor('#ef6966', alpha);
      } else {
        return rgbaColor('#ccc', alpha);
      }
    }

  async delete(blueprintVersion) {
    const afterSgo = store.getters['blueprint/selectGroupOptionsBlueprintVersion'](blueprintVersion).find(
      (sgo) => sgo.before_select_group_option_viid === this.viid
    )
    if (afterSgo) {
      const result = await afterSgo.sync(null, {
        before_select_group_option_viid: this.before_select_group_option_viid,
      })
      store.commit('blueprint/UPDATE_SELECT_GROUP_OPTION', {
        selectGroupOption: result.entities[SelectGroupOption.entity].find(
          (sgo) => sgo.id === result.response.data.id
        ),
        blueprintVersion,
      })
    }

    const result = await super.delete()

    store.commit('blueprint/DELETE_SELECT_GROUP_OPTION', {
      selectGroupOption: this,
      blueprintVersion,
    })

    return result

  }

}

SelectGroupOption.entity = 'select-group-options'
