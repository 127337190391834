import store from '@state/store'
import TrainingSession from '@state/models/training-session'
import formatDate from '@utils/format-date'
import CompanyRoleScormStatus from '@state/models/company-role-scorm-status'

export const state = {
  trainingSessions: null,
  selectedTrainingSession: null,
  company_id: null,
  companyRole: null
}

export const mutations = {
  SET_TRAINING_SESSIONS(state, trainingSessions) {
    state.trainingSessions = trainingSessions
  },
  SET_SELECTED_TRAINING_SESSION(state, trainingSession) {
    state.selectedTrainingSession = trainingSession
  },
  SET_COMPANY_ID(state, company_id) {
    state.company_id = company_id
  },
  SET_COMPANY_ROLE(state, companyRole) {
    state.companyRole = companyRole
  }
}

export const getters = {
  companyRole(state) {
    return state.companyRole
  },
  companyRoleScormStatus(state) {
    return CompanyRoleScormStatus
      .query()
      .where('company_role_id', state.companyRole.id)
      .with('scorm_file')
      .get()
  },
  selectedTrainingSession(state) {
    return state.selectedTrainingSession
  },
  trainingSessionIterations(state, getters, rootState) {
    const selectedTrainingSession = state.selectedTrainingSession
    if (!selectedTrainingSession) return []

    const currentSessionStartDate = new Date(selectedTrainingSession.start_date)
    const currentSessionEndDate = new Date(selectedTrainingSession.end_date)
    const currentSessionInterval = selectedTrainingSession.interval // 1, 2, 3
    const currentSessionIntervalUnit = selectedTrainingSession.interval_unit // months, years

    const iterations = [{ id: 'current', name:  store.getters['navbar/$t']('company.scorm_file.show_current') }]

    let iterationStartDate = new Date(currentSessionStartDate)

    while (iterationStartDate < currentSessionEndDate) {
      const iterationEndDate = addInterval(
        iterationStartDate,
        currentSessionInterval,
        currentSessionIntervalUnit
      )
      addIteration(iterations, iterationStartDate, iterationEndDate, currentSessionEndDate, store.getters['navbar/$t'])
      iterationStartDate = new Date(iterationEndDate)
    }

    sortIterations(iterations)
    moveAllToFirstPosition(iterations)

    return formatIterations(iterations)
  },
  trainingSessions(state, getters) {
    return TrainingSession.query()
    .where('company_id', store.getters['navbar/selectedCompany'].id)
    .with('session_scorm_links.scorm_file')
    .with('training_session_company_role_associations')
    .all()
  },
  trainingSessionWithCurrentAndFinishedWithoutSessionItems(state,  getters) {
    const sessions = getters.trainingSessions
    return [
      { id: 'current', name: store.getters['navbar/$t']('company.scorm_file.show_current') },
      { id: 'completed_without_training_session', name: store.getters['navbar/$t']('company.scorm_file.completed_without_training_session') },
      ...sessions
    ]
  },
  trainingSessionsWithCurrentItems(state, getters) {
    const sessions = getters.trainingSessions
    return [{ id: 'current', name: store.getters['navbar/$t']('company.scorm_file.show_current') }, ...sessions]
  },
  trainingSessionWithAllItems(state, getters) {
    const sessions = getters.trainingSessions
    return [{ id: 'all', name: store.getters['navbar/$t']('company.scorm_file.show_all') }, ...sessions]
  }
}

export const actions = {
  async initTrainingSessions({ dispatch, getters }, {company_id, company_role_id, exclude_ended_sessions = false}) {
    TrainingSession.$all({ company_id: company_id, company_role_id: company_role_id, exclude_ended_sessions: exclude_ended_sessions })
  },
  initCompanyRole({ commit }) {
    commit('SET_COMPANY_ROLE', store.getters['navbar/selectedCompany'].getMyCompanyRole())
  },
  setSelectedTrainingSession({ commit }, trainingSession) {
    commit('SET_SELECTED_TRAINING_SESSION', trainingSession)
  },
  async addCompanyRoleToTrainingSession({ dispatch, state }, { company_role, training_session_ids }) {
    return await company_role.addToTrainingSessions(training_session_ids)
  }
}

// Helper functions
function addInterval(date, interval, unit) {
  const newDate = new Date(date)
  if (unit === 'months') {
    newDate.setMonth(newDate.getMonth() + interval)
  } else if (unit === 'years') {
    newDate.setFullYear(newDate.getFullYear() + interval)
  }
  return newDate
}

function formatDate1(date) {
  return date.toISOString().split('T')[0] // Format: YYYY-MM-DD
}

function addIteration(iterations, iterationStartDate, iterationEndDate, currentSessionEndDate, translate) {
  if (iterationEndDate <= currentSessionEndDate) {
    const formattedIteration = `${formatDate1(iterationStartDate)} ${translate('company.scorm_file.to')} ${formatDate1(iterationEndDate)}`
    const visibleFormattedIteration = `${formatDate(iterationStartDate.toISOString())} - ${formatDate(new Date(iterationEndDate.getTime() - 1).toISOString())}`
    iterations.push({
      id: formattedIteration,
      name: visibleFormattedIteration,
      start: new Date(iterationStartDate),
      end: new Date(iterationEndDate),
    })
  } else {
    const formattedIteration = `${formatDate1(iterationStartDate)} ${translate('company.scorm_file.to')} ${formatDate1(currentSessionEndDate)}`
    const visibleFormattedIteration = `${formatDate(iterationStartDate.toISOString())} - ${formatDate(new Date(currentSessionEndDate.getTime() - 1).toISOString())}`
    iterations.push({
      id: formattedIteration,
      name: visibleFormattedIteration,
      start: new Date(iterationStartDate),
      end: new Date(currentSessionEndDate),
    })
  }
}

function sortIterations(iterations) {
  iterations.sort((a, b) => {
    if (!a.start) return 1
    if (!b.start) return -1
    return b.start - a.start
  })
}

function moveAllToFirstPosition(iterations) {
  const allIndex = iterations.findIndex(iteration => iteration.id === 'current')
  if (allIndex > 0) {
    const [allIteration] = iterations.splice(allIndex, 1)
    iterations.unshift(allIteration)
  }
}

function formatIterations(iterations) {
  return iterations.map(({ id, name }) => ({ id, name })) // Entferne start und end aus der Rückgabe
}
