import PlanitModel from './planit-model'
import CompanyRole from './company-role'
import Approval from '@state/models/approval'
import Task from '@state/models/task'
import { DocumentVersion } from './document-version'

export default class TaskCompanyRoleAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),
      id: this.attr(),

      is_done: this.attr(),

      task_id: this.attr(),
      task: this.belongsTo(Task, 'task_id'),

      company_role_id: this.attr(),
      company_role: this.belongsTo(CompanyRole, 'company_role_id'),

      document_version_id: this.attr(),
      document_version: this.belongsTo(DocumentVersion, 'document_version_id'),
    }
  }
  static async getTaskUsers(approvalId, taskId) {
    const promise = []
    promise.push(this.refreshSubResource(TaskCompanyRoleAssociation,{
      params: {
        approval_id: approvalId,
        task_id: taskId
      },
    })
    )
    Promise.allSettled(promise).then(resolve)

    const response = await Approval.api().get(`/approvals/${approvalId}/tasks`)
    console.log("response.data: ",response)
    return response
  }

}


TaskCompanyRoleAssociation.entity = 'task-company-role-associations'
