import axios from 'axios'
import store from '@state/store'

import BrandSetting from '@state/models/brand-setting'

export const state = {
  dsbCommentPages: null,
  home: null,
}

export const mutations = {
  SET_PAGES(state, pages) {
    state.dsbCommentPages = pages
  },
  SET_HOME(state, home) {
    state.home = home
  },
}

export const getters = {
  pages(state) {
    return state.dsbCommentPages
  },
  home(state) {
    console.log('home', state.home)
    return state.home
  },
  pagesWithHome(state) {
    if (state.dsbCommentPages) {
      return state.dsbCommentPages.concat([state.home])
    }

    return null
  },
  brandSetting() {
    return BrandSetting.query().first()
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {},

  async resolvePages({ commit }) {
    const url = '/dsbcp'
    let pages = null
    let home = null

    try {
      const pagesResponse = await axios.get(
        url + '/wp-json/wp/v2/book?per_page=100&orderby=menu_order&order=asc'
      )
      console.log('pagesResponse', pagesResponse)

      if (!pagesResponse || !pagesResponse.data) {
        throw new Error('No pages data received.')
      }

      pages = pagesResponse.data
      const pagesTemp = []
      pages.forEach((page) => {
        if (page.slug === 'ruw_9999') {
          home = page
        } else {
          pagesTemp.push(page)
        }
      })

      if (!home) {
        throw new Error('Home page not found.')
      }

      function buildChildPages(parent) {
        const childPages = pagesTemp.filter((p) => p.parent === parent.id)

        childPages.forEach((child) => {
          const parentSlug = parent.fullSlug ? parent.fullSlug : parent.slug
          child.fullSlug = parentSlug !== home.slug ? parentSlug + '/' + child.slug : child.slug
          const children = buildChildPages(child)
          if (children.length) {
            child.children = children
          }
        })

        return childPages
      }

      pages = buildChildPages(home)

      commit('SET_HOME', home)
      commit('SET_PAGES', pages)
    } catch (error) {
      console.error('Error resolving pages:', error.message)
      commit('SET_HOME', null)
      commit('SET_PAGES', null)
      return false
    }
  },
}
