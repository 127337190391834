import PlanitModel from './planit-model'
import PermissionRole from './permission-role'
import { Document } from './document'
import File from './file'
import DocumentBlueprint from './document-blueprint'
import ScormFile from './scorm-file'
import Folder from './folder'

export default class PermissionRoleModelAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      write: this.attr(),
      delete: this.attr(),
      recursive: this.attr(),
      assign: this.attr(),
      create_new: this.attr(),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),

      document_id: this.attr(),
      document: this.belongsTo(Document, 'document_id'),

      file_id: this.attr(),
      file: this.belongsTo(File, 'file_id'),

      document_blueprint_id: this.attr(),
      document_blueprint: this.belongsTo(DocumentBlueprint, 'document_blueprint_id'),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),
    }
  }

  getTargetModel() {
    return this.document || this.file || this.document_blueprint || this.scorm_file || this.folder
  }

  apiDelete() {
    return super.delete()
  }
}

PermissionRoleModelAssociation.entity = 'permission-role-model-associations'
