<script>
export default {
  name: 'AutoGrowTextarea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'compact',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: [Number, String],
      default: 5,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:model-value', 'blur', 'focus'],
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  mounted() {

  },
  methods: {
    onInput(e) {
      this.internalValue = e;
      this.$emit('update:model-value', e);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
  },
};
</script>

<template>
  <div class="textarea-grow-wrap">
    <v-textarea
      v-model="internalValue"
      @update:model-value="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :error-messages="errorMessages"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :color="color"
      :theme="dark ? 'dark' : light ? 'light' : ''"
      :rows="rows"
      :density="density"
      :variant="outlined ? 'outlined' : 'filled'"
      :hide-details="hideDetails"
      auto-grow
    ></v-textarea>
  </div>
</template>
