import PlanitModel from './planit-model'
import CompanyRoleScormStatus from './company-role-scorm-status'
import ScormFile from './scorm-file'
import TrainingSession from './training-session'


export default class SessionScormLink extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      training_session_id: this.attr(),
      training_session: this.belongsTo(TrainingSession, 'training_session_id'),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      company_role_scorm_status: this.hasMany(CompanyRoleScormStatus, 'session_scorm_link_id'),
    }
  }
}

SessionScormLink.entity = 'session-scorm-links'
