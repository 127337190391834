import PlanitModel from './planit-model'
import Folder from './folder'
import Vendor from './vendor'

export default class FolderVendorAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      vendor_id: this.attr(),
      vendor: this.belongsTo(Vendor, 'vendor_id'),
    }
  }
}

FolderVendorAssociation.entity = 'folder-vendor-associations'
