import { SupportedLocale } from '@enums'
import i18n from '@src/plugins/vue-i18n'

import { app } from '../main'

export default function enumItems(enumObj, translationPrefix, allLanguages) {
  allLanguages = allLanguages || false

  if (allLanguages) {
    return Object.keys(enumObj).map((k) => {
      const obj = { name_translations: {}, value: enumObj[k] }
      Object.values(SupportedLocale).forEach((locale) => {
        obj.name_translations[locale] = i18n.global.t(
          'enums.' + (translationPrefix ? translationPrefix + '.' : '') + enumObj[k],
          locale
        )
      })
      return obj
    })
  }

  if (enumObj.length !== undefined) {
    return enumObj.map((k) => {
      return {
        text: i18n.global.t('enums.' + (translationPrefix ? translationPrefix + '.' : '') + k),
        value: k,
      }
    })
  }

  return Object.keys(enumObj).map((k) => {
    return {
      text: i18n.global.t('enums.' + (translationPrefix ? translationPrefix + '.' : '') + enumObj[k]),
      value: enumObj[k],
    }
  })
}
