import PlanitModel from './planit-model'
import Commit from './commit'
import Section from './section'
import { DocumentVersion } from './document-version'
import {
  SelectGroup,
} from './select-group'
import {
  SectionSelectGroupOptionResult,
} from './section-select-group-option-result'

import store from '@state/store'


export class DocumentBlueprintVersion extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_blueprint_id: this.attr(),

      version: this.attr(),
      data: this.attr(),
      data_is_pending: this.attr(),

      last_document_blueprint_version_id: this.attr(),
      next_document_blueprint_version_id: this.attr(),

      commit: this.hasOne(Commit, 'document_blueprint_version_id'),

      sections: this.hasManyBy(Section, 'section_ids'),
      section_ids: this.attr(),

      document_versions: this.hasMany(DocumentVersion, 'document_blueprint_version_id'),
      current_upgrade_document_blueprint_version_reverse_id: this.attr(),
    }
  }

  async createSelectGroup(name) {
    const data = { name }
    return SelectGroup.api()
      .post(this.url() + SelectGroup.$url(), data, { save: false })
      .then(async (result) => {
        await result.save()

        return result
      })
  }

  async createSectionSelectGroupOptionResult(data) {
    const result = await SectionSelectGroupOptionResult.api()
      .post(this.url() + SectionSelectGroupOptionResult.$url(), data)

    const sectionSelectGroupOptionResult = result.entities['section-select-group-option-results'].find(
      ssgor => ssgor.id === result.response.data.id
    )

    store.dispatch('blueprint/addSectionSelectGroupOptionResult', {
      sectionSelectGroupOptionResult,
      blueprintVersion: this.version,
    })

  }
}

DocumentBlueprintVersion.entity = 'document-blueprint-versions'
