import PlanitModel from './planit-model'
import Approval from './approval'
import TaskCompanyRoleAssociation from './task-company-role-association'
import Reminder from './reminder'
import ChecklistStepFolderStatus from './checklist-step-folder-status'


export default class Task extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      task_type: this.attr(),
      deadline: this.attr(),
      description: this.attr(),
      status: this.attr(),
      priority: this.attr(),

      approved_counter: this.attr(),
      required_approval_count: this.attr(),

      can_start_independently: this.attr(),
      progress: this.attr(),

      approval_id: this.attr(),
      approval: this.belongsTo(Approval, 'approval_id'),

      task_company_role_associations: this.hasMany(TaskCompanyRoleAssociation, 'task_id'),

      reminder_id: this.attr(),
      reminder: this.belongsTo(Reminder, 'reminder_id'),

      checklist_step_folder_status_id: this.attr(),
      checklist_step_folder_status: this.belongsTo(ChecklistStepFolderStatus, 'checklist_step_folder_status_id'),
    }
  }

  async refreshContent() {
    const promise = []
    promise.push(this.refreshSubResource(TaskCompanyRoleAssociation))
    Promise.allSettled(promise)
  }


}

Task.entity = 'tasks'
