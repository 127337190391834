import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistance'

export default function formatDateRelative(fromDate, toDate = new Date()) {
  fromDate = parseISO(fromDate)
  toDate = parseISO(toDate)

  let locale
  if (window.lang === 'de') {
    locale = require('date-fns/locale/de').default
  }

  // return formatDistance(fromDate, toDate, { locale }) + (isToday(toDate) ? ' ago' : '')
  return formatDistance(fromDate, toDate, { locale })
}
