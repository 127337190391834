import axios from 'axios'
import store from '@state/store'

import BrandSetting from '@state/models/brand-setting'


export const state = {
  infoCenterPages: null,
  home: null,
}

export const mutations = {
  SET_PAGES(state, pages) {
    state.infoCenterPages = pages
  },
  SET_HOME(state, home) {
    state.home = home
  },
}

export const getters = {
  pages(state) {
    return state.infoCenterPages
  },
  home(state) {
    return state.home
  },
  pagesWithHome(state) {
    if (state.infoCenterPages) {
      return state.infoCenterPages.concat([state.home])
    }

    return null
  },
  brandSetting() {
    return BrandSetting.query().first()
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {},

  async resolvePages({ commit }) {
    const locale = store.getters['auth/currentLocale']
    const url = '/icp'
    let pages = null
    let home = null

    const pagesResponse = await axios
      .get(url + '/wp-json/wp/v2/pages?per_page=100&orderby=menu_order&order=asc')
      .catch(() => {
        commit('SET_HOME', null)
        commit('SET_PAGES', null)
      })

    if (!pagesResponse) {
      return false
    }

    pages = pagesResponse.data
    const pagesTemp = []
    const localeRegex = new RegExp('/' + locale + '/', 'gi')
    pages
    .filter((page) => {
      return (
        page.slug.toLowerCase().indexOf('planit') === -1 ||
        !getters.brandSetting
      )
    })
    .forEach((page, index) => {
      if (page.link.match(localeRegex) || page.locale === locale) {
        if (page.slug.includes('home')) {
          home = page
        } else {
          pagesTemp.push(page)
        }
      }
    })

    function buildChildPages(parent) {
      const childPages = pagesTemp.filter((p) => p.parent === parent.id)

      childPages.forEach((child) => {
        if (!parent.slug.includes('home')) {
          const parentSlug = parent.fullSlug ? parent.fullSlug : parent.slug
          child.fullSlug = parentSlug + '/' + child.slug
        }
        const children = buildChildPages(child)
        if (children.length) {
          child.children = children
        }
      })

      return childPages
    }

    pages = buildChildPages(home)

    if (pages !== null) {
      commit('SET_HOME', home)
      commit('SET_PAGES', pages)
    } else {
      commit('SET_PAGES', null)
      return false
    }
  },
}
