import PlanitModel from './planit-model'
import { Document } from './document'
import DocumentBlueprint from './document-blueprint'
import Folder from './folder'

export default class FolderLink extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_blueprint_id: this.attr(),
      document_blueprint: this.belongsTo(DocumentBlueprint, 'document_blueprint_id'),

      document_id: this.attr(),
      document: this.belongsTo(Document, 'document_id'),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      menu_position: this.attr(),
      blueprint_preselected: this.attr(),
    }
  }
}

FolderLink.entity = 'folder-links'
