<script>
export default {
  name: 'ScrollContainer',
  data() {
    return {
      scrollbarVisibleClass: '',
    }
  },
  mounted() {
    new ResizeObserver(this.handleResize).observe(this.$refs.scrollContainer)
    this.setScrollbarVisibleClass()
  },
  methods: {
    handleResize() {
      setTimeout(this.setScrollbarVisibleClass, 100) //sometimes scrollContainer height is not updated yet
    },
    setScrollbarVisibleClass() {
      if (this.$refs.scrollContainer) {
        this.scrollbarVisibleClass = this.$refs.scrollContainer.scrollHeight > this.$refs.scrollContainer.clientHeight ? 'scrollbar-visible' : ''
      }
    },
  },
}
</script>

<template>
  <div
    class="fill-height scrollbar-hover"
    style="overflow-y: auto"
    ref="scrollContainer"
    :class="scrollbarVisibleClass"
  >
    <slot />
  </div>
</template>
