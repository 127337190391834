import { PermissionRoleType, SectionType, ChangelogType } from '@enums'

import store from '@state/store'
import Basket from '@state/models/basket'
import { OrderType } from '@enums'
import { path } from 'd3'
import NotFound from '@views/_404'

import { defineAsyncComponent } from 'vue'
export default [
  {
    path: '/login',
    name: 'home',
    component: () => import(/* webpackChunkNamelogin': "login" */ '@views/login'),
    beforeEnter(to, from) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn'] && store.getters['auth/currentUser'].verified) {
        // Redirect to the home page instead
        return { name: 'profile.dashboard' }
      }
    },
    meta: {
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
      useLandingPagePrivacyPolicy: true,
    },
  },
  {
    path: '/',
    name: 'login',
    redirect: { name: 'home' },
  },
  {
    path: '/demo/invite',
    name: 'demo',
    redirect: { name: 'register' },
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName': "test" */ '@views/test'),
    props: (route) => ({}),
    meta: {
      authRequired: true,
      disableLayoutGutters: true,
      belongsToApp: 'profile',
    },
  },
  {
    path: '/changelogs',
    name: '',
    component: () => import(/* webpackChunkName': "changelogs" */ '@views/changelog'),
    props: (route) => ({}),
    meta: {},
    children: [
      {
        path: '',
        name: 'changelog.list',
        component: () => import(/* webpackChunkName': "changelogs.list" */ '@views/changelog/changelogs'),
        props: (route) => ({}),
        meta: {
          // authRequired: true,
          belongsToApp: 'profile',
          transparentBars: false,
          disableLayoutGutters: true,
        },
      },
      {
        path: ':slug',
        name: 'changelog.slug',
        component: () => import(/* webpackChunkName': "changelogs.detail" */ '@views/changelog/changelog'),
        meta: {
          /* authRequired: true, */
          belongsToApp: 'profile',
          transparentBars: false,
          disableLayoutGutters: true,
          // enableFullWidth: true,
          // hideNavBar: true,
        },
        props: (route) => ({ slug: route.params.slug }),
      },
    ],
  },
  {
    path: '/saml-login',
    name: 'samlLogin',
    component: () => import(/* webpackChunkName': "saml-login" */ '@views/saml-login'),
    beforeEnter(to, from) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        return { name: 'profile.dashboard' }
      }
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile.profile" */ '@views/profile/profile'),
    meta: {
      authRequired: true,
      disableLayoutGutters: true,
      belongsToApp: 'profile',
    },
    props: (route) => ({
      userId: store.state.auth.currentUser ? store.state.auth.currentUser.id : -1,
      showInviteConfirm: !!route.params.showInviteConfirm,
      inviteConfirmText: route.params.inviteConfirmText || null,
    }),
    children: [
      {
        path: 'edit',
        name: 'profile.edit',
        component: () => import(/* webpackChunkName: "profile.edit" */ '@views/profile/edit'),
        meta: {
          authRequired: true,
        },
        // props: (route) => ({ userId: store.state.auth.currentUser.id || {} }),
      },
      {
        path: '',
        name: 'profile.dashboard',
        component: () => import(/* webpackChunkName: "profile.dashboard" */ '@views/profile/dashboard'),
        meta: {
          authRequired: true,
          waitForTargetDomLoaded: true,
        },
        props: (route) => ({
          showNextSteps: !!route.params.showNextSteps,
          showWelcomeModal: !!route.params.showWelcomeModal,
        }),
      },
      {
        path: 'payments',
        name: 'profile.payments',
        component: () => import(/* webpackChunkName: "profile.payments" */ '@views/profile/payments'),
        meta: {
          authRequired: true,
          belongsToApp: 'profile',
        },
      },
    ],
  },
  {
    path: '/styleguide',
    name: '',
    component: () => import(/* webpackChunkName: "styleguide.index" */ '@views/styleguide/index'),
    meta: {
      authRequired: true,
      adminRequired: true,
      belongsToApp: 'superadmin',
    },
    props: (route) => ({}),
    children: [
      {
        path: 'dashboard',
        name: 'styleguide.dashboard',
        component: () => import(/* webpackChunkName: "styleguide.dashboard" */ '@views/styleguide/dashboard'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'page',
        name: 'styleguide.page',
        component: () => import(/* webpackChunkName: "styleguide.page" */ '@views/styleguide/page'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'typography',
        name: 'styleguide.typography',
        component: () => import(/* webpackChunkName: "styleguide.typography" */ '@views/styleguide/typography'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'resources',
        name: 'styleguide.resources',
        component: () => import(/* webpackChunkName: "styleguide.resources" */ '@views/styleguide/resources'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
    ],
  },
  {
    path: '/companies/invite/:inviteCode',
    name: 'companyInvite',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      authRequired: false,
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
    },
    props: (route) => ({
      inviteCode: route.params.inviteCode,
      inviteMode: 'company',
    }),
  },
  {
    path: '/scorm-files/invite/:inviteCode',
    name: 'sfcaInvite',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      authRequired: false,
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
    },
    props: (route) => ({
      inviteCode: route.params.inviteCode,
      inviteMode: 'sfca',
    }),
  },
  {
    path: '/invite/:inviteCode',
    name: 'invite',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      authRequired: false,
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
    },
    props: (route) => ({
      inviteCode: route.params.inviteCode,
      inviteMode: 'invite',
    }),
  },
  {
    path: '/die3',
    name: 'die3',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      authRequired: false,
      showDie3Background: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
      hideSupport: true,
      async beforeResolve(routeTo, routeFrom, next) {
        store.commit('global/SET_DIE3MODE', true)

        if (store.getters['auth/loggedIn'] && store.getters['auth/currentUser'].verified) {
          console.log('die3 routes')
          await Order.$new({
            order_type: OrderType.DIE3,
            quantity: 12,
          })

          next({ name: 'profile.dashboard', params: { showWelcomeModal: true } })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/invite-code/:inviteCode',
    name: 'inviteCode',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      authRequired: false,
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
      hideSupport: true,
    },
    props: (route) => ({
      inviteCode: route.params.inviteCode,
      inviteMode: 'inviteCode',
    }),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '@views/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn'] && store.getters['auth/currentUser'].verified) {
          next({ name: 'profile.dashboard' })
        } else {
          next()
        }
      },
      authRequired: false,
      showLoginBackground: true,
      transparentBars: true,
      /* forceNavbarDark: true, */
      disableLayoutGutters: true,
      enableFullWidth: true,
      enableCenterContent: true,
      hideEmptyHeaderOnMobile: true,
      hideSupport: true,
      useLandingPagePrivacyPolicy: true,
    },
    props: () => ({}),
  },
  {
    path: '/google-fonts-reply',
    name: 'gfontsForm',
    component: () => import(/* webpackChunkName: "gfonts" */ '@views/gfonts'),
    meta: {
      authRequired: false,
      showLoginBackground: false,
      transparentBars: false,
      disableLayoutGutters: true,
      enableFullWidth: true,
      hideNavBar: true,
    },
    props: () => ({}),
  },
  {
    path: '/info-center',
    name: 'infoCenter',
    component: () => import(/* webpackChunkName: "infoCenter" */ '@views/info-center/index'),
    meta: {
      authRequired: true,
      fullWidth: true,
      disableLayoutGutters: true,
    },
    props: (route) => ({}),
    children: [
      {
        path: ':slug(.*)?',
        name: 'infoCenter.external',
        component: () => import(/* webpackChunkName: "infoCenter.external" */ '@views/info-center/external'),
        meta: {
          authRequired: true,
          disableLayoutGutters: true,
          waitForTargetDomLoaded: true,
        },
        props: (route) => ({ slug: route.params.slug }),
      },
    ],
  },
  {
    path: '/dsb-comment',
    name: 'dsbComment',
    component: () => import(/* webpackChunkName: "dsbComment" */ '@views/dsb-comment/index'),
    meta: {
      authRequired: true,
      disableLayoutGutters: true,
      enableFullWidth: true,
    },
  },
  {
    path: '/companies/:companyId',
    name: '',
    component: () => import(/* webpackChunkName: "company" */ '@views/company'),
    meta: {
      authRequired: true,
      disableLayoutGutters: true,
    },
    props: (route) =>
      ({
        companyId: parseInt(route.params.companyId) || {},
        showInviteConfirm: !!route.params.showInviteConfirm,
      }) || {},
    children: [
      {
        path: '',
        name: 'company.dashboard',
        component: () => import(/* webpackChunkName: "company.dashboard" */ '@views/company/dashboard'),
        meta: {
          authRequired: true,
          belongsToApp: 'company',
          disableLayoutGutters: true,
          enableFullWidth: true,
        },
        props: (route) => ({
          companyId: parseInt(route.params.companyId) || {},
        }),
      },
      {
        path: 'settings',
        name: '',
        component: () => import(/* webpackChunkName: "company.settings" */ '@views/company/settings/index'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
          belongsToApp: 'company',
        },
        props: (route) => ({
          companyId: parseInt(route.params.companyId) || {},
        }),
        children: [
          {
            path: '',
            name: 'company.settings.general',
            component: () => import(
                  /* webpackChunkName: "company.settings.general" */ '@views/company/settings/general'),
            meta: {
              authRequired: true,
              permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
            },
            props: (route) => ({
              companyId: parseInt(route.params.companyId) || {},
            }),
          },
          {
            path: 'company_data',
            name: 'company.settings.company_data',
            component: () => import(
                  /* webpackChunkName: "company.settings.company-data" */ '@views/company/settings/company-data'),
            meta: {
              authRequired: true,
              permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
              waitForTargetDomLoaded: true,
            },
            props: (route) => ({
              companyId: parseInt(route.params.companyId) || {},
            }),
          },
          {
            path: 'organization',
            name: 'company.settings.organization',
            component: () => import(
                  /* webpackChunkName: "company.settings.organization" */ '@views/company/settings/organization'),
            meta: {
              authRequired: true,
              permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
            },
            props: (route) => ({
              companyId: parseInt(route.params.companyId) || {},
            }),
          },
          {
            path: 'admin-settings',
            name: 'company.settings.adminSettings',
            component: () => import(
                  /* webpackChunkName: "company.settings.adminSettings" */ '@views/company/settings/admin-settings'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
            props: (route) => ({
              companyId: parseInt(route.params.companyId) || {},
            }),
          },
        ],
      },
      {
        path: 'people',
        name: 'company.people',
        component: () => import(/* webpackChunkName: "company.people" */ '@views/company/people'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
          belongsToApp: 'company',
        },
        props: (route) => ({
          companyId: parseInt(route.params.companyId) || {},
        }),
      },
      {
        path: 'invites',
        name: 'company.invites',
        component: () => import(/* webpackChunkName: "company.people" */ '@views/company/people'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
          belongsToApp: 'company',
        },
      },
      {
        path: 'training',
        name: 'company.training',
        component: () => import(/* webpackChunkName: "company.training" */ '@views/company/training'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.SCORM_FILES,
          waitForTargetDomLoaded: true,
        },
        props: (route) => ({ companyId: parseInt(route.params.companyId) }),
      },
      {
        path: 'marketplace',
        name: 'company.marketplace',
        component: () => import(/* webpackChunkName: "company.marketplace" */ '@views/company/marketplace'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.SCORM_FILES,
          belongsToApp: 'training',
        },
        props: (route) => ({
          companyId: parseInt(route.params.companyId),
        }),
      },
    ],
  },
  {
    path: '/companies/:companyId/scorm-participants',
    name: 'companyScormParticipants',
    component:
        () => import(
          /* webpackChunkName: "companyScormParticipants" */ '@views/company/scormFile/scormParticipants'),
    meta: {
      authRequired: true,
      permissionRoleTypeRequired: PermissionRoleType.SCORM_FILES,
      trainingAdminRequired: true,
      belongsToApp: 'training',
    },
    props: (route) => ({ companyId: parseInt(route.params.companyId) }),
  },
  {
    path: '/companies/:companyId/training-manager',
    name: 'training.trainingManager',
    component:
        () => import(/* webpackChunkName: "trainingManager" */ '@views/training/training-manager'),
    meta: {
      authRequired: true,
      permissionRoleTypeRequired: PermissionRoleType.SCORM_FILES,
      belongsToApp: 'training',
    },
    props: (route) => ({
      companyId: parseInt(route.params.companyId),
    }),
  },
  {
    path: '/companies/:companyId/my-training-plan',
    name: 'training.myTrainingSession',
    component:
        () => import(/* webpackChunkName: "myTrainingSession" */ '@views/training/my-training-plan'),
    meta: {
      authRequired: true,
      permissionRoleTypeRequired: PermissionRoleType.SCORM_FILES,
      belongsToApp: 'training',
    },
    props: (route) => ({
      companyId: parseInt(route.params.companyId),
    }),
  },
  {
    path: '/companies/:companyId/scorm-files/:scormFileId',
    name: '',
    component:
        () => import(/* webpackChunkName: "companyScormFile" */ '@views/company/scormFile/index'),
    meta: {
      authRequired: true,
      belongsToApp: 'training',
      disableLayoutGutters: true,
    },
    props: (route) => ({
      companyId: parseInt(route.params.companyId),
      scormFileId: parseInt(route.params.scormFileId),
    }),
    children: [
      {
        path: 'settings',
        name: 'companyScormFile.settings',
        component: () => import(
              /* webpackChunkName: "companyScormFile.settings" */ '@views/company/scormFile/settings'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.COMPANY_ADMIN,
        },
      },
      {
        path: '',
        name: 'companyScormFile.detail',
        component: () => import(
              /* webpackChunkName: "companyScormFile.detail" */ '@views/company/scormFile/detail'),
        meta: {
          authRequired: true,
        },
      },
    ],
  },
  {
    path: '/audit-projects/:docsAuditProjectId',
    name: 'auditProject',
    component: () => import(/* webpackChunkName: "auditProject" */ '@views/audit/detail'),
    meta: {
      authRequired: true,
      adminRequired: true,
    },
    props: (route) => ({
      docsAuditProjectId: route.params.docsAuditProjectId.toString(),
    }),
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import(/* webpackChunkNamedocuments': "documents" */ '@views/documents'),
    meta: {
      authRequired: true,
      belongsToApp: 'dataProtectionDocumentation',
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
    children: [
      {
        path: 'deletion-concept/:companyId',
        name: 'deletion-concept',
        component: () => import(/* webpackChunkName': "test" */ '@views/documents/deletion-concept'),
          props: (route) => ({
            companyId: parseInt(route.params.companyId),
          }
        ),
          children: [
          {
            path: 'results',
            name: 'deletion-concept.results',
            component: () =>
                import(/* webpackChunkName: "documents.results" */ '@views/documents/results'),
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'history',
            name: 'deletion-concept.history',
            component: () =>
                import(/* webpackChunkName: "documents.history" */ '@views/documents/history'),
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'settings',
            name: 'deletion-concept.settings',
            component: () =>
                import(/* webpackChunkName: "documents.settings" */ '@views/documents/settings'),
            meta: {
              authRequired: true,
            },
          },
          {
            path: 'exports',
            name: 'deletion-concept.exports',
            component: () =>
                import(/* webpackChunkName: "documents.exports" */ '@views/documents/exports'),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':page',
            name: 'deletion-concept.page',
            component: () =>
                import(/* webpackChunkName: "documents.page" */ '@views/documents/page'),
            meta: {
              authRequired: true,
            },
            props: ( route ) =>  ({
              page: parseInt(route.params.page),
            })
          },
          {
            path: 'uploads',
            name: 'deletion-concept.uploads',
            component: () =>
                import(/* webpackChunkName: "documents.uploads" */ '@views/documents/uploads'),
            meta: {
              authRequired: true,
            },
          },
        ],
      },
      {
        path: ':companyId',
        name: 'documents.dashboard',
        component: () => import(/* webpackChunkName: "documents.dashboard" */ '@views/documents/dashboard'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          disableLayoutGutters: true,
          enableFullWidth: false,
          waitForTargetDomLoaded: true,
        },
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
          }
        },
      },
      {
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
            isAdminView: false,
          }
        },
        path: 'companies/:companyId/prima-folder-templates/',
        name: 'documents.prima.folder.templates',
        component: () => import('@components/prima-folder-templates'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          disableLayoutGutters: true,
          enableFullWidth: false,
          waitForTargetDomLoaded: true,
        },
      },
      {
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
            primaFolderTemplateId: parseInt(route.params.primaFolderTemplateId),
            isAdminView: false,
          }
        },
        path: 'companies/:companyId/prima-folder-templates/:primaFolderTemplateId',
        name: 'documents.prima.folder.template',
        component: () => import('@components/prima-folder-template'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          disableLayoutGutters: true,
          waitForTargetDomLoaded: true,
        },
      },
      {
        path: 'repositories/:companyId',
        name: 'documents.repositories',
        component: () => import(/* webpackChunkName: "documents.repositories" */ '@views/documents/repositories'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          documentsAdminRequired: true,
          disableLayoutGutters: true,
        },
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
          }
        },
        children: [
          {
            path: ':repositoryId',
            name: 'documents.repositories.detail',
            component: () => import(
                  /* webpackChunkName: "documents.repositories.detail" */ '@views/documents/repository-detail'),
            props(route) {
              return {
                repositoryId: parseInt(route.params.repositoryId),
                companyId: parseInt(route.params.companyId),
              }
            },
            meta: {
              enableFullWidth: true,
            },
          },
        ],
      },
      {
        path: 'folders/:companyId',
        name: 'company.dataProtectionDocumentation',
        component: () => import(/* webpackChunkName: "company.files" */ '@views/documents/dashboard'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
        },
        props(route) {
          return {
            companyId: parseInt(route.params.companyId),
          }
        }
      },
      {
        path: 'folders/:companyId/:folderId',
        name: 'company.folders',
        component: () => import(/* webpackChunkName: "company.files" */ '@views/documents/base-folder'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          openModal: 'addFolderModal',
          waitForTargetDomLoaded: true,
        },
        props: (route) => {
          const props = {
            companyId: parseInt(route.params.companyId),
            folderId: parseInt(route.params.folderId),
          }
          return props
        },
      },
      // {
      //   path: 'folders/:companyId/:folderId/f',
      //   name: 'company.folders.folder-details',
      //   component: () =>
      //     lazyLoadView(() => import(/* webpackChunkName: "folder-details" */ '@views/documents/folder-details'),
      //   meta: {
      //     authRequired: true,
      //     permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
      //   },
      //   props: (route) => {
      //     const props = {
      //       companyId: parseInt(route.params.companyId),
      //       folderId: parseInt(route.params.folderId),
      //     }
      //     return props
      //   },
      // },
      {
        path: ':companyId/:documentId/upgrade/:targetDocumentBlueprintVersionId',
        name: 'documents.detailUpgrade',
        component: () => import(/* webpackChunkName: "documents.upgrade" */ '@views/documents/upgrade'),
        meta: {
          authRequired: true,
          disableLayoutGutters: true,
          enableFullWidth: false,
        },
        props: (route) => {
          return {
            documentId: parseInt(route.params.documentId) || {},
            targetDocumentBlueprintVersionId: parseInt(
              route.params.targetDocumentBlueprintVersionId
            ),
          }
        },
      },
      {
        path: ':companyId/:documentId/dv/:documentVersionVersion',
        name: 'documents.version',
        component: () => import(/* webpackChunkName: "documents.version" */ '@views/documents/version'),
        meta: {
          authRequired: true,
        },
        props: (route) => ({
          documentVersionVersion: parseInt(route.params.documentVersionVersion),
        }),
        children: [
          {
            path: ':page',
            name: 'documents.version.page',
            component: () => import(/* webpackChunkName: "documents.page" */ '@views/documents/page'),
            meta: {
              authRequired: true,
            },
            props: (route) => ({ page: parseInt(route.params.page) || {} }),
          },
        ],
      },
      {
        path: ':companyId/:documentId',
        name: 'documents.detail',
        component: () => import(/* webpackChunkName: "documents.detail" */ '@views/documents/detail'),
        meta: {
          authRequired: true,
          disableLayoutGutters: true,
          enableFullWidth: false,
          hasOwnContainer: true,
        },
        props(route) {
          return {
            documentId: parseInt(route.params.documentId) || {},
            companyId: parseInt(route.params.companyId),
          }
        },
        children: [
          {
            path: 'results',
            name: 'documents.detail.results',
            component: () => import(/* webpackChunkName: "documents.results" */ '@views/documents/results'),
            meta: {
              authRequired: true,
            },
            props: (route) => {},
          },
          {
            path: 'history',
            name: 'documents.detail.history',
            component: () => import(/* webpackChunkName: "documents.history" */ '@views/documents/history'),
            meta: {
              authRequired: true,
            },
            props: (route) => {},
          },
          {
            path: 'settings',
            name: 'documents.detail.settings',
            component: () => import(/* webpackChunkName: "documents.settings" */ '@views/documents/settings'),
            meta: {
              authRequired: true,
            },
            props: (route) => {},
          },
          {
            path: 'exports',
            name: 'documents.detail.exports',
            component: () => import(/* webpackChunkName: "documents.exports" */ '@views/documents/exports'),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':page',
            name: 'documents.detail.page',
            component: () => import(/* webpackChunkName: "documents.page" */ '@views/documents/page'),
            meta: {
              authRequired: true,
            },
            props: (route) => ({ page: parseInt(route.params.page) || {} }),
          },
        ],
      },
    ],
  },
  {
    path: '/companies/:companyId/rollouts',
    name: '',
    component: /* webpackChunkName': "rollouts" */ () => import('@views/rollouts/index'),
    meta: {
      authRequired: true,
      belongsToApp: 'rollouts',
    },
    props: (route) => {
      return {
        companyId: parseInt(route.params.companyId),
      }
    },
    children: [
      {
        path: 'manager',
        name: 'rollouts.manager',
        component: () => import('@views/rollouts/manager'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.ROLLOUTS,
          disableLayoutGutters: true,
          enableFullWidth: false,
          waitForTargetDomLoaded: true,
        },
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
          }
        },
      },
      {
        path: 'assigned_rollouts',
        name: 'rollouts.assigned_rollouts',
        component: () => import('@views/rollouts/assigned-rollouts'),
        meta: {
          authRequired: true,
          disableLayoutGutters: true,
          enableFullWidth: false,
          waitForTargetDomLoaded: true,
        },
        props: (route) => {
          return {
            companyId: parseInt(route.params.companyId),
          }
        },
      },
    ],
  },
  {
    path: '/rollouts/code/:rolloutCode',
    name: 'rollouts.code.rollout',
    component: () => import('@views/rollouts/rollout-viewer'),
    meta: {
      authRequired: false,
      belongsToApp: 'rollouts',
    },
    props: (route) => ({
      rolloutCode: route.params.rolloutCode.toString(),
    }),
  },
  {
    path: '/admin',
    name: '',
    component: () => import(/* webpackChunkName: "admin.index" */ '@views/admin/index'),
    meta: {
      belongsToApp: 'superadmin',
    },
    children: [
      {
        path: 'audit-projects',
        name: 'admin.auditProjects',
        component: () => import(
              /* webpackChunkName: "admin.auditProjects.list" */ '@views/admin/audit-projects/list'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'audit-projects/:auditProjectId/:docsAuditProjectId',
        name: 'admin.auditProjects.detail',
        component: () => import(
              /* webpackChunkName: "admin.auditProjects.detail" */ '@views/admin/audit-projects/detail'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          auditProjectId: route.params.auditProjectId,
          docsAuditProjectId: route.params.docsAuditProjectId.toString(),
        }),
      },
      {
        path: 'scorm-files',
        name: 'admin.scormFiles',
        component: () => import(/* webpackChunkName: "admin.scormFiles" */ '@views/admin/scorm/scormFiles'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        // props: (route) => ({ user: store.state.auth.currentUser || {} }),
      },
      {
        path: 'scorm-files/:scormFileId',
        name: '',
        component: () => import(/* webpackChunkName: "admin.scormFile" */ '@views/admin/scorm/scormFile'),
        meta: {
          authRequired: true,
          adminRequired: true,
          enableFullWidth: false,
          disableLayoutGutters: true,
        },
        props: (route) => ({ scormFileId: parseInt(route.params.scormFileId) }),
        children: [
          {
            path: 'settings',
            name: 'admin.scormFile.settings',
            component: () => import(
                  /* webpackChunkName: "admin.scormFileSettings" */ '@views/admin/scorm/scormFileSettings'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
          },
          {
            path: 'participants',
            name: 'admin.scormFile.participants',
            component: () => import(
                  /* webpackChunkName: "admin.scormFileParticipants" */ '@views/admin/scorm/scormFileParticipants'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
          },
          {
            path: '',
            name: 'admin.scormFile.preview',
            component: () => import(
                  /* webpackChunkName: "admin.scormFilePreview" */ '@views/admin/scorm/scormFilePreview'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
          },
        ],
      },
      {
        path: 'users',
        name: '',
        component: () => import(/* webpackChunkName: "empty" */ '@views/empty'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        children: [
          {
            path: '',
            name: 'admin.users',
            component: () => import(/* webpackChunkName: "admin.users" */ '@views/admin/users'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
          },
          {
            path: ':userId',
            name: 'admin.user',
            component: () => import(/* webpackChunkName: "admin.user" */ '@views/admin/user'),
            meta: {
              authRequired: true,
              adminRequired: true,
            },
            props: (route) => ({ userId: parseInt(route.params.userId) }),
          },
        ],
      },
      {
        path: 'companies',
        name: 'admin.companies',
        component: () => import(/* webpackChunkName: "admin.companies" */ '@views/admin/company/companies'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        // props: (route) => ({ user: store.state.auth.currentUser || {} }),
      },
      {
        path: 'folders',
        name: 'admin.folders',
        component: () => import(/* webpackChunkName: "admin.folders" */ '@views/admin/folders'),
        meta: {
          authRequired: true,
          adminRequired: true,
          managerRequired: true,
          belongsToApp: ['superadmin', 'manager'],
        },
        props: (route) => {},
      },
      {
        path: 'settings',
        name: 'admin.settings',
        component: () => import(/* webpackChunkName: "admin.settings" */ '@views/admin/settings'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => {},
      },
      {
        path: 'blueprints',
        name: 'admin.documentBlueprints',
        component: () => import(
              /* webpackChunkName: "admin.document-blueprint.document-blueprints" */ '@views/admin/document-blueprint/documentBlueprints'),
        meta: {
          authRequired: true,
          adminRequired: true,
          managerRequired: true,
          belongsToApp: ['superadmin', 'manager'],
        },
        props: (route) => {},
      },
      {
        path: 'prima-folder-templates',
        name: 'admin.prima.folder.templates',
        component: () => import('@components/prima-folder-templates'),
        meta: {
          authRequired: true,
          adminRequired: true,
          managerRequired: true,
          belongsToApp: ['superadmin', 'manager'],
        },
        props: (route) => {
          return {
            isAdminView: true,
          }
        },
      },
      {
        path: 'prima-folder-templates/:primaFolderTemplateId',
        name: 'admin.prima.folder.template',
        props: (route) => {
          return {
            primaFolderTemplateId: parseInt(route.params.primaFolderTemplateId),
            isAdminView: true,
          }
        },
        component: () => import('@components/prima-folder-template'),
        meta: {
          authRequired: true,
          permissionRoleTypeRequired: PermissionRoleType.DOCUMENTS,
          disableLayoutGutters: true,
          enableFullWidth: false,
          waitForTargetDomLoaded: true,
        },
      },
      {
        path: 'blueprints/:documentBlueprintId',
        name: '',
        component: () => import(
              /* webpackChunkName: "admin.document-blueprint.document-blueprint" */ '@views/admin/document-blueprint/documentBlueprint'),
        meta: {
          authRequired: true,
          adminRequired: true,
          managerRequired: true,
          belongsToApp: ['superadmin', 'manager'],
          enableFullWidth: false,
          hasOwnContainer: true,
          disableLayoutGutters: true,
        },
        props: (route) => ({
          documentBlueprintId: parseInt(route.params.documentBlueprintId),
        }),
        children: [
          {
            path: 'history',
            name: 'admin.documentBlueprint.history',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.history" */ '@views/admin/document-blueprint/documentBlueprintHistory'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'version-matrix',
            name: 'admin.documentBlueprint.versionMatrix',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.version-matrix" */ '@views/admin/document-blueprint/documentBlueprintVersionMatrix'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
              enableFullWidth: true,
              disableLayoutGutters: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'templates',
            name: '',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.templates" */ '@views/admin/document-blueprint/documentBlueprintTemplates'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
            children: [
              {
                path: 'upgrade/:templateId/:targetDocumentBlueprintVersionId',
                name: 'admin.documentBlueprint.templates.detailUpgrade',
                component: () => import(/* webpackChunkName: "documents.upgrade" */ '@views/documents/upgrade'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => {
                  return {
                    documentId: parseInt(route.params.templateId),
                    targetDocumentBlueprintVersionId: parseInt(
                      route.params.targetDocumentBlueprintVersionId
                    ),
                  }
                },
              },
              {
                path: ':templateId',
                name: 'admin.documentBlueprint.templates.detail',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.templates-detail" */ '@views/admin/document-blueprint/documentBlueprintTemplatesDetail'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({
                  templateId: parseInt(route.params.templateId),
                }),
                children: [
                  {
                    path: ':page',
                    name: 'admin.documentBlueprint.templates.detail.page',
                    component: () => import(/* webpackChunkName: "documents.page" */ '@views/documents/page'),
                    meta: {
                      authRequired: true,
                      adminRequired: true,
                      managerRequired: true,
                    },
                    props: (route) => ({
                      page: parseInt(route.params.page) || {},
                    }),
                  },
                ],
              },
              {
                path: '',
                name: 'admin.documentBlueprint.templates.list',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.templates-list" */ '@views/admin/document-blueprint/documentBlueprintTemplatesList'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({
                  documentBlueprintId: parseInt(route.params.documentBlueprintId),
                }),
              },
            ],
          },
          {
            path: 'variants',
            name: 'admin.documentBlueprint.variants',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.variants" */ '@views/admin/document-blueprint/documentBlueprintVariants'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'settings',
            name: 'admin.documentBlueprint.settings',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.settings" */ '@views/admin/document-blueprint/documentBlueprintSettings'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'documents',
            name: 'admin.documentBlueprint.documents',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.documents" */ '@views/admin/document-blueprint/documentBlueprintDocuments'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'npi-blueprints',
            name: 'admin.documentBlueprint.nonPrimaryInstanceBlueprints',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.npi-blueprints" */ '@views/admin/document-blueprint/documentBlueprintNonPrimaryInstanceBlueprints.vue'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
          },
          {
            path: 'history/:documentBlueprintVersionVersion',
            name: 'admin.documentBlueprint.version',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.version" */ '@views/admin/document-blueprint/documentBlueprintVersion'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
              documentBlueprintVersionVersion: parseInt(
                route.params.documentBlueprintVersionVersion
              ),
            }),
            children: [
              {
                path: ':page',
                name: 'admin.documentBlueprint.version.page',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.sections.page" */ '@views/admin/document-blueprint/documentBlueprintSectionsPage'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({ page: parseInt(route.params.page) || {} }),
              },
            ],
          },
          {
            path: '',
            name: 'admin.documentBlueprint.sections',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.sections" */ '@views/admin/document-blueprint/documentBlueprintSections'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
            }),
            children: [
              {
                path: ':page',
                name: 'admin.documentBlueprint.sections.page',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.sections.page" */ '@views/admin/document-blueprint/documentBlueprintSectionsPage'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({ page: parseInt(route.params.page) || {} }),
              },
            ],
          },
          {
            path: 'results',
            name: 'admin.documentBlueprint.results',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.sections" */ '@views/admin/document-blueprint/documentBlueprintSections'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
              sectionTypes: [SectionType.RESULT],
            }),
            children: [
              {
                path: ':page',
                name: 'admin.documentBlueprint.results.page',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.sections.page" */ '@views/admin/document-blueprint/documentBlueprintSectionsPage'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({ page: parseInt(route.params.page) || {} }),
              },
            ],
          },
          {
            path: 'condition-groups',
            name: 'admin.documentBlueprint.conditionGroups',
            component: () => import(
                  /* webpackChunkName: "admin.document-blueprint.sections" */ '@views/admin/document-blueprint/documentBlueprintSections'),
            meta: {
              authRequired: true,
              adminRequired: true,
              managerRequired: true,
            },
            props: (route) => ({
              documentBlueprintId: parseInt(route.params.documentBlueprintId),
              sectionTypes: [SectionType.CONDITION_GROUP],
            }),
            children: [
              {
                path: ':page',
                name: 'admin.documentBlueprint.conditionGroups.page',
                component: () => import(
                      /* webpackChunkName: "admin.document-blueprint.sections.page" */ '@views/admin/document-blueprint/documentBlueprintSectionsPage'),
                meta: {
                  authRequired: true,
                  adminRequired: true,
                  managerRequired: true,
                },
                props: (route) => ({ page: parseInt(route.params.page) || {} }),
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: () => import(/* webpackChunkName: "admin.dashboard" */ '@views/admin/dashboard'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
      },
      {
        path: 'licenses',
        name: 'admin.licenses',
        component: () => import(/* webpackChunkName: "admin.licenses" */ '@views/admin/licenses'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: true,
      },
      {
        path: 'promotion',
        name: 'admin.promotions',
        component: () => import(/* webpackChunkName: "admin.promotions" */ '@views/admin/promotion'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: true,
      },
      {
        path: 'table-columns',
        name: 'admin.tableColumns',
        component: () => import(/* webpackChunkName: "admin.tableColumns" */ '@views/admin/table-columns'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: true,
      },
      {
        path: 'permission-roles',
        name: 'admin.permissionRoles',
        component: () => import(/* webpackChunkName: "admin.permissionRoles" */ '@views/admin/permission-roles'),
        meta: {
          authRequired: true,
          adminRequired: true,
          enableFullWidth: false,
        },
      },
      {
        path: 'permission-role/:permissionRoleId',
        name: 'admin.permissionRole',
        component: () => import(/* webpackChunkName: "admin.permissionRole" */ '@views/admin/permission-role'),
        meta: {
          authRequired: true,
          adminRequired: true,
        },
        props: (route) => ({
          permissionRoleId: parseInt(route.params.permissionRoleId),
        }),
      },
      {
        path: 'guides',
        name: 'admin.guides',
        component: () => import(/* webpackChunkName: "admin.guides" */ '@views/admin/guides'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: false,
        },
      },
      {
        path: 'payments',
        name: 'admin.payments',
        component: () => import(/* webpackChunkName: "admin.payments" */ '@views/admin/payments'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'events',
        name: 'admin.events',
        component: () => import(/* webpackChunkName: "admin.events" */ '@views/admin/events'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'prima-instances',
        name: 'admin.instances',
        component: () => import(/* webpackChunkName: "admin.instances" */ '@views/admin/instances'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'mega-admin',
        name: 'admin.megaAdmin',
        component: () => import(/* webpackChunkName: "admin.megaAdmin" */ '@views/admin/mega-admin'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'user-reminder',
        name: 'admin.userReminders',
        component: () => import(/* webpackChunkName: "admin.userReminder" */ '@views/admin/user-reminders'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'debug-blueprints',
        name: 'admin.debugBlueprints',
        component: () => import(/* webpackChunkName: "admin.debugBlueprints" */ '@views/admin/debug-blueprints'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'download-resources',
        name: 'admin.downloadResources',
        component: () => import(
              /* webpackChunkName: "admin.downloadResources" */ '@views/admin/download-resources'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'repositories',
        name: 'admin.repositories',
        component: () => import(/* webpackChunkName: "documents.repositories" */ '@views/documents/repositories'),
        meta: {
          authRequired: true,
          adminRequired: true,
          disableLayoutGutters: true,
        },
        props: (route) => {
          return {}
        },
        children: [
          {
            path: ':repositoryId',
            name: 'admin.repositories.detail',
            component: () => import(
                  /* webpackChunkName: "documents.repositories.detail" */ '@views/documents/repository-detail'),
            props(route) {
              return {
                repositoryId: parseInt(route.params.repositoryId),
              }
            },
            meta: {
              enableFullWidth: true,
            },
          },
        ],
      },
      {
        path: 'reminders',
        name: 'admin.reminders',
        component: () => import(/* webpackChunkName: "admin.reminders" */ '@views/admin/reminders'),
        meta: {
          authRequired: true,
          adminRequired: true,
          primaryInstanceRequired: true,
        },
      },
      {
        path: 'blog',
        name: 'admin.blog',
        component: () => import(/* webpackChunkName': "admin.blog" */ '@views/changelog/changelogs'),
        props: (route) => ({ changelogType: ChangelogType.BLOG }),
        meta: {
          belongsToApp: 'profile',
          transparentBars: false,
          disableLayoutGutters: true,
        },
      },
    ],
  },
  {
    path: '/manage',
    name: '',
    component: () => import(/* webpackChunkName: "manager.index" */ '@views/manager'),
    meta: {
      authRequired: true,
      managerRequired: true,
      belongsToApp: 'manager',
    },
    children: [
      {
        path: '',
        name: 'manager.dashboard',
        component: () => import(/* webpackChunkName: "manager.dashboard" */ '@views/admin/manager-dashboard'),
        meta: {
          authRequired: true,
          managerRequired: true,
        },
      },
      {
        path: 'permission-roles',
        name: 'manager.permissionRoles',
        component: () => import(/* webpackChunkName: "admin.permissionRoles" */ '@views/admin/permission-roles'),
        meta: {
          authRequired: true,
          managerRequired: true,
          enableFullWidth: false,
        },
      },
      {
        path: 'permission-role/:permissionRoleId',
        name: 'manager.permissionRole',
        component: () => import(/* webpackChunkName: "admin.permissionRole" */ '@views/admin/permission-role'),
        meta: {
          authRequired: true,
          managerRequired: true,
        },
        props: (route) => ({
          permissionRoleId: parseInt(route.params.permissionRoleId),
        }),
      },
      {
        path: 'licenses',
        name: 'manager.licenses',
        component: () => import(/* webpackChunkName: "admin.licenses" */ '@views/admin/licenses'),
        meta: {
          authRequired: true,
          managerRequired: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/audit-tool',
    alias: '/audit-tool/*',
    name: 'adminAuditTool',
    component: () => import(/* webpackChunkName: "admin.auditTool" */ '@views/admin/audit-tool'),
    meta: {
      authRequired: true,
      adminRequired: true,
      disableLayoutGutters: true,
      enableFullWidth: true,
    },
    props: (route) => {
      return {
        subpath: route.path.split('/admin/audit-tool')[1],
      }
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "reset-password" */ '@views/reset-password'),
    beforeEnter( to, from) {
      if (store.getters['auth/loggedIn']) {
        store.dispatch('auth/logOut', { refreshUrl: to.fullPath })
        return false
      }
    },
    meta: {
      showLoginBackground: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from) {
      const authRequiredOnPreviousRoute = from.matched.some(
        (route) => route.meta.authRequired
      )
      store.dispatch('auth/logOut', {
        refreshUrl: authRequiredOnPreviousRoute ? '/' : from.path,
      })
    },
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/nutzungsbedingungen',
    alias: '/terms-of-use',
    name: 'brand.terms_of_use',
    component:
        () => import(/* webpackChunkName: "brand.terms_of_use" */ '@views/brand/terms-of-use'),
    meta: {
      belongsToApp: 'empty',
      hideNavBar: true,
    },
  },
  {
    path: '/datenschutz',
    alias: '/data-protection',
    name: 'dataProtection',
    component: () => import(/* webpackChunkName: "dataProtection" */ '@views/brand/data-protection'),
    meta: {
      belongsToApp: 'empty',
      hideNavBar: true,
    },
  },
  {
    path: '/datenschutz-landing-page',
    alias: '/data-protection-landing-page',
    name: 'dataProtectionLandingPage',
    component: () => import(/* webpackChunkName: "dataProtection" */ '@views/brand/data-protection'),
    meta: {
      belongsToApp: 'empty',
      hideNavBar: true,
    },
  },
  {
    path: '/impressum',
    alias: '/imprint',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "imprint" */ '@views/brand/imprint'),
    meta: {
      belongsToApp: 'empty',
      hideNavBar: true,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "support" */ '@views/support'),
    meta: {
      hideSupport: true,
      belongsToApp: 'empty',
      hideNavBar: true,
    },
  },
  // special routes that are the same for every user and redirect to a corresponding page with company_id set to first available
  {
    path: '/company-dashboard',
    name: 'company-dashboard',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.dashboard',
      },
    },
  },
  {
    path: '/company-invites',
    name: 'company-invites',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.people',
        params: {
          openInviteModal: true,
        },
      },
    },
  },
  {
    path: '/company-create-task',
    name: 'company-create-task',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'documents.detail.page',
        params: {
          openTaskModal: true,
          page: 1,
        },
      },
    },
  },
  {
    path: '/company-processing-directories',
    name: 'company-processing-directories',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.folders',
        params: {
          folderId: 1,
        },
      },
    },
  },
  {
    path: '/company-audit',
    name: 'company-audit',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.folders',
        params: {
          folderId: 7,
        },
      },
    },
  },
  {
    path: '/company-vendors',
    name: 'company-vendors',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.folders',
        params: {
          folderId: 3,
        },
      },
    },
  },
  {
    path: '/company-deletion-concept',
    name: 'company-deletion-concept',
    meta: {
      authRequired: true,
      redirectWithCompanyId: {
        name: 'company.folders',
        params: {
          folderId: 1271,
        },
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true, // Erlaubt das Übergeben von Props zur 404-Seite
    meta: {
      hideNavBar: true,
      belongsToApp: 'empty',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404', // Absolute Pfadangabe empfohlen
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => import(/* webpackChunkName: "my-view" */ '@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => () => import(/* webpackChunkName: "my-view" */ '@views/my-view')
//

function lazyLoadView(loader) {
  return defineAsyncComponent({
    loader: () => loader,
    loadingComponent: require('@views/_loading').default,
    errorComponent: require('@views/_timeout').default,
    delay: 200,
    timeout: 20000,
  })
}
