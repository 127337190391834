import { Model } from '@vuex-orm/core'

import Section from './section'
import Answer from './answer'
import { Document } from './document'
import { DocumentVersion } from './document-version'
import RepositoryReferenceItem from './repository-reference-item'


export default class SectionReferenceItem extends Model {
  /**
   * Wrapper class that holds all relevant information for a section reference to make API retrival easier
   */
  static fields() {
    return {
      id: this.uid(),

      new: this.attr(),
      deleted: this.attr(),

      section_id: this.attr(),
      section: this.belongsTo(Section, 'section_id'),

      answer_id: this.attr(),
      answer: this.belongsTo(Answer, 'answer_id'),

      document_id: this.attr(),
      document: this.belongsTo(Document, 'document_id'),

      document_version_id: this.attr(),
      document_version: this.belongsTo(DocumentVersion, 'document_version_id'),

      next_section_reference_item_id: this.attr(),
      next_section_reference_item: this.belongsTo(SectionReferenceItem, 'next_section_reference_item_id'),

      table_content: this.attr(),
      position: this.attr(),
      referred_document_outdated: this.attr(),

      repository_reference_item_id: this.attr(),
      repository_reference_item: this.belongsTo(RepositoryReferenceItem, 'repository_reference_item_id'),

    }
  }

  hasUpdate() {
    return (
      this.new ||
      this.deleted ||
      this.next_section_reference_item
    )
  }

  updateAttr(data) {
    return this.constructor.update({
      where: this.$id,
      data,
    })
  }

}

SectionReferenceItem.entity = 'section-reference-items'
