1<script>
import {
  groupBy,
} from 'lodash'

import PlanitInput from '@components/form/input'
import IconButton from '@components/icon-button'

import {
  CompanyLinkType,
} from '@enums'

import store from '@state/store'

import CompanyLink from '@state/models/company-link'


export default {
  name: 'CompanyLinkEdit',
  components: {
    PlanitInput,
    IconButton,
  },
  props: {
  },
  data() {
    return {
      CompanyLinkType,

      loading: true,

    }
  },
  computed: {
    company() {
      return store.getters['navbar/selectedCompany']
    },
    companyLinksByType() {
      const allCompanyLinks = CompanyLink.query().where('company_id', this.company.id).all()

      return groupBy(allCompanyLinks, 'company_link_type')
    },
  },
  async created() {
    await Promise.allSettled([
      this.company.refreshSubResource(CompanyLink),
    ])

    await this.initEmptyCompanyLinks()

    this.loading = false

  },
  methods: {
    async initEmptyCompanyLinks() {
      for (const companyLinkType of Object.values(CompanyLinkType)) {
        if ((this.companyLinksByType[companyLinkType] || []).length === 0) {
          await CompanyLink.insert({
            data: {
              company_id: this.company.id,
              company_link_type: companyLinkType,
            }
          })
        }
      }

    },
    async createCompanyLink(companyLinkType) {
      await CompanyLink.insert({
        data: {
          company_id: this.company.id,
          company_link_type: companyLinkType,
        }
      })

    },
    async deleteCompanyLink(companyLink) {
      const companyLinkType = companyLink.company_link_type
      await companyLink.delete()
      await this.initEmptyCompanyLink(companyLinkType)
    },
    async initEmptyCompanyLink(companyLinkType) {
      await CompanyLink.insert({
        data: {
          company_id: this.company.id,
          company_link_type: companyLinkType,
        }
      })

    },
  },
}
</script>

<template>
  <div v-if="!loading">
    <div v-for="companyLinkType in Object.values(CompanyLinkType)" :key="companyLinkType">
      <div
        class="my-4 d-flex align-center"
      >
        <h3>
          {{ $t('company.settings.company_links_' + companyLinkType) }}
        </h3>

        <IconButton
          v-if="
            companyLinkType === CompanyLinkType.PRIVACY_POLICY
            || (companyLinksByType[companyLinkType] || []).length === 0
          "
          icon="fa-plus"
          color="primary"
          class="ml-2"
          @click="createCompanyLink(companyLinkType)"
        ></IconButton>
      </div>

      <div
        v-for="companyLink in companyLinksByType[companyLinkType]"
        :key="companyLink.id"
        class="d-flex align-center mb-2"
      >
        <PlanitInput
          :model="companyLink"
          attr="href"
          :label="$t('company.settings.company_links_url')"
          highlight
          save-on-blur
          class="flex-grow-1"
        ></PlanitInput>

        <IconButton
          icon="fa-trash-alt"
          class="ml-2"
          @click="deleteCompanyLink(companyLink)"
        ></IconButton>
      </div>
    </div>
  </div>
</template>
