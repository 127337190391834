import store from '@state/store'

import PlanitModel from './planit-model'
import Company from './company'
import PermissionRole from '@state/models/permission-role'


export default class InviteCode extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      invite_link: this.attr(),
      invite_data: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),
    }
  }

}

InviteCode.entity = 'invite-codes'
