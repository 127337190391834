import {
  SLIDE_DURATION_MAX_DISCREPANCY,
  ScormStatus,
} from '@enums'

import PlanitModel from './planit-model'
import ScormFile from './scorm-file'
import CompanyRole from './company-role'
import SessionScormLink from './session-scorm-link'


export default class CompanyRoleScormStatus extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      company_role_id: this.attr(),
      company_role: this.belongsTo(CompanyRole, 'company_role_id'),

      session_scorm_link_id: this.attr(),
      session_scorm_link: this.belongsTo(SessionScormLink, 'session_scorm_link_id'),

      status: this.attr(),
      data: this.attr(),

      finished_at: this.attr(),

      session_start_date: this.attr(),
      session_end_date: this.attr(),
      progress: this.attr(),

      // local fields
      unsyncedLessonData: this.attr([]),
    }
  }

  startLesson(lessonName, previousLessonName) {
    const lessonData = {
      now: Date.now(),
      lessonName,
    }

    if (lessonName.indexOf('Slide_') !== -1) {
      lessonData.slide = parseInt(lessonName.split('Slide_')[1])
    } else if (previousLessonName) {
      const previousLesson = this.data.lesson_data.find((l) => l.name === previousLessonName)
      if (previousLesson) {
        if (previousLesson.slide) {
          lessonData.slide = previousLesson.slide + 1
        } else {
          lessonData.slide = this.data.lesson_data.indexOf(previousLesson) + 2
        }
      } else {
        // wrong
      }
    } else {
      // must be first otherwise
      lessonData.slide = 1
    }

    if (previousLessonName) {
      lessonData.previousLessonName = previousLessonName
    }

    const start_lesson = [
      ...this.unsyncedLessonData,
      lessonData,
    ]

    return this.sync(null, { start_lesson })
      .then((r) => {
        return this.updateAttr({ unsyncedLessonData: [] }).then(() => {
          return r
        })
      })
      .catch((error) => {
        this.updateAttr({
          unsyncedLessonData: [...this.unsyncedLessonData, lessonData],
        })
      })
  }

  getSlideDuration(slideNum) {
    slideNum = parseInt(slideNum)
    const crssSlideData = this.data?.lesson_data?.find((l) => l.slide === slideNum)

    if (!crssSlideData || !crssSlideData.started || !crssSlideData.ended) {
      return null
    }

    return crssSlideData.ended - crssSlideData.started
  }

  getScormFileData() {
    const sf = ScormFile.allFast().find(sf => sf.id === this.scorm_file_id)
    const locale = sf.current_locale
    const dataKeys = Object.keys(sf.data)
    if (dataKeys.length === 0) {
      return {}
    }

    return sf.data[locale] || sf.data[dataKeys[0]]
  }

  getFinishedSlidesCount(scormFile) {
    const scormFileData = this.getScormFileData()
    if (!scormFileData?.slide_data) {
      return null
    }

    if (this.status === ScormStatus.FINISHED_SUCCESS) {
      return scormFileData.slide_data.length
    }

    return Object.keys(scormFileData.slide_data).filter((slideNum) => {
      const expected = scormFileData.slide_data[slideNum].duration
      const actual = this.getSlideDuration(slideNum)
      if (!actual) {
        return false
      }

      // difference cant be greater than threshold
      return actual - expected > -1 * SLIDE_DURATION_MAX_DISCREPANCY
    }).length
  }

  getScore() {
    if (this.status === ScormStatus.FINISHED_SUCCESS) {
      return 100
    }

    // == is correct as we want to match both null and undefined
    if (
      !this.data?.cmi?.core?.score ||
      this.data.cmi.core.score.raw == null ||
      this.data.cmi.core.score.min == null ||
      this.data.cmi.core.score.max == null
    ) {

      return null

    }

    return Math.round(
      (100 * parseInt(this.data.cmi.core.score.raw)) /
        (parseInt(this.data.cmi.core.score.max) - parseInt(this.data.cmi.core.score.min))
    )
  }

  downloadCertificate() {
    // dont use window.open to not trigger popup warning
    const el = document.createElement('a')
    el.href = '/api/company-role-scorm-status/' + this.id + '/certificate'
    // el.target = '_blank'
    el.click()
  }
}

CompanyRoleScormStatus.entity = 'company-role-scorm-status'
