<script>
import store from '@state/store'
import BrandSetting from '@state/models/brand-setting'
import { debugGetters, debugActions, authMethods } from '@state/helpers'


export default {
  name: 'PlanitFooterContent',
  props: {
    fromFooter: { type : Boolean, default: false, required: false },
  },
  components: {},
  data() {
    return {
      debugLoginAs: '',
      debugLoginData: [
        {
          email: 'admin@planit.legal',
          name: 'Superadmin',
          icon: 'fal fa-fw fa-wand-magic',
        },
        {
          email: 'license_manager@planit.legal',
          name: 'License Manager',
          icon: 'fal fa-fw fa-user-tie',
        },
        {
          email: 'company_admin@planit.legal',
          name: 'Company Admin',
          icon: 'fal fa-fw fa-user-crown',
        },
        {
          email: 'company_user@planit.legal',
          name: 'Company Editor',
          icon: 'fal fa-fw fa-user',
        },
        {
          email: 'company_observer@planit.legal',
          name: 'Company Observer',
          icon: 'fal fa-fw fa-user',
        },
      ],
    }
  },
  computed: {
    ...debugGetters,
    hideDebugFooterComputed: {
      get() {
        return store.getters['debug/hideDebugFooter']
      },
      set(value) {
        return store.dispatch('debug/setHideDebugFooter', value)
      },
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    currentYear() {
      return new Date().getFullYear()
    },
    brandSetting() {
      return BrandSetting.query().first()
    },
    brandLogoFooterSrc() {
      return this.brandSetting?.logo_brand && '/api/images/' + this.brandSetting.logo_brand + '.png'
    },
    tosTo() {
      return store.getters['global/tosTo']
    },
    isPrimaryInstance() {
      return window.primary_instance
    },
    footerColor() {
      /* if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.transparentBars)) {
        return 'transparent'
      } */
    },
    dark() {
      // return this.footerColor === 'transparent'
      return false
    },
    useLandingPagePrivacyPolicy() {
      return this.$route.matched.some(
        (matchedRoute) => matchedRoute.meta?.useLandingPagePrivacyPolicy
      )
    },
    privacyPolicyLink() {
      const link = '/' + (
        this.useLandingPagePrivacyPolicy ?
          this.$t('data_protection.path_name_landing_page') :
          this.$t('data_protection.path_name')
      )

      return link
    },
    termsText() {
      return this.brandSetting?.terms_display_text_translations?.[this.currentLocale] || this.$t('components.footer.terms_of_use')
    },
    privacyPolicyText() {
      return this.brandSetting?.privacy_policy_display_text_translations?.[this.currentLocale] || this.$t('data_protection.data_protection')
    },
    imprintText() {
      return this.brandSetting?.imprint_display_text_translations?.[this.currentLocale] || this.$t('imprint.imprint')
    },
  },
  mounted() {
    this.$nextTick(() => {
      // couldnt figure out what is causing the layout to be that different up to ~100ms in
      setTimeout(() => {
        store.dispatch('navbar/setSmallScreenVariables')
      }, 250)
    })
  },
  methods: {
    ...debugActions,
    ...authMethods,
    showConsentSettings() {
      if (window.initKlaro) {
        window.initKlaro({ force: true })
      }
    }
  },
}
</script>

<template>
  <div :id="fromFooter ? '' : 'planit-footer'">
    <div class="d-flex align-center justify-center">
      <div class="d-flex align-center justify-center flex-wrap">
        <span class="mx-4" :class="dark ? 'text-white' : ''">&copy;{{ currentYear }}</span
        ><!-- <strong class="text-primary">PLANIT <span class="text-success">//</span> LEGAL</strong></span>-->

        <img
          v-if="brandLogoFooterSrc"
          :src="brandLogoFooterSrc"
          style="width: auto; max-height: 2rem;"
        />

        <img
          v-else-if="!brandLogoFooterSrc && !dark"
          src="@/public/logo_planit_tech.svg"
          style="width: auto; height: 0.75rem;"
        />

        <img
          v-else
          src="@/public/logo_planit_tech_dark.svg"
          style="width: auto; height: 0.75rem;"
        />
        <router-link
          v-if="!brandSetting || brandSetting.tos_text || brandSetting.tos_file"
          class="mx-4"
          :to="tosTo"
          :class="dark ? 'text-white' : ''"
        >
          {{ termsText }}
        </router-link>

        <router-link
          v-if="!brandSetting || brandSetting.dpi_text || brandSetting.dpi_file"
          class="mx-4"
          :to="{
            path: privacyPolicyLink,
          }"
          :class="dark ? 'text-white' : ''"
        >
          {{ privacyPolicyText }}
        </router-link>

        <router-link
          v-if="!brandSetting || brandSetting.imprint_text || brandSetting.imprint_file"
          class="mx-4"
          :to="{ path: '/' + $t('imprint.path_name') }"
          :class="dark ? 'text-white' : ''"
        >
          {{ imprintText }}
        </router-link>

        <a
          v-if="!brandSetting"
          class="mx-4"
          :class="dark ? 'text-white' : ''"
          @click="showConsentSettings"
        >
          {{ $t('components.footer.consent_settings') }}
        </a>
      </div>

      <PlanitButton
        v-if="showDebugInfo && hideDebugFooterComputed && !$vuetify.display.mobile"
        class="mx-4 pa-2"
        variant="text"
        @click="hideDebugFooterComputed = false"
      >
        <PlanitIcon size="small" icon="far fa-fw fa-chevron-up"></PlanitIcon>

      </PlanitButton>
    </div>
  </div>
</template>
