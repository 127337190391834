<script>

import {
  BASIC_PRICE_MONTHLY,
  PRO_PRICE_MONTHLY,
  LicenseType,
} from '@enums'

export default {
  name: 'Die3Description',
  components: {
  },
  props: {
  },
  data: function () {
    return {
      LicenseType,
    }
  },
}
</script>

<template>
  <div>
    <ol class="die3-ol-list pl-0" style="list-style-position: outside;">
      <li>
        <div>
          <h3>
            <i18n-t keypath="die3.prima_year_license">
              <template #licenseType>{{ $t('enums.license_type.' + LicenseType.BASIC) }}</template>
            </i18n-t>
          </h3>
          <div>
            <p v-html="$t('die3.prima_description')"></p>
            <a href="/" target="_blank">{{ $t('die3.prima_link') }}</a>
          </div>
        </div>
      </li>
      <li>
        <h3>{{ $t('die3.gdpr_commentary_title') }}</h3>
        <div>
          <p v-html="$t('die3.gdpr_commentary_description1')"></p>
          <p v-html="$t('die3.gdpr_commentary_description2')"></p>
          <a target="_blank" rel="noopener" href="//shop.ruw.de/startseite/5087-praxis-kommentar-dsgvo.html">{{ $t('die3.more_info_link') }}</a>
        </div>
      </li>
      <li>
        <h3>{{ $t('die3.juris_title') }}</h3>
        <div>
          <a target="_blank" rel="noopener" href="//juris.de/jportal/nav/pob/pkdsgvo.jsp">{{ $t('die3.more_info_link') }}</a>
        </div>
      </li>
    </ol>
  </div>
</template>

<style lang="scss">
.die3-ol-list {
  & li {
    margin-bottom: 0.5rem;

    &::marker {
      padding-right: 1rem;
      color: #808080;
      font-size: 14pt;
      font-weight: 600;
    }
  }

  p {
    margin-bottom: 8px; // half of normal
  }
}
</style>
