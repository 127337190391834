<script>
import { useTheme } from 'vuetify'

import appConfig from '@src/app.config'

import Layout from '@layouts/main'

import store from '@state/store'
import BrandSetting from '@state/models/brand-setting'

import '@utils/currency-filter'


export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
  setup() {
    const theme = useTheme()

    store.commit('global/SET_VUETIFY_THEME', theme)

    return { theme }
  },
  components: { Layout },
  computed: {
    adminRequired() {
      return this.$route.meta.adminRequired || false
    },
    initDone() {
      return store.getters['global/initDone']
    },
  },
  created() {

  },
  async beforeMount() {
    if (window.brand_setting) {
      const bsResult = await BrandSetting.insert({ data: window.brand_setting })
      const bs = bsResult['brand-settings'][0]

      // TODO: maybe optimize by not calling this in navbar.js too in intial load
      // but needs to stay there because it needs to be set when company is switched that has no custom colors
      bs.setThemeColors()
    }
  },
}
</script>

<template>
  <div id="app">
    <Layout
      v-if="!!initDone"
      :admin="adminRequired"
    >
      <RouterView></RouterView>
    </Layout>
  </div>
</template>
