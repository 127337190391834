import parseISO from 'date-fns/parseISO'

import {
  LicenseType,
  LICENSE_EXPIRATION_NOTIFICATION_TIME,
} from '@enums'

import PlanitModel from './planit-model'
import PermissionRole from './permission-role'


export default class License extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),

      reseller_license_id: this.attr(),
      reseller_license: this.belongsTo(License, 'reseller_license_id'),

      resold_licenses: this.hasMany(License, 'reseller_license_id'),

      license_type: this.attr(),
      amount: this.attr(),
      amount_available: this.attr(),

      license_start: this.attr(),
      license_end: this.attr(),

      price: this.attr(),

      is_active: this.attr(),

      me_can_extend: this.attr(),
      currency: this.attr(),

      is_test_license: this.attr(),
      archived: this.attr(),
      is_quick_check: this.attr(),

    }
  }

  static calcIsLicenseAboutToExpire(licenseEnd) {
    const now = new Date()
    licenseEnd = parseISO(licenseEnd)
    const LICENSE_EXPIRATION_NOTIFICATION_TIME_milliseconds =
      LICENSE_EXPIRATION_NOTIFICATION_TIME * 24 * 60 * 60 * 1000
    return this.displayLicenseType !== LicenseType.FREE &&
      (licenseEnd - now) < LICENSE_EXPIRATION_NOTIFICATION_TIME_milliseconds
  }

  isLicenseAboutToExpire() {
    return License.calcIsLicenseAboutToExpire(this.license_end)
  }
}

License.entity = 'licenses'
