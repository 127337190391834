<script>
export default {
  name: 'ConfirmModal',
  components: {},
  props: {
    heading: { type: String, required: false, default: null },
    text: { type: String, required: false, default: null },
  },
  emits: ['confirm', 'cancel'],
  data: function () {
    return {
      open: false,
      enterListener: null,
    }
  },
  computed: {
    headingComputed() {
      return this.heading || this.$t('components.confirm_modal.confirmation_required')
    },
  },
  created() {
    this.enterListener = (e) => {
      // enter
      if (e.keyCode === 13) {
        this.confirm()
      }
    }

    window.addEventListener('keydown', this.enterListener)

    this.open = true
  },
  unmounted() {
    window.removeEventListener('keydown', this.enterListener)
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.open = false
    },
    cancel() {
      this.$emit('cancel')
      this.open = false
    },
  },
}
</script>

<template>
  <div>
    <PlanitDialog
      v-model="open"
      width="40rem"
      @click:outside="cancel"
    >
      <div class="bg-white border-radius pa-6">
        <div class="mb-6">
          <div class="d-flex align-center justify-space-between mb-4">
            <h1 v-text="headingComputed"></h1>

            <PlanitButton
              variant="text"
              class="icon-btn"
              @click="cancel"
            >
              <PlanitIcon icon="fal fa-times"></PlanitIcon>

            </PlanitButton>
          </div>

          <p
            v-if="text"
            style="white-space: pre-line"
            v-text="text"
          ></p>
        </div>

        <div class="d-flex align-center justify-end">
          <PlanitButton
            color="primary"
            @click="confirm"
          >
            <span>{{ $t('generic.confirm') }}</span>
          </PlanitButton>
        </div>
      </div>
    </PlanitDialog>
  </div>
</template>
