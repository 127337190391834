// src/filters/formatDate.js
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

export default function formatDate(date, fmt) {
  // Check if date is null, undefined, or an empty string, return an empty string
  if (!date) {
    return ''; // or return date if you want to return null as is
  }

  fmt = fmt || 'PP';
  let locale;
  if (window.lang === 'de') {
    locale = require('date-fns/locale/de').default;
  }

  if (typeof date === 'string') {
    // dates should always be UTC
    if (date.indexOf('Z') === -1) {
      date += 'Z';
    }

    date = parseISO(date);
  }

  return format(date, fmt, { locale });

}
