<script>
import { debounce } from 'underscore'
import { format, parseISO } from 'date-fns'

import DateTimePicker from '@components/form/date-time-picker'
import PlanitUserSearch from '@components/planit-user-search'
import PlanitListItem from '@components/planit-list-item'
import IconButton from '@components/icon-button'

import { TaskType } from '@enums'

import { approvalActions, approvalGetters } from '@state/helpers'
import Invite from '@state/models/invite'
import Task from '@state/models/task'
import Timer from '@state/models/timer'
import User from '@state/models/user'

import {
  dateToISOString,
} from '@utils/date-helpers'


export default {
  name: 'AddReviewTask',
  components: {
    DateTimePicker,
    PlanitUserSearch,
    PlanitListItem,
    IconButton,
  },
  props: {
    documentId: { type: Number, required: false, default: null },
    isEdit: { type: Boolean, required: false, default: false },
    taskId: { type: Number, required: false, default: null },
    initialTaskType: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: null },
    showUsersOnly: { type: Boolean, required: false, default: false },
    company: { type: Object, required: true },
  },
  emits: ['closeDialog', 'openNewTaskTab'],
  data() {
    return {
      newAssignees: [],
      maxRequiredApprovals: 1,
      deadline: null,
      description: '',
      taskType: '',
      dialog: true,
      taskItems: [
        { key: TaskType.WRITER, label: this.$t('components.review.tasks.writer') },
        { key: TaskType.REVIEWER, label: this.$t('components.review.tasks.reviewer') },
        { key: TaskType.APPROVER, label: this.$t('components.review.tasks.approver') },
      ],
      userSearchString: '',
      userSearchResultIds: [],

      inviteError: null,
      showTimePicker: false,
      tmpDeadline: null,
      timePickerIsFocused: false,
      invitedUsers: [],
      requiredApprovals: 1,
      reminderDates: [],
      minDate: new Date().toISOString().substr(0, 10),
      isSubmitting: false,
    }
  },
  watch: {
    newAssignees: {
      immediate: true,
      handler(assignees) {
        if (!this.isEdit) this.requiredApprovals = Math.max(assignees.length, 1)
      },
    },
  },
  computed: {
    ...approvalGetters,
    currentDocumentCompany() {
      return this.company
    },
    invites() {
      return Invite.query()
        .where((i) => i.approval && i.approval.id === this.approval.id && !i.accepted)
        .all()
    },
  },
  async created() {
    this.searchForUserDebounced = debounce(this.searchForUser, 250)
    if (this.isEdit && this.taskId) {
      const task = Task
        .query()
        .with('task_company_role_associations.company_role.user')
        .with('reminder.timers')
        .where((t) => t.id === this.taskId)
        .first()

      if (!task) {
        return
      }

      if (task.deadline) {
        const deadlineDate = parseISO(task.deadline + 'Z')
        this.tmpDeadline = deadlineDate

        if (format(deadlineDate, 'HH:mm') !== '00:00') {
          this.showTimePicker = true
        }
      }

      this.description = task.description
      this.maxRequiredApprovals = task.task_company_role_associations.length
      this.requiredApprovals = task.required_approval_count

      const taskTypeItem = this.taskItems.find(item => item.key === task.task_type)
      if (taskTypeItem) {
        this.taskType = taskTypeItem
      }

      this.newAssignees = task.task_company_role_associations.map((association) => {
        return User.query().where((u) => u.id === association.company_role.user_id).first()
      })

      if (task.reminder && task.reminder.timers) {
        for (const timer of task.reminder.timers) {
          const newTimer = parseISO(timer.start_timestamp + 'Z')
          this.reminderDates.push({
            date: newTimer,
            timer_id: timer.id,
          })
        }
      }

    } else {
      this.taskType = this.initialTaskType || this.taskItems[0].key

    }
  },
  methods: {
    ...approvalActions,
    searchForUser() {
      if (this.userSearchString.length > 1) {
        User.$all({ search: this.userSearchString }).then((result) => {
          this.userSearchResultIds = result.response.data.map((u) => u.id)
        })
      } else {
        this.userSearchResultIds = []
      }
    },
    addUserToList(user) {
      this.maxRequiredApprovals += 1
      this.newAssignees.push({ user: user, email: user.email })
    },
    addEmailToList(email) {
      this.maxRequiredApprovals += 1
      this.newAssignees.push({user: null, email: email})
    },
    formattedReminderDateObjs() {
      return this.reminderDates
        .filter(reminder => !!reminder.date)
        .map(reminder => {
          const formattedReminder = {
            date: dateToISOString(reminder.date),
          }
          if (reminder.timer_id) {
            formattedReminder.timer_id = reminder.timer_id
          }
          return formattedReminder
        })
    },
    formatDeadline() {
      if (!this.tmpDeadline) {
        return
      }
      this.deadline = dateToISOString(this.tmpDeadline)
    },
    async createTask() {
      this.formatDeadline()
      let data = {
        approval_id: this.approval.id,
        task_type: this.taskType,
        description: this.description,
        deadline: this.deadline,
        required_approval_count: this.requiredApprovals,
        can_start_independently: this.can_start_independently,
        reminder_dates: this.formattedReminderDateObjs(),
        assignees: this.newAssignees.map(assignee => assignee.email),
      }

      await this.$store.dispatch('approval/addTask', {
        data: data,
      })

      // reset
      this.newAssignees = []
      this.task = ''
      this.description = ''
      this.deadline = null
      this.tmpDeadline = null
      this.$emit('openNewTaskTab', this.taskType)
      this.closeDialog()
    },
    async editTask() {
      this.formatDeadline()

      const data = {
        description: this.description,
        deadline: this.deadline,
        required_approval_count: this.requiredApprovals,
        can_start_independently: this.can_start_independently,
        reminder_dates: this.formattedReminderDateObjs(),
        assignees: this.newAssignees.map(assignee => assignee.email),
      }

      await this.$store.dispatch('approval/updateTask', {
        data: data,
        taskId: this.taskId,
      })

      this.newAssignees = []
      this.task = ''
      this.description = ''
      this.deadline = null
      this.tmpDeadline = null

      this.closeDialog()
    },
    removeUser(user) {
      this.maxRequiredApprovals -= 1
      this.newAssignees.splice(this.newAssignees.indexOf(user), 1)
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.dialog = false
    },
    addNewReminder() {
      this.reminderDates.push({ date: null })
    },
    addAutomaticReminder(date) {
      this.reminderDates.push({
        date,
        timer_id: null,
      })
    },
    updateTmpDeadlineDate(val) {
      this.tmpDeadline = val
      if (this.reminderDates.length == 0) {
        const oneWeekBefore = new Date(val)
        oneWeekBefore.setDate(oneWeekBefore.getDate() - 7)
        if (oneWeekBefore > new Date()) {
          this.addAutomaticReminder(format(oneWeekBefore, 'yyyy-MM-dd'))
        }
      }
    },
    removeReminder(index) {
      if(!this.isEdit || !this.reminderDates[index].timer_id) {
        this.reminderDates.splice(index, 1)
        return
      }
      else {
        const reminderDate = this.reminderDates[index]
        const timer = Timer.query().where((t) => t.id === reminderDate.timer_id).first()
          if (timer) {
            timer.delete()
            this.reminderDates.splice(index, 1)
          }
      }
    },

    async handleFormSubmit() {
      if(this.isSubmitting) {
        return
      }

      this.isSubmitting = true
      if (this.isEdit) {
        await this.editTask()
      } else {
        await this.createTask()
      }

      this.isSubmitting = false

    }
  },
}
</script>

<template>
  <PlanitDialog
    v-model="dialog"
    max-width="60rem"
    content-class="bg-white"
  >
    <div class="pa-6">
      <div class="d-flex align-center justify-space-between mb-6">
        <h1 v-if="title">{{ title }}</h1>
        <div v-else>
          <h1 v-if="!isEdit">{{ $t('components.review.new_task') }}</h1>
          <h1 v-else>{{ $t('components.review.edit_assignee') }}</h1>
        </div>

        <IconButton
          icon="fa-times"
          large
          @click="closeDialog"
        ></IconButton>
      </div>

      <div>
        <v-form @submit.prevent="handleFormSubmit">
          <PlanitSelect
            v-if="!isEdit"
            v-model="taskType"
            :items="taskItems"
            item-title="label"
            item-value="key"
            :label="$t('components.review.task')"
            variant="outlined"
            density="compact"
            hide-details
            class="mb-4"
          ></PlanitSelect>

          <v-textarea
            v-if="!showUsersOnly"
            v-model="description"
            :label="$t('generic.description')"
            variant="outlined"
            density="compact"
            hide-details
            class="mb-4"
          ></v-textarea>

          <PlanitUserSearch
            class="mb-4"
            :placeholder="$t('admin.users.add_user')"
            :disable-string="!currentDocumentCompany"
            :company="currentDocumentCompany"
            @set-user="addUserToList"
            @set-string="addEmailToList"
          ></PlanitUserSearch>
          <v-alert
            v-if="inviteError"
            type="error"
            class="mt-4"
          >
            {{ inviteError }}
          </v-alert>

          <div
            v-if="newAssignees.length"
            class="mb-4"
          >
            <label>{{ $t('components.review.to_be_assigned_to') }}:</label>
            <div class="mb-2">
              <div
                v-for="(user, index) in newAssignees"
                :key="'user-' + index"
              >
                <PlanitListItem
                  class="mb-2"
                  :user="user"
                  :showRemoveButton="true"
                  @remove-user="removeUser"
                ></PlanitListItem>
              </div>
            </div>
          </div>

          <div v-if="!showUsersOnly">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <DateTimePicker
                  :value="tmpDeadline"
                  type="date"
                  :label="$t('components.review.deadline')"
                  :min="minDate"
                  @update:value="(v) => updateTmpDeadlineDate(v)"
                >
                </DateTimePicker>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <PlanitCheckbox
                  v-if="!showTimePicker"
                  v-model="showTimePicker"
                  :label="$t('generic.pick_time')"
                ></PlanitCheckbox>
                <DateTimePicker
                  v-else
                  v-model:value="tmpDeadline"
                  type="time"
                >
                </DateTimePicker>
              </v-col>
            </v-row>
            <v-row
              v-if="tmpDeadline"
              class="mt-0"
            >
              <v-col
                cols="12"
                sm="4"
                class="d-flex align-center"
              >
                <h3>{{ $t('components.review.add_reminder') }}</h3>
                <PlanitButton
                  variant="text"
                  class="icon-btn pa-2 ml-2"
                  @click="addNewReminder"
                >
                  <PlanitIcon size="small" icon="fal fa-plus"></PlanitIcon>

                </PlanitButton>
              </v-col>

              <v-col
                v-for="(reminderDate, index) in reminderDates"
                :key="index"
                cols="12"
                sm="3"
                class="d-flex align-center position-relative"
              >
                <DateTimePicker
                  v-model:value="reminderDate.date"
                  type="date"
                  :label="$t('components.input.date')"
                  class="flex-grow-1"
                  :min="minDate"
                >
                </DateTimePicker>
                <PlanitButton
                  variant="text"
                  class="position-absolute icon-btn pa-2"
                  style="top: 4px; right: 4px; margin-top: 10px; margin-right: 10px"
                  @click="removeReminder(index)"
                >
                  <PlanitIcon size="small" icon="far fa-fw fa-trash-alt"></PlanitIcon>

                </PlanitButton>
              </v-col>
            </v-row>

            <v-slider
              v-if="newAssignees.length > 1"
              v-model="requiredApprovals"
              :max="isEdit ? maxRequiredApprovals : newAssignees.length"
              :min="1"
              step="1"
              thumb-label="always"
              class="my-4"
              color="primary"
              thumb-color="primary"
              :label="$t('components.review.required_approval_count')"
            ></v-slider>
          </div>

          <div class="d-flex align-center justify-end mt-6">
            <PlanitButton
              color="primary"
              type="submit"
              :loading="isSubmitting"
              class="text-white"
            >
              <PlanitIcon size="small" class="mr-1" icon="fal fa-save"></PlanitIcon>

              {{ $t('generic.save') }}
            </PlanitButton>
          </div>
        </v-form>
      </div>
    </div>
  </PlanitDialog>
</template>

<style scoped>
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
</style>
