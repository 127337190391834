<script>

import PlanitFooterContent from '@components/planit-footer-content'

import store from '@state/store'
import BrandSetting from '@state/models/brand-setting'
import Checklist from '@state/models/checklist'
import { debugGetters, debugActions, syncQueueGetters, authMethods } from '@state/helpers'


export default {
  name: 'PlanitFooter',
  components: {
    PlanitFooterContent,
  },
  data() {
    return {
      debugLoginAs: '',
      debugLoginData: [
        {
          email: 'admin@planit.legal',
          name: 'Superadmin',
          icon: 'fal fa-fw fa-wand-magic',
        },
        {
          email: 'license_manager@planit.legal',
          name: 'License Manager',
          icon: 'fal fa-fw fa-user-tie',
        },
        {
          email: 'company_admin@planit.legal',
          name: 'Company Admin',
          icon: 'fal fa-fw fa-user-crown',
        },
        {
          email: 'company_user@planit.legal',
          name: 'Company Editor',
          icon: 'fal fa-fw fa-user',
        },
        {
          email: 'company_observer@planit.legal',
          name: 'Company Observer',
          icon: 'fal fa-fw fa-user',
        },
        {
          email: 'matz.radloff@planit.legal',
          name: 'Matz',
          icon: 'fal fa-fw fa-user-astronaut',
        },
      ],
    }
  },
  computed: {
    ...debugGetters,
    ...syncQueueGetters,
    hideDebugFooterComputed: {
      get() {
        return store.getters['debug/hideDebugFooter']
      },
      set(value) {
        return store.dispatch('debug/setHideDebugFooter', value)
      },
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    currentYear() {
      return new Date().getFullYear()
    },
    brandSetting() {
      return BrandSetting.query().first()
    },
    brandLogoFooterSrc() {
      return this.brandSetting?.logo_brand && '/api/images/' + this.brandSetting.logo_brand + '.png'
    },
    tosTo() {
      if (this.brandSetting && this.brandSetting.tos_text) {
        return {
          name: 'brand.terms_of_use',
        }
      } else {
        return {
          path: '/' + this.$t('components.footer.terms_of_use_path_name'),
        }
      }
    },
    debugSwitch: {
      get() {
        return this.debug
      },
      set(value) {
        this.setDebug({ debug: value })
      },
    },
    isPrimaryInstance() {
      return window.primary_instance
    },
    footerColor() {
      /* if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.transparentBars)) {
        return 'transparent'
      } */
    },
    dark() {
      // return this.footerColor === 'transparent'
      return false
    },
    availableChecklists() {
      return Checklist.all()
    },
    navDrawerWidth() {
      return store.getters['navbar/navDrawerWidth']
    },
    rightDrawerWidth() {
      return store.getters['navbar/rightDrawerWidth']
    },
    showNavigationDrawer() {
      return store.getters['navbar/showNavigationDrawer']
    },
    isRightDrawerOpen() {
      return store.getters['navbar/showApprovalDrawer'] || store.getters['navbar/showChecklistDrawer'] || store.getters['navbar/showGuideChecklistDrawer']
    },
    footerStyles() {
      const styles = {
        bottom: 0,
        position: 'absolute',
      }

      styles.left = 'calc(' + (
        this.showNavigationDrawer
          ? this.navDrawerWidth
          : '0px'
        ) + ' + ' + store.getters['navbar/rightAppPanelWidth'] + 'px)'

      styles.right = 'calc(' + (
        this.isRightDrawerOpen
          ? this.rightDrawerWidth
          : '0px'
        ) + ' + ' + store.getters['navbar/rightAppPanelWidth'] + 'px)'

      return styles
    }
  },
  created() {},
  mounted() {

  },
  methods: {
    ...debugActions,
    ...authMethods,
    async loginAs(email, password) {
      store.commit('global/SET_INIT_DONE', false)
      await this.logIn({
        email,
        password,
        $root: this.$root,
        logout: true,
      })

      store.commit('global/SET_INIT_DONE', true)

    },
  },
}
</script>

<template>
  <v-footer
    id="planit-footer"
    :style="footerStyles"
    :class="dark ? 'footer-dark' : ''"
  >
    <div
      :key="hideDebugFooterComputed"
      class="d-block mx-auto"
      :theme="dark ? 'dark' : ''"
      :color="footerColor"
    >
      <div
        v-if="showDebugInfo && !hideDebugFooterComputed"
        class="d-flex align-center flex-wrap justify-center pa-2"
        style="border: 1px solid var(--v-error-base)"
      >
        <PlanitSwitch
          v-model="debugSwitch"
          label="Debug"
          density="compact"
          hide-details
        ></PlanitSwitch>

        <PlanitChip
          v-if="instanceMode && instanceMode !== 'production'"
          label
          color="error"
          class="ml-4"
        >
          {{ instanceMode }}
        </PlanitChip>

        <PlanitChip
          v-if="isPrimaryInstance && (debug || (instanceMode && instanceMode !== 'production'))"
          label
          color="light-blue-lighten-2"
          class="ml-4"
        >
          PRIMARY INSTANCE
        </PlanitChip>

        <span class="px-2 ml-4">Queue Length: {{ queueLength }}</span>

        <div class="ml-4 d-flex align-center">
          <v-menu
            location="top"
            :style="{ borderRadius: '0px' }"
            :offset="[0, 16]"
            content-class="bg-white"
          >
            <template #activator="{ props }">
              <PlanitButton

                variant="text"
                v-bind="props"
              >
                Debug Login
              </PlanitButton>
            </template>

            <div class="pa-2">
              <div
                v-for="item in debugLoginData"
                :key="item.email"
                class="py-2 d-flex align-center"
                style="cursor: pointer"
                @click="loginAs(item.email, 'planit')"
              >
                <PlanitIcon
                  class="mr-2"
                  :icon="item.icon"
                ></PlanitIcon>
                {{ item.name }}
              </div>
            </div>
          </v-menu>
        </div>

        <div class="ml-4 d-flex align-center">
          <!-- <v-menu
            top
            tile
            :offset="[0, 16]"
            content-class="bg-white"
          >
            <template #activator="{ on, attrs }">
              <PlanitButton
                v-bind="attrs"
                text
                v-on="on"
              >
                Start Checklist
              </PlanitButton>
            </template>

            <div class="pa-2">
              <div
                v-for="item in availableChecklists"
                :key="item.id"
                class="py-2 d-flex align-center"
                style="cursor: pointer"
                @click="item.start()"
              >
                {{ item.name_translations[currentLocale] }}
              </div>
            </div>
          </v-menu> -->

          <PlanitSwitch
            :model-value="$store.getters['global/debugInstanceUpToDateOverride']"
            label="Instance Up To Date"
            density="compact"
            hide-details
            @update:model-value="$store.commit('global/SET_DEBUG_INSTANCE_UP_TO_DATE_OVERRIDE', !($store.getters['global/debugInstanceUpToDateOverride']))"
          ></PlanitSwitch>
        </div>

        <form @submit="loginAs(debugLoginAs, 'planit')" class="ml-4">
          <planitTextField
            v-model.trim="debugLoginAs"
            label="Login as"
            variant="outlined"
            hide-details
            density="compact"
            :theme="dark ? 'dark' : ''"
          ></planitTextField>
        </form>

        <PlanitButton
          class="ml-4 pa-2"
          variant="text"
          @click="hideDebugFooterComputed = true"
        >
          <PlanitIcon size="small" icon="far fa-fw fa-chevron-down"></PlanitIcon>

        </PlanitButton>
      </div>

      <PlanitFooterContent from-footer></PlanitFooterContent>

    </div>
  </v-footer>
</template>
