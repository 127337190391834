<script>
export default {
  name: 'TooltipIcon',
  props: {
    icon: { type: String, required: true },
    tooltip: { type: String, required: false, default: '' },
    tooltipHtml: { type: String, required: false, default: null },
    color: { type: String, required: false, default: 'primary' },
    small: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
  },
}
</script>

<template>
  <div>
    <v-tooltip
      :location="!bottom ? 'top' : undefined"
      :bottom="bottom"
      style="z-index: 9000;"
    >
      <template #activator="{ props }">
        <span
          v-bind="props"
        >
          <PlanitIcon
            :color="color"
            :size="small ? 'small' : undefined"
            :icon="icon"/>

        </span>
      </template>

      <span
        v-if="tooltipHtml"
        v-html="tooltipHtml"
      ></span>
      <span
        v-if="tooltip"
        style="white-space: pre-line"
        v-text="tooltip"
      ></span>
    </v-tooltip>
  </div>
</template>
