import PlanitModel from './planit-model'
import User from './user'
import License from './license'
import Basket from './basket'


export default class Order extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      license_id: this.attr(),
      license: this.belongsTo(License, 'license_id'),

      basket_id: this.attr(),
      basket: this.belongsTo(Basket, 'basket_id'),

      data: this.attr(),

      price_paid: this.attr(),
      price_custom: this.attr(),
      quantity: this.attr(),

      order_type: this.attr(),

    }
  }

}

Order.entity = 'orders'
