import PlanitModel from './planit-model'
import File from './file'
import Rollout from './rollout'

export default class RolloutFileAssociation extends PlanitModel {
    static fields() {
      return {
        ...super.fields(),
        file_id: this.attr(),
        file: this.belongsTo(File, 'file_id'),
        rollout_id: this.attr(),
        rollout: this.belongsTo(Rollout, 'rollout_id'),
      }
    }

    static uploadFile(formData, onUploadProgress) {
      return this.api().request({
        method: 'post',
        url: '/rollout-file-associations',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress,
      })
    }

    static addDocument(rolloutId, documentId) {
      RolloutFileAssociation.api().post(this.entity + '/add-document', {
        rollout_id: rolloutId,
        document_id: documentId
      })
    }

}

RolloutFileAssociation.entity = 'rollout-file-associations'