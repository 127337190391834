<script>
import VueSunEditor from '@components/form/vue-sun-editor'
import AutoGrowTextarea from '@components/form/auto-grow-textarea'

import store from '@state/store'


export default {
  name: 'PlanitMarkdown',
  components: {
    AutoGrowTextarea,
    VueSunEditor,
  },
  props: {
    value: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false, default: false },
    reducedMarkdown: { type: Boolean, required: false, default: false },
    html: { type: Boolean, required: false, default: false },
    showRawHtmlButton: { type: Boolean, required: false, default: false },
    focusOnCreation: { type: Boolean, required: false, default: false },
    toolbarBottom: { type: Boolean, required: false, default: false },
    stickyToolbar: { type: Number, required: false, default: 0 },
  },
  emits: ['input', 'blur', 'focus'],
  data() {
    return {
      internalValue: '',

      editRawHtml: false,

    }
  },
  computed: {
    content: {
      get() {
        return this.value
      },
      set(newValue) {
        if (newValue === '<p><br></p>') {
          newValue = null
        }

        // only emit if value actually changed
        if (newValue !== this.internalValue) {
          this.$emit('input', newValue)
          this.internalValue = newValue
        }
      },
    },
  },
  created() {
    this.internalValue = this.value
  },
  methods: {
    onBlur() {
      this.$emit('blur')
    },
    onFocus() {
      this.$emit('focus')

    },
  },
}
</script>
<!-- todo: on focus animation like other PlanitInput -->
<template>
  <div style="height: auto;">
    <label
      v-if="label"
      class="v-label pa-2"
    >
      {{ label }}
    </label>

    <div v-if="html">
      <PlanitSwitch
        v-if="html && showRawHtmlButton"
        v-model="editRawHtml"
      >
        <template #label>
          <PlanitIcon
            size="small"
            class="mr-1"
           icon="fal fa-code"/>
          HTML
        </template>
      </PlanitSwitch>

      <VueSunEditor
        v-if="!editRawHtml"
        v-model:value="content"
        :focus-on-creation="focusOnCreation"
        :disabled="disabled"
        :placeholder="placeholder"
        :extended-buttons="showRawHtmlButton"
        :toolbar-bottom="toolbarBottom"
        :sticky-toolbar="stickyToolbar"
        @blur="onBlur"
        @focus="onFocus"
      ></VueSunEditor>
      <AutoGrowTextarea
        v-if="editRawHtml"
        v-model="content"
        :disabled="disabled"
        variant="outlined"
      ></AutoGrowTextarea>
      <!--<div style="border: 1px solid red;">editor</div>-->
    </div>
  </div>
</template>
