import store from '@state/store'

import File from './file'
import PlanitModel from './planit-model'
import Commit from './commit'
import Answer from './answer'
import TaskCompanyRoleAssociation from './task-company-role-association'
import { DocumentBlueprintVersion } from './document-blueprint-version'
import { Document } from './document'

import emitter from '@utils/global-events'


export class DocumentVersion extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      document_id: this.attr(),
      is_edit_document_version_of_id: this.attr(),

      version: this.attr(),
      head: this.attr(),

      current_progress: this.attr(),

      commit: this.hasOne(Commit, 'document_version_id'),

      // needed to save answers returned by DV patch
      answer_ids: this.attr(),
      answers: this.hasManyBy(
        Answer,
        'answer_ids',
      ),

      document_blueprint_version_id: this.attr(),
      document_blueprint_version: this.belongsTo(
        DocumentBlueprintVersion,
        'document_blueprint_version_id'
      ),

      task_company_role_associations: this.hasMany(TaskCompanyRoleAssociation, 'document_version_id'),

      files: this.hasMany(File, 'document_version_id'),
    }
  }

  refreshAnswers() {
    return Answer.api()
      .get('/document-versions/' + this.id + '/answers', { save: false })
      .then((result) => {
        result.response.data.forEach((answer) => {
          answer.document_version_id = this.id
        })

        result.save()
      })
  }

  refreshAnswerDocumentAssociations() {
    return Answer.api().get('/document-versions/' + this.id + '/answer-document-associations')
  }

  refreshBlueprintVersion({ params } = {}) {
    return DocumentBlueprintVersion.api()
      .get(this.url() + '/document-blueprint-version', { save: false, params })
      .then(async (result) => {
        await this.updateAttr({
          document_blueprint_version_id: result.response.data.id,
        })
        await result.save()

        return result
      })
  }

  async sync(attr, data, config, callback) {
    const documentId = this.is_edit_document_version_of_id || this.document_id
    const currentDocument = documentId
      ? Document.find(documentId)
      : store.getters['currentDocument/document']

    if (!currentDocument) {
      console.warn('DocumentVersion.sync: currentDocument not found')
    }

    if (currentDocument?.current_locale) {
      config = config || {}
      config.params = config.params || {}
      config.params.document_content_locale = currentDocument.current_locale
    }

    const result = await super.sync(attr, data, config, callback)

    if (result?.response?.data?.current_progress) {
      const savedDocumentId = result.response.data.document_id || result.response.data.is_edit_document_version_of_id
      const documentUpdateData = { current_progress: result.response.data.current_progress }

      Document.update({
        where: savedDocumentId,
        data: documentUpdateData,
      }).then((r) => {
        emitter.emit('documentUpdate:' + savedDocumentId.id)
      })
    }

    return result

  }
}

DocumentVersion.entity = 'document-versions'
