import axios from 'axios'

import router from '@router'

import { FolderType } from '@enums'

import store from '@state/store'

import PlanitModel from './planit-model'
import Company from './company'
import { Document } from './document'
import DocumentBlueprint from './document-blueprint'
import FolderLink from '@state/models/folder-link'
import File from './file'
import FolderVendorAssociation from '@state/models/folder-vendor-association'
import PermissionRole from './permission-role'
import PermissionRoleModelAssociation from './permission-role-model-association'
import TableColumn from './table-column'
import TableSettingActivation from './table-setting-activation'
import MenuFolderSetting from './menu-folder-setting'
import ChecklistStepFolderStatus from './checklist-step-folder-status'
import { MenuCategoryUuid } from '@enums'

export default class Folder extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      parent_folder_id: this.attr(),
      parent_folder: this.belongsTo(Folder, 'parent_folder_id'),

      template_folder_id: this.attr(),

      name: this.attr(),
      name_singular: this.attr(),

      name_sort: this.attr(), // local only, to prevent sorting when renaming

      folder_type: this.attr(),
      icon: this.attr(),
      image: this.attr(),

      menu_position: this.attr(),

      full_path: this.attr(),

      name_translations: this.attr(),
      name_singular_translations: this.attr(),

      gender: this.attr(),

      link_subfolders_to_vendor: this.attr(),
      allow_uploads: this.attr(),
      show_only_for_data_processors: this.attr(),
      show_only_for_works_council: this.attr(),
      hidden: this.attr(),
      archived: this.attr(),

      me_can_create: this.attr(),

      has_visible_children: this.attr(),

      is_prima_folder: this.attr(),

      folder_links: this.hasMany(FolderLink, 'folder_id'),
      folder_vendor_associations: this.hasMany(FolderVendorAssociation, 'folder_id'),

      permission_role_model_associations: this.hasMany(PermissionRoleModelAssociation, 'folder_id'),

      menu_category_uuid: this.attr(),

      table_setting_activations: this.hasMany(TableSettingActivation, 'folder_id'),

      menu_folder_settings: this.hasMany(MenuFolderSetting, 'folder_id'),
      checklist_step_folder_statuses: this.hasMany(ChecklistStepFolderStatus, 'folder_id'),

      data: this.attr(),
      me_can_read_documents: this.attr(),
      // local attrs
      contentLoaded: this.attr(false),
    }
  }

  static beforeCreate(model) {
    model.name_sort = model.name
  }

  static getSortAttrs() {
    return [
      (_f) => {
        return _f?.menu_position > 0
          ? _f?.menu_position
          : _f?.name_sort.toLowerCase() || _f?.name.toLowerCase()
      },
      (_f) => {
        return _f?.name_sort.toLowerCase() || _f?.name.toLowerCase()
      },
    ]
  }

  static loadMoveFolderTree(folderId, isMoveFile, companyId, menuFoldersPermissionRoleId) {
    let params = {
      company_id: companyId,
      isMoveFile: isMoveFile
    }

    if (menuFoldersPermissionRoleId !== undefined) {
      params.menuFoldersPermissionRoleId = menuFoldersPermissionRoleId
    }

    return axios.get('/api/folders/' + folderId + '/folder-move-list', {params: params})
  }

  static loadFolders(id, company_id) {
    return Folder.api().get('/folders/' + id + '/folders', {
      params: {
        company_id: company_id,
      }
    })
  }

  static loadTopLevelFolder(company_id, currentFolderId) {
    return Folder.api().get('/folder/top-level-folder', {
      params: {
        company_id: company_id,
        current_folder_id: currentFolderId
      }
    })
  }

  async getParentFolders() {
    return Folder.api().get('/folders/' + this.id + '/parent-folders')
  }

  async delete(config) {
    await Document.deleteAll((d) => d.primary_folder_id === this.id)
    await File.deleteAll((f) => f.folder_id === this.id)
    // await Folder.deleteAll((f) => f.folder_id === this.id) // somehow this triggers a bug when switching to a different category folder after deletion

    await super.delete(config)

  }

  getDisplayName() {
    if (this.name) {
      return this.name
    }

    if (this.document_blueprint_id) {
      return DocumentBlueprint.find(this.document_blueprint_id).name_translations[
        store.getters['auth/currentLocale']
      ]
    }

    console.error('FOLDER WITH NEITHER NAME NOR BLUEPRINT ID')
  }

  getFullPath() {
    return this.full_path || ''
  }

  getHref(contextCompanyId) {
    if (this.company_id) {
      const folderId = this.id
      return router.resolve({
        name: 'company.folders',
        params: {
          companyId: this.company_id,
          folderId,
        },
      }).href
    } else if (this.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE) {
      return router.resolve({
        name: 'company.folders',
        params: {
          companyId: contextCompanyId,
          folderId: this.id,
        },
      }).href
    }
  }

  getRouterTo(companyId) {
    return this.getHref(companyId)
  }

  getMenuItemName(companyId) {
    return 'dbp-folder-' + (companyId || this.company_id) + '-' + this.id
  }

  containsDocuments() {
    return !!FolderLink.allFast().find((fl) => !!fl.document_id && fl.folder_id === this.id)
  }

  isPrimaFolder() {
    return !!Folder.is_prima_folder
  }
  getCategoryFolderNameSuggestion(companyId) {
    return axios.post('/api' + this.url() + '/category-folder-name-suggestion', { companyId })
  }

  getParentFolderIds() {
    if (!this.parent_folder_id) {
      return []
    }

    const parentFolder = Folder.find(this.parent_folder_id)

    return [this.parent_folder_id].concat(parentFolder.getParentFolderIds())
  }

  getLinkedBlueprintIds() {
    const parentFolderIds = this.getParentFolderIds()
    parentFolderIds.push(this.id)

    let blueprintIds = []

    parentFolderIds.forEach((folderId) => {
      const blueprintFolderLinks = FolderLink.allFast().filter(
        (fl) => !!fl.document_blueprint_id && fl.folder_id === folderId
      )
      blueprintIds = blueprintIds.concat(blueprintFolderLinks.map((fl) => fl.document_blueprint_id))
    })

    return blueprintIds
  }

  createFolderLink(data) {
    return FolderLink.api().post(this.url() + FolderLink.$url(), { ...data }, { save: true })
  }

  postOrPatch(data, config) {
    const shouldSave = config?.save !== undefined ? config.save : true
    return super.postOrPatch(data, { ...config, save: false }).then((r) => {
      if (r.response.data) {
        r.response.data.name_sort = r.response.data.name
      }

      if (shouldSave) {
        r.save()
      }

      return r
    })
  }

  getIcon() {
    return this.icon || 'fal fa-folder'
  }

  async getFolderStats() {
    const response = await axios.get('/api' + this.url() + '/stats')
    return response.data
  }

  async refreshContent(company, forceRefresh, resourcesToRefresh = ['all'], resourcesToExclude = [], params = {}, user_id = null) {
    if (forceRefresh) {
      await this.updateAttr({ contentLoaded: false })
    }

    const promise = new Promise((resolve) => {
      const folderContentPromises = []

      if (
        !resourcesToExclude.includes('Folder') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('Folder')
      ) {
        folderContentPromises.push(
          this.refreshSubResource(Folder, {
            params: {
              ...params,
              company_id: company.id,
              user_id: user_id,
              menu_category_uuid: store.getters['files/topLevelFolder']?.menu_category_uuid
            }
          })
        )
      }
      if (
        !resourcesToExclude.includes('DocumentBlueprint') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('DocumentBlueprint')
      ) {
        folderContentPromises.push(
          this.refreshSubResource(DocumentBlueprint, {
            params: {
              company_id: company.id,
              include_permission_roles: true,
              ...params,
            }
          })
        )
      }

      if (
        !resourcesToExclude.includes('FolderLink') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('FolderLink')
      ) {
        folderContentPromises.push(this.refreshSubResource(FolderLink, {
            params:
            {
              company_id: company.id
            }
          })
        )
      }

      if (
        !resourcesToExclude.includes('File') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('File')
      ) {
        folderContentPromises.push(
          this.refreshSubResource(File, {
            params: {
              company_id: company.id
            }
          })
        )
      }

      if (
        this.company_id &&
        !resourcesToExclude.includes('Document') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('Document')
      ) {
        folderContentPromises.push(
          this.refreshSubResource(Document, {
            params: {
              include_last_modified_by: true,
              ...params,
            }
          })
        )
      }

      if (
        !resourcesToExclude.includes('PermissionRoleModelAssociation') &&
        resourcesToRefresh.includes('all') ||
        resourcesToRefresh.includes('PermissionRoleModelAssociation')
      ) {
        folderContentPromises.push(
          this.refreshSubResource(PermissionRoleModelAssociation)
        )
      }

      Promise.allSettled(folderContentPromises).then(resolve)

    })

    const results = await promise
    await this.updateAttr({ contentLoaded: true })
    return results
  }

  getMenuItem(company, hasFutureChildren) {
    let routeName = ''
    let params = {}
    if (this.menu_category_uuid === MenuCategoryUuid.DELETION_CONCEPT) {
      routeName = 'deletion-concept.page'
      params = {
        page: 1,
        companyId: company.id,
      }
    } else {
      routeName = 'company.folders'
      params = {
        companyId: company.id,
        folderId: this.id,
      }
    }
    const folderItem = {
      folder: this,
      name: this.name,
      nameId: this.getMenuItemName(company.id),
      type: 'folder',
      futureChildren: [],
      to: {
        name: routeName,
        params: params,
      },
      isTemplateFolder: !!this.template_folder_id,
      isMenuFolder:
        !!this.template_folder_id || this.folder_type === FolderType.BLUEPRINT_MENU_STRUCTURE,
      openOnClick: true,
      disabled: !this.me_can_create && !this.me_can_read_documents && !company.me_can_create_documents
    }

    if (this.folder_type === FolderType.COMPANY) {
      folderItem.company = company
    }

    if (this.icon) {
      folderItem.icon = 'fas ' + this.icon
    } else if (this.image) {
      folderItem.image = '/api/images/' + this.image + '.png'
    }

    // // v-treeview needs empty list to allow async loading of children
    // if (this.has_visible_children || hasFutureChildren) {
    //   folderItem.children = []
    // }

    return folderItem
  }

  uploadPrimaExport(formData, onUploadProgress, companyId) {
    return axios.request({
      method: 'post',
      url: '/api' + this.url() + '/import-prima-export',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
      params: { company_id: companyId },
    })
  }

  async getFolderAccessPermissionRole(companyId) {
    companyId = companyId || this.company_id

    const result = await PermissionRole.api().get(
      this.url() + '/folder-access-permission-role?company_id=' + companyId
    )

    return result?.response?.status === 200
      ? result.entities['permission-roles'].find((pr) => pr.id === result.response.data.id)
      : null
  }

  static async loadFolder(folderId) {
    const folder = await Folder.find(folderId)
    if (folder) {
      return folder
    }
    return Folder.api().get('/folders/' + folderId)
  }

  async loadAllAvailableTemplates(companyId) {
    const result = await axios.get(
      '/api' + this.url() + '/available-templates?company_id=' + companyId
    )
    return result?.status === 200 ? result.data : null
  }

  async loadAvailableTableColumns(companyId, userId) {
    const result = await TableColumn.api().get(this.url() + '/table-columns', {
      params: {
        company_id: companyId,
        user_id: userId,
      },
    })
  }
  async getCompanyRolesInApprovals(companyId, taskType) {
    return await axios.get('/api' + this.url() + '/company-roles-in-approvals', {
      params: {
        company_id: companyId,
        task_type: taskType,
      },
    })
  }
  async getSubFoldersModifiedBy(companyId) {
    return await axios.get('/api' + this.url() + '/users', {
      params: {
        company_id: companyId,
      },
    })
  }
  static async getDocumentBlueprints(companyId, folder_id) {
    return DocumentBlueprint.api().get(`${this.$url()}/${folder_id}/document-blueprints`, {
      params: {
        company_id: companyId,
      },
    })
  }

}

Folder.entity = 'folders'
