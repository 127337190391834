<script>
import TooltipButton from '@components/tooltip-button'

export default {
  name: 'PlanitSupport',
  components: {
    TooltipButton,
  },
  data() {
    return {
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
      mailtoLink: 'mailto:bugs@planitprima.com?subject=Fehlerbericht&body=Auf welcher Seite trat der Fehler auf:%0D%0A%0D%0AWas ich vorher gemacht habe:%0D%0A%0D%0ABeschreibung des Fehlers:%0D%0A%0D%0ABrowser und Betriebssystem:%0D%0A'    }
  },
  computed: {
    /* rightPositionStyles() {
      let right
      if (this.$store.getters['navbar/showChecklistDrawer'] || this.$store.getters['navbar/showApprovalDrawer']) {
        right = 'calc(' + (this.$store.getters['navbar/rightDrawerWidth'] + ' + 16px)')

      } else {
        right = '16px'

      }

      return {
        right,
      }

    }, */
    isPrimaryInstance() {
      return window.primary_instance
    },
  },
}
</script>

<template>
  <div>
    <TooltipButton
      v-if="isPrimaryInstance"
      :tooltip="$t('generic.bug_report_tooltip')"
      :href="mailtoLink"
      :customClass="'icon-btn pa-1'"
      left
      icon
      :style="{ borderRadius: '0px' }"
    >
      <PlanitIcon icon="fas fa-fw fa-bug"></PlanitIcon>

    </TooltipButton>
    <TooltipButton
      :tooltip="$t('generic.support_tooltip')"
      :to="{ name: 'support' }"
      :customClass="'icon-btn pa-1'"
      left
      icon
      :style="{ borderRadius: '0px' }"
    >
    <PlanitIcon icon="fas fa-fw fa-question"></PlanitIcon>

    </TooltipButton>
  </div>

</template>
