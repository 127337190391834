export default function flattenByAttr(list, attr, mapFn) {
  const items = []

  mapFn = mapFn || ((item) => item)

  const addItems = (item) => {
    items.push(mapFn(item))

    const children = item[attr] || []
    children.forEach(addItems)

  }

  list.forEach(addItems)

  return items

}
