<script>
export default {
  props: {
    /**
     * The color of the button.
     * Accepts any valid CSS color or Vuetify theme color.
     */
    color: {
      type: String,
      default: 'default',
    },
    /**
     * If true, displays an icon instead of text.
     */
    icon: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, shows a loading spinner inside the button.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, the button is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If true, the button spans the full width of its parent container.
     */
    block: {
      type: Boolean,
      default: false,
    },
    /**
     * The variant of the button. Can be 'text', 'outlined', 'elevated', 'tonal', or 'flat'.
     */
    variant: {
      type: String,
      default: 'flat',
    },
    /**
     * The size of the button. Can be 'x-small', 'small', 'default', 'large', 'x-large'.
     */
    size: {
      type: String,
      default: 'default',
    },
    /**
     * If true, the button has rounded corners.
     */
    rounded: {
      type: [Boolean, String],
      default: undefined,
    },
    /**
     * The elevation of the button, which adds a shadow effect.
     * Accepts a value between 0 and 24.
     */
    elevation: {
      type: [Number, String],
      default: 0,
    },
    tag: {
      type: String,
      default: 'button',
    },
    for: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    to: {
      type: [Object, String],
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
  }
}
</script>

<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :icon="icon"
    :loading="loading"
    :disabled="disabled"
    :block="block"
    :variant="variant"
    :size="size"
    :rounded="rounded"
    :elevation="elevation"
    :tag="tag"
    :for="for"
    :to="to"
    :class="class"
    :active="active"
  >
    <slot></slot>
  </v-btn>
</template>


