<script>
import File from '@state/models/file'
import { FileExportStatus } from '@enums'
import { orderBy } from 'lodash'

export default {
  name: 'RolloutOverview',
  props: {
    rollout: { type: Object, required: true },
    stateIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      orderBy,
      FileExportStatus,
    }
  },
  computed: {
    nextReminderDate() {
      const filtered = this.rollout.reminder.timers.filter((t) => !t.last_triggered)
      if (filtered.length === 0) {
        return ''
      }
      return orderBy(filtered, (t) => t.start_timestamp)[0].start_timestamp
    },
  },
  methods: {
    confirmedCount(recipients) {
      return recipients.filter((recipient) => recipient.has_confirmed).length
    },
    getIconByExtension(filename) {
      if (!filename) {
        return 'fal fa-file'
      }
      return File.getIconByExtension(filename)
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex mb-8 pt-2">
      <div v-if="rollout.name" class="mr-8">
        <h4>{{ $t('generic.name') }}</h4>
        {{ rollout.name }}
      </div>
      <div v-if="nextReminderDate" class="mr-8">
        <h4>{{ $t('rollouts.reminder') }}</h4>
        {{ $filters.formatDate(nextReminderDate) }}
      </div>
      <div class="mr-8">
        <h4>{{ $t('rollouts.with_confirmation') }}</h4>
        {{ rollout.with_confirmation ? $t('generic.yes') : $t('generic.no') }}
      </div>
      <div class="mr-8">
        <h4>{{ $t('rollouts.email_as_password') }}</h4>
        {{ rollout.email_as_password ? $t('generic.yes') : $t('generic.no') }}
      </div>
      <div v-if="rollout.link_expiration_date" class="mr-8">
        <h4>{{ $t('rollouts.expiration_date') }}</h4>
        {{ $filters.formatDate(rollout.link_expiration_date) }}
      </div>
    </div>
    <div
      v-if="rollout.description"
      class="mb-8"
    >
      <h4>{{ $t('rollouts.description') }}</h4>
      <div
        v-html="rollout.description"
        class="html-container"
      ></div>
    </div>
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center">
          <h3 class="mb-2 mr-2">{{ $t('rollouts.recipient') }} </h3>
          <div class="table-text">{{ rollout.with_confirmation ? ` ${$t('rollouts.confirmed')}: ${confirmedCount(rollout.recipients)} / ${rollout.recipients.length}` : ''}}</div>
        </div>
        <div style="max-height: 16rem; overflow-y: auto">
          <div
            v-for="(recipient, i) in rollout.recipients"
            :key="i"
            class="d-flex align-center mb-2 ml-2"
          >
            <PlanitIcon class="mr-2" icon="fal fa-user"></PlanitIcon>

            <div class="table-text flex-grow-1">
              <div>{{ recipient.email }}</div>
              <div v-if="rollout.with_confirmation">
                <div
                  v-if="recipient.has_confirmed"
                  class="mr-2 text-green"
                >
                  {{ $t('rollouts.confirmed_at') }} {{ $filters.formatDate(recipient.confirmation_date) }}
                </div>
                <div
                  v-else
                  class="mr-2 text-red"
                >
                  {{ $t('rollouts.not_confirmed') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <h3 class="mb-2">{{ $t('rollouts.files') }}</h3>
        <div style="max-height: 16rem; overflow-y: auto">
          <div
            v-for="(association, i) in orderBy(rollout.file_associations, 'created', 'desc')"
            :key="i"
            class="d-flex align-center mb-2 ml-2"
          >
            <PlanitIcon
              class="mr-2"
              :icon="getIconByExtension(association.file.filename)"
            />
            <div class="table-text flex-grow-1">
              <div v-if="association.file.export_status !== FileExportStatus.FINISHED">
                <div class="mb-1">{{ association.file.document_name }}</div>
                <v-progress-linear
                  color="primary"
                  indeterminate
                  class="mr-4"
                ></v-progress-linear>
              </div>
              <div v-else>
                <div>
                  <a
                    :href="association.file.link"
                    target="_blank"
                    >{{ association.file.filename }}</a
                  >
                </div>
                <div>{{ association.file.getFormattedFileSize() }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.table-text {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}
</style>
