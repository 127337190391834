import PlanitModel from './planit-model'
import License from './license'
import store from '@state/store'

export default class Promotion extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      description: this.attr(),
      code: this.attr(),

      start_date: this.attr(),
      end_date: this.attr(),

      discount: this.attr(),
      free_months: this.attr(),

      license_type: this.attr(),

      usage_count_cache: this.attr(),
    }
  }

  static getUsedPromotionCodesCount() {
    return Promotion.api().get('/promotions/used-count')
  }

  static getAllPromotionCodes() {
    return Promotion.api().get('/promotions')
  }

  static getPromotionByCodeAndType(code, license_type) {
    return Promotion.api().get('/promotion/code/' + code + '/' + license_type)
  }
  static getPromotionByCode(code) {
    return Promotion.api().get('/promotion/code/' + code)
  }

}

Promotion.entity = 'promotions'
