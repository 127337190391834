<script>
export default {
  name: 'PrimaInfoCenterTextHighlight',
  props: {
    text: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    cut: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      content: '',
    }
  },
  watch: {
    text: function () {
      this.highlight()
    },
    query: function () {
      this.highlight()
    },
  },
  methods: {
    escapeRegEx(string) {
      return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
    },
    highlight() {
      if (!this.text || !this.query) {
        this.content = this.text
      }

      const escapedQuery = this.escapeRegEx(this.query)
      const re = new RegExp(`(${escapedQuery})`, 'gid')

      let content = this.text
      if (this.cut) {
        const match = re.exec(this.text)
        if (match) {
          const offset = 100
          const minIndex = 0
          const maxIndex = this.text.length - 1

          const lowerMatchIndexWithOffset = match.index - offset
          const upperMatchIndexWithOffset = match.index + match.length + offset

          const startIndex =
            lowerMatchIndexWithOffset > minIndex ? lowerMatchIndexWithOffset : minIndex
          const endIndex =
            upperMatchIndexWithOffset < maxIndex ? upperMatchIndexWithOffset : maxIndex

          content = this.text.substring(startIndex, endIndex)

          if (startIndex > minIndex) {
            content = `...${content}`
          }
          if (endIndex < maxIndex) {
            content = `${content}...`
          }
        }
      }

      this.content = content.replace(re, '<span class="bg-primary text-white">$1</span>')
    },
  },
  created() {
    this.highlight()
  },
}
</script>

<template>
  <div v-html="content"></div>
</template>
