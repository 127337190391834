import romans from 'romans'

const lawPattern = /(§+|Art|Artikel|Article)\.?\s*(?<norm>\d+(?:\w\b)?)\s*(?:(?:Abs\.?|Absatz)?\s*(?<absatz>(?:\d+)?|(?:[IXV]+)(?:\w\b)?))?\s*(?:S\.\s*(?<satz>\d+))?\s*(?:(?:Nr\.?|Nummer|No\.?)\s*(?<nr>\d+(?:\w\b)?))?\s*(?:lit\.\s*(?<lit>[a-z]?))?(?:.{0,10}?(?<gesetz>\b[A-Z][A-Za-z]*[A-Z](?:(?<buch>(?:\s|\b)[XIV]+)?))?)?/i

export function matchLawPattern(searchTerm) {
  const regex = new RegExp(lawPattern, 'g')
  const matches = [...searchTerm.matchAll(regex)]
  return matches
}

export function matchSingleLawPattern(searchTerm) {
  const regex = new RegExp(lawPattern)
  const match = searchTerm.match(regex)

  if (!match) {
    return null
  }

  const { norm, absatz, satz, nr, lit, gesetz, buch } = match.groups;

  //TODO: die restlichen Werte auch zurückgeben damit die korrekten Gesetze geöffnet werden
  const paragraphNo = isRomanNumeral(absatz) ? romans.deromanize(absatz) : absatz;

  return {
    articleNo: +norm,
    paragraphNo: +paragraphNo,
  }
}

const isRomanNumeral = (str) => {
  const romanRegex = /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/
  return romanRegex.test(str)
}

const logMatches = (matches) => {
  for (const match of matches) {
    logMatch(match);
  }
}

const logMatch = (match) => {
    console.log("Match:", match[0]);
    const { norm, absatz, satz, nr, lit, gesetz, buch } = match.groups;
    console.log("Norm:", norm);
    console.log("Absatz:", absatz);
    console.log("Satz:", satz);
    console.log("Nr:", nr);
    console.log("Lit:", lit);
    console.log("Gesetz:", gesetz);
    console.log("Buch:", buch);
}
