import PlanitModel from './planit-model'
import Company from './company'
import SessionScormLink from './session-scorm-link'
import TrainingSessionCompanyRoleAssociation from './training-session-company-role-association'
import Reminder from './reminder'
import CompanyRoleScormStatus from './company-role-scorm-status'


export default class TrainingSession extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      description: this.attr(),

      training_session_type: this.attr(),

      start_date: this.attr(),
      end_date: this.attr(),
      interval: this.attr(),
      interval_unit: this.attr(),

      status: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      reminder_id: this.attr(),
      reminder: this.belongsTo(Reminder, 'reminder_id'),

      session_scorm_links: this.hasMany(SessionScormLink, 'training_session_id'),
      training_session_company_role_associations: this.hasMany(TrainingSessionCompanyRoleAssociation, 'training_session_id'),

      data: this.attr(),
    }
  }

  async refreshCompanyRoleScormStatus(start_date, end_date, loadFinishedCourses = false, company_role_id = null) {
    await CompanyRoleScormStatus.deleteAll()

    const result = await this.refreshSubResource(CompanyRoleScormStatus, {
      params: {
        start_date: start_date,
        end_date: end_date,
        loadFinishedCourses: loadFinishedCourses,
        company_role_id: company_role_id
      }
    })

    await this.updateAttr( { contentLoaded: true } )

    return result

  }

  async addOrInviteParticipants(user_id, email) {
    const data = {
      user_id,
      email,
    }
    const response = await TrainingSession.api().patch( this.url() + '/assign-participant', data)
    return response
  }

  async sendMail() {
    const response = await TrainingSession.api().patch( this.url() + '/send-mail')
    return response.data
  }

  async addPermissionRoles(role_ids) {
    const data = {
      role_ids: role_ids,
    }
    const response = await TrainingSession.api().patch( this.url() + '/assign-permission', data)
    return response?.response?.data
  }

  loadCompanyRoleScormStatus(start_date, end_date, loadFinishedCourses = false, company_role_id = null) {
    return TrainingSession.api().get(
      this.url() + '/company-role-scorm-status',
      {
        params: {
          start_date,
          end_date,
          loadFinishedCourses,
          company_role_id,
        }
      }
    )
  }
}

TrainingSession.entity = 'training-sessions'
