import {
  sortBy,
  cloneDeep,
  maxBy,
} from 'lodash'

import {
  ConditionTargetType,
  QuestionType,
} from '@enums'

import store from '@state/store'

import PlanitModel from './planit-model'
import Answer from './answer'
import { SectionSelectGroupOptionResult } from './section-select-group-option-result'
import Condition from './condition'

import getSectionPositionForSorting from '@utils/section-position-sorting'


export default class Section extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      uuid: this.attr(),
      viid: this.attr(),

      parent_section_viid: this.attr(),
      before_section_viid: this.attr(),

      // parent_section_id: this.attr(), // filled-in by vuex-orm
      // subsections: this.hasMany(Section, 'parent_section_id'),

      answers: this.hasMany(Answer, 'section_id'),
      conditions: this.hasMany(Condition, 'section_viid'),

      // adding sdbas is handled manually
      // section_document_blueprint_associations: this.hasMany(SectionDocumentBlueprintAssociation, 'section_id'),

      /* document_blueprint_versions: this.belongsToMany(
        DocumentBlueprintVersion,
        SectionDocumentBlueprintAssociation,
        'section_id',
        'document_blueprint_version_id'
      ), */

      last_section_id: this.attr(),
      last_section: this.belongsTo(Section, 'last_section_id'),

      // select_group_id: this.attr(),
      select_group_viid: this.attr(),

      // document_blueprint_version_id: this.attr(),
      commit_id: this.attr(),
      deleted_by_commit_id: this.attr(),

      // translated attributes
      text: this.attr(),
      text_translations: this.attr({}),

      info_text: this.attr(),
      info_text_translations: this.attr({}),

      placeholder: this.attr(),
      placeholder_translations: this.attr({}),

      section_type: this.attr(),
      question_type: this.attr(),
      dynamic_question_type: this.attr(),
      data: this.attr(),
      allow_placeholder_carry_over: this.attr(),
      allow_adding_additional_options: this.attr(),
      export_always_detailed: this.attr(),
      tooltip_info_text_translations: this.attr({}),
      answer_optional: this.attr(),
      allow_comment: this.attr(),
      allow_multiple_answers: this.attr(),

      is_top_level: this.attr(),

      display_mode: this.attr(),
      result_type: this.attr(),

      is_pending: this.attr(),
      marked_for_deletion: this.attr(),

      full_position: this.attr(),
      position: this.attr(),
      clause_position: this.attr(),
      heading_position: this.attr(),

      // local attributes
      updateText: this.attr(false),
      showTranslations: this.attr(false),


    }
  }

  static async update(config) {
    const newSection = await super.update(config)

    if (newSection) {
      await store.dispatch('blueprint/updateSectionInAllVersions', { section: newSection })
    }

    return newSection

  }

  async sync(attr, data, config, callback) {
    console.error('Section.sync() is deprecated, use Section.patchViaBlueprintVersion() instead')
    throw new Error('alskdnnaskld')

    /* if (!attr && !!data) {
      await this.updateAttr(data)
    }

    const oldParentId = this.parent_section_id
    data = data || this.getSyncData(attr)

    if (callback) {
      return super
        .postOrPatch(data, config)
        .then((r) => {
          return this.syncQueueReplace(r)
        })
        .then(callback)
    } else {
      return super
        .postOrPatch(data, config)
        .then((r) => {
          return this.syncQueueReplace(r)
        })
    } */
  }

  getSortedItems(filterEmpty) {
    if (!this.data) {
      return []
    }

    const sortedItems = sortBy(this.data.items, (i) => i.value)

    if (filterEmpty) {
      return sortedItems.filter((i) => !!Object.keys(i.text_translations).length)
    } else {
      return sortedItems
    }
  }

  getPositionForSorting(actualPosition) {
    const positionForSorting = getSectionPositionForSorting(actualPosition || this.actual_position)
    return positionForSorting
  }

  getSelectGroupOptionItems(documentBlueprintVersion) {
    const currentLocale = store.getters['auth/currentLocale']

    const selectGroupOptions = store.getters['blueprint/selectGroupOptionsBlueprintVersion'](
      documentBlueprintVersion.version
    )

    const options = selectGroupOptions
      .filter((sgo) => {
        return sgo.select_group_viid === this.select_group_viid
      })

    const sortedOptions = []
    let currentOption = options.find(sgo => !sgo.before_select_group_option_viid)
    while (!!currentOption) {
      sortedOptions.push(currentOption)
      currentOption = options.find(sgo => sgo.before_select_group_option_viid === currentOption.viid)
    }

    const mappedOptions = sortedOptions.map((sgo) => {
      return {
        ...sgo,
        text: sgo.name_translations[currentLocale],
        value: sgo.viid,
      }
    })

    return mappedOptions
  }

  async createCondition(blueprintVersion) {
    const result = await this.createSubResource(Condition, {})

    store.dispatch(
      'blueprint/addCondition',
      {
        condition: result.entities.conditions[0],
        blueprintVersion,
      }
    )
    return result
  }

  getConditions(documentBlueprintVersion) {
    documentBlueprintVersion = documentBlueprintVersion || store.getters['blueprint/documentBlueprintVersion']

    const section = this

    const conditionsBlueprintVersion = store.getters['blueprint/conditions'][documentBlueprintVersion.version]
    const sectionsBlueprintVersion = store.getters['blueprint/sections'][documentBlueprintVersion.version]

    const queryCondition = (cViid, level) => {
      const style = {
        'margin-left': 2 * level + 'rem',
      }

      const condition = conditionsBlueprintVersion.find(c => c.viid === cViid)
      const section1 = sectionsBlueprintVersion.find(s => s.viid === condition.section1_viid)
      const section2 = sectionsBlueprintVersion.find(s => s.viid === condition.section2_viid)

      return {
        condition,
        section1,
        section2,
        level,
        style,
      }
    }

    const booleanConditions = Condition.allFast().filter(
      (c) =>
        c.section_viid === section.viid &&
        c.target1_type === ConditionTargetType.CONDITION &&
        c.target2_type === ConditionTargetType.CONDITION
    )

    if (!booleanConditions.length) {
      // should always be 1 condition
      return Condition.allFast()
        .filter((c) => c.section_viid === section.viid)
        .map((c) => queryCondition(c.viid, 0))
    } else {
      const conditions = []

      const addDistinctToConditions = (cViid, level) => {
        if (!conditions.find((c) => c.condition.viid === cViid)) {
          conditions.push(queryCondition(cViid, level))
        }
      }

      const addBooleanConditionRecursively = (c, level) => {
        const leftBooleanConditions = booleanConditions.filter((bc) => c.condition2_viid === bc.viid)

        if (leftBooleanConditions.length) {
          leftBooleanConditions.forEach((bc) => addBooleanConditionRecursively(bc, level + 1))
        } else {
          addDistinctToConditions(c.condition2_viid, level + 1)
        }

        addDistinctToConditions(c.viid, level)

        const rightBooleanConditions = booleanConditions.filter((bc) => c.condition1_viid === bc.viid)

        if (rightBooleanConditions.length) {
          rightBooleanConditions.forEach((bc) => addBooleanConditionRecursively(bc, level + 1))
        } else {
          addDistinctToConditions(c.condition1_viid, level + 1)
        }
      }

      const topLevelBooleanCondition = booleanConditions.find((bc) => {
        return !booleanConditions.find(
          (_bc) => _bc.condition1_viid === bc.viid || _bc.condition2_viid === bc.viid
        )
      })

      addBooleanConditionRecursively(topLevelBooleanCondition, 0)

      return conditions
    }
  }

  getItemTree(customItems) {
    const clonedItems = cloneDeep(customItems || this.data.items)

    const mapChildItems = (item) => {
      item.children = sortBy(
        clonedItems
        .filter((i) => i.parent_value === item.value)
        .map(mapChildItems),
        (i) => i.position,
      )

      return item
    }

    const itemTree = sortBy(
      clonedItems
      .filter((i) => !i.parent_value)
      .map(mapChildItems),
      (i) => i.position,
    )

    return itemTree

  }

  getAnswerByDocumentVersion(documentVersion) {
    const answers = store.getters['currentDocument/answersDocumentVersion'](documentVersion.version)

    const answer = answers.find((a) => a.section_viid === this.viid)

    return answer

  }

  getItemsForCondition(filterEmpty, documentBlueprintVersion) {
    if (this.question_type === QuestionType.AGGREGATED_SELECT) {
      const selectGroupOptions = store.getters['blueprint/selectGroupOptionsBlueprintVersion'](documentBlueprintVersion)

      const mappedSgos = (
        selectGroupOptions
        .filter((sgo) => {
          return sgo.select_group_viid === this.select_group_viid
        }).map(sgo => ({
          value: sgo.viid,
          text_translations: sgo.name_translations,
          type: 'select-group-option',
        }))
      )

      return mappedSgos

    } else {
      return this.getSortedItems(filterEmpty)

    }
  }

  getLastSection(config) {
    return this.constructor.api().get(this.url() + '/last-section', config)
  }

  async patchViaBlueprintVersion({ data, blueprintVersion }) {
    await this.updateAttr(data)

    await new Promise((resolve) => {
      const queueItem = {
        model: this,
        groupSaveModel: blueprintVersion,
        method: 'patch',
        data: {
          viid: this.viid,
          ...data,
        },
        callback: resolve,
      }

      store.dispatch('syncQueue/queueNow', queueItem)

    })
  }

  async refreshSsgors(blueprintVersion) {
    const ssgorResult = await this.refreshSubResource(
      SectionSelectGroupOptionResult,
      { params: { document_blueprint_version_id: blueprintVersion.id } },
    )

    ssgorResult.entities['section-select-group-option-results'].forEach((ssgor) => {
      const existingSsgor = store.getters['blueprint/sectionSelectGroupOptionResultsBlueprintVersion'](blueprintVersion.version)
        .find(s => s.viid === ssgor.viid)

      if (existingSsgor) {
        store.commit('blueprint/UPDATE_SECTION_SELECT_GROUP_OPTION_RESULT', {
          sectionSelectGroupOptionResult: ssgor,
          blueprintVersion: blueprintVersion.version,
        })

      } else {
        store.commit('blueprint/ADD_SECTION_SELECT_GROUP_OPTION_RESULT_FOR_BLUEPRINT_VERSION', {
          sectionSelectGroupOptionResult: ssgor,
          blueprintVersion: blueprintVersion.version,
        })

      }
    })

  }

  async resetNewOptionsItem() {
    // create new item if there is either one item with no value or no text
    if (
      this.data &&
      this.data.items &&
      !this.data.items.find((i) => {
        return Object.keys(i.text_translations).length === 0
      })
    ) {
      const maxValueItem = maxBy(this.data.items, (i) => i.value) || {
        value: 0,
      }
      const maxValue = maxValueItem.value
      const newValue = maxValue + 1

      const data = {}
      data.data = {
        ...this.data,
        items: [
          ...this.data.items,
          { text_translations: {}, custom_text: false, value: newValue },
        ],
      }

      await Section.update({
        where: this.id,
        data,
      })

      return true

    } else {
      return false

    }
  }

}

Section.entity = 'sections'
