<script>

import {
  orderBy,
} from 'lodash'

import BaseCard from '@components/card/base-card'
import PlanitFile from '@components/planit-file'
import DateTimePicker from '@components/form/date-time-picker'
import IconButton from '@components/icon-button'
import TooltipButton from '@components/tooltip-button'
import ProfilePicture from '@components/profile-picture'
import AddReviewTask from '@components/modal/add-review-task'

import {
  TaskType,
  TaskStatus,
} from '@enums'

import store from '@state/store'

import '@utils/filters'
import {
  todayMidnightISO,
  dateToISOString,
} from '@utils/date-helpers'


export default {
  name: 'PlanitTask',
  components: {
    BaseCard,
    PlanitFile,
    DateTimePicker,
    IconButton,
    TooltipButton,
    ProfilePicture,
    AddReviewTask,
  },
  props: {
    task: { type: Object, required: true },
    noCard: { type: Boolean, default: false },
    hideAssignedOn: { type: Boolean, default: false },
    showReminders: { type: Boolean, default: false },
    hideName: { type: Boolean, default: false },
    hideChip: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    showAssignees: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    addSectionBottomMargins: { type: Boolean, default: false },
    includeStatus: { type: Boolean, default: false },
  },
  data() {
    return {
      orderBy,
      TaskStatus,

      showAddTimer: false,
      showAddAssignee: false,
      dateFilter: todayMidnightISO()
    }
  },
  computed: {
    taskTypeConfig() {
      return {
        [TaskType.APPROVER]: {
          icon: 'fal fa-check-circle',
          color: 'green',
          description: this.$t('components.review.approve'),
        },
        [TaskType.WRITER]: {
          icon: 'fal fa-pencil-alt',
          color: 'blue',
          description: this.$t('components.review.fill_out'),
        },
        [TaskType.REVIEWER]: {
          icon: 'fal fa-eye',
          color: 'orange',
          description: this.$t('components.review.check'),
        },
        [TaskType.CHECKLIST_STEP]: {
          icon: 'fal fa-tasks',
          color: 'purple',
        },
      }
    },
    myCompanyRole() {
      return store.getters['navbar/selectedCompanyMyCompanyRole']
    },
  },
  async created() {
  },
  methods: {
    getTaskProperties(taskType) {
      return this.taskTypeConfig[taskType] || { icon: '', color: 'grey', description: '' };
    },
    approvalProgress(approvedCount, requiredCount) {
      return (approvedCount / requiredCount) * 100
    },
    async addReminderTimer(date) {
      const dateISOString = dateToISOString(date)
      await this.task.reminder.createTimer(dateISOString)

      this.showAddTimer = false

    },
    removeTimer(timer) {
      timer.delete()
    },
    onCloseAddAssigneeDialog() {
      this.showAddAssignee = false
    },
    async removeUser(tcra) {
      await tcra.delete()
    },
  },
}
</script>

<template>
  <component
    :is="noCard ? 'div' : 'BaseCard'"
    :cardStyle="{ minHeight: '12rem', padding: '16px' }"
    :class="flex ? 'd-flex flex-wrap align-top' : ''"
  >
    <main v-if="!hideName">
      <div v-if="task.approval" class="d-flex align-items-center justify-space-between">
        <PlanitFile
          :document="task.approval.document"
          showPrimaryFolderName
        ></PlanitFile>
      </div>
      <div v-if="task.checklist_step_folder_status">
        <PlanitFile
          :folder="task.checklist_step_folder_status.folder"
          :to="{
            name: 'company.folders',
            params: {
              folderId: task.checklist_step_folder_status.folder.id,
              companyId: task.checklist_step_folder_status.folder.company_id,
            },
          }"
          :icon="task.checklist_step_folder_status.folder.menu_folder_icon"
          bold
        ></PlanitFile>
      </div>
    </main>
    <div v-if="!hideChip" :class="hideName ? '' : 'mt-2'">
      <PlanitChip color="primary" size="small" variant="outlined" :ripple="false">
        <PlanitIcon
          size="x-small"
          :class="`icon-${task.task_type}`"
          class="mr-1"
          :icon="getTaskProperties(task.task_type).icon"
       />
        {{ task.description || getTaskProperties(task.task_type).description }}
      </PlanitChip>
    </div>

    <v-divider v-if="!hideName" class="my-2"></v-divider>

    <div
      class="d-flex"
      :class="[
        !flex ? 'mt-auto pt-2' : 'mr-10',
        addSectionBottomMargins ? 'mb-4' : '',
      ]">
      <div v-if="task.deadline" class="flex-grow-1">
        <div class="text-grey-darken-1" :class="flex ? 'mb-2 line-height-28px' : ''">{{ $t('generic.deadline') }}</div>
        <div class="font-weight-medium">{{ $filters.formatDate(task.deadline ) }}</div>
      </div>
      <div v-if="!hideAssignedOn" class="flex-grow-1">
        <div class="text-grey-darken-1">{{ $t('generic.assigned_on') }}</div>
        <div>{{ $filters.formatDate(task.created) }}</div>
      </div>
    </div>

    <div
      v-if="showReminders && task.reminder?.timers"
      :class="[
        !flex ? 'mt-2' : 'mr-10',
        addSectionBottomMargins ? 'mb-4' : '',
      ]">
      <div>
        <div class="d-flex align-center">
          <div class="text-grey-darken-1">{{ $t('components.nav_bar.reminders') }}</div>
          <IconButton
            v-if="!showAddTimer && canEdit"
            icon="fa-plus"
            class="ml-2"
            small
            @click="showAddTimer = !showAddTimer"
          ></IconButton>
        </div>
        <DateTimePicker
          v-if="showAddTimer"
          type="date"
          :label="$t('rollouts.add_reminder')"
          :min="dateFilter"
          density="compact"
          class="mt-2"
          @update:value="addReminderTimer"
        ></DateTimePicker>
      </div>

      <template
        v-for="(timer, i) in orderBy(task.reminder.timers, t => t.start_timestamp)"
        :key="'timer-'+i"
      >
        <PlanitChip
          :closable="canEdit"
          class="mt-2"
          :class="i > 0 ? 'ml-2' : ''"
          size="small"
          :color="timer.last_triggered? 'success' : $store.getters['global/backgroundLightColorDarken']"
          @click:close="removeTimer(timer)"
        >
          <PlanitIcon
            start
            size="small"
            :icon="timer.last_triggered? 'fal fa-envelope' : 'fal fa-clock'"
          />
          {{ $filters.formatDate(timer.start_timestamp) }}
        </PlanitChip>
      </template>
    </div>

    <div
      v-if="showAssignees"
      :class="[
        !flex ? 'mt-2' : 'mr-10',
        addSectionBottomMargins ? 'mb-4' : '',
      ]"
    >
      <AddReviewTask
        v-if="showAddAssignee"
        :taskId="task.id"
        :title="$t('documents.detail.assignments')"
        :company="$store.getters['navbar/selectedCompany']"
        is-edit
        show-users-only
        @closeDialog="onCloseAddAssigneeDialog"
      ></AddReviewTask>

      <div class="d-flex align-center">
        <div class="text-grey-darken-1">{{ $t('documents.detail.assignments') }}</div>
        <IconButton
          v-if="!showAddAssignee && canEdit"
          icon="fa-plus"
          class="ml-2"
          small
          @click="showAddAssignee = !showAddAssignee"
        ></IconButton>
      </div>
      <div
        v-if="task.task_company_role_associations.length"
        :class="flex ? 'd-flex flex-wrap' : ''"
      >
        <div
          v-for="(tcra, assigneeIndex) in task.task_company_role_associations"
          :key="`assignee-${tcra.id}`"
          class="d-flex align-center mt-2"
          :class="flex ? 'mr-4' : ''"
        >
          <div
            class="d-flex align-center"
            v-if="tcra.company_role.user"
          >
            <ProfilePicture
              :model="tcra.company_role.user"
              rounded
              class="mr-2"
              width="2rem"
            ></ProfilePicture>
            <div class="font-weight-medium">
              {{ tcra.company_role.user.name || tcra.company_role.user.email }}
            </div>
          </div>
          <div
            class="d-flex align-center"
            v-else-if="tcra.company_role.invite"
          >
            <ProfilePicture
              :model="tcra.company_role.invite"
              rounded
              class="mr-2"
              width="2rem"
            ></ProfilePicture>
            <div class="font-weight-medium">
              {{ tcra.company_role.invite.email }}
            </div>
          </div>
          <div class="ml-2">
            <TooltipButton
              v-if="canEdit"
              icon
              small
              color="default"
              @click="removeUser(tcra)"
              :tooltip="$t('components.review.remove_assignee')"
            >
              <PlanitIcon size="x-small" icon="far fa-fw fa-trash-alt"></PlanitIcon>

            </TooltipButton>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="includeStatus"
      :class="[
        addSectionBottomMargins ? 'mb-4' : '',
      ]"
    >
      <div class="text-grey-darken-1 mb-2">{{ $t('generic.status') }}</div>
      <PlanitChip
        :color="task.status === TaskStatus.DONE ? 'success' : 'grey-darken-2'"
        variant="outlined"
      >{{ $t('enums.task_status.' + task.status) }}</PlanitChip>
    </div>

    <footer v-if="task.approval" class="d-flex align-items-center justify-space-between pt-2 pr-2">
      <div class="approval-progress d-flex align-items-center align-center">
        <v-progress-circular
          :model-value="task.progress"
          size="20"
          width="3"
          color="grey-lighten-2"
          class="progress-circle"
        ></v-progress-circular>
        <span class="progress-text">
          {{ task.approved_counter }} / {{ task.required_approval_count }}
        </span>
      </div>
      <div class="comments-count d-flex align-items-center align-center">
        <PlanitIcon
          size="small"
          class="comments-icon"
          icon=" fal fa-comments"
        />
        <span>{{ task.approval.approval_comments_count }}</span>
      </div>
    </footer>
  </component>
</template>

<style scoped>



.approval-progress .progress-circle {
  margin-right: 0.5rem;
}

.approval-progress .progress-text {
  font-size: 0.9rem;
}

.comments-count {
  font-size: 1rem;
}

.comments-icon {
  margin-right: 0.25rem;
}

.line-height-28px {
  line-height: 28px;
}

</style>
