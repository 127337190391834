import PlanitModel from './planit-model'
import Reminder from './reminder'
import User from './user'
import Timer from './timer'

export default class ReminderUserAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      enabled: this.attr(),

      reminder_id: this.attr(),
      reminder: this.belongsTo(Reminder, 'reminder_id'),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      can_edit: this.attr(),
    }
  }
}

ReminderUserAssociation.entity = 'reminder-user-associations'