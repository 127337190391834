import store from '@state/store'
import hexToCSrgb from '@utils/csrgb'

import defaultThemes from '@plugins/default-themes'


export default function setThemeColors({ type, value } = { type: 'all', value: null }) {
  const theme = store.getters['global/vuetifyTheme']

  const colors =
    type === 'all' ? ['primary', 'success', 'error', 'warning', 'appbar', 'background'] : [type]

  if (value && !store.getters['global/hasCustomTheme']) {
    store.dispatch('global/setHasCustomTheme', true)
  } else if (!value && !!store.getters['global/hasCustomTheme']) {
    store.dispatch('global/setHasCustomTheme', false)
  }

  const lightColors = { ...theme.themes.light.colors }
  const darkColors = { ...theme.themes.dark.colors }

  colors.forEach((color) => {
    let themeColor = value || defaultThemes.light.colors[color]

    if (themeColor === undefined) {
      delete lightColors[color]
      delete darkColors[color]

    } else {
      lightColors[color] = themeColor
      darkColors[color] = themeColor

    }

    if (color === 'primary') {
      // convert to rgb
      const cSrgb = hexToCSrgb(themeColor)

      delete lightColors.anchor

      // const darkModeThreshold = 0.33
      const darkModeThreshold = 0

      /* if (cSrgb >= darkModeThreshold) {
        theme.value.light = true
        theme.value.dark = false

        if (cSrgb < 0.55 && store.getters['debug/debugTheme'] === 3) {
          lightColors.anchor = '#ffffff'
        } else if (cSrgb > 0.7 || store.getters['debug/debugTheme'] === 3) {
          lightColors.anchor = '#212121'
        }

      } else {
        theme.value.light = false
        theme.value.dark = true
      } */

      store.dispatch('global/setThemeCSrgb', { themeCSrgb: cSrgb })

    } else if (color === 'appbar') {
      const appbarCSrgb = hexToCSrgb(themeColor)
      store.dispatch('global/setAppbarCSrgb', { appbarCSrgb })

    }
  })

  store.commit('global/SET_THEME_COLORS', { lightColors, darkColors })
}
