<script>
export default {
  props: {
    modelValue: {
      type: [Object, Array, String, Number],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    itemTitle: {
      type: [String, Function],
      default: 'text'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'outlined'
    },
    density: {
      type: String,
      default: 'compact'
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    color : {
      type: String,
      default: 'primary'
    },
    bgColor: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    chips: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    closableChips: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      internalValue: null,
    }
  },
  created() {
    this.internalValue = this.modelValue
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.internalValue = Array.isArray(newValue) ? [...newValue] : newValue
      },
      deep: true,
    },
  },
  methods: {
    updateModelValue(value) {
      this.internalValue = Array.isArray(value) ? [...value] : value
      this.$emit('update:model-value', this.internalValue)
    }
  }
}
</script>


<template>
  <v-select
    v-bind="$attrs"
    :model-value="internalValue"
    @update:model-value="updateModelValue"
    :label="label"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :return-object="returnObject"
    :variant="variant"
    :density="density"
    :hide-details="hideDetails"
    :disabled="disabled"
    :readonly="readonly"
    :color="color"
    :bg-color="bgColor"
    :placeholder="placeholder"
    :required="required"
    :error-messages="errorMessages"
    :chips="chips"
    :clearable="clearable"
    :closable-chips="closableChips"
  >
    <template v-for="(slot, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </v-select>
</template>
