<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'IconButton',
  props: {
    icon: { type: String, required: true },
    color: { type: String, required: false, default: 'default' },
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    to: { type: [Object, String], required: false, default: null },
    target: { type: String, required: false, default: null },
    href: { type: String, required: false, default: null },
    active: { type: Boolean, required: false, default: false },
  },
  emits: ['click'],
  setup(props) {
    const computedSize = computed(() => {
      return props.small
        ? '12'
        : props.large
          ? 'large'
          : 'small'
    })

    return {
      computedSize
    }
  }
})
</script>


<template>
  <PlanitButton
    :color="color"
    variant="text"
    :size="small ? 'small' : ''"
    class="icon-btn pa-2"
    :disabled="disabled"
    :loading="loading"
    :to="to"
    :target="target"
    :href="href"
    :active="active"
    @click="$emit('click')"
  >
    <PlanitIcon
      :size="computedSize"
      :icon="'far fa-fw ' + icon"
    />
  </PlanitButton>
</template>
