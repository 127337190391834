<script>
export default {
  name: 'PlanitProduct',
  components: {},
  props: {
    text: { type: String, required: true },
    inverted: { type: Boolean, required: false, default: false },
    brandText: { type: String, required: false, default: 'PLANIT' },
  },
  data: function () {
    return {}
  },
  computed: {},
  created() {},
  methods: {},
}
</script>

<template>
  <span>
    <span v-if="!inverted">
      <span style="font-weight: 500;">
        <span class="text-primary" style="font-weight: 700;">{{ brandText }}</span><span class="text-success" style="margin: 0 4px;">//</span>
        <span class="text-primary">{{ text }}</span>
      </span>
    </span>
    <span v-else>
      <span style="font-weight: 400;">
        <span class="text-white" style="font-weight: 700;">{{ brandText }}</span><span class="text-success" style="margin: 0 4px;">//</span>
        <span class="text-white">{{ text }}</span>
      </span>
    </span>
  </span>
</template>
