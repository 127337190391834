import PlanitModel from './planit-model'

import User from './user'


export default class TosAcceptance extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      user_id: this.attr(),

      tos_version: this.attr(),
      tos_filename: this.attr(),
      locale: this.attr(),

      opt_in_newsletter: this.attr(),
      opt_in_tour: this.attr(),
      acceptance_type: this.attr(),

    }
  }
}

TosAcceptance.entity = 'tos-acceptances'
