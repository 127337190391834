import { Chart } from 'chart.js'

import {
  SectionSelectGroupOptionResultType,
  SECTION_SELECT_GROUP_OPTION_RESULT_RISK_TYPE_TO_COLOR,
} from '@enums'

import defaultThemes from '@plugins/default-themes'

import store from '@state/store'

import PlanitModel from './planit-model'
import Section from './section'
import { SelectGroupOption } from './select-group'
import { rgbaColor } from '@/src/utils/rgbaColor'

export class SectionSelectGroupOptionResult extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      uuid: this.attr(),
      viid: this.attr(),

      section_viid: this.attr(),
      section: this.belongsTo(Section, 'section_viid'),
      select_group_option_viid: this.attr(),
      select_group_option: this.belongsTo(SelectGroupOption, 'select_group_option_viid'),

      text_translations: this.attr({}),
      result_type: this.attr(),
      risk_type: this.attr(),
      color: this.attr(),
      enabled: this.attr(),

      is_pending: this.attr(),

      // local attrs
      commit_id: this.attr(),

    }
  }

  getSortValue() {
    const sortMap = {
      [SectionSelectGroupOptionResultType.RISK]: 1,
      [SectionSelectGroupOptionResultType.RESULT_TEXT]: 2,
      [SectionSelectGroupOptionResultType.USER_STATUS]: 3,
      [SectionSelectGroupOptionResultType.USER_COMMENT]: 4,
      [SectionSelectGroupOptionResultType.CONCLUSION_TEXT]: 5,
    }

    return sortMap[this.result_type] || 6

  }

  getColor() {
    return this.color || SECTION_SELECT_GROUP_OPTION_RESULT_RISK_TYPE_TO_COLOR[this.risk_type];
  }

  getChartColor(alpha=0.5) {
    const color = this.getColor()
    if (color === 'success') {
      return rgbaColor(defaultThemes.light.colors.success, alpha)

    } else if (color === 'warning') {
      return rgbaColor(defaultThemes.light.colors.warning, alpha)

    } else if (color === 'error') {
      return rgbaColor('#ed4512', alpha)

    } else if (color === 'info') {
      return rgbaColor(defaultThemes.light.colors.info, alpha)

    } else if (color === 'grey-lighten-2') {
      return rgbaColor('#e0e0e0', alpha)

    } else if (color) {
      return rgbaColor(color, alpha)

    } else {
      return rgbaColor('#e0e0e0', alpha)
    }
  }



  getAssgorudByAnswer({ answer, documentVersion }) {
    /* const assgoruddva =
      AnswerSectionSelectGroupOptionResultUserDataDocumentVersionAssociation.allFast().find(
        (_assgoruddva) => {
          return (
            _assgoruddva.answer_document_association_id === adaId &&
            _assgoruddva.section_select_group_option_result_id === this.id
          )
        }
      )

    const assgorud = AnswerSectionSelectGroupOptionResultUserData.find(
      assgoruddva.answer_section_select_group_option_result_user_data_id
    )

    return assgorud */

    const assgoruds = store.getters['currentDocument/answerSectionSelectGroupOptionResultUserDataDocumentVersion'](
      documentVersion.version,
    )

    const assgorud = assgoruds.find((_assgorud) => {
      return _assgorud.answer_viid === answer.viid &&
        _assgorud.section_select_group_option_result_viid === this.viid
    })

    return assgorud

  }

  async delete(blueprintVersion) {
    const result = await super.delete()

    store.commit('blueprint/DELETE_SECTION_SELECT_GROUP_OPTION_RESULT', {
      sectionSelectGroupOptionResult: this,
      blueprintVersion,
    })

    return result

  }

}

SectionSelectGroupOptionResult.entity = 'section-select-group-option-results'
