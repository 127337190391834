<script>
export default {
  name: 'BaseCard',
  props: {
    cardStyle: {
      type: Object,
      default: () => ({}),
    },
    cardClass: {
      type: String,
      default: '',
    },
    canHover: {
      type: Boolean,
      default: false,
    },
    shadow: { type: Boolean, default: true },
    border: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
  },
  emits: ['click'],
}
</script>

<template>
  <div
    class="card border-radius d-flex flex-column"
    :class="[
      noPadding ? '' : 'pa-4',
      canHover ? 'card-hover' : '',
      border ? 'border': '',
      cardClass,
      shadow ? 'card-box-shadow': '',
    ]"
    :style="cardStyle"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  background-color: white;
}
.card.can-hover {
  /* border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  transition: all 0.1s ease;
  background-color: #f5f5f5;
}

.card.border {
  border: 1px solid #eee;
}

.card.card-hover:hover {
  transition: box-shadow 0.1s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

</style>
