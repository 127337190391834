import { orderBy } from 'lodash'

import axios from 'axios'

import { InviteTargetType, LicenseType, PermissionRoleType } from '@enums'

import { app } from '@src/main'

import store from '@state/store'

import PlanitModel from './planit-model'
import Company from './company'
import PermissionRoleUserAssociation from './permission-role-user-association'
import PermissionRoleCompanyAssociation from './permission-role-company-association'
import PermissionRoleModelAssociation from './permission-role-model-association'
import PermissionRoleCompanyRoleAssociation from './permission-role-company-role-association'
import License from './license'
import Invite from '@state/models/invite'
import InviteCode from '@state/models/invite-code'


export default class PermissionRole extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_translations: this.attr(),
      permission_role_type: this.attr(),

      permission_role_user_associations: this.hasMany(
        PermissionRoleUserAssociation,
        'permission_role_id'
      ),

      permission_role_company_associations: this.hasMany(
        PermissionRoleCompanyAssociation,
        'permission_role_id'
      ),

      permission_role_model_associations: this.hasMany(
        PermissionRoleModelAssociation,
        'permission_role_id'
      ),

      permission_role_company_role_associations: this.hasMany(
        PermissionRoleCompanyRoleAssociation,
        'permission_role_id'
      ),

      licenses: this.hasMany(License, 'permission_role_id'),

      invites: this.hasMany(Invite, 'permission_role_id'),
      invite_codes: this.hasMany(InviteCode, 'permission_role_id'),

      license_permission_role_id: this.attr(),
      license_permission_role: this.belongsTo(PermissionRole, 'license_permission_role_id'),
      license_permission_roles: this.hasMany(PermissionRole, 'license_permission_role_id'),

      has_custom_menu_folder_permission_role: this.attr(),

      partner_code: this.attr(),

      reseller_permission_role_id: this.attr(),
      reseller_permission_role: this.belongsTo(PermissionRole, 'reseller_permission_role_id'),

      user_count: this.attr(),
      scorm_files_count: this.attr(),

    }
  }

  static getPermissionRoleSelectItems({
    includeAdditionalItems = false,
    permissionRoleType = PermissionRoleType.LICENSE,
    licenseTypes = null,
  }) {
    const $t = store.getters['navbar/$t']
    const isAdmin = store.getters['auth/isAdmin']
    const currentLocale = store.getters['auth/currentLocale']

    const selectItems = []

    if (includeAdditionalItems) {
      // "planit-approved" is now included in "admin" but will be shown only as
      // "planit-approved" for managers

      if (permissionRoleType === PermissionRoleType.DOCUMENT_BLUEPRINTS && !isAdmin) {
        selectItems.push({
          value: 'admin',
          text: $t('admin.document_blueprint.planit_approved'),
        })

      } else if (isAdmin) {
        selectItems.push({
          value: 'admin',
          text: $t('generic.admin'),
          icon: 'fas fa-folder',
        })

      }
    }

    PermissionRole.allFast()
      .filter(
        (pr) =>
          pr.permission_role_type === permissionRoleType &&
          (!!pr.license_permission_role_id || permissionRoleType === PermissionRoleType.LICENSE)
      )
      .forEach((pr) => {
        let licensePermissionRole
        if (pr.license_permission_role_id) {
          licensePermissionRole = PermissionRole.find(pr.license_permission_role_id)
        } else {
          licensePermissionRole = PermissionRole.find(pr.id)
        }

        const currentLicense = licensePermissionRole.getCurrentLicense()

        // licenseType filter
        if (
          licenseTypes !== null &&
          (
            !currentLicense ||
            licenseTypes.indexOf(currentLicense.license_type) === -1
          )
        ) {
          return
        }

        let text = $t('admin.licenses.license_text', {
          license_type: $t('enums.license_type.' + currentLicense.license_type),
        })

        if (
          licensePermissionRole.name_translations &&
          licensePermissionRole.name_translations[currentLocale]
        ) {
          text += ': ' + licensePermissionRole.name_translations[currentLocale]

        } else {
          const companies = PermissionRoleCompanyAssociation.allFast().filter(
            prca => prca.permission_role_id === licensePermissionRole.id
          ).map(prca => Company.query().whereId(prca.company_id).first())

          if (companies.length) {
            text += ': ' + companies.map(c => c.name).join(', ')
          }

        }

        let icon = null
        if (licensePermissionRole.has_custom_menu_folder_permission_role) {
          icon = 'fas fa-folder'
        }

        selectItems.push({
          value: pr.id,
          text,
          icon,
          licensePermissionRoleId: licensePermissionRole.id,
        })
      })

    return selectItems
  }

  static deleteWithRelated(payload) {
    let permissionRoleIds = []
    if (typeof payload === 'function') {
      permissionRoleIds = PermissionRole.allFast().filter(payload)
    } else if (typeof payload === 'number') {
      permissionRoleIds = [payload]
    } else {
      permissionRoleIds = [payload.id]
    }

    License.delete((l) => permissionRoleIds.indexOf(l.permission_role_id) !== -1)
    PermissionRoleUserAssociation.delete(
      (prua) => permissionRoleIds.indexOf(prua.permission_role_id) !== -1
    )
    PermissionRoleModelAssociation.delete(
      (prma) => permissionRoleIds.indexOf(prma.permission_role_id) !== -1
    )
    PermissionRoleCompanyAssociation.delete(
      (prca) => permissionRoleIds.indexOf(prca.permission_role_id) !== -1
    )
    PermissionRoleCompanyRoleAssociation.delete(
      (prcra) => permissionRoleIds.indexOf(prcra.permission_role_id) !== -1
    )
    PermissionRole.delete((pr) => permissionRoleIds.indexOf(pr.license_permission_role_id) !== -1)
    Invite.delete((i) => permissionRoleIds.indexOf(i.permission_role_id) !== -1)

    PermissionRole.delete((pr) => permissionRoleIds.indexOf(pr.id) !== -1)
  }

  async addUser(user) {
    const data = { user_id: user.id }

    return PermissionRoleUserAssociation.api().post(
      this.url() + PermissionRoleUserAssociation.$url(),
      data
    )
  }

  async addCompanyRole(companyRole) {
    const data = { company_role_id: companyRole.id }

    return PermissionRoleCompanyRoleAssociation.api().post(
      this.url() + PermissionRoleCompanyRoleAssociation.$url(),
      data,
    )
  }

  async addCompany(company, config = {}) {
    const data = { company_id: company.id }

    return PermissionRoleCompanyAssociation.api().post(
      this.url() + PermissionRoleCompanyAssociation.$url(),
      data,
      config,
    )
  }

  async createInvite(args) {
    const data = {
      permission_role_id: this.id,
      invite_target_type: InviteTargetType.PERMISSION_ROLE,
    }

    if (args.user) {
      data.user_id = args.user.id
    } else if (args.email) {
      data.email = args.email
    }

    if (args.invite_data) {
      data.invite_data = args.invite_data
    }

    return Invite.$new(data)
  }

  async resolveInvites() {
    return this.resolveSubResource(Invite, { save: false }).then((result) => {
      result.response.data.forEach((invite) => {
        invite.permission_role_id = this.id
      })
      result.save()
      return result
    })
  }

  licenseFastQuery() {
    return License.allFast().filter((l) => l.permission_role_id === this.id)
  }

  getActiveLicense() {
    const activeLicenseFast = this.licenseFastQuery().find((l) => l.is_active)
    if (activeLicenseFast) {
      return License.find(activeLicenseFast.id)
    }
  }

  getMostRecentLicense() {
    const sortedLicensesFast = orderBy(this.licenseFastQuery(), 'license_start', 'desc')

    if (sortedLicensesFast.length) {
      return License.find(sortedLicensesFast[0].id)
    }
  }

  getCurrentLicense() {
    // most recent license, active or not
    return this.getActiveLicense() || this.getMostRecentLicense()
  }

  isReseller() {
    const currentLicense = this.getCurrentLicense()
    return (
      currentLicense.license_type === LicenseType.RESELLER ||
      currentLicense.license_type === LicenseType.PROFESSIONAL
    )
  }

  async addModel(model, { recursive = false } = {}) {
    const data = {}

    if (model.constructor.entity === 'documents') {
      data.document_id = model.id
    } else if (model.constructor.entity === 'files') {
      data.file_id = model.id
    } else if (model.constructor.entity === 'document-blueprints') {
      data.document_blueprint_id = model.id
    } else if (model.constructor.entity === 'scorm-files') {
      data.scorm_file_id = model.id
    } else if (model.constructor.entity === 'folders') {
      data.folder_id = model.id
    }

    if (recursive) {
      data.recursive = true
    }

    return PermissionRoleModelAssociation.api()
      .post(this.url() + PermissionRoleModelAssociation.$url(), data, { save: false })
      .then((result) => {
        result.response.data.permission_role_id = this.id
        result.save()

        return result
      })
  }

  async refreshRemoteResoldPermissionRoles() {
    const response = await axios.get(
      '/api/permission-roles/' + this.id + '/remote-resold-permission-roles',
      { timeout: 4000 }
    )

    return response.data
  }

  getDisplayName() {
    return this.name_translations[store.getters['auth/currentLocale']] || 'Role ' + this.id
  }

  async refreshLicensePermissionRoles(params) {
    return this.refreshSubResource(PermissionRole, {
      url: this.url() + '/license-permission-roles',
      params: params || {},
    })
  }
}

PermissionRole.entity = 'permission-roles'
