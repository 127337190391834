export default function downloadBlobResponse(response) {
  var element = document.createElement('a')
  element.style.display = 'none'

  const url = window.URL.createObjectURL(response.data)
  element.href = url
  element.download = response.headers['content-disposition'].split('attachment; filename=')[1]

  document.body.appendChild(element)

  element.click()

  window.URL.revokeObjectURL(url)
  document.body.removeChild(element)

}
