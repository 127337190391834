// import store from '@state/store'

import { Model } from '@vuex-orm/core'

import store from '@state/store'


export default class PlanitModel extends Model {
  // api wrappers
  static $find(pk, config) {
    return this.api().get('/' + this.entity + '/' + pk, config)
  }

  static $resolve(pk, config) {
    const entity = this.find(pk)
    return entity ? Promise.resolve(entity) : this.$find(pk, config)
  }

  static fields() {
    return {
      id: this.attr(-1),
      created: this.attr(),
      updated: this.attr(),

      uuid: this.attr(),

      $resolved: this.boolean(false),
      subResourceResolved: this.attr({}),
    }
  }

  static $url() {
    let url = '/'
    url += this.entity
    return url
  }

  static $new(data, config) {
    return this.api().post(this.$url(), data, config)
  }

  static $all(params) {
    return this.api().get(this.$url(), { params })
  }

  static allFast() {
    return Object.keys(store.state.entities[this.entity].data).map(
      (k) => store.state.entities[this.entity].data[k]
    )
  }

  /* constructor(record) {
    super(record)

  } */

  url() {
    let url = '/'
    url += this.constructor.entity
    if (this.hasId()) {
      url += '/' + this.id
    }
    return url
  }

  postUrl() {
    return this.url()
  }

  refresh(config = {}) {
    return this.constructor.api().get(this.url(), config)
  }

  hasId() {
    return this.id && this.id !== -1 && (!this.id.startsWith || !this.id.startsWith('$uid'))
  }

  postOrPatch(data, config) {
    let promise

    config = {
      ...config,
      persistOptions: {
        insert: ['repository-reference-items'],
      },
    }

    if (this.hasId()) {
      promise = this.constructor.api().patch(this.url(), data, config)

    } else {
      const oldId = this.id

      // make sure to send all data
      data = Object.assign(this.getPostData(), data)

      promise = this.constructor.api().post(this.postUrl(), data, config).then((r) => {
        if (r.isSaved && (r.response?.status === 200 || r.response?.status === 201)) {
          this.constructor.delete(oldId)
        }

        return r
      })

    }

    return promise
  }

  delete(config) {
    if (!this.hasId()) {
      return this.constructor.delete(this.id)
    }

    config = config || {}
    return this.constructor.api().delete(
      this.url(),
      { delete: this.id, ...config },
    )
  }

  updateAttr(data) {
    return this.constructor.update({
      where: this.$id,
      data,
    })
  }

  getSyncData(attr) {
    let data = {}
    if (attr === undefined) {
      data = this.$toJson()

      const filterKeys = [
        'id',
        '$id',
        'created',
        'updated',
        'uuid',
        '$resolved',
        'subResourceResolved',
      ]

      for (const key in filterKeys) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          delete data[key]
        }
      }

    } else if (Array.isArray(attr)) {
      attr.forEach((a) => {
        data[a] = this[a]
      })
    } else {
      data[attr] = this[attr]
    }

    return data
  }

  getPostData() {
    return this.getSyncData()
  }

  getLocalStorageKey() {
    return this.constructor.entity + ':' + this.id
  }

  async sync(attr, data, config, callback) {
    /* if (!attr && !!data) {
      await this.updateAttr(data)
    } */

    data = data || this.getSyncData(attr)

    if (callback) {
      return this.postOrPatch(data, config)
        .then((r) => {
          return this.syncQueueReplace(r)
        })
        .then(callback)
    } else {
      return this.postOrPatch(data, config).then((r) => {
        return this.syncQueueReplace(r)
      })
    }
  }

  syncQueueReplace(resp) {
    // dont try if save is false or not set
    if (
      resp.entities &&
      (!resp.config || resp.config.save || resp.config.save === undefined)
    ) {
      const oldModel = this
      const newModel = resp.entities[this.constructor.entity].find(e => e.id === resp.response.data.id)

      store.dispatch('syncQueue/replaceModel', { oldModel, newModel })
    }

    return resp
  }

  syncToLocalStorage(attr) {
    let data = this.getSyncData(attr)

    const key = this.getLocalStorageKey()

    const storedData = JSON.parse(window.localStorage.getItem(key))
    if (storedData) {
      data = Object.assign(storedData, data)
    }

    window.localStorage.setItem(key, JSON.stringify(data))
  }

  fillFromLocalStorage() {
    const key = this.getLocalStorageKey()

    const storedData = JSON.parse(window.localStorage.getItem(key))
    if (storedData && Object.keys(storedData).length > 0) {
      const updateData = {}
      let _key
      for (_key in storedData) {
        // reset old selected values
        if (key.indexOf('companies') !== -1 && _key === 'selected') {
          localStorage.removeItem(key)
        } else {
          const value = storedData[_key]
          if (value !== this[_key]) {
            updateData[_key] = value
          }
        }
      }

      if (Object.keys(updateData).length > 0) {
        return this.constructor.update({
          where: this.id,
          data: updateData,
        })
      }
    }

    return Promise.resolve(this)
  }

  async resolveSubResource(model, config) {
    // never cache when config is set
    if (config) {
      return model
        .api()
        .get(this.url() + model.$url(), config)
    }

    if (!this.subResourceResolved[model.entity]) {
      // const subResourceResolved = this.subResourceResolved || {}
      // subResourceResolved[model.entity] = true
      // // TODO: find out why this is sometimes initialized wrong
      // // console.log('RESOLVE setting subResourceResolved', this.id, subResourceResolved)
      // await this.constructor.update({
      //   where: this.id,
      //   data: {
      //     subResourceResolved,
      //   },
      // })
      return model
        .api()
        .get(this.url() + model.$url(), config)
        .then((r) => {
          return r
        })
    } else {
      return Promise.resolve()
    }
  }

  async refreshSubResource(model, config) {
    // const subResourceResolved = this.subResourceResolved || {}
    // subResourceResolved[model.entity] = true
    // await this.constructor.update({
    //   where: this.id,
    //   data: {
    //     subResourceResolved,
    //   },
    // })
    return await model.api().get(this.url() + model.$url(), config)
  }

  createSubResource(model, data, config) {
    return model.api().post(this.url() + model.$url(), data, config)
  }
}

PlanitModel.apiConfig = {
  dataTransformer: (response) => {
    if (response.data) {
      response.data.$resolved = true
    }
    return response.data
  },
}
