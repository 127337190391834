import PlanitModel from './planit-model'
import CompanyRole from './company-role'
import PermissionRole from './permission-role'

export default class PermissionRoleCompanyRoleAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      create: this.attr(),
      write: this.attr(),

      is_app_admin: this.attr(),

      company_role_id: this.attr(),
      company_role: this.belongsTo(CompanyRole, 'company_role_id'),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),
    }
  }
}

PermissionRoleCompanyRoleAssociation.entity = 'permission-role-company-role-associations'
