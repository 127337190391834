import PlanitModel from '@state/models/planit-model'
import DocumentBlueprint from '@state/models/document-blueprint'
import Folder from '@state/models/folder'
import { Document } from '@state/models/document'

export default class StandardBlueprintFolderAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),
      blueprint_id: this.attr(),
      blueprint: this.belongsTo(DocumentBlueprint, 'blueprint_id'),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      document_id: this.attr(),
      document: this.belongsTo(Document, 'document_id'),
    }
  }
}
StandardBlueprintFolderAssociation.entity = 'standard-blueprint-folder-associations'