import { app } from '@src/main'

import $router from '@router'
import i18n from '@src/plugins/vue-i18n'

import {
  PermissionRoleType,
  FolderType,
} from '@enums'

import store from '@state/store'
import Folder from '@state/models/folder'
import PermissionRole from '@state/models/permission-role'

export const state = {
  currentLicensePermissionRoleId: null,
  menuFoldersLoading: false,
}

export const mutations = {
  SET_CURRENT_LICENSE_PERMISSION_ROLE_ID(state, value) {
    state.currentLicensePermissionRoleId = value
  },
  SET_MENU_FOLDERS_LOADING(state, value) {
    state.menuFoldersLoading = value
  },
}

export const getters = {
  $t: () => (key, ...params) => {
    return i18n.global.t(key, ...params);
  },
  currentRoute() {
    return store.getters['navbar/currentRoute']
  },
  currentLicensePermissionRoleId(state) {
    return state.currentLicensePermissionRoleId
  },
  currentLicensePermissionRole(state) {
    if (state.currentLicensePermissionRoleId && typeof state.currentLicensePermissionRoleId === 'number') {
      return PermissionRole.find(state.currentLicensePermissionRoleId)
    }
  },
  selectedMenuFolderPermissionRole(state, getters) {
    if (typeof state.selectedLicensePermissionRoleId !== 'number') {
      return null
    }

    const prFast = PermissionRole.allFast().find(
      pr => pr.permission_role_type === PermissionRoleType.MENU_FOLDERS &&
        pr.license_permission_role_id ===
          this.selectedBlueprintPermissionRole.license_permission_role_id
    )

    if (prFast) {
      return PermissionRole.find(prFast.id)

    }
  },
  selectedMenuFolderPermissionRoleId(state, getters) {
    if (state.currentLicensePermissionRoleId === 'admin') {
      return 'admin'
    }

    return getters.selectedMenuFolderPermissionRole?.id
  },
  selectedBlueprintPermissionRole(state, getters) {
    if (typeof state.currentLicensePermissionRoleId !== 'number') {
      return null
    }

    const prFast = PermissionRole.allFast().find(
      pr => pr.permission_role_type === PermissionRoleType.DOCUMENT_BLUEPRINTS &&
        pr.license_permission_role_id === getters.currentLicensePermissionRoleId
    )

    if (prFast) {
      return PermissionRole.find(prFast.id)
    }

  },
  selectedMenuFoldersPermissionRole(state, getters) {
    if (typeof state.currentLicensePermissionRoleId !== 'number') {
      return null
    }

    const prFast = PermissionRole.allFast().find(
      pr => pr.permission_role_type === PermissionRoleType.MENU_FOLDERS &&
        pr.license_permission_role_id === getters.currentLicensePermissionRoleId
    )

    if (prFast) {
      return PermissionRole.find(prFast.id)
    }

  },
  selectedBlueprintPermissionRoleId(state, getters) {
    if (state.currentLicensePermissionRoleId === 'admin') {
      return 'admin'
    }

    return getters.selectedBlueprintPermissionRole?.id
  },
  selectedMenuFoldersPermissionRoleId(state, getters) {
    if (state.currentLicensePermissionRoleId === 'admin') {
      return 'admin'
    }

    return getters.selectedMenuFoldersPermissionRole?.id
  },
  menuFoldersLoading(state) {
    return state.menuFoldersLoading
  },
}

export const actions = {
  init({ state, dispatch, getters }) {
  },
  async setCurrentLicensePermissionRoleId({ commit, getters, dispatch }, currentLicensePermissionRoleId) {
    if (state.currentLicensePermissionRoleId !== currentLicensePermissionRoleId) {
      if (currentLicensePermissionRoleId) {

        commit('SET_CURRENT_LICENSE_PERMISSION_ROLE_ID', currentLicensePermissionRoleId)

        await dispatch('refreshFolders')

        if (getters.currentRoute.query.prid?.toString() !== currentLicensePermissionRoleId?.toString()) {
          $router.replace({
            ...getters.currentRoute,
            query: {
              prid: currentLicensePermissionRoleId,
            },
          })
        }

      }
    }
  },
  async refreshFolders({ getters, commit }) {
    commit('SET_MENU_FOLDERS_LOADING', true)

    await Folder.delete(f => [
      FolderType.BLUEPRINT_MENU_STRUCTURE,
      FolderType.SUBFOLDER_TEMPLATE,
      FolderType.MENU_GROUP,
    ].includes(f.folder_type))

    if (typeof getters.currentLicensePermissionRoleId === 'number') {
      // includes prma + folders
      await PermissionRole.$all({
        license_permission_role_id: getters.currentLicensePermissionRoleId,
        permission_role_type: PermissionRoleType.MENU_FOLDERS,
      })

    } else {
      const folderParams = {
        blueprint_menu_structure: true,
      }

      await Folder.$all(folderParams)

    }

    commit('SET_MENU_FOLDERS_LOADING', false)

  },
  setBlueprintPermissionRoleId({ commit, getters, dispatch }, blueprintPermissionRoleId) {
    let licensePermissionRoleId
    if (blueprintPermissionRoleId === 'admin') {
      licensePermissionRoleId = 'admin'

    } else {
      const prFast = PermissionRole.allFast().find(pr => pr.id === blueprintPermissionRoleId)
      licensePermissionRoleId = prFast?.license_permission_role_id

    }

    dispatch('setCurrentLicensePermissionRoleId', licensePermissionRoleId)

  },
  setMenuFoldersPermissionRoleId({ commit, getters, dispatch }, menuFoldersPermissionRoleId) {
    let licensePermissionRoleId
    if (menuFoldersPermissionRoleId === 'admin') {
      licensePermissionRoleId = 'admin'

    } else {
      const prFast = PermissionRole.allFast().find(pr => pr.id === menuFoldersPermissionRoleId)
      licensePermissionRoleId = prFast?.license_permission_role_id

    }

    dispatch('setCurrentLicensePermissionRoleId', licensePermissionRoleId)

  },
}
