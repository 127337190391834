import router from '@router'

import { app } from '@src/main'

import { InviteTargetType, PermissionRoleType } from '@enums'

import PlanitModel from './planit-model'
import Company from './company'
import User from './user'
import ScormFileCompanyAssociation from './scorm-file-company-association'
import { Document } from './document'
import Folder from './folder'

import store from '@state/store'

import emitter from '@utils/global-events'
import PermissionRole from '@state/models/permission-role'
import Approval from '@state/models/approval'


export default class Invite extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      email: this.attr(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      invite_link: this.attr(),
      invite_target_type: this.attr(),

      accepted: this.attr(),

      invite_data: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      scorm_file_company_association_id: this.attr(),
      scorm_file_company_association: this.belongsTo(
        ScormFileCompanyAssociation,
        'scorm_file_company_association_id'
      ),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),

      approval_id: this.attr(),
      approval: this.belongsTo(
        Approval,
        'approval_id'
      ),
    }
  }

  static $redeemInviteCode(code, redeem = true) {
    return Invite.api().post(Invite.$url() + '/by-code/' + code, {
      redeem,
    })
  }

  accept() {

    // store.commit('navbar/SET_SHOW_NAVIGATION_DRAWER', true)

    return this.postOrPatch({ accept: true }, { save: true }).then(async (result) => {
      // refresh company-role related stuff
      await store.dispatch('auth/validate', { refresh: true })

      if (this.invite_target_type === InviteTargetType.COMPANY) {
        const $t = store.getters['navbar/$t']

        // redirect to training if adding user to 1 training but nothing else
        if (this.invite_data?.scorm_file_company_association_ids?.length === 1) {
          await store.dispatch('navbar/setSelectedCompany', {
            selectedCompany: Company.find(this.company_id),
            dontRedirect: true,
            skipSetLoading: true,
          })

          const sfcaId = this.invite_data.scorm_file_company_association_ids[0]
          const company = Company.find(this.company_id)
          const result = await company.refreshSubResource(ScormFileCompanyAssociation)
          const sfca = result.entities['scorm-file-company-associations'].find(
            (_sfca) => _sfca.id === sfcaId
          )

          await router.push({
            name: 'companyScormFile.detail',
            params: {
              companyId: this.company_id,
              scormFileId: sfca.scorm_file_id,
            },
          })

        } else if (this.invite_data?.target_document_id) {

          await store.dispatch('navbar/setSelectedCompany', {
            selectedCompany: Company.find(this.company_id),
            dontRedirect: true,
            skipSetLoading: true,
          })

          await Document.$find(this.invite_data.target_document_id)

          await router.push({
            name: 'documents.detail.page',
            params: {
              page: 1,
              documentId: this.invite_data.target_document_id,
              companyId: this.company_id,
            },
          })

        } else if (this.invite_data?.target_folder_id) {

          await store.dispatch('navbar/setSelectedCompany', {
            selectedCompany: Company.find(this.company_id),
            dontRedirect: true,
            skipSetLoading: true,
          })

          await Folder.$find(this.invite_data.target_folder_id)

          await router.push({
            name: 'company.folders',
            params: {
              folderId: this.invite_data.target_folder_id,
              companyId: this.company_id,
            },
          })

        } else {
          await store.dispatch('navbar/setSelectedCompany', {
            selectedCompany: Company.find(this.company_id),
            dontRedirect: true,
            skipSetNavTreeItems: true,
            skipSetLoading: true,
          })

          await store.dispatch('navbar/setNavTreeItems')

          await router.push(
            store.getters['navbar/selectedCompanyWithPermissionHelpers']
              .myCompanyRole.getPrimaryCompanyRoute()
          )
        }

        emitter.emit('showGlobalAlert', {
          type: 'success',
          message: $t('generic.invite_accepted'),
        })

        store.commit('navbar/SET_SHOW_NAVIGATION_DRAWER', true)

      } else if (this.invite_target_type === InviteTargetType.SCORM_FILE_COMPANY_ASSOCIATION) {
        const companyId = this.scorm_file_company_association.company_id
        await store.dispatch('navbar/setSelectedCompany', {
          selectedCompany: Company.find(companyId),
          dontRedirect: true,
          skipSetLoading: true,
        })

        await router.push({
          name: 'companyScormFile.detail',
          params: {
            companyId,
            scormFileId: this.scorm_file_company_association.scorm_file_id,
          },
        })

      } else if (this.invite_target_type === InviteTargetType.PERMISSION_ROLE) {
        const permissionRole = PermissionRole.find(this.permission_role_id)

        if (permissionRole.permission_role_type === PermissionRoleType.LICENSE) {
          await router.push({ name: 'manager.licenses' })

        } else if (
          permissionRole.permission_role_type === PermissionRoleType.ACCESS_AUTHORIZATION_DOCUMENT
        ) {
          await router.push({
            name: 'admin.permissionRole',
            params: {
              permissionRoleId: permissionRole.$id,
            },
          })

        } else if (permissionRole.permission_role_type === PermissionRoleType.DOCUMENT_BLUEPRINTS) {
          await router.push({
            name: 'admin.documentBlueprints',
          })

        } else if (permissionRole.permission_role_type === PermissionRoleType.MENU_FOLDERS) {
          await router.push({
            name: 'admin.folders',
          })

        } else if (permissionRole.permission_role_type === PermissionRoleType.COMPANY_ADMIN) {
          await router.push({
            name: 'profile.dashboard',
          })

        }
      }

      store.commit('navbar/SET_NAV_TREE_LOADING', false)
      store.commit('navbar/SET_CONTENT_LOADING', false)

      await Invite.update({ where: this.id, data: { accepted: true } })
      store.commit('navbar/SET_SHOW_NAVIGATION_DRAWER', true)

    })
  }

  decline() {
    this.delete()
  }

  resend() {
    return this.constructor.api().patch(this.url(), { resend: true })
  }
}

Invite.entity = 'invites'
