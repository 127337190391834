import PlanitModel from './planit-model'
import PrimaFolderTemplate from '@state/models/prima-folder-template'
import DocumentBlueprint from '@state/models/document-blueprint'

export default class PrimaFolderTemplateAssociation extends PlanitModel {
    static fields() {
        return {
            ...super.fields(),
            prima_folder_template_id: this.attr(),
            prima_folder_template: this.belongsTo(PrimaFolderTemplate, 'prima_folder_template_id'),

            blueprint: this.belongsTo(DocumentBlueprint, 'blueprint_uuid', 'uuid'),
            blueprint_uuid: this.attr(),
            document_template_uuid:this.attr(),
        }
    }
}

PrimaFolderTemplateAssociation.entity = 'prima-folder-template-associations'