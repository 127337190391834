import PlanitModel from './planit-model'
import ScormFile from './scorm-file'
import Company from './company'

export default class ScormFileCompanyBooking extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      scorm_file_id: this.attr(),
      scorm_file: this.belongsTo(ScormFile, 'scorm_file_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      status: this.attr(),
    }
  }
}

ScormFileCompanyBooking.entity = 'scorm-file-company-bookings'
