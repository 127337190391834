import PlanitModel from './planit-model'

export default class FileFolderDocumentBlueprintAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      file_id: this.attr(),
      folder_id: this.attr(),
      document_blueprint_id: this.attr(),
    }
  }
}

FileFolderDocumentBlueprintAssociation.entity = 'file-folder-document-blueprint-associations'
