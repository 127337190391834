export const state = {
  debug: false,
  debugTheme: 0,
  hideDebugFooter: true,
}

export const mutations = {
  SET_DEBUG(state, { debug }) {
    state.debug = debug
  },
  SET_DEBUG_THEME(state, { debugTheme }) {
    state.debugTheme = debugTheme
  },
  SET_HIDE_DEBUG_FOOTER(state, value) {
    state.hideDebugFooter = value
  },
}

export const getters = {
  debug(state) {
    return state.debug
  },
  debugTheme(state) {
    return state.debugTheme
  },
  instanceMode(state, getters) {
    return process.env.INSTANCE_MODE || window.instance_mode || process.env.NODE_ENV
  },
  showDebugInfo(state, getters) {
    return (
      process.env.NODE_ENV !== 'production' ||
      (getters.instanceMode &&
        (getters.instanceMode === 'testing' || getters.instanceMode === 'debug')) ||
      window.primaForceDebug
    )
  },
  hideDebugFooter(state) {
    return state.hideDebugFooter
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {
    if (window.localStorage.getItem('hideDebugFooter') !== null) {
      dispatch('setHideDebugFooter', JSON.parse(window.localStorage.getItem('hideDebugFooter')))
    } else if (getters.instanceMode === 'testing') {
      dispatch('setHideDebugFooter', true)
    }

    if (window.localStorage.getItem('debug')) {
      dispatch('setDebug', { debug: JSON.parse(window.localStorage.getItem('debug')) })
    }
  },

  setDebug({ commit }, { debug } = {}) {
    window.localStorage.setItem('debug', debug.toString())
    commit('SET_DEBUG', { debug })
  },

  setHideDebugFooter({ commit }, value) {
    window.localStorage.setItem('hideDebugFooter', value.toString())
    commit('SET_HIDE_DEBUG_FOOTER', value)
  },
}
