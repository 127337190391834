// import 'suneditor/assets/css/suneditor.css'
// import 'suneditor/assets/css/suneditor-contents.css'
import suneditor from 'suneditor'
import {
  align,
  list,
  image,
  link,
  formatBlock,
} from 'suneditor/src/plugins'


export const state = {
  sunInitEditor: null,
}

export const mutations = {
  SET_SUN_INIT_EDITOR(state, sunInitEditor) {
    state.sunInitEditor = sunInitEditor
  }
}

export const getters = {
  sunInitEditor(state) {
    return state.sunInitEditor
  },
}

export const actions = {
  init({ commit }) {
    const sunInitEditor = suneditor.init({
      height: 'auto',
      minHeight: '2rem',
      width: '100%',
      plugins: [
        align,
        list,
        image,
        link,
        formatBlock,
      ],
      mode: 'classic',
      value: this.value || '',
      font: ['Segoe UI'],
      buttonList: [
        [
          'bold',
          'italic',
          'underline',
        ],
        [
          'align',
          'list',
          'link',
          // 'image',
        ],
      ],
      className: 'suneditor-planit markdown-container',
      tabDisable: true,
      showPathLabel: false,
      resizingBar: false,
      resizeEnabled: false,
    })

    commit('SET_SUN_INIT_EDITOR', sunInitEditor)

  },
}
