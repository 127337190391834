<script>

export default {
  name: 'DocumentUploadModal',
  props: {
    uploadFiles: {
      type: Array,
      required: true,
    },
  },
  emits: ['uploadDone', 'close'],
  data() {
    return {
      showModal: true,
      uploadFile: null,
      uploadProgress: null,
      uploadError: null,
    }
  },
  computed: {
    company() {
      return this.$store.getters['navbar/selectedCompany']
    },
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
  },
  created() {
  },
  methods: {
    async uploadSelectedFiles() {
      const promises = []
      this.uploadFiles
        .filter((f) => !f.disabled)
        .forEach((uf) => {
          promises.push(this.uploadFileHandler(true, uf))
        })

      await Promise.allSettled(promises)
      this.$emit('uploadDone')
      this.$emit('close')
    },
    cancelAction() {
      this.showModal = false
      this.$emit('close')
    },
    async uploadFileHandler(skipReloadEvent, selectedUploadFile) {
      this.uploadError = null
      const setUploadProgress = (value) => {
        if (selectedUploadFile) {
          this.$store.dispatch('files/setFileUploadProgress', {
            file: selectedUploadFile,
            value,
          })

        } else {
          this.uploadProgress = value

        }
      }

      setUploadProgress(1)
      const formData = new FormData()

      const uploadFile = selectedUploadFile?.file || this.uploadFile
      formData.append('file', uploadFile, uploadFile.name)

      if (this.currentFolder) {
        formData.append('parent_folder_id', this.currentFolder.id)
      }

      const onUploadProgress = (e) => {
        const value = Math.max(1, Math.round((100 * e.loaded) / e.total)) // minimum: 1
        setUploadProgress(value)
      }
      const thenHandler = () => {
        if (!skipReloadEvent) {
          // store.dispatch('navbar/setNavTreeItems')
        }
        if (!selectedUploadFile) {
          this.$emit('uploadDone')
        }
      }
      const catchHandler = (r) => {
        this.$emit('uploadDone')
        this.uploadError = r.response.data.message
      }
      if (selectedUploadFile.isPrimaExport) {
        return this.currentFolder
          .uploadPrimaExport(formData, onUploadProgress, this.company.id)
          .then(thenHandler, catchHandler)
      } else {
        return this.company
          .uploadDocument(formData, onUploadProgress)
          .then(thenHandler, catchHandler)
      }
    },
  },
}
</script>
<template>
  <div>
    <PlanitDialog
      :modelValue="showModal"
      content-class="bg-white"
      persistent
      no-click-animation
      max-width="60rem"
    >
      <div class="pa-6">
        <div class="d-flex align-center justify-space-between mb-6">
          <h1>
            {{
              $t('documents.overview.drag_upload_confirm', {
                fileLength: uploadFiles.length,
              })
            }}
          </h1>

          <PlanitButton
            variant="text"
            class="icon-btn"
            @click="cancelAction"
          >
            <PlanitIcon icon="fal fa-times"></PlanitIcon>

          </PlanitButton>
        </div>

        <div>
          <div
            v-for="(f, index) in uploadFiles"
            :key="index"
            class="d-flex align-center mb-2"
          >
            <PlanitIcon
              v-if="!f.isPrimaExport"
              :color="f.disabled ? 'error' : 'primary'"
              class="mr-2"
             :icon="f.icon "></PlanitIcon>


            <div
              v-else
              style="width: 24px"
              class="mr-2"
            >
              <img
                src="@/public/android-chrome-192x192.png"
                class="img-responsive"
              />
            </div>

            <div :class="f.disabled ? 'text-decoration-line-through text-grey' : ''" class="flex-grow-1">
              <div>{{ f.name }}</div>
              <v-progress-linear
                v-if="f.uploadProgress"
                :model-value="f.uploadProgress"
              ></v-progress-linear>
            </div>

            <PlanitChip
              v-if="f.disabled"
              class="ml-2 flex-shrink-0"
              label
              color="error"
              variant="outlined"
              size="small"
            >
              {{ $t('documents.overview.file_type_not_allowed') }}
            </PlanitChip>
          </div>
        </div>

        <div class="d-flex align-center justify-end mt-6">
          <PlanitButton
            color="primary"
            @click="uploadSelectedFiles"
          >
            <PlanitIcon
              size="small"
              class="mr-1"
             icon="fal fa-file-upload
            "/>
            <span>{{ $t('generic.upload') }}</span>
          </PlanitButton>
        </div>
      </div>
    </PlanitDialog>
  </div>
</template>
