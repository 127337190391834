import PlanitModel from './planit-model'
import Company from './company'
import CompanyRole from './company-role'
import Address from './address'
import CompanyPersonType from './company-person-type'

export default class CompanyPerson extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      company: this.belongsTo(Company, 'company_id'),
      company_id: this.attr(),

      company_role: this.belongsTo(CompanyRole, 'company_role_id'),
      company_role_id: this.attr(),

      address: this.belongsTo(Address, 'address_id'),
      address_id: this.attr(),

      text: this.attr(),

      company_person_type: this.belongsTo(CompanyPersonType, 'company_person_type_id'),
      company_person_type_id: this.attr(),
    }
  }

  delete() {
    const addressId = this.address_id
    return super.delete().then(() => {
      Address.delete(addressId)
    })
  }

  getName() {
    return (
      this.text ||
      (this.company_role &&
        this.company_role.user &&
        (this.company_role.user.name || this.company_role.user.email))
    )
  }

  getImportData(locale) {
    const name = this.getName()

    let retString = ''
    if (name) {
      retString += name
    }

    if (this.address) {
      retString += '<br>' + this.address.getMultilineText(locale)
    }

    return retString
  }
}

CompanyPerson.entity = 'company-persons'
