import PlanitModel from './planit-model'
import Timer from './timer'

export default class Reminder extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      type: this.attr(),
      enabled: this.attr(),
      content: this.attr(),
      name: this.attr(),

      timers: this.hasMany(Timer, 'reminder_id'),

    }
  }

  async createTimer(dateTime) {
    const result = await Timer.api().post(
      this.url() + Timer.$url(),
      { start_timestamp: dateTime },
      { save: false }
    )
    result.response.data.reminder_id = this.id
    await result.save()

    return result
  }

}

Reminder.entity = 'reminders'
