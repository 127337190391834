<script>

import store from '@state/store'
import BrandSetting from '@state/models/brand-setting'


export default {
  name: 'BrandLogo',
  props: {
    height: {
      type: String,
      default: '2.5em',
    },
  },
  computed: {
    brandSetting() {
      return BrandSetting.query().first()
    },
    brandLogoSrc() {
      return this.brandSetting?.logo && '/api/images/' + this.brandSetting.logo + '.png'
    },
    brandLogoDarkSrc() {
      return this.brandSetting?.logo_dark && '/api/images/' + this.brandSetting.logo_dark + '.png'
    },
    forceNavbarDark() {
      return this.$route.matched.some((route) => !!route.meta.forceNavbarDark)
    },
    navbarDark() {
      return (
        !store.getters['global/showPartnerKBrand'] &&
        (this.forceNavbarDark || store.getters['global/navbarDark'])
      )
    },
    primaLogoStyles() {
      const appbarColorHex = this.$vuetify.theme.themes.light.colors.appbar.slice(1, 7)
      const r = appbarColorHex.substring(0, 2)
      const g = appbarColorHex.substring(2, 4)
      const b = appbarColorHex.substring(4, 6)

      const appbarColorIsGreyscale = r === g && g === b

      if (!this.navbarDark && !appbarColorIsGreyscale) {
        return { filter: 'brightness(20%) contrast(400%) grayscale(1)' }

      } else if (this.navbarDark && !appbarColorIsGreyscale) {
        return { filter: 'brightness(90%) contrast(400%) grayscale(1)' }

      }

      return {}

    },
    heightComputed() {
      return this.height || (
        (brandLogoDarkSrc || brandLogoSrc)
          ? '64px'
          : '2.5em'
      )
    },
    logoStyles() {
      return {
        width: 'auto',
        height: this.heightComputed,
        marginTop: '0px',
      }
    },
  },
}
</script>

<template>
  <img
    v-if="!brandLogoSrc && navbarDark"
    src="@/public/prima-logo-rgb_neg_shadow_middle.svg"
    :style="[logoStyles, primaLogoStyles]"
  />
  <img
    v-else-if="!brandLogoSrc && !navbarDark"
    src="@/public/prima-logo-rgb_web_shadow_middle.svg"
    :style="[logoStyles, primaLogoStyles]"
  />

  <img
    v-else-if="brandLogoSrc && !navbarDark"
    :src="brandLogoSrc"
    :style="logoStyles"
  />
  <img
    v-else-if="brandLogoDarkSrc && navbarDark"
    :src="brandLogoDarkSrc || brandLogoSrc"
    :style="logoStyles"
  />

</template>
