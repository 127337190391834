<script>
import ProfilePicture from '@components/profile-picture'
import PlanitProgress from '@components/planit-progress'

import store from '@state/store'
import { Document } from '@state/models/document'
import { debugGetters } from '@state/helpers'


export default {
  name: 'NavTreeIcon',
  components: { ProfilePicture, PlanitProgress },
  props: {
    item: { type: Object, required: true },
    open: { type: Boolean, required: true },
    navIconWidth: { type: String, required: false, default: '2rem' },
    companyIconWidth: { type: String, required: false, default: '2rem' },
    color: { type: String, required: false, default: null },
  },
  computed: {
    ...debugGetters,
    menuIconStyle() {
      const styles = {
        color: 'rgb(var(--v-theme-primary))',
        'font-size': '1.25rem',
      }

      return styles
    },
    document() {
      if (this.item.documentId) {
        return Document.find(this.item.documentId)
      }
    },
    progress() {
      return this.document && parseInt(this.document.current_progress)
    },
    colorComputed() {
      if (this.item.folder) {
        return (
          this.item.folder.me_can_create ||
          store.getters['navbar/selectedCompany'].me_can_create_documents ||
          this.item.folder.me_can_read_documents
        ) ?
          this.color :
          'grey-darken-1'

      } else {
        return this.color

      }
    },
    menuFolderClass() {
      if (this.navIconWidth === '2rem') {
        return 'document-folder-menu-sm'
      } else {
        return 'document-folder-menu'
      }
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="item.type === 'company' && item.company?.image"
      class="py-1"
    >
      <ProfilePicture
        :model="item.company"
        :width="companyIconWidth"
        :border="false"
        :disabled="item.disabled"
        placeholder-type="company"
      ></ProfilePicture>
    </div>
    <div
      v-else
      :style="{
        width: item.type === 'company' ? companyIconWidth : navIconWidth,
        height: item.type === 'company' && companyIconWidth,
        'text-align': 'center',
      }"
      class="d-flex align-center justify-space-around"
    >
      <div v-if="item.type === 'company'">
        <PlanitIcon
          :style="menuIconStyle"
          :color="color"
          icon="fas fa-landmark"
          ></PlanitIcon
        >
      </div>
      <div v-else>
        <div v-if="item.documentId && !isNaN(item.progress)">
          <PlanitProgress
            v-if="!item.iconTooltip"
            :progress="progress"
            :icon="item.icon"
            :icon-color="item.iconColor"
            size="20"
            subscript
          ></PlanitProgress>
          <v-tooltip
            v-else
            location="top"
          >
            <template #activator="{ props }">
              <div v-bind="props">
                <PlanitProgress
                  :progress="progress"
                  :icon="item.icon"
                  :icon-color="item.iconColor"
                  size="20"
                  subscript
                ></PlanitProgress>
              </div>
            </template>
            <span>{{ item.iconTooltip }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <div
            v-if="item.image"
            class="d-flex align-center"
          >
            <img
              :src="item.image"
              style="width: auto; height: 30px"
            />
          </div>
          <div v-else>
            <PlanitIcon
              v-if="!item.icon"
              :style="menuIconStyle"
              :color="colorComputed"
              :class="!open && item.isMenuFolder ? menuFolderClass : ''"
             :icon="open ? 'fal fa-folder-open' : 'fal fa-folder' "></PlanitIcon>

            <PlanitIcon
              v-else
              :color="colorComputed || item.iconColor"
              :style="menuIconStyle"
              :icon="item.icon "></PlanitIcon>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
