
<script>
  export default {
  props: {
    modelValue: {
      type: [Boolean, Object, String, Number],
      default: true,
    },
    color: {
      type: [String, Object],
      default: 'primary',
    },
    label: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'tonal',
    },
    density: {
      type: String,
      default: 'compact',
    },
    class: {
      type: String,
      default: '',
    },
    style: {
      type: [String, Array, Object],
      default: '',
    },
    size: {
      type: [String, Number],
      default: 'default',
    },
  },
  emits: ['update:model-value'],
  methods: {
    updateModelValue(value) {
      this.$emit('update:model-value', value)
    },
  },
}
</script>

<template>
  <v-chip
    v-bind="{ ...$attrs, ...$props }"
    @update:model-value="updateModelValue"
  >
    <!-- Dynamische Slots für flexible Inhalte -->
    <template v-for="(slot, name) in $slots" :slot="name" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </v-chip>
</template>
