<script>

import {
  BASIC_PRICE_MONTHLY,
  PRO_PRICE_MONTHLY,
  LicenseType,
  DEMO_DURATION_DAYS,
} from '@enums'

export default {
  name: 'LicenseFeatureCardContent',
  props: {
    licenseType: { type: String, required: true },
    hover: { type: Boolean, required: false, default: false },
    noBackgroundColor: { type: Boolean, required: false, default: false },
    hideFeatureList: { type: Boolean, required: false, default: false },
    coloredSlashes: { type: Boolean, required: false, default: false },
    promotion: { type: Object, required: false, default: () => ({}) },
  },
  data: function () {
    return {
      BASIC_PRICE_MONTHLY,
      PRO_PRICE_MONTHLY,
      LicenseType,
      DEMO_DURATION_DAYS,
    }
  },
  computed: {
    reducedPriceText() {
      if (this.promotion && Object.keys(this.promotion).length > 0 && this.promotion.free_months) {
        let currentPrice = 0
         if (this.promotion.license_type === LicenseType.BASIC) {
            currentPrice = BASIC_PRICE_MONTHLY

          } else if (this.promotion.license_type === LicenseType.PRO) {
            currentPrice = PRO_PRICE_MONTHLY

          } else {
            return ''

          }

          const totalMonths = 12 - this.promotion.free_months
          const totalCost = totalMonths * currentPrice

          return totalCost / 12

        }
      return ''
    },
    isBasicPromotion() {
      return this.promotion && Object.keys(this.promotion).length > 0 && this.promotion.license_type === LicenseType.BASIC
    },
    isProPromotion() {
      return this.promotion && Object.keys(this.promotion).length > 0 && this.promotion.license_type === LicenseType.PRO
    },
  },
}
</script>

<template>
  <div class="fill-height">
    <div
      v-if="licenseType === LicenseType.FREE"
      class="fill-height card-box-shadow"
      :class="
        noBackgroundColor ? '' :
        !hover ?
          'sheet-border bg-white' :
          'sheet-border-active bg-grey-lighten-5'
      "
      style="border-width: 2px !important; border-radius: 4px;"
    >
      <div class="pa-4 d-flex align-center justify-space-around">
        <h3>{{ $t('licenses.free.free_text') }}</h3>
      </div>
      <div v-if="!hideFeatureList" class="px-4 pb-4">
        <ul class="fa-ul px-0">
          <li
            class="
              d-flex
              align-center
              font-weight-bold
              bg-gradient
              primary-dark-gradient
              text-gradient
              font-weight-medium
            "
            style="list-style: outside;"
          >
            <span
              class="
                fa-li
                bg-gradient
                primary-dark-gradient
                text-gradient
                font-weight-medium
              "
            >
              <i class="far fa-plus"></i>
            </span>
            <span
              v-if="promotion && Object.keys(promotion).length > 0 && promotion.free_months"
            >
              {{ $t('licenses.free.feature_pro_free_months', { demo_duration_months: promotion.free_months }) }}
            </span>
            <span v-else>
              {{ $t('licenses.free.feature_pro_free', { demo_duration_days: DEMO_DURATION_DAYS }) }}
            </span>
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.free.feature_1') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.free.feature_2') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.free.feature_3') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.free.feature_4') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.free.feature_5') }}
          </li>
        </ul>
      </div>
      <h3 class="pb-4 px-4 text-center align-self-end font-weight-medium">
        0€
      </h3>
    </div>

    <div
      v-if="licenseType === LicenseType.BASIC"
      class="fill-height card-box-shadow"
      :class="
        noBackgroundColor ? '' :
        !hover ?
          'bg-success text-white' :
          'bg-success-lighten-1 text-white'
      "
      style="border: 2px solid transparent !important; border-radius: 4px;"
    >
      <div class="pa-4 d-flex align-center justify-space-around">
        <h3>
          <span v-if="coloredSlashes" class="text-success">//</span>
          {{ $t('licenses.basic.basic_text') }}
        </h3>
      </div>
      <div v-if="!hideFeatureList" class="px-4 pb-4">
        <ul class="fa-ul px-0">
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_all_free') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_1') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_2') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_3') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_4') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check text-white"></i></span>
            {{ $t('licenses.basic.feature_5') }}
          </li>
        </ul>
      </div>
      <div class="pb-4 px-4 text-center">
        <h3 v-if="isBasicPromotion" class="font-weight-medium">{{ reducedPriceText }}€</h3>
        <h3 v-else class="font-weight-medium">{{ BASIC_PRICE_MONTHLY }}€</h3>
        <small>{{ $t('licenses.per_month_and_company') }}</small>
      </div>
    </div>

    <div
      v-if="licenseType === LicenseType.PRO"
      class="fill-height card-box-shadow"
      :class="
        noBackgroundColor ? '' :
        !hover ?
          'primary text-white' :
          'primary-dark-gradient text-white'
      "
      style="border: 2px solid transparent !important; border-radius: 4px;"
    >
      <div class="pa-4 d-flex align-center justify-space-around">
        <h3>
          <span v-if="coloredSlashes" class="text-primary">//</span>
          {{ $t('licenses.pro.pro_text') }}
        </h3>
      </div>
      <div v-if="!hideFeatureList" class="px-4 pb-4">
        <ul class="fa-ul px-0">
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_all_basic') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_1') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_2') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_3') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_4') }}
          </li>
          <li class="d-flex align-center" style="list-style: outside;">
            <span class="fa-li"><i class="far fa-check"></i></span>
            {{ $t('licenses.pro.feature_5') }}
          </li>
        </ul>
      </div>
      <div class="px-4 pb-4 text-center">
        <h3 v-if="isProPromotion" class="font-weight-medium">{{ reducedPriceText }}€</h3>
        <h3 v-else class="font-weight-medium">{{ PRO_PRICE_MONTHLY }}€</h3>
        <small>{{ $t('licenses.per_month_and_company') }}</small>
      </div>
    </div>
  </div>
</template>
