<script>
import { debounce } from 'lodash'

import { useDisplay } from 'vuetify'

import {
  authComputed,
  authMethods,
  debugActions,
  debugGetters,
  infoCenterActions,
  infoCenterGetters,
  dsbCommentActions,
  dsbCommentGetters,
  syncQueueGetters,
  navbarGetters,
  navbarActions,
  checklistGetters,
} from '@state/helpers'

import ProfilePicture from '@components/profile-picture'
import PlanitProgress from '@components/planit-progress'
import PlanitProduct from '@components/planit-product'
import TooltipIcon from '@components/tooltip-icon'
import NavTreeIcon from '@components/nav-tree-icon'
import PlanitInput from '@components/form/input'
import PlanitModelSearch from '@components/planit-model-search'
import Checklist from '@components/checklist'
import PlanitLicenseExpirationNotice from '@components/planit-license-expiration-notice'
import LicenseLabel from '@components/license-label'
import ReviewMain from '@components/review-system/review-main'
import RightDrawer from '@components/right-drawer'
import PlanitSupport from '@components/planit-support'
import ScrollContainer from '@components/scroll-container'
import BrandLogo from '@components/brand-logo'

import { LOCALE_SHORT_NAMES, LicenseType, ChecklistStepStatus, ALLOWED_LICENSE_TYPES } from '@enums'

import BrandSetting from '@state/models/brand-setting'
import Section from '@state/models/section'

import store from '@state/store'

import emitter from '@utils/global-events'
import enumItems from '@utils/enum-items'

export default {
  components: {
    ReviewMain,
    ProfilePicture,
    PlanitProgress,
    PlanitProduct,
    TooltipIcon,
    NavTreeIcon,
    PlanitInput,
    PlanitModelSearch,
    Checklist,
    PlanitLicenseExpirationNotice,
    LicenseLabel,
    RightDrawer,
    PlanitSupport,
    ScrollContainer,
    BrandLogo,
  },
  props: {
    admin: Boolean,
  },
  setup() {
    const { mobile, name } = useDisplay()

    return {
      mobile,
      breakpointName: name,
    }
  },
  data() {
    return {
      ChecklistStepStatus,
      LicenseType,

      loading: true,

      companySelectOpenData: false,
      companySelectOpenLoading: false,
      adminSelectedCompanySearch: null,

      showOmniSearch: false,
      omniSearchString: null,
      showFullSizeSearchBar: false,

      routeChangeCounter: 0,
    }
  },
  computed: {
    ...authComputed,
    ...syncQueueGetters,
    ...debugGetters,
    ...infoCenterGetters,
    ...dsbCommentGetters,
    ...navbarGetters,
    ...checklistGetters,
    navTreeItems() {
      return store.getters['navbar/navTreeItems']
    },
    currentDocumentId() {
      return store.getters['currentDocument/document']?.id
    },
    isManager() {
      return this.currentUser?.is_manager
    },
    isAdmin: authComputed.isAdmin,
    selectedApp: {
      get() {
        return store.getters['navbar/selectedApp']
      },
      async set(selectedApp) {
        store.dispatch('navbar/setSelectedApp', { selectedApp })
      },
    },
    selectedAppItem() {
      return store.getters['navbar/selectedAppItem']
    },
    selectedCompany: {
      get() {
        return store.getters['navbar/selectedCompany']
      },
      async set(selectedCompany) {
        store.dispatch('navbar/setSelectedCompany', { selectedCompany })
      },
    },
    companyTreeActive: {
      get() {
        return this.selectedCompany ? [this.selectedCompany.id] : []
      },
      set(selectedCompanyIds) {
        this.companySelectOpen = false
        this.setSelectedCompany(this.allCompanies.find((c) => c.id === selectedCompanyIds[0]))
      },
    },
    showCompany() {
      return this.selectedApp === 'company'
    },
    hasCustomTheme() {
      return store.getters['global/hasCustomTheme']
    },
    forceNavbarDark() {
      return this.$route.matched.some((route) => !!route.meta.forceNavbarDark)
    },
    navbarDark() {
      return (
        !store.getters['global/showPartnerKBrand'] &&
        (this.forceNavbarDark || store.getters['global/navbarDark'])
      )
    },
    impersonator() {
      return store.getters['auth/impersonator']
    },
    navTreeOpen() {
      return store.getters['navbar/navTreeOpen']
    },
    navTreeActive() {
      return store.getters['navbar/navTreeActive']
    },
    navTreeOpenComputed: {
      set(value) {
        let newNavTreeOpen = this.navTreeOpen
          .filter((i) => value.includes(i))
          .concat(value.filter((i) => !this.navTreeOpen.includes(i)))
        store.commit('navbar/SET_NAVTREE_OPEN', { navTreeOpen: newNavTreeOpen })
      },
      get() {
        return this.navTreeOpen
      },
    },
    navIconWidth() {
      return '2rem'
    },
    companyIconWidth() {
      return '2rem'
    },
    instanceMode() {
      return process.env.INSTANCE_MODE || window.instance_mode || process.env.NODE_ENV
    },
    brandSetting() {
      return BrandSetting.query().first()
    },
    languageSelectItems() {
      return store.getters['global/localeSelectItems'].map((i) => {
        return {
          ...i,
          text: LOCALE_SHORT_NAMES[i.value],
        }
      })
    },
    currentLocaleComputed: {
      get() {
        return this.currentLocale
      },
      set(value) {
        this.setLocale({ locale: value })
      },
    },
    appBarColor() {
      if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.transparentBars)) {
        return 'transparent'
      }

      return this.$vuetify.theme.themes.light.colors.appbar
    },
    commonAppDrawerStyles() {
      const styles = {}

      if (this.hasSmallScreen) {
        // styles['padding-top'] = this.navbarHeight
      } else {
        styles.top = this.navbarHeight + ' !important'
      }

      /* if (!this.hideDebugFooter) {
        styles.bottom = this.footerHeight + ' !important'
      } */

      return styles
    },
    navDrawerStyles() {
      const styles = {}
      styles['left'] = store.getters['navbar/rightAppPanelWidth'] + 'px'
      // styles['z-index'] = '200 !important'
      styles['width'] = this.navDrawerWidth // width prop not working?
      if (this.hasSmallScreen) {
        styles['padding-top'] = this.navbarHeight
      }

      return styles
    },
    rightAppPanelStyles() {
      const styles = {}

      styles.height = 'calc(100vh - ' + this.navbarHeight + ')'
      styles.width = store.getters['navbar/rightAppPanelWidth'] + 'px'
      styles['z-index'] = '201 !important'

      return styles
    },
    leftAppPanelStyles() {
      const styles = {}

      styles.height = 'calc(100vh - ' + this.navbarHeight + ')'
      styles.width = store.getters['navbar/rightAppPanelWidth'] + 'px'
      styles['z-index'] = '201 !important'

      return styles
    },
    showNavigationDrawer: {
      get() {
        return store.getters['navbar/showNavigationDrawer']
      },
      set(value) {
        store.dispatch('navbar/setShowNavigationDrawer', value)
      },
    },
    showUnauthorizedError() {
      return store.getters['global/showUnauthorizedError']
    },
    unauthorizedPermissionRole() {
      return store.getters['global/unauthorizedPermissionRole']
    },
    showCompanySelectFilter: {
      get() {
        return store.getters['navbar/showCompanySelectFilter']
      },
      set(value) {
        store.commit('navbar/SET_SHOW_COMPANY_SELECT_FILTER', value)
      },
    },
    adminSelectedCompanyLicenseFilterValues: {
      get() {
        return store.getters['navbar/adminSelectedCompanyLicenseFilterValues']
      },
      set(value) {
        store.commit('navbar/SET_ADMIN_SELECTED_COMPANY_LICENSE_FILTER_VALUES', value)
      },
    },
    searchBarBackgroundColor() {
      /* if (this.showFullSizeSearchBar) {
        if (this.$vuetify.theme.themes.light.colors.primary === defaultThemes.light.colors.primary) {
          return '#3551b8'
        } else {
          return '#f1f1f1'
        }
      } */

      /* if (this.$vuetify.theme.themes.light.colors.primary === defaultThemes.light.colors.primary) {
        return '#85adff66'
      } else */ if (this.navbarDark) {
        return '#e6e6e64d'
      } else if (!this.hasCustomTheme) {
        return '#00000011'
      } else {
        return '#ffffff4d'
      }
    },
    omniSearchStyles() {
      const styles = {
        left: !this.mobile
          ? 'calc(' +
            this.navDrawerWidth +
            ' + ' +
            (store.getters['navbar/rightAppPanelWidth'] + 'px') +
            ')'
          : // ? this.navDrawerWidth
            '5px',
        top: this.navbarHeight,
        width: this.getOmniSearchWidth(),
        position: 'fixed',
        'z-index': '100',
      }

      // TODO: update for mobile
      /* if (this.$vuetify.display.sm) {
        styles.left = '100px'
      } */

      return styles
    },
    isActuallyAdmin() {
      return store.getters['auth/currentUser'].is_admin
    },
    isAdminComputed: {
      get() {
        return store.getters['auth/isAdmin']
      },
      async set(value) {
        await store.dispatch('auth/syncIsAdminExplicitlyEnabled', value)
        store.commit('global/SET_INIT_DONE', false)
        this.$nextTick(() => {
          store.commit('global/SET_INIT_DONE', true)
        })
      },
    },
    adminShowSelectedCompanyOnly: {
      get() {
        return store.getters['auth/adminShowSelectedCompanyOnly']
      },
      async set(value) {
        store.commit('auth/SET_ADMIN_SHOW_SELECTED_COMPANY_ONLY', value)
      },
    },
    dpdReadOnly() {
      return store.getters['navbar/dpdReadOnly']
    },
    sectionCount() {
      return Section.allFast().length
    },
    companySelectOpen: {
      get() {
        return this.companySelectOpenData
      },
      async set(v) {
        this.companySelectOpenData = v

        if (this.isAdmin && v) {
          this.companySelectOpenLoading = true
          await store.dispatch('navbar/adminRefreshCompanies')
          this.companySelectOpenLoading = false
        }
      },
    },
    isPrimaryInstance() {
      return window.primary_instance
    },
    licenseTypeSelectItems() {
      let allowedLicenseTypes

      if (this.isAdmin) {
        if (this.isPrimaryInstance) {
          allowedLicenseTypes = ALLOWED_LICENSE_TYPES.admin.primary
        } else {
          allowedLicenseTypes = ALLOWED_LICENSE_TYPES.admin.non_primary
        }
      } else {
        allowedLicenseTypes = ALLOWED_LICENSE_TYPES.manager
      }

      return enumItems(allowedLicenseTypes, 'license_type')
    },
    layoutFullWidth() {
      return store.getters['global/layoutFullWidth']
    },
    showInfoCenter() {
      return store.getters['global/showInfoCenter']
    },
    rightDrawerArgs() {
      if (this.showApprovalDrawer) {
        return {
          currentDocumentId: this.currentDocumentId,
        }
      } else if (this.showChecklistDrawer) {
        return {
          activeListChecklist: this.activeListChecklist,
        }
      } else if (this.showInfoCenter) {
        return {}
      }
    },
    backgroundLightColor() {
      return store.getters['global/backgroundLightColor']
    },
    backgroundLighterColor() {
      return store.getters['global/backgroundLighterColor']
    },
    rightAppSelectItems() {
      return store.getters['navbar/rightAppSelectItems']
    },
    rightAppActive() {
      return store.getters['navbar/rightAppActive']
    },
    showSupportButton() {
      return (
        !this.$route.meta?.hideSupport &&
        (!this.brandSetting || this.brandSetting.enable_support_page) &&
        (!this.currentUser || this.currentUser.show_support_btn)
      )
    },
    planitHeaderWidth() {
      return (
        'calc(' + this.navDrawerWidth + ' + ' + store.getters['navbar/rightAppPanelWidth'] + 'px)'
      )
    },
    companySelectMenuStyles() {
      const styles = {}

      styles['position'] = 'fixed'
      styles['z-index'] = '100'
      styles['right'] = store.getters['navbar/rightAppPanelWidth'] + 'px'
      styles['top'] = this.navbarHeight

      return styles
    },
    noTreeItemHasChildren() {
      return this.navTreeItems.every((item) => item.items.every((_item) => !_item.children?.length))
    },
  },
  watch: {
    omniSearchString(v, ov) {
      if (v) {
        this.showOmniSearch = true
      }
    },
    $route(v, ov) {
      setTimeout(() => {
        this.routeChangeCounter++
      }, 200)
      // this.routeChangeCounter++
    },
  },
  async created() {
    this.adminSelectedCompanyLicenseFilterValues = [...Object.values(LicenseType)]

    this.debouncedSetAdminSelectedCompanies = debounce(this.setAdminSelectedCompanies, 250)

    if (this.loggedIn) {
      const navbarInitPromise = store.dispatch('navbar/initNavbar')
      store.commit('navbar/SET_NAVBAR_INIT_PROMISE', navbarInitPromise)
      await navbarInitPromise
    } else {
      store.commit('navbar/SET_CONTENT_LOADING', false)
    }

    store.dispatch('navbar/setNavbarLoading', false)

    this.loading = false

    emitter.on('navbar:queryDocumentsByCompany', this.queryDocumentsByCompany)
    emitter.on('navbar:setNavTreeItems', () => {
      store.dispatch('navbar/setNavTreeItems')
    })

    const searchListener = (e) => {
      if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.showFullSizeSearchBar = true
      }
    }

    document.addEventListener('keydown', searchListener)
  },
  methods: {
    ...authMethods,
    ...debugActions,
    ...infoCenterActions,
    ...dsbCommentActions,
    ...navbarActions,
    adminRefreshCompanies() {
      return store.dispatch('navbar/adminRefreshCompanies')
    },
    saveQueue() {
      store.dispatch('syncQueue/handleQueueNow')
    },
    async stopImpersonating() {
      await store.dispatch('auth/stopImpersonating')
      await this.setSelectedCompany(null, { dontRedirect: true, skipSetNavTreeItems: true })

      window.location.href = '/admin/users'
    },
    async setSelectedCompany(selectedCompany, { dontRedirect, skipSetNavTreeItems } = {}) {
      await store.dispatch('navbar/setSelectedCompany', {
        selectedCompany,
        dontRedirect,
        skipSetNavTreeItems,
      })
    },
    async setAdminSelectedCompanies(selectedCompanies, { dontRedirect, skipSetNavTreeItems } = {}) {
      // unselectedCompanies
      const newSelectedCompanyIds = selectedCompanies.map((c) => c.id)
      const unselectedCompanies = this.selectedCompanies.filter(
        (c) => newSelectedCompanyIds.indexOf(c.id) === -1
      )

      await store.dispatch('navbar/setAdminSelectedCompanies', {
        selectedCompanies,
        dontRedirect,
        skipSetNavTreeItems,
      })
      this.navTreeLoading = false
    },
    async loadNavTreeChildren(item) {
      await store.dispatch('navbar/loadNavTreeChildren', { item })
    },
    addToNavTreeOpen(item) {
      store.dispatch('navbar/addToNavTreeOpen', { item })
    },
    getCompanyItemText(item) {
      return item.name
    },
    getOmniSearchWidth() {
      if (this.$refs.omniSearchInput) {
        return this.$refs.omniSearchInput.$el.getBoundingClientRect().width + 'px'
      }
    },
    resetOmniSearch() {
      this.showOmniSearch = false
      this.omniSearchString = null
      this.showFullSizeSearchBar = false
    },
    hideOmniSearch(value) {
      this.showOmniSearch = false
    },
    afterCompanyImageSave(result) {
      this.selectedCompany = result.entities.companies[0]
    },
    toggleFullWidth() {
      store.commit('global/SET_LAYOUT_FULL_WIDTH', !store.getters['global/layoutFullWidth'])
    },
    handleSetShowRightDrawer(show) {
      const documentId = this.$store.getters['currentDocument/document']?.id
      if (documentId) {
        let rightDrawerClosed = JSON.parse(window.localStorage.getItem('rightDrawerClosed')) || {}
        if (show) {
          delete rightDrawerClosed[documentId]
        } else {
          rightDrawerClosed[documentId] = true
        }
        window.localStorage.setItem('rightDrawerClosed', JSON.stringify(rightDrawerClosed))
      }
      this.setShowApprovalDrawer(show)
    },
  },
}
</script>

<template>
  <div v-if="!loading">
    <div
      v-if="showOmniSearch"
      :style="omniSearchStyles"
    >
      <div
        class="bg-white prima-dialog-box-shadow border-bottom-left-radius border-bottom-right-radius"
      >
        <PlanitModelSearch
          v-if="omniSearchString && omniSearchString.length > 1"
          :value="omniSearchString"
          :company-id="selectedCompany && selectedCompany.id"
          hide-search-input
          link-instead-of-plus
          show-close-button
          include-companies
          two-columns
          max-height="80vh"
          @set-model="resetOmniSearch"
          @close="resetOmniSearch"
        ></PlanitModelSearch>
      </div>
    </div>

    <div
      v-if="companySelectOpen"
      :style="companySelectMenuStyles"
      v-click-outside="
        () => {
          if (companySelectOpen) companySelectOpen = false
        }
      "
    >
      <div class="bg-white prima-dialog-box-shadow border-bottom-left-radius">
        <div v-if="!companySelectOpenLoading">
          <div class="d-flex align-center pa-2">
            <planitTextField
              v-if="isAdmin || companies.length > 10"
              v-model="adminSelectedCompanySearch"
              class="ma-0 pa-0"
              :placeholder="$t('generic.search')"
              append-icon="fal fa-search"
              hide-details
              variant="outlined"
              density="compact"
            ></planitTextField>

            <PlanitButton
              v-if="isAdmin"
              variant="text"
              color="grey"
              class="icon-btn"
              @click="showCompanySelectFilter = !showCompanySelectFilter"
            >
              <PlanitIcon icon="fal fa-filter"></PlanitIcon>
            </PlanitButton>
          </div>

          <div
            v-if="showCompanySelectFilter"
            class="pa-2"
          >
            <PlanitSelect
              v-model="adminSelectedCompanyLicenseFilterValues"
              density="compact"
              item-title="text"
              item-value="value"
              multiple
              chips
              variant="outlined"
              :items="licenseTypeSelectItems"
            >
              <template #chip="{ item, index, remove }">
                <PlanitChip
                  :key="index"
                  :model-value="item.selected"
                  closable
                  size="small"
                  @click:close="remove"
                >
                  {{ item.title }}
                </PlanitChip>
              </template>
            </PlanitSelect>
          </div>

          <div
            style="max-height: max(50vh, 40rem); overflow-y: auto; min-width: max(17vw, 20rem)"
            class="scrollbar scrollbar-hover border-bottom-left-radius"
          >
            <v-treeview
              :items="companyTreeItems"
              item-value="id"
              item-title="name"
              v-model:activated="companyTreeActive"
              active-strategy="single-independent"
              activatable
              :opened="companyTreeOpen"
              :search="adminSelectedCompanySearch"
              expand-icon="$angleRight"
              collapse-icon="$angleDown"
              :item-children="(item) => (item.children.length ? item.children : null)"
              item-props
            >
              <template #prepend="{ item }">
                <div :class="item.children.length > 0 ? '' : $style.moveItemRight">
                  <NavTreeIcon
                    :item="item"
                    :open="companyTreeOpen.includes(item.id)"
                    nav-icon-width="1.5rem"
                    company-icon-width="1.5rem"
                  ></NavTreeIcon>
                </div>
              </template>
            </v-treeview>
          </div>
        </div>
      </div>
    </div>

    <v-app-bar
      id="planit-app-bar"
      :color="appBarColor"
      :scroll-behavior="mobile ? 'hide' : undefined"
      :height="navbarHeight"
      :class="'debugTheme-' + debugTheme"
      flat
      :elevation="0"
      :theme="navbarDark ? 'dark' : 'light'"
      :style="{
        color: navbarDark ? 'white' : 'black',
        'caret-color': navbarDark ? 'white' : 'black',
      }"
    >
      <div
        id="planit-header"
        :style="{ width: planitHeaderWidth }"
        class="d-flex align-center"
      >
        <PlanitButton
          v-if="loggedIn && currentUser.verified"
          variant="text"
          class="icon-btn"
          :height="navbarHeight"
          :theme="navbarDark ? 'dark' : 'light'"
          @click="showNavigationDrawer = !showNavigationDrawer"
        >
          <PlanitIcon
            size="16px"
            icon="fas fa-bars"
          ></PlanitIcon>
        </PlanitButton>

        <router-link
          :to="{ name: currentUser ? 'profile.dashboard' : 'home' }"
          :style="{ height: navbarHeight + '!important' }"
          class="d-flex align-center ml-2"
          :class="{ 'ml-4': !loggedIn || !currentUser.verified }"
        >
          <BrandLogo></BrandLogo>
        </router-link>

        <div
          v-if="instanceMode === 'intranet_testing'"
          style="
            margin: 0 8px;
            display: inline-block;
            padding: 0px 6px;
            border-radius: 4px;
            font-weight: 600;
            background-image: linear-gradient(135deg, rgb(243 223 124) 0%, rgb(195 102 22) 100%);
            color: rgb(0, 0, 0);
          "
        >
          Testing
        </div>

        <div
          v-if="instanceMode === 'staging'"
          style="
            margin: 0 8px;
            display: inline-block;
            padding: 0px 6px;
            border-radius: 4px;
            font-weight: 600;
            background-image: linear-gradient(135deg, rgb(227 102 159) 0%, rgb(195 22 110) 100%);
            color: rgb(0, 0, 0);
          "
        >
          Staging
        </div>

        <div
          v-if="instanceMode === 'intranet_qa'"
          style="
            margin: 0 8px;
            display: inline-block;
            padding: 0px 6px;
            border-radius: 4px;
            font-weight: 600;
            background-image: linear-gradient(135deg, rgb(124 243 210) 0%, rgb(22 195 59) 100%);
            color: rgb(0, 0, 0);
          "
        >
          QA
        </div>

        <v-toolbar-title
          v-if="loggedIn && currentUser.verified && !mobile"
          class="mx-2"
        >
          <div
            v-if="isLoading"
            class="d-flex align-center"
          >
            <v-progress-circular
              indeterminate
              size="24"
              color="primary"
              class="mr-1"
            ></v-progress-circular>
            <span :class="{ 'text-white': navbarDark }">
              {{ $t('components.nav_bar.saving') }}
            </span>
          </div>

          <v-hover
            v-if="!isLoading"
            v-slot="{ isHovering, props }"
            close-delay="1000"
          >
            <div
              v-bind="props"
              class="d-flex align-center"
            >
              <PlanitButton
                v-if="queueLength > 0"
                :theme="navbarDark ? 'dark' : 'light'"
                variant="text"
                class="icon-btn pa-2"
                @click="saveQueue"
              >
                <PlanitIcon
                  size="small"
                  icon="far fa-sync"
                ></PlanitIcon>
              </PlanitButton>
              <PlanitIcon
                v-if="queueLength === 0 && !isHovering && !showQueueInfoText"
                class="pa-2"
                :theme="navbarDark ? 'dark' : 'light'"
                size="small"
                icon="far fa-check"
              />
              <v-scroll-x-transition>
                <div
                  v-if="isHovering || showQueueInfoText"
                  class="ml-2"
                  :class="{ 'text-white': navbarDark }"
                >
                  <span v-if="queueLength > 0">{{ $t('components.nav_bar.save_queue') }}</span>
                  <span v-if="queueLength === 0">{{ $t('components.nav_bar.queue_saved') }}</span>
                </div>
              </v-scroll-x-transition>
            </div>
          </v-hover>
        </v-toolbar-title>
      </div>

      <div class="flex-grow-1">
        <planitTextField
          v-if="loggedIn && currentUser.verified && showFullSizeSearchBar"
          v-model.trim.debounce="omniSearchString"
          ref="omniSearchInput"
          :placeholder="$t('generic.search')"
          variant="solo"
          :bg-color="searchBarBackgroundColor"
          :theme="navbarDark ? 'dark' : 'light'"
          hide-details
          prepend-inner-icon="fal fa-search"
          class="search-input-icon-fix"
          autofocus
        >
          <template
            v-if="showFullSizeSearchBar"
            #append-inner
          >
            <PlanitButton
              variant="text"
              class="icon-btn"
              @click="resetOmniSearch"
            >
              <PlanitIcon icon="fal fa-times"></PlanitIcon>
            </PlanitButton>
          </template>
        </planitTextField>
      </div>

      <PlanitButton
        v-if="loggedIn && currentUser.verified && !showFullSizeSearchBar"
        class="icon-btn ml-4"
        :theme="navbarDark ? 'dark' : 'light'"
        :height="navbarHeight"
        variant="text"
        @click="showFullSizeSearchBar = true"
      >
        <PlanitIcon
          icon="far fa-search"
          size="large"
        ></PlanitIcon>
      </PlanitButton>

      <v-btn
        v-if="showCompanySelect && loggedIn && currentUser.verified"
        :disabled="!isAdmin && companies.length === 1"
        @click="companySelectOpen = !companySelectOpen"
        :height="navbarHeight"
        :loading="companySelectOpenLoading"
        rounded="0"
        class="custom-disabled-btn"
        style="letter-spacing: normal !important"
      >
        <template #prepend>
          <ProfilePicture
            v-if="selectedCompany"
            :model="selectedCompany"
            placeholder-type="company"
            thumbnail
            width="48px"
            :theme="navbarDark ? 'dark' : 'light'"
          ></ProfilePicture>
          <div v-else-if="mobile">
            <PlanitIcon
              theme="dark"
              width="48px"
              icon="fas fa-landmark"
            />
          </div>
        </template>

        <div
          v-if="!mobile"
          class="d-flex flex-column align-start"
        >
          {{
            !selectedCompany
              ? $t('components.nav_bar.select_company')
              : getCompanyItemText(selectedCompany)
          }}
          <LicenseLabel
            v-if="selectedCompany"
            :license-type="activeLicenseType"
            :inverted="activeLicenseType === LicenseType.FREE"
          ></LicenseLabel>
        </div>

        <template
          v-if="(isAdmin || companies.length > 1) && !mobile"
          #append
        >
          <PlanitIcon
            :class="{ 'text-white': navbarDark }"
            class="mx-2"
          >
            fas fa-caret-down
          </PlanitIcon>
        </template>
      </v-btn>

      <div v-if="loggedIn && currentUser.verified">
        <v-menu
          location="bottom"
          :offset="[5, 30]"
          :close-on-content-click="false"
          width="20rem"
        >
          <template #activator="{ props }">
            <PlanitButton
              :theme="navbarDark ? 'dark' : 'light'"
              variant="text"
              class="icon-btn navbar-select-hover"
              :height="navbarHeight"
              v-bind="props"
            >
              <div v-if="isAdmin">
                <PlanitIcon
                  :color="navbarDark ? 'white' : 'error'"
                  icon="far fa-user-crown"
                ></PlanitIcon>
              </div>
              <div
                v-else-if="currentUser.image"
                style="width: 40px; height: 40px"
              >
                <ProfilePicture
                  :model="currentUser"
                  width="40px"
                  rounded
                ></ProfilePicture>
              </div>
              <PlanitIcon
                v-else
                icon="fas fa-user"
              ></PlanitIcon>
            </PlanitButton>
          </template>

          <div class="bg-white card-box-shadow border-bottom-left-radius">
            <div class="d-flex align-center pa-2">
              <div class="pa-2">
                <ProfilePicture
                  :model="currentUser"
                  width="30px"
                  rounded
                ></ProfilePicture>
              </div>
              <div class="ml-2">
                <div>
                  <span class="font-weight-medium">{{ currentUser.name }}</span>
                </div>
                <div>
                  <span
                    v-if="currentUser.name"
                    color="text-grey"
                    >{{ currentUser.email }}</span
                  >
                  <span
                    v-else
                    class="font-weight-medium"
                    >{{ currentUser.email }}</span
                  >
                </div>
              </div>
            </div>

            <v-divider></v-divider>

            <v-list class="pa-0">
              <v-list-item
                class="py-2"
                :to="{ name: 'profile.dashboard' }"
              >
                <PlanitIcon
                  class="mr-2"
                  size="small"
                  icon="fas fa-fw fa-user"
                />
                {{ $t('profile.dashboard_text') }}
              </v-list-item>
              <v-list-item class="py-2">
                <PlanitIcon
                  class="mr-2"
                  size="small"
                  icon="fas fa-fw fa-language"
                />
                <span>{{ $t('generic.language') }}</span>
                <template #append>
                  <v-btn-toggle
                    v-model="currentLocaleComputed"
                    mandatory
                    density="compact"
                  >
                    <template #default="{ isSelected }">
                      <PlanitButton
                        v-for="lsi in languageSelectItems"
                        :key="lsi.value"
                        :value="lsi.value"
                        :color="isSelected(lsi.value) ? '' : 'grey-lighten-2'"
                        size="x-small"
                      >
                        {{ lsi.text }}
                      </PlanitButton>
                    </template>
                  </v-btn-toggle>
                </template>
              </v-list-item>
              <v-list-item class="py-2">
                <PlanitIcon
                  class="mr-2"
                  color="error"
                  size="small"
                  :icon="layoutFullWidth ? 'fal fa-compress' : 'fal fa-expand'"
                ></PlanitIcon>

                <span>{{ $t('components.nav_bar.full_width_content') }}</span>
                <template #append>
                  <PlanitSwitch
                    :model-value="layoutFullWidth"
                    small
                    hide-details
                    color="primary"
                    @update:model-value="toggleFullWidth"
                  ></PlanitSwitch>
                </template>
              </v-list-item>
              <v-list-item
                class="py-2"
                v-if="isActuallyAdmin"
              >
                <PlanitIcon
                  class="mr-2"
                  color="error"
                  size="small"
                  icon="fas fa-fw fa-wand-magic"
                />
                <span>{{ $t('generic.admin') }}</span>
                <template #append>
                  <PlanitSwitch
                    v-model="isAdminComputed"
                    small
                    hide-details
                    color="error"
                  ></PlanitSwitch>
                </template>
              </v-list-item>
              <v-list-item
                class="py-2"
                v-if="isActuallyAdmin"
              >
                <PlanitIcon
                  class="mr-2"
                  color="error"
                  size="small"
                  icon="fas fa-fw fa-landmark"
                />
                <span class="pr-4">{{
                  $t('components.nav_bar.admin_show_selected_company_only')
                }}</span>
                <template #append>
                  <PlanitSwitch
                    v-model="adminShowSelectedCompanyOnly"
                    small
                    hide-details
                    color="error"
                  ></PlanitSwitch>
                </template>
              </v-list-item>
              <v-list-item
                class="py-2"
                @click="logOut"
              >
                <PlanitIcon
                  class="mr-2"
                  size="small"
                  icon="fas fa-fw fa-sign-out"
                />
                {{ $t('components.nav_bar.logout') }}
              </v-list-item>
            </v-list>
          </div>
        </v-menu>

        <PlanitButton
          v-if="impersonator"
          :theme="navbarDark ? 'dark' : 'light'"
          color="warning"
          class="mx-2"
          @click="stopImpersonating"
        >
          <PlanitIcon
            class="mr-1"
            icon="fal fa-user-secret"
          ></PlanitIcon>

          <span>{{ $t('components.nav_bar.stop_impersonation') }}</span>
        </PlanitButton>
      </div>

      <PlanitButton
        v-if="!loggedIn && $route.name !== 'home'"
        :theme="navbarDark ? 'dark' : 'light'"
        color="primary"
        variant="text"
        class="mx-2 btn-alt"
        :to="{ name: 'home' }"
      >
        <PlanitIcon
          size="small"
          class="mr-1"
          icon="fal fa-sign-in-alt"
        ></PlanitIcon>

        <span>{{ $t('login.login') }}</span>
      </PlanitButton>
    </v-app-bar>

    <div v-if="currentUser && currentUser.verified">
      <div
        class="left-app-panel"
        :style="[
          commonAppDrawerStyles,
          leftAppPanelStyles,
          { 'background-color': backgroundLightColor },
        ]"
        style="left: 0; position: fixed"
      >
        <div class="d-flex flex-column fill-height pt-4 px-2">
          <v-tooltip
            v-for="item in appSelectItems"
            :key="item.value + '-' + routeChangeCounter"
            location="right"
            class="mb-2"
          >
            <template #activator="{ props }">
              <PlanitButton
                v-bind="props"
                :disabled="item.disabled"
                :to="!navTreeLoading && item.to ? item.to : ''"
                variant="text"
                selected-class="_"
                exact
                class="icon-btn mb-2"
                style="min-width: unset; height: auto; border-radius: 0; --v-border-opacity: 1"
                :color="selectedApp === item.value ? undefined : 'primary'"
                :class="
                  selectedApp === item.value
                    ? 'border-s-lg border-' + (item.iconColor || 'primary')
                    : 'border-radius'
                "
                :style="selectedApp === item.value ? { 'border-left-width': '3px' } : {}"
                @click="item.fn && item.fn()"
              >
                <PlanitIcon
                  class="my-2"
                  :color="
                    selectedApp === item.value ? item.iconColor || 'primary' : 'grey-darken-3'
                  "
                  :icon="item.icon"
                  size="x-large"
                ></PlanitIcon>
              </PlanitButton>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>
        </div>
      </div>

      <v-navigation-drawer
        v-if="
          showNavigationDrawer /* workaround because of https://github.com/vuetifyjs/vuetify/issues/19877 */
        "
        id="navDrawer"
        ref="navDrawer"
        :model-value="showNavigationDrawer"
        :style="[commonAppDrawerStyles, navDrawerStyles]"
        disable-resize-watcher
        disable-route-watcher
        :temporary="hasSmallScreen"
        :color="hasSmallScreen ? 'white' : 'transparent'"
        :mobile="false"
        floating
        :width="navDrawerWidth"
      >
        <ScrollContainer>
          <div class="fill-height d-flex">
            <div class="flex-grow-1 fill-height d-flex flex-column pt-4">
              <div v-if="debug">
                <!--<pre v-if="debug">navTreeItems: {{ navTreeItems }}</pre>-->
                <!--<pre v-if="debug">companyTreeItems: {{ companyTreeItems }}</pre>-->
                <pre>breakpoint: {{ breakpointName }}</pre>
                <pre>mobile: {{ mobile }}</pre>
                <pre>companySelectOpen: {{ companySelectOpen }}</pre>
                <pre>navTreeActive: {{ navTreeActive }}</pre>
                <pre>navTreeOpen: {{ navTreeOpen }}</pre>
                <pre>showProfile: {{ showProfile }}</pre>
                <pre>selectedApp: {{ selectedApp }}</pre>
                <pre>navTreeLoading: {{ navTreeLoading }}</pre>
                <pre>contentLoading: {{ contentLoading }}</pre>
                <pre>navbarHeight: {{ navbarHeight }}</pre>
                <pre>footerHeight: {{ footerHeight }}</pre>
                <pre>sectionCount: {{ sectionCount }}</pre>
              </div>

              <div v-if="showProfile">
                <div class="d-flex">
                  <div class="d-flex align-center">
                    <div class="pa-4">
                      <ProfilePicture
                        :model="currentUser"
                        width="5rem"
                        editable
                        thumbnail
                        rounded
                        @after-refresh="$store.dispatch('auth/validate')"
                      ></ProfilePicture>
                    </div>
                    <div>
                      <!-- fix weird overflow on right with pr-4 -->
                      <h1
                        class="pr-2 d-flex align-baseline"
                        style="word-break: break-word"
                      >
                        {{ currentUser.name || currentUser.email }}
                        <TooltipIcon
                          v-if="isAdminComputed"
                          icon="far fa-wand-magic"
                          tooltip="Superadmin"
                          color="error"
                          bottom
                          class="ml-2"
                        ></TooltipIcon>
                      </h1>

                      <div
                        v-if="!currentUser.verified"
                        class="mt-4"
                      >
                        <h3 class="font-weight-regular mr-6">
                          <PlanitChip label>{{
                            $t('generic.unverified', { email: currentUser.email })
                          }}</PlanitChip>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="hasSmallScreen"
                    class="ml-auto pa-2 pt-6"
                  >
                    <PlanitButton
                      variant="text"
                      class="icon-btn"
                      @click="setShowNavigationDrawer(false)"
                    >
                      <PlanitIcon icon="far fa-chevron-left"></PlanitIcon>
                    </PlanitButton>
                  </div>
                </div>
              </div>

              <div v-if="showCompany">
                <div class="d-flex align-center pa-4">
                  <div>
                    <ProfilePicture
                      :model="selectedCompany"
                      width="5rem"
                      thumbnail
                      placeholder-type="company"
                      editable
                      @after-refresh="afterCompanyImageSave"
                    ></ProfilePicture>
                  </div>
                  <div class="pl-2">
                    <h3
                      class="mb-1"
                      style="word-break: break-word"
                    >
                      {{ selectedCompany.name }}
                    </h3>
                    <div v-if="selectedCompany.is_company_group">
                      <LicenseLabel
                        license-type="free"
                        :text="$t('company.company_group')"
                        style="display: inline-block"
                      ></LicenseLabel>
                    </div>
                    <div v-else-if="!!activeLicense || activeLicenseType">
                      <component
                        :is="isManager ? 'router-link' : 'div'"
                        :to="
                          isManager
                            ? { name: 'manager.licenses', state: { licenseId: activeLicense.id } }
                            : {}
                        "
                        style="display: inline-block"
                        :style="isManager ? { cursor: 'pointer !important' } : {}"
                      >
                        <LicenseLabel :license-type="activeLicenseType"></LicenseLabel>
                      </component>
                    </div>
                  </div>
                </div>
              </div>

              <!--<PlanitLicenseExpirationNotice
                v-if="!activeLicensePermissionRole && !!selectedCompany"
                class="border-bottom"
              ></PlanitLicenseExpirationNotice>-->

              <!--<PlanitLicenseExpirationNotice
                v-if="
                "
                class="border-bottom"
              ></PlanitLicenseExpirationNotice>-->

              <div
                v-if="dpdReadOnly"
                class="px-4 mb-4"
              >
                <PlanitChip
                  label
                  color="default"
                >
                  {{ $t('generic.read_only') }}
                </PlanitChip>
              </div>

              <router-link
                v-if="selectedAppItem && !showCompany && !showProfile"
                :to="selectedAppItem.to || {}"
                style="width: 100%"
                class="mb-2"
              >
                <h1 class="px-4 flex-grow-1 fill-height lora-heading">
                  <div
                    class="d-flex align-center"
                    :class="[dpdReadOnly ? '' : 'fill-height', selectedAppItem.textClass || '']"
                  >
                    {{ selectedAppItem.text }}
                  </div>
                </h1>
              </router-link>
              <div
                v-if="navTreeLoading"
                class="pa-3"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </div>
              <div
                v-else-if="
                  !unauthorizedPermissionRole ||
                  (showUnauthorizedError && !unauthorizedPermissionRole)
                "
                class="pa-3"
              >
                <div
                  v-for="(tree, index) in navTreeItems"
                  :key="tree.name"
                >
                  <v-divider
                    v-if="index > 0 && !tree.title"
                    class="mx-6 text-black my-2"
                  ></v-divider>

                  <div v-if="tree.name === 'profileLicenses'">
                    <div
                      v-for="item in tree.items"
                      :key="item.nameId"
                      class="mb-4 mx-4"
                    >
                      <router-link :to="item.to">
                        <div class="license-label profile-card profile-card-hover">
                          <LicenseLabel
                            :license-type="item.licenseType"
                            :text="item.name"
                            fw
                          ></LicenseLabel>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="tree.title"
                      class="px-4 mb-2"
                    >
                      <h3>{{ tree.title }}</h3>
                    </div>

                    <v-treeview
                      v-model:opened="navTreeOpenComputed"
                      :items="tree.items"
                      v-model:activated="navTreeActive"
                      item-value="nameId"
                      color="rgb(var(--v-theme-primary))"
                      base-color="rgb(var(--v-theme-primary))"
                      expand-icon="$angleRight"
                      collapse-icon="$angleDown"
                      active-strategy="independent"
                    >
                      <template #prepend="{ item }">
                        <div
                          :class="
                            item.children?.length > 0 || noTreeItemHasChildren
                              ? ''
                              : $style.moveItemRight
                          "
                        >
                          <NavTreeIcon
                            :item="item"
                            :open="navTreeOpenComputed.includes(item.nameId)"
                            :nav-icon-width="navIconWidth"
                            :company-icon-width="companyIconWidth"
                          ></NavTreeIcon>
                        </div>
                      </template>
                      <template #title="{ item }">
                        <div class="d-flex align-center">
                          <a
                            v-if="item.external"
                            :href="item.disabled ? '' : item.to"
                            :class="[
                              $style.customLink,
                              !item.disabled ? 'text-grey-darken-4' : 'text-grey',
                            ]"
                            target="_blank"
                          >
                            <span>{{ item.name }}</span>
                            <PlanitIcon
                              size="small"
                              class="ml-2"
                              icon="fas fa-external-link-alt"
                            />
                          </a>
                          <div
                            v-else
                            class="flex-grow-1"
                          >
                            <pre v-if="debug">{{ item.nameId }}</pre>

                            <a
                              v-if="!item.to && item.openOnClick"
                              href
                              :class="[
                                $style.customLink,
                                !item.disabled ? 'grey-text-darken-4' : 'text-grey',
                              ]"
                              @click.prevent="item.disabled ? () => {} : addToNavTreeOpen(item)"
                            >
                              <span :style="{ color: item.disabled ? '#666' : 'inherit' }">
                                {{ item.name }}
                              </span>
                            </a>
                            <div
                              v-else
                              class="flex-grow-1"
                            >
                              <router-link
                                v-if="item.to"
                                :to="item.disabled ? '' : item.to"
                                :exact="item.exact"
                                :class="[
                                  $style.customLink,
                                  item.disabled
                                    ? 'text-grey'
                                    : item.color
                                      ? item.color
                                      : 'text-grey-darken-4',
                                ]"
                                @click.prevent="addToNavTreeOpen(item)"
                              >
                                <div>
                                  <span>
                                    {{ item.name }}
                                  </span>
                                  <div
                                    v-if="item.newFeatureRibbon"
                                    :class="$style.ribbonTest"
                                  >
                                    <span>{{ $t('generic.new') }}</span>
                                  </div>
                                </div>
                                <small
                                  v-if="item.description"
                                  class="text-caption font-weight-thin"
                                >
                                  {{ item.description }}
                                </small>
                              </router-link>
                              <div v-else>
                                <span>
                                  {{ item.name }}
                                </span>
                                <small
                                  v-if="item.description"
                                  class="text-caption font-weight-thin"
                                >
                                  {{ item.description }}
                                </small>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="!!item.disabledLicenseTypeRequired"
                            class="ml-auto"
                          >
                            <LicenseLabel
                              :license-type="item.disabledLicenseTypeRequired"
                              density
                            ></LicenseLabel>
                          </div>
                        </div>
                      </template>
                    </v-treeview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollContainer>
      </v-navigation-drawer>

      <RightDrawer
        v-if="showRightDrawer"
        v-bind="rightDrawerArgs"
      ></RightDrawer>

      <div
        class="right-app-panel"
        :style="[
          commonAppDrawerStyles,
          rightAppPanelStyles,
          { 'background-color': backgroundLightColor },
        ]"
        style="z-index: 199; right: 0; position: fixed"
      >
        <div class="d-flex flex-column fill-height pt-4 px-2">
          <v-tooltip
            v-for="item in rightAppSelectItems"
            :key="item.value"
            location="left"
            class="mb-2"
          >
            <template #activator="{ props }">
              <PlanitButton
                v-bind="props"
                :disabled="item.disabled"
                :to="!navTreeLoading && item.to ? item.to : ''"
                variant="text"
                selected-class="_"
                exact
                style="min-width: unset; height: auto; border-radius: 0; --v-border-opacity: 1"
                :color="rightAppActive === item.value ? undefined : 'primary'"
                class="icon-btn mb-2"
                :class="
                  rightAppActive === item.value
                    ? 'border-e-lg border-' + (item.iconColor || 'primary')
                    : 'border-radius'
                "
                :style="rightAppActive === item.value ? { 'border-right-width': '3px' } : {}"
                @click="item.fn && item.fn()"
              >
                <PlanitIcon
                  class="my-2"
                  :color="
                    rightAppActive === item.value ? item.iconColor || 'primary' : 'grey-darken-3'
                  "
                  :icon="item.icon"
                  size="x-large"
                ></PlanitIcon>
              </PlanitButton>
            </template>
            <span>{{ item.text }}</span>
          </v-tooltip>

          <div class="mt-auto mb-4">
            <PlanitSupport v-if="showSupportButton" />
          </div>
        </div>
      </div>

      <Checklist
        v-if="activeTourChecklist"
        :checklist="activeTourChecklist"
      ></Checklist>
    </div>
  </div>
</template>

<style scoped>
.custom-disabled-btn.v-btn--disabled {
  opacity: 1 !important;
}
</style>

<style lang="scss" module>
.customLink {
  padding: 0.5rem 0 0.5rem 4px;
  display: block;
  flex-grow: 1;
  font-weight: 400;
}

.customLinkDark {
  background-color: rgb(var(--v-theme-primary));
  color: #fff;
}

.moveItemRight {
  margin-left: 28px;
}

.ribbonTest {
  --f: -21px; /* control the folded part */
  --r: 15px; /* control the ribbon shape */
  --t: 7px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px;
  text-transform: uppercase;
  // -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  background: rgb(var(--v-theme-warning));
  // box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  border-radius: 4px; /* rounded corners */
  transform: rotate(0deg); /* slight rotation */
  color: white; /* text color */
}
</style>
