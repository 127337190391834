<script>

import {
  QuestionType,
} from '@enums'

import PlanitInput from '@components/form/input'
import AnswerAddressEdit from '@components/answer-address-edit.vue'

import store from '@state/store'
import {
  debugGetters,
} from '@state/helpers'
import DocumentBlueprint from '@state/models/document-blueprint'
import { Document } from '@state/models/document'
import Answer from '@state/models/answer'
import Section from '@state/models/section'


export default {
  name: 'RepoDocumentTable',
  components: {
    PlanitInput,
    AnswerAddressEdit,
  },
  props: {
    repoDocumentId: { type: Number, required: true },
    readOnly: { type: Boolean, default: null },
    limitToSectionViid: { type: String, default: null },
  },
  data() {
    return {
      QuestionType,

      loading: false,
    }
  },
  computed: {
    ...debugGetters,
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    repoDocument() {
      return Document.query().whereId(this.repoDocumentId).with('repository_edit_document_version').first()
    },
    repository() {
      return DocumentBlueprint.query().whereId(this.repoDocument.document_blueprint_id).first()
    },
    repositoryAttributes() {
      return Section.query().where(s => {
        if (this.limitToSectionViid) {
          return s.viid === this.limitToSectionViid
        } else {
          return this.repository.attribute_ids.includes(s.id)
        }
      }).all()
    },
    filteredRepositoryAttributes() {
      return this.repositoryAttributes.filter(attr => {
        if (this.limitToSectionViid) {
          return attr.viid === this.limitToSectionViid
        }
        return true
      })
    },
    repoDocumentAttributes() {
      return Answer.query().where(a => {
        if (this.limitToSectionViid) {
          return a.section_viid === this.limitToSectionViid
        } else {
          return this.repoDocument.attribute_ids.includes(a.id)
        }
      }).all()
    },
    mappedAttributes() {
      return this.repoDocumentAttributes
      .filter(attr => !this.limitToSectionViid || attr.section_viid === this.limitToSectionViid)
      .reduce((acc, attr) => {
        acc[attr.section_viid] = attr
        return acc
      }, {})
    },
    readOnlyComputed() {
      return this.readOnly !== null
        ? this.readOnly
        : this.repoDocument.repository_edit_document_version?.version !== -1
    },
    repoTableClasses() {
      const classes = []

      if (this.repoDocumentAttributes.length > 1) {
        classes.push('repo-table-full-width')
      }

      if (this.limitToSectionViid) {
        classes.push('limit-to-single-section')
      }

      return classes
    },
  },
  async created() {
  },
  methods: {
  },
}
</script>

<template>
  <div>
    <table
      class="repo-table"
      :class="repoTableClasses"
    >
      <tbody>
        <tr v-for="attr in filteredRepositoryAttributes" :key="attr.id">
          <th v-if="!limitToSectionViid" scope="row" class="py-1">
            <div v-if="attr.answer_optional" class="text-grey-darken-1 text-small">
              {{ $t('generic.optional') }}
            </div>
            <div class="d-flex align-center justify-end">
              <div>{{ attr.text_translations[currentLocale] }}</div>
              <div v-if="debug">{{ attr.viid }}</div>

              <v-tooltip
                v-if="
                  attr.tooltip_info_text_translations[currentLocale]
                "
                location="top"
              >
                <template #activator="{ props }">
                  <PlanitIcon
                    v-bind="props"
                    class="ml-3"
                    icon="fal fa-question-circle"/>
                </template>
                <span
                  class="markdown-container"
                  v-html="attr.tooltip_info_text_translations[currentLocale]"
                ></span>
              </v-tooltip>
            </div>
          </th>
          <td class="py-1">
            <div v-if="mappedAttributes[attr.viid]">
              <div v-if="attr.question_type == QuestionType.TEXT_MULTIPLE">

                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  attr="text_translations"
                  :document-version="repoDocument.repository_edit_document_version"
                  type="textarea"
                  :section="attr"
                  :lang="currentLocale"
                  class="flex-grow-1"
                  dont-validate
                  markdown
                  :disabled="readOnlyComputed"
                ></PlanitInput>

              </div>
              <div v-else-if="attr.question_type === QuestionType.TIMEDELTA" class="d-flex align-center">
                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  attr="data.timedelta.amount"
                  input-type="number"
                  :min="1"
                  :placeholder="$t('components.section_answer.timedelta_amount_placeholder')"
                  :document-version="repoDocument.repository_edit_document_version"
                  highlight
                  dont-validate
                  class="mr-2"
                  width="6rem"
                  :save-on-blur="false"
                  :immediate-save="false"
                  re-query-model-data-before-save
                  :after-save="() => {}"
                ></PlanitInput>
                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  attr="data.timedelta.type"
                  type="select"
                  :placeholder="$t('components.section_answer.timedelta_type_placeholder')"
                  :select-items="timeDeltaTypeSelectItems[
                    (mappedAttributes[attr.viid].data?.timedelta?.amount || 2) > 1
                      ? 1
                      : 0
                  ]"
                  :document-version="repoDocument.repository_edit_document_version"
                  highlight
                  dont-validate
                  class="flex-grow-1"
                  immediate-save
                  re-query-model-data-before-save
                  width="10rem"
                  :after-save="() => {}"
                ></PlanitInput>
              </div>

              <div v-else-if="attr.question_type === QuestionType.ADDRESS">
                <AnswerAddressEdit
                  :answer="mappedAttributes[attr.viid]"
                  :document-version="repoDocument.repository_edit_document_version"
                  :disabled="readOnlyComputed"
                ></AnswerAddressEdit>

              </div>
              <div
                v-else-if="
                  [
                    QuestionType.DROPDOWN,
                    QuestionType.RADIO,
                    QuestionType.CHECKBOX,
                  ].includes(attr.question_type)
                "
              >
                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  :type="attr.question_type"
                  :items="attr.data.items"
                  attr="data"
                  :document-version="repoDocument.repository_edit_document_version"
                  highlight
                  dont-validate
                  width="6rem"
                  re-query-model-data-before-save
                  :after-save="() => {}"
                  :immediate-save="
                    (
                      attr.question_type !== QuestionType.DROPDOWN ||
                      !attr.data.dropdown_multiple
                    ) &&
                    (
                      attr.question_type !== QuestionType.CHECKBOX ||
                      attr.data.items.length === 1
                    ) && !attr.data.items.find(i => !!i.custom_text)
                  "
                  :dropdown-multiple="
                    attr.question_type === QuestionType.DROPDOWN && attr.data.dropdown_multiple
                  "
                  :checkbox-type="attr.question_type"
                  :save-on-blur="
                    attr.question_type === QuestionType.DROPDOWN &&
                    attr.data?.dropdown_multiple
                  "
                ></PlanitInput>
              </div>
              <div v-else-if="attr.question_type === QuestionType.DATE">
                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  :section="attr"
                  attr="data"
                  type="date"
                  :document-version="repoDocument.repository_edit_document_version"
                  :is-date-time="!!attr.data?.date?.with_time"
                  :timespan="!!attr.data?.date?.allow_timespan"
                  highlight
                  dont-validate
                  immediate-save
                  :disabled="readOnlyComputed"
                  :after-save="() => {}"
                ></PlanitInput>
              </div>
              <div v-else-if="attr.question_type === QuestionType.SMART_FIELD">
                <PlanitInput
                  :model="mappedAttributes[attr.viid]"
                  :section="attr"
                  attr="text_translations"
                  :placeholder="attr.placeholder_translations[currentLocale]"
                  :disabled="
                    readOnlyComputed ||
                    (
                      attr.question_type === QuestionType.SMART_FIELD &&
                      !attr.data.smart_field.editable
                    )
                    "
                  :document-version="repoDocument.repository_edit_document_version"
                  :lang="currentLocale"
                  highlight
                  dont-validate
                  :after-save="() => {}"
                ></PlanitInput>

              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss">

.repo-table {
  width: 50%;

  &.repo-table-full-width {
    width: 100%;
  }

  &.limit-to-single-section {
    width: 100%;
  }

  & {
    border-collapse: collapse;
  }

  & {
    border-spacing: 0px;
  }

  & {
    table-layout: fixed;
  }

  & tbody > tr > th {
    width: 30%;
    text-align: right;
  }

  &:not(.limit-to-single-section) tbody > tr > th,
  &:not(.limit-to-single-section) tbody > tr > td {
    padding: 4px;
  }
}

</style>

