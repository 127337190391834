<script>
import ProfilePicture from '@components/profile-picture'
import TooltipIcon from '@components/tooltip-icon'
import IconButton from '@components/icon-button'

import copyToClipboard from '@utils/copy-to-clipboard'


export default {
  name: 'PlanitListItem',
  components: {
    ProfilePicture,
    TooltipIcon,
    IconButton,
  },
  props: {
    company: { type: Object, required: false, default: null },
    user: { type: Object, required: false, default: null },
    documentBlueprint: { type: Object, required: false, default: null },
    invite: { type: Object, required: false, default: null },
    companyRole: { type: Object, required: false, default: null },
    showRemoveCRButton: { type: Boolean, required: false, default: false },
    document: { type: Object, required: false, default: null },
    scormFile: { type: Object, required: false, default: null },

    width: { type: String, required: false, default: '2rem' },
    showAddButton: { type: Boolean, required: false, default: false },
    showRemoveButton: { type: Boolean, required: false, default: false },

    blueprintAsLink: { type: Boolean, required: false, default: false },
  },
  emits: ['add', 'remove-user', 'remove-company-role'],
  data: function () {
    return {}
  },
  computed: {
    containerStyles() {
      const styles = {}

      styles.width = this.width
      styles.height = this.width

      return styles
    },
  },
  created() {},
  methods: {
    add(target) {
      this.$emit('add', target)
    },
    removeUser(user) {
      this.$emit('remove-user', user)
    },
    removeInvite(invite) {
      invite.delete()
    },
    copyInviteLink(invite) {
      copyToClipboard(invite.invite_link)
    },
    removeCompanyRole(companyRole) {
      this.$emit('remove-company-role', companyRole)
    },
  },
}
</script>

<template>
  <div>
    <div
      v-if="company"
      class="d-flex align-center"
    >
      <ProfilePicture
        class="mr-2"
        :model="company"
        :width="width"
        thumbnail
        placeholder-type="company"
      ></ProfilePicture>
      <span class="mr-4">{{ company.name }}</span>
      <PlanitButton
        v-if="showAddButton"
        variant="text"
        color="primary"
        class="icon-btn ml-auto"
        @click="add(company)"
      >
        <PlanitIcon size="small" icon="fal fa-plus"></PlanitIcon>

      </PlanitButton>
    </div>

    <div
      v-if="user"
      class="d-flex align-center"
    >
      <ProfilePicture
        class="mr-2"
        :model="user"
        :width="width"
        rounded
        placeholder-type="user"
      ></ProfilePicture>
      <span>{{ user.name || user.email }}</span>
      <IconButton
        v-if="showAddButton"
        icon="fa-plus"
        color="primary"
        @click="add(user)"
      ></IconButton>

      <IconButton
        v-if="showRemoveButton"
        icon="fa-trash-alt"
        class="ml-auto"
        @click="removeUser(user)"
      ></IconButton>
    </div>

    <div
      v-if="companyRole"
      class="d-flex align-center"
    >
      <ProfilePicture
        class="mr-2"
        :model="companyRole.user || {}"
        :width="width"
        rounded
        placeholder-type="user"
      ></ProfilePicture>
      <span v-if="companyRole.user">{{ companyRole.user.name || companyRole.user.email }}</span>
      <span v-else-if="companyRole.invite">{{
        (companyRole.invite.user && companyRole.invite.user.name) || companyRole.invite.email
      }}</span>
      <!-- <PlanitButton
        v-if="showAddButton"
        variant="text"
        color="primary"
        class="icon-btn"
        @click="add(companyRole)"
      >
        <PlanitIcon size="small" icon="fal fa-plus"></PlanitIcon>
      </PlanitButton> -->
      <!-- <PlanitButton
        v-if="showRemoveCRButton"
        icon
        color="primary"
        class="icon-btn ml-auto"
        @click="removeCompanyRole(companyRole)"
      >
        <PlanitIcon size="small" icon="fal fa-trash-alt"></PlanitIcon>
      </PlanitButton> -->

      <IconButton
        v-if="showAddButton"
        icon="fa-trash-alt"
        class="ml-auto"
        @click="removeCompanyRole(companyRole)"
      ></IconButton>
      <IconButton
        v-if="showRemoveCRButton"
        icon="fa-trash-alt"
        class="ml-auto"
        @click="removeCompanyRole(companyRole)"
      ></IconButton>
    </div>

    <div
      v-if="documentBlueprint"
      class="d-flex align-center"
    >
      <ProfilePicture
        :model="documentBlueprint"
        :placeholder-icon="documentBlueprint.getIcon()"
        placeholder-type="custom"
        :width="width"
        rounded
        class="mr-2"
      ></ProfilePicture>

      <div v-if="!blueprintAsLink">
        {{ documentBlueprint.name }}
      </div>
      <router-link
        v-else
        :to="{
          name: 'admin.documentBlueprint.sections.page',
          params: { documentBlueprintId: documentBlueprint.id, page: 1 },
        }"
        class="font-weight-medium"
      >
        {{ documentBlueprint.name }}
      </router-link>
    </div>

    <div
      v-if="document"
      class="d-flex align-center"
    >
      <ProfilePicture
        :model="document"
        :placeholder-icon="document.getIcon()"
        placeholder-type="custom"
        :width="width"
        rounded
        class="mr-2"
      ></ProfilePicture>

      <div>
        <span class="text-grey-lighten-1">{{ document.name }}</span>
        <br />
        {{ document.primary_folder_name }}
      </div>
    </div>

    <div v-if="invite">
      <div class="d-flex align-center justify-space-between">
        <ProfilePicture
          v-if="invite.user"
          :model="invite.user"
          :width="width"
          rounded
          class="mr-2"
        ></ProfilePicture>
        <div
          v-else
          :style="containerStyles"
          class="mr-2 d-flex"
        >
          <TooltipIcon
            :tooltip="$t('generic.invited')"
            icon="fal fa-envelope"
            class="ma-auto"
          ></TooltipIcon>
        </div>

        <div>
          <div style="font-size: 1rem; line-height: 1.5">
            {{ invite.user ? invite.user.email : invite.email }}
          </div>
        </div>

        <div class="flex-grow-1"></div>

        <div class="mx-1">
          <v-tooltip location="top">
            <template #activator="{ props }">
              <PlanitButton

                variant="text"
                class="icon-btn"
                v-bind="props"
                @click.prevent="copyInviteLink(invite)"
              >
                <PlanitIcon size="small" icon="fal fa-link"></PlanitIcon>

              </PlanitButton>
            </template>
            <span>{{ $t('scorm_participants.copy_direct_invite_link') }}</span>
          </v-tooltip>
        </div>

        <IconButton
          icon="fa-trash-alt"
          class="ml-1"
          @click="removeInvite(invite)"
        ></IconButton>
      </div>
    </div>
  </div>
</template>
