import PlanitModel from './planit-model'
import Company from './company'
import TableSetting from './table-setting'
import User from './user'
import Folder from './folder'

export default class TableSettingActivation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      table_setting_id: this.attr(),
      table_setting: this.belongsTo(TableSetting, 'table_setting_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      is_global_active: this.attr(),
      is_folder_specific_active: this.attr(),
    }
  }
}

TableSettingActivation.entity = 'table-setting-activations'