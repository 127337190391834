import Approval from '@state/models/approval'
import ApprovalComment from '@state/models/approval-comment'
import TaskCompanyRoleAssociation from '@/src/state/models/task-company-role-association'
import Task from '@state/models/task'
import store from '@state/store'
import Timer from '@state/models/timer'

export const state = {
}

export const mutations = {}

export const actions = {
  async getApprovalByDocumentId({ commit }, documentId) {
    await Approval.api().get(`/approvals?document_id=${documentId}`)
  },
  async getApprovalByBlueprintId({ commit }, blueprintId) {
    await Approval.api().get(`/approvals?blueprint_id=${blueprintId}`)
  },
  async createApproval({ commit }, approvalData = {}) {
    await Approval.api().post('/approvals', approvalData)
  },
  async updateApproval({ commit }, { approvalId, approvalData }) {
    await Approval.api().patch(`/approvals/${approvalId}`, approvalData)
  },
  async addTask({ commit, getters }, { data }) {

    await Task.api().post('/tasks', data)

    getters.approval.refreshContent({updateTasks: true})
    return true
  },
  async updateApprovalUser({ commit }, { auaId, approvalId, data }) {
    await TaskCompanyRoleAssociation.api().patch(`/approval-company-role-associations/${auaId}`, data)
  },
  async addComment({ commit, getters }, { approvalId, commentData }) {
    await getters.approval.addComment(commentData)
  },

  async updateComment({ commit }, { commentId, commentData }) {
    await ApprovalComment.api().patch(`/approval-comments/${commentId}`, commentData)
  },

  async deleteComment({ commit }, { approvalId, commentId }) {
    await ApprovalComment.api().delete(`/api/approval-comments/${commentId}`)
  },

  async updateTask({ commit, getters }, { taskId, data }) {
    const result = await Task.api().patch(`/tasks/${taskId}`, data)
    if (getters.approval && result && result.response && result.response.data?.approval_id) {
      getters.approval.refreshContent({updateTasks: true})
    }
  },
}

export const getters = {
  approval: (state) => {
    const currDocumentId = store.getters['currentDocument/document']?.id
    return Approval
      .query()
      .where((approval)=>approval.document_id === currDocumentId)
      .withAllRecursive()
      .first()
  },
  approvalComments: (state, getters) =>{
    return ApprovalComment.allFast()
  },
  tasks: (state, getters) => {
    return Task
    .query()
    .with('task_company_role_associations')
    .with('task_company_role_associations.company_role.user')
    .with('task_company_role_associations.company_role.invite')
    .where((task) => task.approval_id === getters.approval.id)
    .get()
  },
  taskCompanyRoleAssociations: (state, getters) => {
    return TaskCompanyRoleAssociation.query().withAllRecursive().all()
  },
  taskCompanyRoles: (state, getters) => {
    return getters.taskCompanyRoleAssociations.map((task) => task.company_role)
  },
  taskUsers: (state, getters) => {
    return getters.taskCompanyRoles
      .filter(cr => cr.user !== null)
      .map(cr => cr.user);
  },
  ownerTask: (state, getters) => {
    return getters.tasks.find((task) => task.task_type === 'owner')
  },
  writerTasks: (state, getters) => {
    return getters.tasks.filter((task) => task.task_type === 'writer')
  },
  reviewerTasks: (state, getters) => {
    return getters.tasks.filter((task) => task.task_type === 'reviewer')
  },
  approverTasks: (state, getters) => {
    return getters.tasks.filter((task) => task.task_type === 'approver')
  },
  currentUserTasks: (state, getters) => {
    return getters.tasks.filter((task) => task.task_company_role_associations.some(
      (tcra) => tcra.company_role.id === store.getters['navbar/myCompanyRole']?.id)
      )
  },

}