<script>
export default {
  name: 'TooltipButton',
  props: {
    icon: { type: Boolean, required: false, default: false },
    tooltip: { type: String, required: false, default: '' },
    tooltipHtml: { type: String, required: false, default: null },
    color: { type: String, required: false, default: 'primary' },
    small: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    tag: { type: String, required: false, default: 'button' },
    forValue: { type: String, required: false, default: '' },
    outlined: { type: Boolean, required: false, default: false },
    href: { type: String, required: false, default: '' },
    tile: { type: Boolean, required: false, default: false },
    customClass: { type: String, required: false, default: 'icon-btn pa-2' },
    to: { type: Object, required: false, default: null },
    left: { type: Boolean, required: false, default: false },
  },
  emits: ['click'],
  methods: {
    calculateLocation() {
      if (!this.bottom && !this.left) {
        return 'top';
      }
      if (this.bottom && !this.left) {
        return 'bottom';
      }
      if (!this.bottom && this.left) {
        return 'left';
      }
      return 'right';
    }
  }
}
</script>

<template>
  <v-tooltip
    :location="calculateLocation()"
  >
    <template #activator="{ props }">
      <span
        v-bind="props"
      >
        <PlanitButton
          :color="color"
          :variant="!outlined ? 'text' : 'outlined'"
          :size=" small ? 'small' : ''"
          :class="icon ? customClass : ''"
          :disabled="disabled"
          :loading="loading"
          :tag="tag"
          :for="forValue"
          :href="href"
          :style="{ borderRadius: tile ? '0px' : '4px' }"
          :to="to"
          style="cursor: pointer;"
          @click="$emit('click')"
        >
          <slot />
        </PlanitButton>

      </span>
    </template>

    <span
      v-if="tooltipHtml"
      v-html="tooltipHtml"
    ></span>
    <span
      v-if="tooltip"
      style="white-space: pre-line"
      v-text="tooltip"
    ></span>
  </v-tooltip>
</template>
