<script>
import { orderBy } from 'lodash'

import PlanitUserSearch from '@components/planit-user-search'
import PlanitListItem from '@components/planit-list-item'
import PlanitModelSearch from '@components/planit-model-search'
import PlanitInput from '@components/form/input'
import PlanitFileUpload from '@components/file-upload'
import ConfirmModal from '@components/confirm-modal'

import RolloutOverview from '@components/rollouts/overview'
import RolloutGeneral from '@components/rollouts/general'
import RolloutRecipients from '@components/rollouts/recipients'
import RolloutFiles from '@components/rollouts/files'
import RolloutMail from '@components/rollouts/mail'
import RolloutMailOnly from '@components/rollouts/mail-only'

import { RolloutState, RolloutMailType } from '@enums'

import Rollout from '@state/models/rollout'

import '@utils/filters'

export const RolloutTabType = {
  OVERVIEW: 0,
  GENERAL: 1,
  RECIPIENTS: 2,
  FILES: 3,
  MAIL: 4,
  EMAIL_ONLY: 5,
}

export default {
  name: 'Rollout',
  components: {
    ConfirmModal,
    PlanitUserSearch,
    PlanitListItem,
    PlanitInput,
    PlanitFileUpload,
    PlanitModelSearch,
    RolloutGeneral,
    RolloutRecipients,
    RolloutFiles,
    RolloutMail,
    RolloutOverview,
  },
  props: {
    rolloutId: { type: Number, default: null },
    showDialog: { type: Boolean, default: false },
    initialTab: { type: Number, default: 0 },
    excludeTabs: { type: Array, default: () => [] },
    emailOnlyMode: { type: Boolean, default: false },
    rolloutTitle: { type: String, default: 'Rollout' },
    showAttachments: { type: Boolean, default: true },
    showPrivacyPolicySelect: { type: Boolean, default: false },
  },
  emits: ['showDialog', 'email-sent', 'update:showDialog'],
  data() {
    return {
      showSendMailDialog: false,
      emailSuccess: false,
      selectedTab: 0,
      sendConfirmation: false,
      updateEmailSuccess: false,
      forceEdit: false,
      loading: false,
      orderBy,
      RolloutState,
      RolloutMailType,
      RolloutTabType,

      selectedPrivacyPolicyCompanyLinkId: null,
    }
  },
  computed: {
    rollout() {
      return Rollout.query()
        .where((r) => r.id === this.rolloutId)
        .with('recipients')
        .with('file_associations.file')
        .with('reminder')
        .with('reminder.timers')
        .first()
    },
    recipients() {
      return this.rollout.recipients
    },
    showDialogComputed: {
      get() {
        return this.showDialog
      },
      set(value) {
        this.$emit('update:showDialog', value)
      },
    },
    stateIndex() {
      switch (this.rollout.state) {
        case RolloutState.DRAFT:
          return 1
        case RolloutState.SENT:
          return 2
        case RolloutState.COMPLETED:
          return 3
      }
      return null
    },
    emailOnlyTab() {
      return {
        id: 5,
        name: this.$t('rollouts.mail'),
        component: RolloutMailOnly,
        props: {
          rollout: this.rollout,
          withExtraOptions: this.showAttachments,
          showPrivacyPolicySelect: this.showPrivacyPolicySelect,
        },
      }
    },
    tabs() {
      if (this.emailOnlyMode) {
        return []
      }

      const tabs = [
        {
          id: 0,
          name: this.$t('rollouts.overview'),
          component: RolloutOverview,
          props: { rollout: this.rollout, stateIndex: this.stateIndex },
        },
        {
          id: 1,
          name: this.$t('rollouts.general'),
          component: RolloutGeneral,
          props: { rollout: this.rollout, stateIndex: this.stateIndex },
        },
        {
          id: 2,
          name: this.$t('rollouts.recipient'),
          component: RolloutRecipients,
          props: { rollout: this.rollout, stateIndex: this.stateIndex },
        },
        {
          id: 3,
          name: this.$t('rollouts.files'),
          component: RolloutFiles,
          props: { rollout: this.rollout, stateIndex: this.stateIndex },
        },
        {
          id: 4,
          name: this.$t('rollouts.mail'),
          component: RolloutMail,
          props: { rollout: this.rollout, stateIndex: this.stateIndex },
        },
      ]

      return tabs.filter((tab) => !this.excludeTabs.includes(tab.id))
    },
  },
  async beforeMount() {
    this.loading = true
    await this.fetchRolloutData()
    this.selectedTab = this.initialTab
    this.loading = false
  },
  async created() {
    this.interval = setInterval(this.fetchRolloutData, 8000)
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    complete() {
      this.rollout.sync(null, { state: RolloutState.COMPLETED })
    },
    async fetchRolloutData() {
      await Rollout.$find(this.rolloutId)
    },
    onCancelClicked() {
      this.showSendMailDialog = false
    },
    onConfirmClicked() {
      this.showSendMailDialog = false
      this.sendMail()
    },
    onSendMailClicked() {
      if (!this.rollout.with_confirmation && !this.rollout.document_id) {
        this.showSendMailDialog = true
        return
      }
      this.sendMail()
    },
    sendMail() {
      const button = document.querySelector('.hover-disabled')
      button.classList.add('disable-hover')

      if (!this.emailSuccess) {
        this.rollout.sendMail(
          RolloutMailType.INVITE,
          false,
          this.selectedPrivacyPolicyCompanyLinkId
        )
        this.$emit('email-sent')
      }
      this.emailSuccess = true

      setTimeout(() => {
        this.emailSuccess = false
      }, 4000)
    },
    sendUpdateMail() {
      const button = document.querySelector('.hover-disabled')
      button.classList.add('disable-hover')

      if (!this.updateEmailSuccess) {
        this.rollout.sendMail(RolloutMailType.UPDATE)
      }
      this.updateEmailSuccess = true

      setTimeout(() => {
        this.updateEmailSuccess = false
      }, 4000)
    },
    onPrivacyPolicySelected(companyLinkId) {
      this.selectedPrivacyPolicyCompanyLinkId = companyLinkId
    },
  },
}
</script>
<template>
  <div>
    <ConfirmModal
      v-if="showSendMailDialog"
      :text="$t('rollouts.email_sent_no_receipt')"
      @confirm="onConfirmClicked"
      @cancel="onCancelClicked"
    />
    <PlanitDialog
      v-model="showDialogComputed"
      max-width="90rem"
      content-class="bg-white dialog-height-80rem"
    >
      <div
        v-if="!loading"
        class="pa-6 d-flex flex-column fill-height"
      >
        <div class="flex-grow-1">
          <div class="d-flex align-center justify-space-between">
            <h1>{{ rolloutTitle }}</h1>
            <PlanitButton
              variant="text"
              class="icon-btn"
              @click="showDialogComputed = false"
            >
              <PlanitIcon icon="fal fa-times" />
            </PlanitButton>
          </div>
          <v-stepper
            v-if="!emailOnlyMode"
            model-value="stateIndex"
            flat
          >
            <v-stepper-header>
              <v-stepper-item
                value="1"
                color="primary"
                :complete="stateIndex > 1"
                class="font-weight-medium"
              >
                {{ $t('rollouts.draft') }}
              </v-stepper-item>
              <v-divider />
              <v-stepper-item
                value="2"
                color="primary"
                :complete="stateIndex >= 2"
                class="font-weight-medium"
              >
                {{ $t('rollouts.sent') }}
              </v-stepper-item>
              <v-divider />
              <v-stepper-item
                value="3"
                color="primary"
                :complete="stateIndex == 3"
                class="font-weight-medium"
              >
                {{ $t('rollouts.completed') }}
              </v-stepper-item>
            </v-stepper-header>
          </v-stepper>
          <div v-if="tabs.length">
            <v-tabs
              v-model="selectedTab"
              class="mb-4"
              color="primary"
              show-arrows
            >
              <v-tab
                v-for="(tab, index) in tabs"
                :key="index"
              >
                {{ tab.name }}
              </v-tab>
            </v-tabs>
            <v-window v-model="selectedTab">
              <v-window-item
                v-for="(tab, index) in tabs"
                :key="index"
                :value="tab.id"
              >
                <component
                  :is="tab.component"
                  v-bind="{ ...tab.props }"
                />
              </v-window-item>
            </v-window>
          </div>
          <div
            v-else-if="emailOnlyMode"
            class="mt-4"
          >
            <component
              :is="emailOnlyTab.component"
              v-bind="{ ...emailOnlyTab.props }"
              @privacy-policy-selected="onPrivacyPolicySelected"
            />
          </div>
        </div>
        <div class="d-flex align-center justify-end mt-4">
          <PlanitButton
            v-if="rollout.state === RolloutState.SENT"
            color="warning"
            size="small"
            class="mr-2"
            variant="outlined"
            @click="complete"
          >
            {{ $t('rollouts.complete_manually') }}
          </PlanitButton>
          <PlanitButton
            v-if="rollout.state === RolloutState.SENT"
            color="primary"
            size="small"
            class="mr-2"
            variant="outlined"
            @click="sendUpdateMail"
          >
            <PlanitIcon
              v-if="updateEmailSuccess"
              class="mr-1"
              icon="fal fa-check"
            />
            {{ updateEmailSuccess ? $t('rollouts.mail_success') : $t('rollouts.send_update_mail') }}
          </PlanitButton>
          <PlanitButton
            v-if="rollout.state !== RolloutState.COMPLETED"
            :disabled="
              (rollout.recipients.length === 0 || rollout.file_associations.length === 0) &&
                !emailOnlyMode
            "
            class="hover-disabled"
            :color="emailSuccess ? 'success' : 'primary'"
            @click="onSendMailClicked"
          >
            <PlanitIcon
              v-if="emailSuccess"
              class="mr-1"
              icon="fal fa-check"
            />
            {{
              emailSuccess
                ? $t('rollouts.mail_success')
                : rollout.state === RolloutState.SENT
                  ? $t('rollouts.resend_mail')
                  : $t('rollouts.send_mail')
            }}
          </PlanitButton>
        </div>
      </div>
    </PlanitDialog>
  </div>
</template>

<style>
.table-text {
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1rem;
}

.hover-disabled.disable-hover {
  pointer-events: none;
}

.dialog-height-80rem {
  height: 80rem;
}
</style>
