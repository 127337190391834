import PlanitModel from './planit-model'
import PrimaFolderTemplateAssociation from './prima-folder-template-association'
import Company from './company'


export default class PrimaFolderTemplate extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name_translations: this.attr(),
      name: this.attr(),
      icon: this.attr(),
      menu_category_uuid: this.attr(),
      menu_category_name: this.attr(),
      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      associations: this.hasMany(PrimaFolderTemplateAssociation, 'prima_folder_template_id'),
    }
  }
}

PrimaFolderTemplate.entity = 'prima-folder-templates'
