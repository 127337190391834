<script>
export default {
  name: 'PlanitProgress',
  components: {},
  props: {
    progress: { type: Number, required: true },
    size: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: null },
    iconColor: { type: String, required: false, default: null },
    subscript: { type: Boolean, required: false, default: false },
    includePercent: { type: Boolean, required: false, default: false },
    linear: { type: Boolean, required: false, default: false },
    borderRadiusTop: { type: Boolean, required: false, default: false },
    borderRadiusBottom: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: null },
    standalone: { type: Boolean, required: false, default: false },
    progressWidth: { type: Number, required: false, default: null },
  },
  computed: {
    colorComputed() {
      if (this.color) {
        return this.color
      }

      if (this.progress >= 100) {
        return 'success'
      } else if (this.progress < 100 && this.progress >= 50) {
        return 'warning'
      } else {
        return 'error'
      }
      /* let hue = (this.progress * (-0.3) / 100 * 360)

      hue += 102 // 100 is green

      return 'hsl(' + hue + ', 83%, 50%)' */
    },
    textColorClassComputed() {
      if (this.colorComputed === 'success') {
        return 'text-white'
      } else {
        return 'text-black'
      }
    },
    iconStyles() {
      const styles = {
        // 'margin-top': '-3px',
        'font-weight': '100',
        'font-size': '2rem',
      }

      if (this.size) {
        // styles['font-size'] = 'calc(' + this.size + 'px * 1)'
        styles['font-size'] = this.size + 'px'
      }

      return styles
    },
    progressWidthComputed() {
      /* if (this.subscript) {
        return this.size || 5
      } */

      return this.progressWidth || 4
    },
    progressSize() {
      if (this.subscript) {
        return parseInt(this.size || 32) * 0.5
      }

      return this.size || 32
    },
    progressClasses() {
      const classes = []

      if (this.subscript) {
        classes.push('svg-subscript')
      }

      return classes
    },
    containerStyles() {
      const styles = {
        width: (this.size || 32) + 'px',
        'text-align': 'center',
      }
      if (this.subscript) {
        // due to size=50% icon is shifted
        // styles['padding-left'] = '8px'
      }
      return styles
    },
  },
  created() {},
}
</script>

<template>
  <div>
    <div v-if="!linear" :style="containerStyles">
      <v-progress-circular
        :model-value="progress"
        rotate="90"
        :width="progressWidthComputed"
        :color="colorComputed"
        :size="progressSize"
        :class="progressClasses"
      >
        <span v-if="icon">
          <PlanitIcon
            :color="iconColor"
            :style="iconStyles"
            :icon="icon"
          ></PlanitIcon>
        </span>
        <span v-else-if="standalone">
        </span>
        <span v-else class="font-weight-medium">
          {{ progress }}<span v-if="includePercent">%</span>
        </span>
      </v-progress-circular>
    </div>
    <div v-else>
      <v-progress-linear
        :model-value="progress"
        :color="colorComputed"
        :height="progressSize"
        :class="textColorClassComputed"
        :style="
          [
            borderRadiusTop
              ? { 'border-top-left-radius': 'var(--prima-border-radius-small)', 'border-top-right-radius': 'var(--prima-border-radius-small)' }
              : {},
            borderRadiusBottom
              ? { 'border-bottom-left-radius': 'var(--prima-border-radius-small)', 'border-bottom-right-radius': 'var(--prima-border-radius-small)' }
              : {},
          ]
        "
      >
        <span v-if="includePercent" class="font-weight-medium">{{ progress }}%</span>
      </v-progress-linear>
    </div>
  </div>
</template>

<style>
.svg-subscript > svg {
  top: 100%;
  left: 50%;
  z-index: 1;
}

.svg-subscript .v-progress-circular__underlay {
  stroke: #ccc;
  fill: #fff;
  z-index: 1;
}

.svg-subscript .v-progress-circular__overlay {
}
</style>
