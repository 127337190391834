<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    falseIcon: {
      type: String,
      default: '',
    },
    trueIcon: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: this.modelValue,
    }
  },
}
</script>

<template>
  <v-switch
    v-bind="$attrs"
    v-model="internalValue"
    :label="label"
    :color="color"
    :disabled="disabled"
    :ripple="ripple"
    :inline="inline"
    :loading="loading"
    :false-icon="falseIcon"
    :true-icon="trueIcon"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :multiple="multiple"
  >
    <template
      v-for="(slot, name) in $slots"
      v-slot:[name]="slotProps"
    >
      <slot
        :name="name"
        v-bind="slotProps"
      ></slot>
    </template>
  </v-switch>
</template>
