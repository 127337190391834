import axios from 'axios'

import {
  sortBy,
} from 'lodash'

import {
  ChecklistStepStatus,
  ChecklistMode,
  ChecklistStepType,
} from '@enums'

import store from '@state/store'

import PlanitModel from './planit-model'
import ChecklistStep from './checklist-step'
import ChecklistUserActivation from './checklist-user-activation'
import Folder from './folder'

import replaceBrand from '@utils/brand-placeholder-filter'


export default class Checklist extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name_translations: this.attr(),
      name: this.attr(),

      description_translations: this.attr(),
      description: this.attr(),

      published: this.attr(),
      icon: this.attr(),
      checklist_mode: this.attr(),

      progress: this.attr(),
      needs_company_context: this.attr(),

      checklist_steps: this.hasMany(ChecklistStep, 'checklist_id'),

      // local fields

      active: this.attr(true),
    }
  }

  static uploadPrimaExport(formData, onUploadProgress) {
    return axios.request({
      method: 'post',
      url: '/api' + Checklist.$url() + '?import_prima_file=true',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
  }

  async initStepActive({ keepOpenChecklistStepId = null } = {}) {
    const setActiveStep = async (steps) => {
      const startedSteps = steps.filter(
        (cs) => !cs.status
          || cs.status === ChecklistStepStatus.STARTED
          || cs.status === ChecklistStepStatus.FINISHED
      )

      if (startedSteps.length) {
        const promises = []
        startedSteps.forEach((activeStep) => {
          const childChecklistSteps = activeStep.getSortedChildChecklistSteps()

          if (
            (!keepOpenChecklistStepId || activeStep.id !== keepOpenChecklistStepId)
            && childChecklistSteps.length > 0
            && childChecklistSteps.every(
              (cs) =>
                (
                  cs.type === ChecklistStepType.QUESTION
                  && [ChecklistStepStatus.FINISHED, ChecklistStepStatus.OPEN].includes(cs.status)
                )
                || (
                  cs.type === ChecklistStepType.HEADLINE
                  && cs.status === ChecklistStepStatus.FINISHED
                )
            )
          ) {
            promises.push(
              activeStep.updateAttr({ active: false, expanded: false })
            )

          } else {
            const newData = {
              expanded: true,
            }
            if (activeStep.type === ChecklistStepType.QUESTION && activeStep.status !== ChecklistStepStatus.FINISHED) {
              newData.active = true
            }

            promises.push(
              activeStep.updateAttr(newData)
            )

          }

          if (childChecklistSteps) {
            promises.push(
              setActiveStep(childChecklistSteps)
            )

          }
        })

        await Promise.allSettled(promises)

      }
    }

    await ChecklistStep.update({
      data: { active: false },
      where: (cs) => cs.checklist_id === this.id && cs.active,
    })

    const sortedToplevelSteps = this.getSortedToplevelSteps()
    if (sortedToplevelSteps.length) {
      await setActiveStep(sortedToplevelSteps)
    }
  }

  getSortedToplevelSteps() {
    return sortBy(
      ChecklistStep.query().where((cs) => !cs.parent_checklist_step_id && cs.checklist_id === this.id).all(),
      (cs) => cs.position,
    )
  }

  getIcon() {
    return this.icon || 'fal fa-clipboard-list-check'
  }

  async activate() {
    const currentUser = store.getters['auth/currentUser']
    const params = {}
    if (store.getters['navbar/selectedCompany']) {
      params.company_id = store.getters['navbar/selectedCompany'].id

    } else if (this.needs_company_context && store.getters['navbar/allCompanies']?.length) {
      const company = store.getters['navbar/allCompanies'][0]
      await store.dispatch(
        'navbar/setSelectedCompany',
        { selectedCompany: company },
      )

      params.company_id = company.id

    }

    await currentUser.sync(
      null,
      { activate_checklist_id: this.id },
      { params },
    )
    await Checklist.$find(this.id, { params })
  }

  async start({ restart = false } = {}) {
    const currentUser = store.getters['auth/currentUser']
    const params = {}
    if (store.getters['navbar/selectedCompany']) {
      params.company_id = store.getters['navbar/selectedCompany'].id

    } else if (this.needs_company_context && store.getters['navbar/allCompanies']?.length) {
      const company = store.getters['navbar/allCompanies'][0]
      await store.dispatch(
        'navbar/setSelectedCompany',
        { selectedCompany: company },
      )

      params.company_id = company.id

    }

    if (restart) {
      params.restart_active_checklist = true
    }

    await currentUser.sync(
      null,
      { active_checklist_id: this.id },
      { params },
    )
    await Checklist.$find(this.id, { params })
    await this.loadFolders()
    await this.initStepActive()
    if (this.checklist_mode === ChecklistMode.LIST) {
      store.dispatch('navbar/setShowChecklistDrawer', true)
    }

    // make sure waitForTargetDomLoaded doesnt wait when route+component already loaded
    store.commit('checklist/SET_TARGET_DOM_LOADED', true)

  }

  async restart() {
    await this.start({ restart: true })
  }

  async pause() {
    const currentUser = store.getters['auth/currentUser']

    const checklistUserActivation = ChecklistUserActivation
      .query()
      .where(cua => cua.user_id === currentUser.id && cua.checklist_id === this.id)
      .first()

    if (checklistUserActivation) {
      await checklistUserActivation.delete()
    }

    await currentUser.sync(null, {
      active_checklist_id: null,
    })
    await store.dispatch('navbar/setShowChecklistDrawer', false)

    await ChecklistStep.delete(cs => cs.checklist_id === this.id)

  }

  async loadFolders() {
    //check if any step has a To Folder... if so, load folders
    const steps = ChecklistStep.allFast().filter((cs) => cs.checklist_id === this.id)
    const hasFolderStep = steps.some((step) => {
      return (
        step.data?.to?.folder
        || Object.values(step.text_translations || {}).some((text) => text.indexOf('(menuFolderLink)') !== -1)
      )
    })
    const foldersLoaded = store.getters['checklist/foldersLoaded']

    if (hasFolderStep && !foldersLoaded) {
      const folderRequestArgs = {
        blueprint_menu_structure: true,
        company_id: store.getters['navbar/selectedCompany'].id,
      }
      const promises = [
        Folder.$all(folderRequestArgs),
      ]
      const results = await Promise.all(promises)
      if (results) {
        store.dispatch('checklist/setFoldersLoaded', { foldersLoaded: true })
      }
    }

  }

  getName(locale) {
    return replaceBrand(this.name_translations[locale])
  }

  getDescription(locale) {
    return replaceBrand(this.description_translations[locale])
  }

}

Checklist.entity = 'checklists'
