<script>

import AddressEditModal from '@components/address-edit-modal'
import TooltipButton from '@components/tooltip-button'

import Address from '@state/models/address'


export default {
  name: 'AnswerAddressEdit',
  props: {
    answer: {
      type: Object,
      required: true,
    },
    documentVersion: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['address-created'],
  components: {
    AddressEditModal,
    TooltipButton,
  },
  data() {
    return {
      editAddressId: null,
    }
  },
  computed: {
    editAddress() {
      return Address.find(this.editAddressId)
    },
  },
  created() {
  },
  methods: {
    async showEditAddressModal() {
      let addressId = this.answer.address_id
      if (!addressId) {
        const result = await this.answer.createSubResource(Address, {})
        await this.answer.patchViaDocumentVersion({
          data: {
            address_id: result.response.data.id,
          },
          documentVersion: this.documentVersion,
        })

        addressId = result.response.data.id

        this.$emit('address-created')

      }

      this.editAddressId = addressId

    },
  }
}
</script>

<template>
  <div>
    <AddressEditModal
      v-if="editAddress"
      :model-value="editAddress"
      @close="() => editAddressId = null"
    ></AddressEditModal>

    <TooltipButton
      v-if="tooltip"
      :tooltip="$t('company.vendors.edit_address')"
      :disabled="disabled"
      icon
      @click="showEditAddressModal"
    >
      <PlanitIcon icon="fal fa-address-card"></PlanitIcon>

    </TooltipButton>

    <PlanitButton
      v-else
      color="primary"
      variant="text"
      @click="showEditAddressModal"
    >
      <PlanitIcon class="mr-1" icon="fal fa-address-card"></PlanitIcon>

      {{ $t('company.vendors.edit_address') }}
    </PlanitButton>

  </div>
</template>
