import PlanitModel from './planit-model'
import TableSetting from './table-setting'
import TableColumn from './table-column'

export default class TableColumnSettingAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      table_setting_id: this.attr(),
      table_setting: this.belongsTo(TableSetting, 'table_setting_id'),

      table_column_id: this.attr(),
      table_column: this.belongsTo(TableColumn, 'table_column_id'),

      position: this.attr(),
    }
  }

  static async createTableColumnSettingAssociation(table_setting_id, table_column_id, position) {
    const params = {
      table_setting_id: table_setting_id,
      table_column_id: table_column_id,
      position: position
    }
    return TableColumnSettingAssociation.api().post('/table-column-setting-associations', params)
  }

  async updateTableColumnSettingAssociation(table_column_id, position) {
    const params = {
      table_column_id: table_column_id,
      position: position
    }
    return TableColumnSettingAssociation.api().patch(`/table-column-setting-associations/${this.id}`, params)
  }

}

TableColumnSettingAssociation.entity = 'table-column-setting-associations'