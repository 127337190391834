<script>
import {
  delay,
  throttle,
} from 'lodash'

import NavBar from '@components/nav-bar'
import PlanitFooter from '@components/planit-footer'
import PlanitAlert from '@components/planit-alert'
import Checkout from '@components/checkout'
import ChangelogModal from '@components/changelog-modal'
import ReviewMain from '@components/review-system/review-main.vue'

import {
  ChangelogType,
} from '@enums'

import store from '@state/store'
import { debugGetters } from '@state/helpers'
import BrandSetting from '@state/models/brand-setting'
import Changelog from '@state/models/changelog'

import emitter from '@utils/global-events'


export default {
  components: {
    NavBar,
    PlanitFooter,
    PlanitAlert,
    ChangelogModal,
    Checkout,
    ReviewMain,
  },
  props: {
    admin: { type: Boolean, required: true },
  },
  data: () => ({
    alertMessage: null,
    alertType: null,
    alertDismissible: true,
    alertDark: false,
    showAlert: false,
    maintenance: false,

  }),
  computed: {
    ...debugGetters,
    loggedIn() {
      return store.getters['auth/loggedIn']
    },
    currentUser() {
      return store.getters['auth/currentUser']
    },
    mainStyles() {
      // const navbarHeight = ([1, 2, 3].indexOf(this.debugTheme) !== -1) ? '128px' : '64px'
      const navbarHeight = store.getters['navbar/navbarHeight']
      const footerHeight = store.getters['navbar/footerHeight']

      // this needs to be relative/top+bottom instead of margins otherwise toast-ui setHeight
      // triggers weird scroll jumps
      // update: it still triggers weird scroll jumps...
      // after 27.01. update it should be ok bc of proper page scrolling
      const styles = {}

      if (!this.centerContent) {
        styles['margin-top'] = navbarHeight
        styles['margin-bottom'] = footerHeight
      }

      // do add margin-bottom to show footer properly on mobile
      if (this.centerContent && this.$vuetify.display.mobile) {
        styles['margin-bottom'] = footerHeight
      }

      if (this.loggedIn && this.currentUser.verified) {
        styles['padding-left'] = (
          store.getters['navbar/showNavigationDrawer'] &&
          !store.getters['navbar/hasSmallScreen']
        ) ?
          store.getters['navbar/navDrawerWidth'] :
          '0px'

        styles['padding-left'] = 'calc(' + styles['padding-left'] + ' + ' + store.getters['navbar/rightAppPanelWidth'] + 'px)'

        styles['padding-right'] = (
          store.getters['navbar/showRightDrawer'] &&
          !store.getters['navbar/hasVerySmallScreen']
        ) ?
          store.getters['navbar/rightDrawerWidth'] :
          '0px'

        styles['padding-right'] = 'calc(' + styles['padding-right'] + ' + ' + store.getters['navbar/rightAppPanelWidth'] + 'px)'
      }

      /* styles['max-height'] = 'calc(100vh - ' + navbarHeight + ' - ' + footerHeight + ')'
      styles['overflow-y'] = 'auto' */

      return styles
    },
    backgroundLighterColor() {
      return store.getters['global/backgroundLighterColor']
    },
    backgroundLightColor() {
      return store.getters['global/backgroundLightColor']
    },
    backgroundStyles() {
      const styles = {}

      if (this.$route.name === 'home' && this.brandSetting && this.brandSetting.color_login_background) {
        styles['background'] = this.brandSetting.color_login_background

      } else if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.showLoginBackground)) {
        styles['background-color'] = this.backgroundLightColor

      } else {
        styles['background-color'] = this.backgroundLighterColor
      }

      return styles

    },
    containerStyles() {
      const styles = {}

      if (this.noGutters) {
        styles.padding = '0'
      } else {
        styles['padding-top'] = '0'
      }

      const globalStyles = store.getters['global/containerStyles']

      return {
        ...globalStyles,
        ...styles,
      }
    },
    appClasses() {
      if (store.getters['global/showPartnerKBrand']) {
        return 'partner-k-brand-login-background'
      }

      if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.showDie3Background)) {
        return 'die3-site-background'

      }

      if (this.$route.matched.toReversed().some((matchedRoute) => matchedRoute.meta?.backgroundClass)) {
        return this.$route.matched.toReversed().find((matchedRoute) => matchedRoute.meta?.backgroundClass).meta.backgroundClass

      }

      return ''
    },
    noGutters() {
      return (
        this.$route.matched.some(
          (matchedRoute) => matchedRoute.meta?.disableLayoutGutters
        ) || false
      )
    },
    centerContent() {
      return this.$route.matched.some(
        (matchedRoute) => matchedRoute.meta?.enableCenterContent
      ) || false
    },
    hideEmptyHeaderOnMobile() {
      return this.$route.matched.some(
        (matchedRoute) => matchedRoute.meta?.hideEmptyHeaderOnMobile
      ) || false
    },
    alertColor() {
      if (this.alertType === 'error') return 'error'
      else if (this.alertType === 'warning') return 'warning'
      else if (this.alertType === 'success') return 'success'
      else return 'info'
    },
    navbarLoading() {
      return store.getters['navbar/navbarLoading']
    },
    contentLoading() {
      return store.getters['navbar/contentLoading']
    },
    showUnauthorizedError() {
      return store.getters['global/showUnauthorizedError']
    },
    showNewsletterConfirmed() {
      return location.search.indexOf('showNewsletterConfirmed') !== -1
    },
    showNavigationDrawer() {
      return store.getters['navbar/showNavigationDrawer']
    },
    showRightDrawer() {
      return store.getters['navbar/showChecklistDrawer'] || store.getters['navbar/showApprovalDrawer']
    },
    footerColor() {
      if (this.$route.matched.some((matchedRoute) => matchedRoute.meta?.transparentBars)) {
        return 'transparent'
      }
    },
    firstContainerClasses() {
      const classes = []

      if (this.centerContent) {
        classes.push('d-flex')
        classes.push('justify-space-around')

        classes.push(
          this.$vuetify.display.mobile ? 'align-start' : 'align-center'
        )
      }

      return classes

    },
    globalAlertStyles() {
      const styles = {}

      const navbarPxHeight = store.getters['navbar/navbarPxHeight']

      styles.position = 'fixed'
      styles.top = (navbarPxHeight + 8) + 'px'
      styles.right = '8px'
      styles['z-index'] = '300'

      return styles

    },
    brandSetting() {
      return BrandSetting.query().first()
    },
    user() {
      return store.getters['auth/user']
    },
    impersonator() {
      return store.getters['auth/impersonator']
    },
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    cachedUserLastSeenChangelogId() {
      return store.getters['global/cachedUserLastSeenChangelogId']
    },
    latestChangelog() {
      if (!this.user?.show_changelog_notification) {
        return null
      }

      const latestChangelog = Changelog.query().where(
        c => c.changelog_type === ChangelogType.CHANGELOG
      ).orderBy('created', 'desc').first()

      if (
        !!this.brandSetting ||
        !latestChangelog ||
        !latestChangelog.published ||
        !this.user.verified ||
        (
          !!this.cachedUserLastSeenChangelogId &&
          this.cachedUserLastSeenChangelogId >= latestChangelog.id
        ) ||
        this.$route.params?.showWelcomeModal ||
        !this.user.has_dismissed_welcome_screen ||
        this.impersonator
      ) {
        return null
      }

      return latestChangelog
    },
    showCheckoutModal: {
      get() {
        return store.getters['global/showCheckoutModal']
      },
      set(value) {
        store.commit('global/SET_SHOW_CHECKOUT_MODAL', value)
      },
    }
  },
  watch: {
    async debugTheme(newValue, oldValue) {
      // await this.loadTheme()
      window.localStorage.setItem('debugTheme', newValue)
      if ([1, 2, 3].indexOf(oldValue) !== -1 && [1, 2, 3].indexOf(newValue) === -1) {
        window.location.reload(true)
      }
    },
    showNavigationDrawer(newValue, oldValue) {
      this.setVuetifyBreakpoints()
    },
    showRightDrawer(newValue, oldValue) {
      this.setVuetifyBreakpoints()
    },
  },
  async beforeMount() {
    if (window.maintenance === true) {
      this.maintenance = true
      return
    }

    store.commit('navbar/SET_CONTENT_LOADING', true)

    emitter.on('showGlobalAlert', ({ type, message, duration = 3000, dismissible = false } = {}) => {
      this.alertType = type || 'info'
      this.alertMessage = message

      this.alertDark = false
      if (this.alertType === 'info' || this.alertType === 'success') {
        this.alertDark = true
      }

      if (dismissible !== undefined) {
        this.alertDismissible = dismissible
      } else {
        this.alertDismissible = !duration
      }

      this.showAlert = true

      if (duration) {
        delay(() => {
          this.showAlert = false
        }, duration)
      }
    })


  },
  mounted() {
    // currently done in footer-content
    /* this.$nextTick(() => {
      store.dispatch('navbar/setSmallScreenVariables')
    }) */

    setTimeout(() => {
      this.setVuetifyBreakpoints()
    }, 500)

    document.addEventListener(
      'scroll',
      throttle(
        () => {
          if (
            store.getters['syncQueue/queueLength'] > 0 &&
            !store.getters['syncQueue/isPaused']
          ) {
            store.dispatch('syncQueue/handleQueueNow')
          }
        },
        500,
        { leading: true, trailing: false }
      )
    )
  },
  async created() {
    const changelogLatestOnlyParams = {
      order_by: 'created',
      order: 'desc',
      limit: 1,
      published: true,
      changelog_type: ChangelogType.CHANGELOG,
    }

    if (window.primary_instance) {
      await Changelog.$all(changelogLatestOnlyParams)
    } else {
      await Changelog.api().get(
        window.primary_instance_url + '/api' + Changelog.$url(),
        changelogLatestOnlyParams,
      )
    }

    // wat, TODO: fix
    if (this.user?.last_seen_changelog_id) {
      store.commit('global/SET_CACHED_USER_LAST_SEEN_CHANGELOG_ID', this.user.last_seen_changelog_id)
    } else {
      store.commit('global/SET_CACHED_USER_LAST_SEEN_CHANGELOG_ID', -1)
    }

    if (this.$route.query?.showUnsubscribed) {
      emitter.emit('showGlobalAlert', {
        type: 'success',
        message: this.$t('generic.marketing_unsubscribed'),
        duration: 10000,
      })
      this.$router.replace({ query: { ...this.$route.query, showUnsubscribed: undefined } })
    }

  },
  methods: {
    setShowUnauthorizedError(value) {
      if (value === false) {
        store.dispatch('global/setShowUnauthorizedError', value)
      }
    },
    setVuetifyBreakpoints() {
      const thresholds = {
        xs: 768,
        sm: 960,
        md: 1424,
        lg: 1904,
      }

      // subtract drawerWidth from breakpoints for every drawer open
      if (store.getters['navbar/showNavigationDrawer']) {
        const navDrawerWidth = parseInt(
          document.getElementById('navDrawer')?.getBoundingClientRect().width
        )
        if (navDrawerWidth) {
          Object.keys(thresholds).forEach(k => thresholds[k] += navDrawerWidth)
        }
      }

      if (store.getters['navbar/showChecklistDrawer']) {
        const rightDrawerWidth = parseInt(
          document.getElementById('rightDrawer')?.getBoundingClientRect().width
        )
        if (rightDrawerWidth) {
          Object.keys(thresholds).forEach(k => thresholds[k] += rightDrawerWidth)
        }
      }

      this.$vuetify.display.thresholds = thresholds
    },
  },
}
</script>

<template>
  <v-app
    :class="appClasses"
    :style="backgroundStyles"
  >
    <div
      v-if="navbarLoading"
      class="pa-4"
    >
      <v-progress-linear
        indeterminate
        color="primary"
      ></v-progress-linear>
    </div>
    <div
      v-show="!navbarLoading"
      class="flex-grow-1 fill-height"
      :class="firstContainerClasses"
    >
      <div
        v-if="!maintenance"
        style="width: 100%;"
      >
        <NavBar
          :admin="admin"
          :style="
            hideEmptyHeaderOnMobile && $vuetify.display.mobile ?
            { display: 'none' } :
            {}
          "
        ></NavBar>

        <main
          :style="mainStyles"
          :class="!centerContent ? 'pb-6' : ''"
          class="d-flex align-stretch fill-height"
          id="prima-main"
        >
          <div
            :style="containerStyles"
            class="mx-auto"
          ><!-- fill-height -->
            <v-alert
              v-model="showAlert"
              :style="globalAlertStyles"
              :color="alertColor"
              class="mb-4 pa-4 elevation-2"
              :class="alertDark ? 'text-white' : ''"
              density="compact"
              :theme="alertDark ? 'dark' : 'light'"
              :closable="alertDismissible"
            >
              <!-- <PlanitIcon icon="mdi-alert-circle-outline"></PlanitIcon>
 todo: make icon dynamic-->
              {{ alertMessage }}
            </v-alert>

            <div v-if="showUnauthorizedError" class="d-flex justify-end">
              <PlanitAlert
                color="error"
                icon="fal fa-exclamation-triangle"
                class="ma-4"
                style="max-width: 40rem;"
                dismissible
                @input="setShowUnauthorizedError"
              >
                <span>{{ $t('generic.unauthorized') }}</span>
              </PlanitAlert>
            </div>

            <PlanitAlert
              v-if="showNewsletterConfirmed"
              color="success"
              icon="fal fa-check"
              class="ma-4"
              dismissible
            >
              <span>{{ $t('generic.newsletter_confirmed') }}</span>
            </PlanitAlert>

            <ChangelogModal
              v-if="latestChangelog"
              :changelog="latestChangelog"
              :currentLocale="currentLocale"
              :user="user"
            ></ChangelogModal>

            <!-- :overlay-opacity=".7" -->
            <PlanitDialog
              v-if="showCheckoutModal"
              v-model="showCheckoutModal"
              max-width="100rem"
              content-class="bg-white"
              :fullscreen="$vuetify.display.mobile"
            >
              <div v-if="showCheckoutModal">
                <Checkout @close="showCheckoutModal = false"></Checkout>
              </div>
            </PlanitDialog>

            <div
              v-if="!navbarLoading && !contentLoading"
              class="fill-height"
            >
            <!--<v-row
              v-if="!navbarLoading && !contentLoading"
              :no-gutters="noGutters"
              class="fill-height"
            >
              <v-col class="fill-height">-->
                <slot />
              <!--</v-col>
            </v-row>-->
            </div>
            <div
              v-if="!navbarLoading && contentLoading"
              class="pa-4"
            >
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </div>

          <!--<PlanitFooter v-if="!contentLoading && $vuetify.display.mobile"></PlanitFooter>-->
          </div>
          <!--TODO: Make either draggable or figure out how and where to position usefully-->
        </main>

        <PlanitFooter v-if="!contentLoading && !navbarLoading"></PlanitFooter>

      </div>

      <div v-if="maintenance" :style="backgroundStyles">
        <v-container
          class="mx-auto"
          style="max-width: 80rem"
        >
          <v-card
            class="ma-6 pa-6"
            text
            elevation="1"
          >
            <div class="text-center">
              <h1 class="mb-4">
                <PlanitIcon
                  size="x-large"
                  class="mx-4"
                  color="primary"
                 icon="fa-hard-hat
                "/>
                <PlanitIcon
                  size="x-large"
                  class="mx-4"
                  color="success"
                 icon="fa-tools
                "/>
              </h1>
              <h3>
                <img
                  src="@/public/logo_small.svg"
                  class="mr-2"
                  style="width: auto; height: 1em"
                />
                <span v-html="$t('maintenance.text')"></span>
              </h3>
            </div>
          </v-card>
        </v-container>
      </div>
    </div>

  </v-app>
</template>
