<script>
export default {
  name: 'ScrollTo',
  inserted: function (element, binding) {
    if (!binding.value) {
      return
    }

    element.scrollIntoView({
      behavior: 'smooth',
    })
  },
}
</script>
