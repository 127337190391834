import store from '@state/store'

import PlanitModel from './planit-model'

import Folder from '@state/models/folder'
import PermissionRole from '@state/models/permission-role'


export default class MenuFolderSetting extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      hidden: this.attr(),
      menu_position: this.attr(),

      folder_id: this.attr(),
      folder: this.belongsTo(Folder, 'folder_id'),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),

      parent_menu_folder_setting_id: this.attr(),
      parent_menu_folder_setting: this.belongsTo(MenuFolderSetting, 'parent_menu_folder_setting_id'),

    }
  }

}

MenuFolderSetting.entity = 'menu-folder-settings'
