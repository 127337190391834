<script>

export default {
  props: {
    modelValue: {
      type: [Object, Array, String, Number],
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    itemTitle: {
      type: String,
      default: 'text'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'outlined'
    },
    density: {
      type: String,
      default: 'compact'
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    chips: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
    bgColor: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    closableChips: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      internalValue: this.modelValue, // Internal value to ensure reactivity
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.internalValue = Array.isArray(newValue) ? [...newValue] : newValue
      },
      deep: true,
    },
  },
  methods: {
    updateModelValue(value) {
      this.internalValue = Array.isArray(value) ? [...value] : value
      this.$emit('update:model-value', this.internalValue)
    }
  }
}
</script>

<template>
  <v-autocomplete
    v-bind="$attrs"
    :key="modelValue ? modelValue.length : 0"
    :model-value="internalValue"
    @update:model-value="updateModelValue"
    :items="items"
    :item-title="itemTitle"
    :item-value="itemValue"
    :return-object="returnObject"
    :variant="variant"
    :density="density"
    :hide-details="hideDetails"
    :color="color"
    :chips="chips"
    :multiple="multiple"
    :disabled="disabled"
    :required="required"
    :placeholder="placeholder"
    :label="label"
    :error-messages="errorMessages"
    :bg-color="bgColor"
    :clearable="clearable"
    :closable-chips="closableChips"
    :menu-props="menuProps"
  >
    <template v-for="(slot, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps"></slot>
    </template>
  </v-autocomplete>
</template>
