/*
return c_srgb of any hex color to calculate whether to use dark mode
*/

export default function hexToCSrgb(hexColor) {
  const r = parseInt(hexColor.slice(1, 3), 16) / 255.0
  const g = parseInt(hexColor.slice(3, 5), 16) / 255.0
  const b = parseInt(hexColor.slice(5, 7), 16) / 255.0

  const cLinear = 0.2126 * r + 0.7152 * g + 0.0722 * b

  let cSrgb
  if (cLinear <= 0.0031308) {
    cSrgb = cLinear * 12.92
  } else {
    cSrgb = 1.055 * Math.pow(cLinear, 1 / 2.4) - 0.055
  }
  return cSrgb
}
