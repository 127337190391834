<script>
export default {
  page: {
    title: 'Page timeout',
    meta: [{ name: 'description', content: 'The page timed out while loading.' }],
  },
  components: {},
  created() {
    const loc = window.location

    if (!loc.search || loc.search.indexOf('refresh=true') === -1) {
      let search
      if (loc.search.length) {
        search = loc.search + '&refresh=true'
      } else {
        search = '?refresh=true'
      }

      const newHref = loc.origin + loc.pathname + search

      window.location.href = newHref
    }
  },
}
</script>

<template>
  <div>
    <h1
      :class="$style.title"
      class="font-weight-regular"
    >
      <PlanitIcon
        size="large"
        color="error"
        >fas fa-bug</PlanitIcon
      ><br />
      <span v-html="$t('timeout.text')"></span>
    </h1>
  </div>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
