import PlanitModel from './planit-model'
import Company from './company'

export default class Tag extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      color: this.attr(),
      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

    }
  }

  static createTag(data) {
    return Tag.api().post('tags', { name: data.name, color: data.color, company_id: data.company_id })
  }
  updateTag(data) {
    return Tag.api().patch(`tags/${this.id}`, { color: data.color })
  }
}

Tag.entity = 'tags'