import PlanitModel from './planit-model'
import User from './user'
import PermissionRole from './permission-role'

export default class PermissionRoleUserAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      can_edit: this.attr(),

      user_id: this.attr(),
      user: this.belongsTo(User, 'user_id'),

      permission_role_id: this.attr(),
      permission_role: this.belongsTo(PermissionRole, 'permission_role_id'),
    }
  }
}

PermissionRoleUserAssociation.entity = 'permission-role-user-associations'
