import PlanitModel from './planit-model'
import Address from './address'
import Company from './company'
import FolderVendorAssociation from '@state/models/folder-vendor-association'

import store from '@state/store'

export default class Vendor extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      image: this.attr(),
      website: this.attr(),

      address_id: this.attr(),
      address: this.belongsTo(Address, 'address_id'),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

      folder_vendor_associations: this.hasMany(FolderVendorAssociation, 'vendor_id'),
    }
  }

  getImportData() {
    const name = this.name
    const address = this.address

    let retString = ''
    if (name) {
      retString += name
    }

    if (address) {
      retString += '<br>' + address.getMultilineText()
    }

    return retString
  }
}

Vendor.entity = 'vendors'
