<script>
export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: {},
}
</script>

<template>
  <div>test</div>
  <Transition appear>
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </Transition>
</template>

<style lang="scss" module>
// @import '@design';
</style>
