import format from 'date-fns/format'

export const todayMidnight = () => new Date(new Date().setHours(0, 0, 0, 0))
export const todayMidnightISO = () => new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
export const tomorrowMidnight = () => new Date(new Date().setHours(24, 0, 0, 0))
export const tomorrowMidnightISO = () => new Date(new Date().setHours(24, 0, 0, 0)).toISOString()

export function getTimezoneOffsetString(date) {
  date = date || new Date()
  const tzOffsetMinutes = date.getTimezoneOffset()
  if (tzOffsetMinutes !== 0) {
    let leadingZero = ''
    if (Math.abs(tzOffsetMinutes / 60) < 10) {
      leadingZero = '0'
    }
    const negative = tzOffsetMinutes < 0 ? '-' : '+'
    return negative + leadingZero + Math.abs(tzOffsetMinutes / 60).toString() + ':00'
  } else {
    return 'Z'
  }
}

export function dateToISOString(date, { withTime = true } = {}) {
  let ret = format(date, 'yyyy-MM-dd')
  if (withTime) {
    ret += 'T'
      + format(date, 'HH:mm')
      + getTimezoneOffsetString()
  }
  return ret
}

export function dailyDateListFromRange(start, end) {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const dateList = []
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    dateList.push(new Date(d))
  }
  return dateList
}
