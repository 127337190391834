<script>
import axios from 'axios'
import {
  parseISO,
} from 'date-fns'

import PlanitUserSearch from '@components/planit-user-search'
import PlanitAlert from '@components/planit-alert'
import DateTimePicker from '@components/form/date-time-picker.vue'

import { RolloutState } from '@enums'

import Recipient from '@state/models/recipient'
import Rollout from '@state/models/rollout'

import {
  tomorrowMidnightISO,
  dateToISOString,
} from '@utils/date-helpers'


export default {
  name: 'RolloutRecipients',
  components: {
    PlanitUserSearch,
    DateTimePicker,
    PlanitAlert,
  },
  props: {
    rollout: { type: Object, required: true },
    stateIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      bulkInviteUploadFile: null,
      bulkInviteUploading: false,
      bulkInviteUploadError: null,
      documentSearch: '',
      RolloutState,
      dateFilter: tomorrowMidnightISO(),
    }
  },
  computed: {
    company() {
      return this.$store.getters['navbar/selectedCompany']
    },
    excludeUserIds() {
      return this.recipients.filter((r) => r.user_id).map((r) => r.user_id)
    },
    expirationDate: {
      get() {
        return (this.rollout.link_expiration_date && parseISO(this.rollout.link_expiration_date)) || null
      },
      async set(val) {
        await this.rollout.updateAttr({ link_expiration_date: dateToISOString(val, { withTime: false }) })
        await this.rollout.sync('link_expiration_date')
      },
    },
    recipients() {
      return this.rollout.recipients
    },
  },
  methods: {
    addEmailToList(email) {
      if (!this.recipients.some((r) => r.email === email)) {
        Recipient.$new({ email: email, user_id: null, rollout_id: this.rollout.id })
      }
    },
    addUserToList(user) {
      Recipient.$new({ email: user.email, user_id: user.id, rollout_id: this.rollout.id })
    },
    removeRecipient(recipient) {
      this.recipients = this.recipients.filter((r) => r !== recipient)
      if (recipient.id) {
        this.excludeUserIds = this.excludeUserIds.filter((id) => id !== recipient.id)
      }
    },
    removeRecipient(recipient) {
      recipient.delete()
    },
    handleFileUploadBulk() {
      this.bulkInviteUploading = true
      this.bulkInviteUploadError = null
      this.bulkInviteUploadFile = this.$refs.bulkInvite.files[0]
      this.uploadBulkInviteUploadFile()
    },
    async uploadBulkInviteUploadFile() {
      const formData = new FormData()

      formData.append('file', this.bulkInviteUploadFile, this.bulkInviteUploadFile.name)
      formData.append('rollout_id', this.rollout.id)

      axios
        .request({
          url: `/api/recipients/bulk-invite`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          this.bulkInviteUploadFile = null
          this.$refs.bulkInvite.value = null
          this.bulkInviteUploading = false
          this.bulkInviteUploadError =
            error.response?.data?.message || this.$t('generic.default_error')
        })
        .then(async (response) => {
          if (response.status === 200) {
            this.bulkInviteUploading = false
            this.bulkInviteUploadFile = null
            this.$refs.bulkInvite.value = null
            this.bulkInviteUploadError = null
          }
        })
      await Rollout.$find(this.rollout.id)
    },
  },
}
</script>

<template>
  <div>
    <v-row class="mb-4 pt-2">
      <v-col cols="6">
        <p>{{ $t('rollouts.expiration_info') }}</p>
        <DateTimePicker
          :disabled="rollout.state === RolloutState.COMPLETED"
          type="date"
          v-model:value="expirationDate"
          :label="$t('rollouts.expiration_date')"
          :min="dateFilter"
          class="my-2"
        ></DateTimePicker>
      </v-col>
      <v-col cols="6">
        <p>{{ $t('company.bulk_invite_help_text') }}</p>
        <PlanitButton
          color="primary"
          :disabled="rollout.state === RolloutState.COMPLETED"
          variant="outlined"
          tag="label"
          for="bulkInvite"
        >
          <PlanitIcon class="mr-2" icon="fal fa-file-upload"></PlanitIcon>

          <span v-if="!bulkInviteUploadFile">{{ $t('documents.additional.select_file') }}</span>
          <span v-else>{{ bulkInviteUploadFile.name }}</span>
        </PlanitButton>
        <input
          id="bulkInvite"
          ref="bulkInvite"
          type="file"
          class="d-none"
          @change="handleFileUploadBulk"
        />
        <v-progress-circular
          v-if="bulkInviteUploading"
          indeterminate
          color="primary"
          class="mr-4"
        ></v-progress-circular>
        <PlanitAlert
          v-if="bulkInviteUploadError"
          color="error"
          class="my-4"
          no-max-width
        >
          <span>{{ bulkInviteUploadError }}</span>
        </PlanitAlert>
      </v-col>
    </v-row>
    <v-row class="pt-0">
      <v-col cols="6">
        <div
          v-if="stateIndex > 1"
          class="mb-2"
        >
          {{ $t('rollouts.recipient_edit_info') }}
        </div>
        <PlanitUserSearch
          :placeholder="$t('rollouts.add_recipient')"
          :disabled="rollout.state === RolloutState.COMPLETED"
          :company="company"
          @set-user="addUserToList"
          @set-string="addEmailToList"
          :exclude-user-ids="excludeUserIds"
          class="mb-2"
        >
        </PlanitUserSearch>
        <div
          style="max-height: 16rem; overflow-y: auto"
          class="mb-4"
        >
          <div
            v-for="(recipient, i) in recipients"
            :key="i"
            class="d-flex align-center mb-2 ml-2"
          >
            <PlanitIcon class="mr-2" icon="fal fa-user"></PlanitIcon>

            <div class="table-text flex-grow-1">
              <div>{{ recipient.email }}</div>
              <div v-if="rollout.with_confirmation">
                <div
                  v-if="recipient.has_confirmed"
                  class="mr-2 text-green"
                >
                  {{ $t('rollouts.confirmed_at') }} {{ $filters.formatDate(recipient.confirmation_date) }}
                </div>
                <div
                  v-else
                  class="mr-2 text-red"
                >
                  {{ $t('rollouts.not_confirmed') }}
                </div>
              </div>
            </div>
            <PlanitButton
              color="default"
              class="icon-btn"
              :disabled="rollout.state === RolloutState.COMPLETED"
              size="small"
              text
              @click="removeRecipient(recipient)"
            >
              <PlanitIcon size="small" icon=" fal fa-trash-alt "></PlanitIcon>

            </PlanitButton>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
