<script>
import emlFormat from 'eml-format'
import MSGReader from 'msgreader'

import { ChecklistStepStatus, DocumentType } from '@enums'

import RepoDocumentTable from '@components/repo-document-table'
import DocumentUploadModal from '@components/modal/document-upload-modal'
import PlanitAlert from '@components/planit-alert'

import Answer from '@state/models/answer'
import store from '@state/store'

import emitter from '@utils/global-events'
import { Buffer } from 'buffer'
global.Buffer = Buffer

export default {
  name: 'FillRepoDataContent',
  components: {
    RepoDocumentTable,
    DocumentUploadModal,
    PlanitAlert,
  },
  props: {
    status: Object,
    document: Object,
  },
  emits: ['set-status'],
  watch: {
    currentProgress(v, ov) {
      this.checkProgress(v, ov)
    },
  },
  data() {
    return {
      showEmailDragOverlay: false,
      showFileDragOverlay: false,
      file: null,
      error: {
        text: '',
        color: 'text-error',
      },
      uploading: false,
    }
  },
  beforeMount() {
    this.checkProgress(this.currentProgress, this.currentProgress)
  },
  computed: {
    currentFolder() {
      return this.$store.getters['files/currentFolder']
    },
    company() {
      return this.$store.getters['files/company']
    },
    uploadFiles: {
      get() {
        return this.$store.getters['files/uploadFiles']
      },
      set(value) {
        this.$store.dispatch('files/setUploadFiles', value)
      },
    },
    currentProgress() {
      if (this.document && this.document.repository_edit_document_version) {
        return this.document.repository_edit_document_version.current_progress
      }
    },
    showGuideChecklistDrawer() {
      return this.$store.getters['navbar/showGuideChecklistDrawer']
    },
  },
  methods: {
    cancelEmailDragover(evt) {
      this.showEmailDragOverlay = false
    },
    cancelFileDragover(evt) {
      this.showFileDragOverlay = false
    },
    checkProgress(v, ov) {
      if ((!ov || ov < 100) && v == 100) {
        this.$emit('set-status', {
          checklistStepStatus: ChecklistStepStatus.FINISHED,
          skipEmitTaskFinish: true,
        })
      } else if (ov == 100 && v < 100) {
        this.$emit('set-status', {
          checklistStepStatus: ChecklistStepStatus.STARTED,
          skipEmitTaskFinish: true,
        })
      }
    },
    checkValidFile(file) {
      if (!file) {
        return false
      }
      const fileName = file.name.toLowerCase()
      return fileName.endsWith('.eml') || fileName.endsWith('.msg')
    },
    closeUploadModal() {
      this.uploadFiles = []
      this.uploading = false
    },
    extractNames(fullName) {
      if (!fullName || this.isEmail(fullName)) {
        return { firstname: '', lastname: '' }
      }
      const [firstname = '', lastname = ''] = fullName.trim().split(/\s+/)
      return { firstname, lastname }
    },
    isEmail(name) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(name)
    },
    onFileChange(evt) {
      const mail = evt.target.files[0]
      this.upload(mail)
    },
    onEmailDragover(evt) {
      this.showEmailDragOverlay = true
    },
    onEmailDrop(evt) {
      const mail = evt.dataTransfer.files[0]
      this.upload(mail)
    },
    onFileDragover(evt) {
      this.showFileDragOverlay = true
    },
    onFileDrop(evt) {
      this.$store.dispatch('files/handleFileChange', {
        files: evt.dataTransfer.files,
        blueprint: null,
      })
      this.showFileDragOverlay = false
    },
    parseEml(emlContent) {
      let content = null
      emlFormat.read(emlContent, (error, data) => {
        if (error) {
          this.error.text = this.$t('components.checklist.document_unfillable')
          this.error.color = 'text-warning'
          return
        }
        const { firstname, lastname } = this.extractNames(data.from.name)
        const date = new Date(data.date)
        const formattedDate = date.toISOString().split('T')[0]

        content = {
          email: data.from.email,
          first_name: firstname,
          last_name: lastname,
          date: formattedDate,
        }
      })
      return content
    },
    parseMsg(msgContent) {
      const msgReader = new MSGReader(msgContent)
      const data = msgReader.getFileData()

      const headers = data.headers
      const dateMatch = headers.match(/Date: (.+)/)
      const receivedDate = dateMatch ? new Date(dateMatch[1]) : ''
      const date = new Date(receivedDate)

      const formattedDate = date.toISOString().split('T')[0]
      const { firstname, lastname } = this.extractNames(data.senderName)

      return {
        email: data.senderEmail,
        first_name: firstname,
        last_name: lastname,
        date: formattedDate,
      }
    },
    async fillRepoData(emailContent) {
      const repoDocEditVersion = this.document.repository_edit_document_version

      for (const attrId of this.document.attribute_ids) {
        await Answer.$find(attrId).then(async (result) => {
          const answer = result.response.data
          var data = {}
          const viids = this.status.checklist_step.data['section_viids']
          for (const content in emailContent) {
            if (content == viids[answer.section_viid]) {
              if (content == 'date')
                data = {
                  data: {
                    date: emailContent[content],
                  },
                }
              else {
                data = {
                  text_translations: {
                    de: emailContent[content],
                    en: emailContent[content],
                  },
                }
              }
            }
          }

          if (Object.keys(data).length === 0) {
            console.log('Data is empty, skipping...')
            return
          }
          const queueItem = {
            model: answer,
            groupSaveModel: repoDocEditVersion,
            method: 'patch',
            data: {
              viid: answer.viid,
              ...data,
            },
            params: {
              include_answer_repository_reference_items: true,
            },
          }
          store.dispatch('syncQueue/queue', queueItem)
        })
      }
      await store.dispatch('syncQueue/handleQueueNow').then(() => {
        this.currentFolder.refreshContent(this.company, false, ['Document'], [], {
          document_type: DocumentType.REPOSITORY_ITEM,
        })
        this.uploading = false
        this.file = null
      })
    },
    preprocessEmlContent(emlContent) {
      //emlFormat kann die gereinigte eml besser parsen (entfernt zeilenumbrüche die von whitespaces gefolgt werden)
      return emlContent.replace(/([^\r\n])\r?\n(?!\r?\n)\s+(\S)/g, '$1 $2')
    },
    upload(mail) {
      if (!this.checkValidFile(mail)) {
        this.error.text = this.$t('components.checklist.unsupported_file_type')
        this.error.color = 'text-error'
        return
      }
      this.error.text = ''
      this.file = mail

      this.uploading = true
      this.$store.dispatch('files/handleFileChange', {
        files: [this.file],
        blueprint: null,
      })
      this.showEmailDragOverlay = false
    },
    async uploadDone() {
      if (this.file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const fileName = this.file.name.toLowerCase()
          let content = null

          if (fileName.endsWith('.eml')) {
            const cleanedEmailContent = this.preprocessEmlContent(e.target.result)
            content = this.parseEml(cleanedEmailContent)
            console.debug(content)
          } else if (fileName.endsWith('.msg')) {
            content = this.parseMsg(e.target.result)
          }

          if (content) this.fillRepoData(content)
        }
        const fileName = this.file.name.toLowerCase()

        if (fileName.endsWith('.eml')) {
          reader.readAsText(this.file)
        }
        if (fileName.endsWith('.msg')) {
          reader.readAsArrayBuffer(this.file)
        }
      }
      this.uploadFiles = []
    },
    triggerFileUpload() {
      emitter.emit('triggerFileUpload')
    },
  },
}
</script>
<template>
  <div>
    <DocumentUploadModal
      v-if="uploadFiles && uploadFiles.length > 0"
      :uploadFiles="uploadFiles"
      @uploadDone="uploadDone"
      @close="closeUploadModal"
    >
    </DocumentUploadModal>
    <div class="mt-4">
      <div
        class="text-primary border-left border-primary border-radius-sm"
        style="border-width: 6px !important"
      >
        <div class="pa-4 w-100">
          <v-row>
            <v-col :cols="showGuideChecklistDrawer ? '12' : '6'">
              <div
                class="d-flex align-center file-drop-area"
                @dragover.prevent="onEmailDragover"
                @drop.prevent="onEmailDrop"
              >
                <v-fade-transition>
                  <v-overlay
                    v-if="showEmailDragOverlay"
                    absolute
                    @mouseleave.prevent="cancelEmailDragover"
                    @dragleave.prevent="cancelEmailDragover"
                  >
                    <div class="d-flex align-center">
                      <PlanitIcon
                        size="x-large"
                        class="mr-1"
                        icon="fas fa-file-upload
                      "
                      />
                      <label>{{ $t('components.checklist.drag_drop_here') }}</label>
                    </div>
                  </v-overlay>
                </v-fade-transition>
                <div class="mr-4">
                  <PlanitIcon
                    size="x-large"
                    color="primary"
                    icon="fas fa-fw fa-envelope-open-text"
                  ></PlanitIcon>
                </div>
                <div>
                  <div class="mb-2 font-weight-medium">
                    {{ $t('components.fill_repo_data_content.email_import') }}
                  </div>

                  <div v-if="error.text">
                    <label
                      class="my-2"
                      :class="error.color"
                    >
                      {{ error.text }}
                    </label>
                  </div>

                  <PlanitButton
                    color="primary"
                    tag="label"
                    for="upload-mail"
                    variant="text"
                    class="cursor-pointer"
                    :loading="uploading"
                  >
                    <PlanitIcon
                      size="small"
                      class="mr-1"
                      icon="fal fa-file-upload"
                    ></PlanitIcon>

                    {{ $t('generic.upload') }}
                  </PlanitButton>
                  <input
                    id="upload-mail"
                    ref="file"
                    type="file"
                    class="uploadinput"
                    accept=".eml,.msg"
                    @change="onFileChange"
                  />
                </div>
              </div>
            </v-col>
            <v-col :cols="showGuideChecklistDrawer ? '12' : '6'">
              <div
                class="d-flex align-center file-drop-area"
                @dragover.prevent="onFileDragover"
                @drop.prevent="onFileDrop"
              >
                <v-fade-transition>
                  <v-overlay
                    v-if="showFileDragOverlay"
                    absolute
                    @mouseleave.prevent="cancelFileDragover"
                    @dragleave.prevent="cancelFileDragover"
                  >
                    <div class="d-flex align-center">
                      <PlanitIcon
                        size="x-large"
                        class="mr-1"
                        icon="fas fa-file-upload
                      "
                      />
                      <label>{{ $t('documents.overview.drag_release') }}</label>
                    </div>
                  </v-overlay>
                </v-fade-transition>
                <div class="mr-4">
                  <PlanitIcon
                    size="x-large"
                    color="primary"
                    icon="fas fa-fw fa-paperclip"
                  ></PlanitIcon>
                </div>
                <div>
                  <div class="mb-2 font-weight-medium">
                    {{ $t('components.fill_repo_data_content.upload_attachment') }}
                  </div>
                  <PlanitButton
                    color="primary"
                    variant="text"
                    @click="triggerFileUpload"
                  >
                    <PlanitIcon
                      size="small"
                      class="mr-1"
                      icon="fal fa-plus"
                    ></PlanitIcon>

                    {{ $t('components.fill_repo_data_content.add_attachment') }}
                  </PlanitButton>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <RepoDocumentTable
      :repo-document-id="document.id"
      class="mt-4"
    ></RepoDocumentTable>
  </div>
</template>

<style scoped>
.file-drop-area {
  position: relative;
}
</style>
