<script>

import {
  sortBy,
} from 'lodash'

import {
  ChecklistStepStatus,
  ChecklistStepType,
  ChecklistMode,
} from '@enums'

import store from '@state/store'

import {
  debugGetters,
} from '@state/helpers'
import ChecklistStep from '@state/models/checklist-step'
import PlanitInput from '@components/form/input'
import Folder from '@state/models/folder'


export default {
  name: 'ChecklistStep',
  props: {
    step: { type: Object, required: true },
    checklist: { type: Object, required: true },
  },
  components: {
    ChecklistStep: 'ChecklistStep',
    PlanitInput,
  },
  data() {
    return {
      ChecklistStepStatus,
      ChecklistMode,
      ChecklistStepType,

      loading: true,

      checkBoxItems: [
        { text: this.$t('admin.guides.open'), value: ChecklistStepStatus.OPEN },
        { text: this.$t('admin.guides.finished'), value: ChecklistStepStatus.FINISHED },
      ],
      renderedDescription: null,
    }
  },
  async created() {
    if (!this.step.expanded && !this.hasChildren) {
      await this.step.updateAttr({ expanded: true })
    }

    const routerLinkTo = this.step.getRouterLinkTo()
    if (this.step.active && routerLinkTo?.name === this.$route.name) {
      await this.step.checkLinkStep({
        includeCompanyContext: this.checklist.needs_company_context
      })
    }

    if(this.currentLocale && this.step.description_translations[this.currentLocale]) {
      this.renderedDescription = await this.renderDescription(
        this.step.getDescription(this.currentLocale)
      )
    }

    this.loading = false

    //this.generateListFromAvailableTemplates('f81ef3cb-23b8-4487-b07f-15c1ca5b94bf')
  },
  computed: {
    ...debugGetters,
    currentLocale() {
      return store.getters['auth/currentLocale']
    },
    company() {
      return store.getters['navbar/selectedCompany']
    },
    routerLinkTo() {
      if(!this.company) return {}
      return this.step.getRouterLinkTo()
    },
    hasChildren() {
      return !!this.step.getChildChecklistStepsFast().length
    },
    allChildrenFinished() {
      return this.step.allChildrenFinished()
    },
    statusIcon() {
      if (this.step.status === ChecklistStepStatus.STARTED) {
        return 'fal fa-fw fa-play-circle'

      } else if (
        (this.hasChildren && this.allChildrenFinished)
        || this.step.status === ChecklistStepStatus.FINISHED
      ) {
        return 'fal fa-fw fa-check'

      } else if (this.step.status === ChecklistStepStatus.OPEN) {
        return 'fal fa-fw fa-exclamation-circle'

      } else {
        // look for better icon or keep none
        // return 'fal fa-fw fa-circle'
        return ''

      }
    },
    sortedChildChecklistSteps() {
      return this.step.getSortedChildChecklistSteps()
    },
  },
  methods: {
    checkLinkStep(step) {
      step.checkLinkStep({
        includeCompanyContext: this.checklist.needs_company_context,
      })
    },
    getDescriptionPreview(description) {
      const maxLength = 100
      if (description.length > maxLength) {
        return description.substring(0, maxLength) + '...'
      } else {
        return description
      }
    },
    async handleCheckboxChange(value) {
      this.step.status = value
      await this.step.updateStatus({ includeCompanyContext: this.checklist.needs_company_context })
      this.checklist.initStepActive({ keepOpenChecklistStepId: this.step.parent_checklist_step_id })
    },
    handleExpandClick() {
      if (this.hasChildren) {
        this.step.toggleExpanded()
      }
    },
    async renderDescription(description) {
      const pattern = /\{\{ \((\w+)\) (.*?)(?:,\s*(.*?))?\s?\}\}/g // {{ (methodname) text, args(optional) }}
      let parts = []
      let lastIndex = 0
      let match

      while ((match = pattern.exec(description))) {
        const index = match.index
        const [placeholder, command, text, arg1] = match

        if (index > lastIndex) {
          const textSegment = description.substring(lastIndex, index)
          parts.push({ text: textSegment, clickable: false })
        }

        if (command === 'createTemplateListFromFolder') {
          const templateList = await this.generateListFromAvailableTemplates(text)
          const renderedTemplates = await Promise.all(
            templateList.map(async templateObj => {
              const renderedDesc = await this.renderDescription(templateObj.text)
              return renderedDesc.map(part =>
                ({ ...part, uuid: templateObj.uuid })
              )
            })
          )

          parts.push(...renderedTemplates.flat())

        } else if (['createDocument', 'menuFolderLink'].includes(command)) {
          const part = { text, command, clickable: true }

          if (command === 'menuFolderLink') {
            const menuFolder = this.step.getMenuFolderByCategoryUUID(arg1)
            if (menuFolder) {
              part.to = {
                name: 'company.folders',
                params: {
                  folderId: menuFolder.id,
                  companyId: this.company.id,
                },
              }

            } else {
              console.warn(`No menu folder found for category uuid '${arg1}'`)

            }
          }

          parts.push(part)

        } else if (command === 'sectionLink') {
          const part = { text, command, clickable: true }

          if (arg1 === 'dpd') {
            part.to = {
              name: 'documents.dashboard',
              params: { companyId: this.company.id },
            }

          } else if (arg1 === 'training') {
            part.to = {
              name: 'company.training',
              params: { companyId: this.company.id },
            }

          } else if (arg1 === 'info-center') {
            part.to = {
              name: 'infoCenter',
              params: { companyId: this.company.id },
            }

          }

          parts.push(part)

        }

        lastIndex = pattern.lastIndex

      }

      if (lastIndex < description.length) {
        const textSegment = description.substring(lastIndex)
        parts.push({ text: textSegment, clickable: false })

      }

      return parts

    },
    executeCommand(command, text) {
      if (command === 'createDocument') {
        this.createDocument(text)

      } else if (command === 'menuFolderLink') {
        // pass

      } else {
        console.warn(`Command '${command}' is not a function`)

      }
    },
    renderTemplateList(templateList) {
      if(!templateList) return []

      return templateList.map(templateString => this.renderDescription(templateString))
    },
    async generateListFromAvailableTemplates(menu_category_uuid) {
      const templateStrings  = []
      const toplevelFolder  = Folder
        .query()
        .where('menu_category_uuid', menu_category_uuid)
        .first()
      const templates = await toplevelFolder.loadAllAvailableTemplates(this.company.id)
      const sortedTemplates = sortBy(templates, d => d.name_translations[this.currentLocale])

      for (const template of sortedTemplates) {
        templateStrings.push( {
          uuid: template.uuid,
          text: `{{ (createDocument) ${template.name_translations[this.currentLocale]} }} <br>`,
        } )
      }

      return templateStrings

    },
    //wird von executeCommand aufgerufen
    async createDocument(templateUuid) {
      this.$store.dispatch('checklist/setOpenCreateDocumentsInFolderModal', {
        openAddFolderModal: true,
        templateUuid: templateUuid,
      })
    },
  },
}
</script>

<template>
  <div v-if="!loading">
    <div v-if="checklist.checklist_mode === ChecklistMode.LIST">
      <div
        :class="step.active ? 'bg-white card-box-shadow border-radius' : ''"
      >
        <div
          class="d-flex align-center py-1 px-1"
          :class="{ 'clickable list-item': hasChildren }"
          @click="handleExpandClick"
        >
          <router-link
            :to="routerLinkTo || {}"
            class="pr-2"
          >
            <div
              v-if="step.type != ChecklistStepType.QUESTION && step.type != ChecklistStepType.HEADLINE"
              class="d-flex align-baseline"
              @click="checkLinkStep(step)"
            >
              <PlanitIcon
                :color="
                  step.status === ChecklistStepStatus.FINISHED ?
                    'success' :
                    step.active ?
                    'primary' :
                    step.status === ChecklistStepStatus.STARTED ?
                    'grey-darken-4' :
                    'grey-lighten-1'
                "
                class="mr-1"
                size="small"
                :icon="step.getIcon()"
              >
              </PlanitIcon>
              <h4
                class="text-grey-darken-4"
                v-html="step.getName(currentLocale)"
              ></h4>
            </div>
            <div
              v-else
              class="d-flex align-baseline"
            >
              <PlanitIcon
                :color="
                  step.status === ChecklistStepStatus.FINISHED ?
                    'success' :
                    step.active ?
                    'primary' :
                    step.status === ChecklistStepStatus.STARTED ?
                    'grey-darken-4' :
                    'grey-lighten-1'
                "
                class="mr-1"
                size="small"
                :icon="statusIcon"
              ></PlanitIcon>
              <h4
                class="html-container"
                :class="
                  step.status === ChecklistStepStatus.FINISHED
                    ? 'text-grey-darken-4'
                    : 'text-grey-darken-4'
                "
                v-html="step.getName(currentLocale)"
              ></h4>
            </div>
          </router-link>
          <span v-if="debug">
            {{ step.status }}|{{ step.position }}|{{ step.type }}
          </span>
          <div
            v-if="step.type != ChecklistStepType.QUESTION && step.type != ChecklistStepType.HEADLINE"
            class="flex-grow-1 mx-2"
            style="border-bottom: 1px dotted grey"
          ></div>
          <PlanitButton
            v-if="hasChildren || step.description_translations[currentLocale]"
            size="small"
            variant="text"
            class="icon-btn px-1 py-0 ml-auto"
            @click.stop="step.toggleExpanded()"
          >
            <PlanitIcon
              size="small"
              :icon="step.expanded ? 'fal fa-chevron-up' : 'fal fa-chevron-down'"
            ></PlanitIcon>
          </PlanitButton>
        </div>
        <div v-if="step.expanded && step.description_translations[currentLocale]" class="pb-2">
          <div
            class="py-1 px-6"
          >
            <span v-for="(segment, index) in renderedDescription" :key="index">
              <router-link
                v-if="segment.clickable"
                :to="segment.to || routerLinkTo || {}"
                class="clickable"
                @click="executeCommand(segment.command, segment.uuid || segment.text)"
              >
                {{ segment.text }}
              </router-link>
              <span
                v-else
                v-html="segment.text"
                class="html-container"
              ></span>
            </span>
          </div>
        </div>
        <div
          v-if="step.expanded && step.type === ChecklistStepType.QUESTION"
          class="d-flex align-center justify-end pl-2 pb-2"
        >
          <v-radio-group
            :model-value="step.status"
            inline
            hide-details
            @update:model-value="handleCheckboxChange"
          >
            <v-radio
              v-for="item in checkBoxItems"
              :key="item.value"
              :label="item.text"
              :value="item.value"
              class="mr-3"
            ></v-radio>
          </v-radio-group>
        </div>

      </div>
      <div
        v-if="step.expanded"
        :style="hasChildren ? { 'border-left': '1px solid #00000012', 'margin-left': '11px' }: {}"
      >
        <div class="pl-3">
          <div
            v-for="childStep in sortedChildChecklistSteps"
            :key="childStep.id"
            class="py-1"
          >
            <ChecklistStep
              :step="childStep"
              :checklist="checklist"
            ></ChecklistStep>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checklist.checklist_mode === ChecklistMode.TASKS">
      <router-link
        :to="routerLinkTo || {}"
      >
        <div
          class="card-button pl-4 pa-2 text-grey-darken-4"
          @click="checkLinkStep(step)"
        >
          <h3
            class="d-flex align-baseline"
          >
            <PlanitIcon
              v-if="step.getIcon()"
              :color="
                step.status === ChecklistStepStatus.FINISHED ?
                  'success' :
                  step.active ?
                  'primary' :
                  step.status === ChecklistStepStatus.STARTED ?
                  'grey-darken-4' :
                  'grey-lighten-1'
              "
              class="mr-1"
              size="small"
              :icon="step.getIcon()"
            >
            </PlanitIcon>
            <span class="html-container" v-html="step.getName(currentLocale)"></span>
          </h3>
          <div
            v-if="step.description_translations[currentLocale]"
          >
            <div
              class="mt-1 html-container"
              v-html="step.getDescription(currentLocale)"
            ></div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.clickable {
  display: inline;
  text-decoration: none;
  color: rgb(var(--v-theme-primary));
  font-weight: 600;
  cursor: pointer;
}
.list-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
