<script>

import { en, de } from 'suneditor/src/lang'

import store from '@state/store'

import cursorManager from '@utils/cursor-manager'


export default {
  name: 'VueSunEditor',
  props: {
    value: { type: String, required: true },
    lang: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    focusOnCreation: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: null },
    extendedButtons: { type: Boolean, required: false, default: false },
    toolbarBottom: { type: Boolean, required: false, default: false },
    stickyToolbar: { type: Number, required: false, default: 0 },
  },
  emits: ['update:value', 'blur', 'focus'],
  data() {
    return {
      editor: null,
      internalValue: null,
      emptyValueWatchTriggered: false,
      singlePRegex: /^<p>((?:(?!<\/?p>).)*)<\/p>$/s,
    }
  },
  watch: {
    disabled(v, ov) {
      if (v) {
        this.editor.enable()
      } else {
        this.editor.enable()
      }
    },
    value(v, ov) {
      if (!v || v.length === 0) {
        this.emptyValueWatchTriggered = true
      }

      if (v != this.internalValue) {
        this.internalValue = v
        this.editor.setContents(v || '')

        this.checkTabEnable()
      }
    },
    placeholder(v, ov) {
      this.setPlaceholder()
    },
  },
  mounted() {
    const locale = this.lang || store.getters['auth/currentLocale']
    const lang = locale === 'de' ? de : en

    const sunInitEditor = store.getters['sunInitEditor/sunInitEditor']
    const config = { lang }
    if (this.placeholder) {
      config.placeholder = '&nbsp;'
    }

    config.stickyToolbar = this.stickyToolbar

    if (this.extendedButtons) {
      config.buttonList = [
        ['formatBlock'],
        [
          'bold',
          'italic',
          'underline',
        ],
        [
          'align',
          'list',
          'link',
          //'image',
        ],
      ]
    }

    this.editor = sunInitEditor.create(this.$refs.editor, config)

    if (this.value) {
      this.internalValue = this.value
      this.editor.setContents(this.value)
    }

    if (this.disabled) {
      this.editor.readOnly = true
      this.editor.disable()
    }

    if (this.placeholder) {
      this.$nextTick(() => {
        this.setPlaceholder()

      })
    }

    if (this.focusOnCreation) {
      this.$nextTick(() => {
        const el = this.$refs.editor.parentElement.querySelector('div[contenteditable="true"]')
        el.focus()
        cursorManager.setEndOfContenteditable(el)
      })
    }

    this.checkTabEnable()

    this.editor.showInline = (toolbar, context, core) => {
      if (this.toolbarBottom) {
        core._inlineToolbarAttr.top = toolbar.style.top = '36px';
      }
    }

    // this.editor.onImageUpload = (targetElement, index, state, info, remainingFilesCount, core) => {
    //   console.log(`targetElement:${targetElement}, index:${index}, state('create', 'update', 'delete'):${state}`)
    //   console.log(`info:${info}, remainingFilesCount:${remainingFilesCount}`)
    // }

    // this.editor.imageUploadHandler = (xmlHttp, info, core) => {
    //   console.log('upload handler', xmlHttp, info, core)
    //   // Editor code
    //   const response = JSON.parse(xmlHttp.responseText);
    //   if (response.errorMessage) {
    //       this.plugins.image.error.call(this, response.errorMessage, response);
    //   } else {
    //       this.plugins.image.register.call(this, info, response);
    //   }
    // }

    this.editor.onChange = (contents, core) => {
      if (this.emptyValueWatchTriggered) {
        this.emptyValueWatchTriggered = false
        return
      }

      // replace single p if <p> at start, </p> at and and no other p inside
      contents = contents.replace(this.singlePRegex, '$1')

      if (contents !== this.internalValue) {
        this.internalValue = contents
        this.$emit('update:value', contents)
      }
    }

    this.editor.onBlur = (e, core) => {
      this.$emit('blur')
    }

    this.editor.onFocus = (e, core) => {
      this.$emit('focus')
    }

    // otherwise this will interfere when drag-and-dropping e.g. sections
    // accidentally into their own editor inputs
    this.editor.onDrop = (e, cleanData, maxCharCount, core) => {
      if (store.getters['global/dragging']) {
        return false

      } else {
        return true

      }
    }
    if(!this.disabled){
      this.$nextTick(() => {
        const parentEl = this.$refs.editor.parentElement
        const wrapper = parentEl.querySelector('.se-wrapper')
        const button = document.createElement('button')

        button.style.position = 'absolute'
        button.style.right = '0px'
        button.style.bottom = '0px'
        button.style.width = '40px'
        button.style.height = '37px'
        button.style.color = 'rgba(0, 0, 0, 0.87)'
        button.style['border-radius'] = 'var(--prima-border-radius-small)'

        button.setAttribute('tabindex', '-1')

        const iconEl = document.createElement('i')
        iconEl.classList.add('far', 'fa-fw', 'pa-w', 'fa-text-size')
        button.appendChild(iconEl)

        button.classList.add('toggle-toolbar-button')

        button.addEventListener('click', () => {
          const editor = parentEl.querySelector('.sun-editor')

          editor.classList.toggle('show-toolbar')
          button.classList.toggle('active')

          // button.style.top = editor.classList.contains('show-toolbar') ? '-40px' : '0px'
        })

        wrapper.appendChild(button)

      })
    }

    this.editor

  },
  unmounted() {
    this.editor.destroy()
  },
  methods: {
    checkTabEnable() {
      if (this.internalValue && this.editor.core.options.tabDisable && (
        this.internalValue.indexOf('\n') !== -1 ||
        this.internalValue.indexOf('<br>') !== -1
      )) {
        this.editor.setOptions({ tabDisable: false })
      }
    },
    setPlaceholder() {
      let placeholderEl = this.$refs.editor.parentElement.querySelector('.se-placeholder')
      placeholderEl.innerHTML = this.placeholder
      setTimeout(() => {
        placeholderEl = this.$refs.editor.parentElement.querySelector('.se-placeholder')
        const placeholderHeight = placeholderEl.getBoundingClientRect().height

        this.editor.setOptions({ minHeight: placeholderHeight + 'px' })
        placeholderEl = this.$refs.editor.parentElement.querySelector('.se-placeholder')
        placeholderEl.innerHTML = this.placeholder

      }, 0)
    }
  }
}
</script>

<template>
  <div ref="editor"></div>
</template>

<style lang="scss">

@import '~suneditor/dist/css/suneditor.min.css';

.toggle-toolbar-button {
  padding: 4px;

  &.active {
    // background-color: rgba(0, 0, 0, 0.1);
    color: rgb(var(--v-theme-primary));
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.sun-editor {
  font-family: var(--prima-default-font) !important;

  padding: 0px;
  border-radius: var(--prima-border-radius-small);
  border: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */

  color: rgba(0, 0,  0, 0.87);

  background-color: unset !important;

  &.show-toolbar .sun-editor-editable.suneditor-planit {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-width: 0px 1px 1px 1px;
  }

  &.show-toolbar .se-toolbar {
    display: block !important;
  }
}

.sun-editor-editable.suneditor-planit {
  font-family: var(--prima-default-font) !important;

  padding: 8px;
  //background-color: #f0f0f0;
  /* background-color: #a2a7b33d; */
  border: 1px solid rgba(0, 0, 0, 0.15);

  background-color: transparent !important;

  /* &:hover {
    border-color: rgba(0, 0, 0, 0.3);

  } */

  border-radius: var(--prima-border-radius-small);
}

.sun-editor .se-toolbar-inline {
  box-shadow: none !important;
}

.sun-editor.show-toolbar .se-toolbar.se-toolbar-shadow {
  display: none !important;
}

.sun-editor .se-placeholder {
  padding: 8px !important;

  text-overflow: unset !important;
  white-space: unset !important;
}

.sun-editor .se-toolbar {
  // border: none !important;
  background-color: transparent !important;
  outline: none !important;
  display: none !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-width: 1px 1px 0px 1px !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
}

.sun-editor .se-btn-tray {
  border: none !important;
  background-color: white !important;
  border-radius: var(--prima-border-radius-small) var(--prima-border-radius-small) 0 0;
  // background-color: rgb(var(--v-theme-primary)) !important;
  // box-shadow: 1px 1px 15px -5px rgba(0, 0, 0, 0.5), 0px 0px 6px -2px rgba(0, 0, 0, 0.5);
  outline: none !important;
}

.sun-editor .se-btn-module-border {
  border: none !important;
}

.sun-editor .se-btn {
  // background: linear-gradient(178deg, #fafafa, #f0f0f0) !important;
  // background: #f0f0f0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: var(--prima-border-radius-small) !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.sun-editor-editable a.on {
  color: var(--v-anchor-base);
  background-color: unset;
}

</style>
