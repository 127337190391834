import PlanitModel from './planit-model'

export default class ScormFileCategory extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      name_translations: this.attr(),
      icon: this.attr(),
      position: this.attr(),

      // local attrs
      editMode: this.attr(false),
    }
  }

  static getSortAttrs() {
    return (sfc) => {
      return [
        sfc => sfc.position,
        sfc => sfc.name.toLowerCase(),
      ]
    }
  }

}

ScormFileCategory.entity = 'scorm-file-categories'
