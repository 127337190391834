import PlanitModel from './planit-model'
import CompanyRole from './company-role'
import TrainingSession from './training-session'


export default class TrainingSessionCompanyRoleAssociation extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      training_session_id: this.attr(),
      training_session: this.belongsTo(TrainingSession, 'training_session_id'),

      company_role_id: this.attr(),
      company_role: this.belongsTo(CompanyRole, 'company_role_id'),
    }
  }
}

TrainingSessionCompanyRoleAssociation.entity = 'training-session-company-role-associations'
