import ScormFile from '@state/models/scorm-file'


export const state = {
  availableScormFileIds: [],
  scormFilesForMarketPlace: [],
  scormFilesSettings: [],
  scormFilesStatus: [],
}

export const mutations = {
  SET_AVAILABLE_SCORM_FILE_IDS(state, scormFileIds) {
    state.availableScormFileIds = scormFileIds
  },
  SET_SCORM_FILES_FOR_MARKET_PLACE(state, scormFiles) {
    state.scormFilesForMarketPlace = scormFiles
  },
  SET_SCORM_FILES_SETTINGS(state, scormFiles) {
    state.scormFilesSettings = scormFiles
  },
  SET_SCORM_FILES_STATUS(state, scormFiles) {
    state.scormFilesStatus = scormFiles
  },
}

export const actions = {
  async refreshAvailableScormFiles({ commit }, { company }) {
    const result = await company.refreshSubResource(ScormFile, { params: { include_marketplace_scorm_files: true } })
    if (result?.response?.data) {
      const ids = result.response.data.filter(scormFile => scormFile.activated).map(scormFile => scormFile.id)

      commit('SET_AVAILABLE_SCORM_FILE_IDS', ids)
      return ids
    }
  },
  setScormFilesForMarketPlace({ commit }, scormFiles) {
    commit('SET_SCORM_FILES_FOR_MARKET_PLACE', scormFiles)
  },
  setScormFilesSettings({ commit }, scormFiles) {
    commit('SET_SCORM_FILES_SETTINGS', scormFiles)
  },
  setScormFilesStatus({ commit }, scormFiles) {
    commit('SET_SCORM_FILES_STATUS', scormFiles)
  },
}

export const getters = {
  availableScormFiles: (state) => {
    return ScormFile
      .query()
      .whereIdIn(state.availableScormFileIds)
      .with('scorm_file_category_associations')
      .with('scorm_file_category_associations.scorm_file_category')
      .all()
  },
  scormFilesForMarketPlace: state => state.scormFilesForMarketPlace,
  scormFilesSettings: state => state.scormFilesSettings,
  scormFilesStatus: state => state.scormFilesStatus,
  availableScormFileIds: state => state.availableScormFileIds,
}
