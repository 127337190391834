<script>

import axios from 'axios'

import PlanitAlert from '@components/planit-alert'

import store from '@state/store'
import File from '@state/models/file'


export default {
  props: {
    url: { type: String, required: false, default: '' },
    method: { type: String, required: false, default: 'post' },
    showUploadButton: { type: Boolean, required: false, default: true },
    value: { type: Object, required: false },
    locale: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    multiple: { type: Boolean, required: false, default: false },
    uploadDirectly: { type: Boolean, required: false, default: false },
    dontResetAfterUpload: { type: Boolean, required: false, default: false },
    uploadTriggerKey: { type: Boolean, required: false, default: null },
  },
  components: {
    PlanitAlert,
  },
  emits: ['afterUpload', 'finished'],
  data() {
    return {
      enterListener: null,
      fileLoading: false,
      fileError: null,
      open: false,

      uploadFile: null,

      uploadFiles: null,
      uploadProgress: false,
      uploadError: null,
    }
  },
  watch: {
    uploadTriggerKey(v, ov) {
      if (v !== ov && v !== null && ov !== null) {
        this.directUpload(this.url)
      }
    },
  },
  created() {
    this.open = true
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      this.open = false
    },
    finished(resp) {
      this.$emit('finished', resp)
    },
    handleFileChange() {
      if (this.disabled) {
        return
      }

      if (this.value) {
        this.uploadError = null
        this.uploadFiles = [...this.$refs.file.files].map((file) => {
          return {
            name: file.name,
            icon: File.getIconByExtension(file.name),
            file,
            disabled: File.isFileAllowed(file.name),
          }
        })

        if (this.uploadFiles.length === 1) {
          this.upload()
        }

      } else {
        this.uploadFile = this.$refs.file.files[0]
        if (this.uploadDirectly) {
          this.directUpload(this.url)
        }

      }
    },
    async directUpload(url) {
      this.fileLoading = true
      const formData = new FormData()
      formData.append('file', this.uploadFile, this.uploadFile.name)
      await axios
        .request({
          method: this.method,
          url: url,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .catch((error) => {
          this.uploadFile = null
          this.fileLoading = false
          this.fileError = error.response?.data?.message ||
            this.$t('generic.default_error')
        })
        .then(async (resp) => {
          if (resp && (resp.status === 204 || resp.status == 200)) {
            if (!this.dontResetAfterUpload) {
              this.uploadFile = null
            }

            this.fileLoading = false
            this.finished(resp)

          }
        })
    },
    async upload() {
      const promises = []

      this.uploadFiles
      .filter(f => !f.disabled)
      .forEach((uploadFile) => {
        const formData = new FormData()
        formData.append('file', uploadFile.file, uploadFile.file.name)

        promises.push(
          this.value
          .uploadFile(formData, {
            onUploadProgress: (e) => {
              this.uploadProgress = Math.round((100 * e.loaded) / e.total)
            },
            params: {
              locale: this.locale || store.getters['auth/currentLocale'],
            },
          })
          .catch((error) => {
            this.uploadError = error.response.data.message
          })
        )
      })

      const results = await Promise.allSettled(promises)

      let eventData
      if (results.length > 1) {
        eventData = {
          isFilesArray: true,
          files: results.map(r => File.find(r.value.response.data.id)),
        }

      } else {
        eventData = results[0].value.entities.files[0]

      }

      this.$emit('afterUpload', eventData)

      this.uploadError = null
      this.uploadFiles = null
      this.uploadProgress = null
    },

  },

}
</script>

<template>
  <div v-if="!value">
    <v-form @submit.prevent="directUpload(url)">
      <div class="d-flex align-center">
        <div>
          <slot v-if="!fileLoading">
            <PlanitButton
              color="primary"
              variant="outlined"
              tag="label"
              for="file"
            >
              <PlanitIcon class="mr-2" icon="fal fa-file-upload"></PlanitIcon>

              <span v-if="!uploadFile">
                {{ $t('documents.additional.select_file') }}
              </span>
              <span v-else>{{ uploadFile.name }}</span>
            </PlanitButton>
          </slot>
          <input
            id="file"
            ref="file"
            type="file"
            class="uploadinput"
            @change="handleFileChange"
          />
          <PlanitButton
            v-if="uploadFile && !fileError && showUploadButton"
            color="primary"
            class="ml-4"
            type="submit"
          >
            <span v-if="!fileLoading">
              {{ $t('generic.upload') }}
            </span>
            <span v-else>
              <v-progress-circular
                size="24"
                indeterminate
                small
              ></v-progress-circular>
            </span>
          </PlanitButton>
          <div
            v-if="!showUploadButton && fileLoading"
          >
            <v-progress-circular
              size="24"
              indeterminate
              small
            ></v-progress-circular>
          </div>

          <PlanitAlert
            v-if="fileError"
            color="error"
            class="my-4"
            no-max-width
          >
            <span>{{ fileError }}</span>
          </PlanitAlert>

        </div>
      </div>
    </v-form>
  </div>

  <div v-else>
    <v-progress-linear
      v-if="!uploadError && uploadProgress"
      class="mb-2"
      :model-value="uploadProgress"
    ></v-progress-linear>

    <v-alert
      v-if="uploadError"
      type="error"
      class="mb-2"
    >
      {{ uploadError }}
    </v-alert>

    <PlanitButton
      v-if="!uploadFiles || uploadFiles.length === 1"
      :disabled="disabled"
      color="primary"
      variant="outlined"
      tag="label"
      :for="'file-' + value.id + (locale ? '-' + locale : '')"
      class="mr-2"
      style="cursor: pointer;"
    >
      <PlanitIcon class="mr-2" icon="fal fa-file-upload"></PlanitIcon>

      <span v-if="!uploadFiles">{{ $t('components.file_upload.upload_file', locale) }}</span>
      <span v-else-if="uploadFiles.length === 1">{{ uploadFiles[0].name }}</span>
    </PlanitButton>

    <div v-if="uploadFiles && uploadFiles.length > 1" class="pa-2 sheet-border">
      <div
        v-for="(f, index) in uploadFiles"
        :key="index"
        class="d-flex align-center mb-2"
      >
        <PlanitIcon
          :color="f.disabled ? 'error' : 'primary'"
          class="mr-2"
          :icon="f.icon"
        ></PlanitIcon>

        <div :class="f.disabled ? 'text-decoration-line-through text-grey' : ''">
          <div>{{ f.name }}</div>
          <v-progress-linear
            v-if="f.uploadProgress"
            :model-value="f.uploadProgress"
          ></v-progress-linear>
        </div>

        <PlanitChip
          v-if="f.disabled"
          class="ml-2"
          label
          color="error"
          variant="outlined"
          size="small"
        >
          {{ $t('documents.overview.file_type_not_allowed') }}
        </PlanitChip>
      </div>

      <div class="d-flex align-center justify-end mt-6">
        <PlanitButton
          color="primary"
          @click="upload"
        >
          <PlanitIcon
            size="small"
            class="mr-1"
           icon="fal fa-file-upload
          "/>
          <span>{{ $t('generic.upload') }}</span>
        </PlanitButton>
      </div>
    </div>

    <input
      :id="'file-' + value.id + (locale ? '-' + locale : '')"
      ref="file"
      type="file"
      class="uploadinput"
      :multiple="multiple"
      @change="handleFileChange"
    />
  </div>

</template>
