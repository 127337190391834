import { debounce } from 'lodash'

import { app } from '@src/main'

import store from '@state/store'
import { ColumnType } from '@enums'
import TableSetting from '@state/models/table-setting'



export const state = {
  tableSettings: null,
  tableColumns: null,
}

export const mutations = {
  SET_TABLE_SETTINGS(state, tableSettings) {
    state.tableSettings = tableSettings
  },
  SET_TABLE_COLUMNS(state, tableColumns) {
    state.tableColumns = tableColumns;
  },
}

export const getters = {
  // tableSettings(state) {
  //   return state.tableSettings
  // },
  currentTableSetting(state) {
    return state.tableSettings.find((tableSetting) => tableSetting.id === store.state.currentTableSettingId)
  },
  tableColumns(state) {
    return state.tableColumns
  },
  tableSettings (state) {
    return TableSetting
      .query()
      .with('table_column_setting_associations')
      .with('table_column_setting_associations.table_column')
      .with('table_setting_activations')
      .all() || null
  }
}

export const actions = {
  async initTableSettings({ commit }, { company_id, user_id, folder_id=null }) {
    await TableSetting.loadTableSettings(company_id, user_id, folder_id)
  }
}
