import router from '@router'


export default function global404Handler(error) {
  console.log('Global 404 handler', error)

  // Redirect to 404 page
  router.replace({ name: '404' })

}
