<script>
import { orderBy } from 'lodash'
import { FileExportStatus } from '@enums'

import File from '@state/models/file'
import PlanitModelSearch from '@components/planit-model-search'
import RolloutFileAssociation from '@state/models/rollout-file-association'
import PlanitAlert from '@components/planit-alert'
import { RolloutState } from '@enums'

export default {
  name: 'RolloutFiles',
  components: {
    PlanitModelSearch,
    PlanitAlert,
  },
  props: {
    rollout: { type: Object, required: true },
    stateIndex: { type: Number, default: 0, required: true },
  },
  data() {
    return {
      orderBy,
      documentSearch: null,
      FileExportStatus,

      uploading: false,
      uploadFiles: [],
      uploadError: null,
      showDragOverlay: false,
      RolloutState,
    }
  },
  methods: {
    addDocument(document) {
      RolloutFileAssociation.addDocument(this.rollout.id, document.id)
      this.documentSearch = null
    },
    addFiles(files) {
      for (const file of files) {
        this.uploadFiles.push({
          name: file.name,
          icon: File.getIconByExtension(file.name),
          data: file,
          state: 'Pending',
          disabled: File.isFileAllowed(file.name),
        })
      }
    },
    cancelDragover(evt) {
      this.showDragOverlay = false
    },
    deleteAssociation(association) {
      association.delete()
    },
    getIconByExtension(filename) {
      if (!filename) {
        return 'fal fa-file'
      }
      return File.getIconByExtension(filename)
    },
    onDragover(evt) {
      this.showDragOverlay = true
    },
    onDrop(evt) {
      this.addFiles(evt.dataTransfer.files)
      if (!this.uploading) this.upload()
      this.showDragOverlay = false
    },
    onFileChange(event) {
      if (!event.target.files.length) {
        return
      }
      this.addFiles(event.target.files)

      if (!this.uploading) {
        this.upload()
      }
    },
    async upload() {
      this.uploading = true
      this.uploadError = null

      const pendingFiles = this.uploadFiles.filter((file) => file.state === 'Pending')

      if (pendingFiles.length === 0) {
        this.uploading = false
        return
      }

      const file = pendingFiles[0]
      const setUploadProgress = (value) => {
        file.uploadProgress = value
      }
      setUploadProgress(1)
      const formData = new FormData()
      formData.append('file', file.data, file.name)
      formData.append('rollout_id', this.rollout.id)

      const onUploadProgress = (e) => {
        const value = Math.max(1, Math.round((100 * e.loaded) / e.total)) // minimum: 1
        setUploadProgress(value)
      }
      const thenHandler = (r) => {
        file.state = 'Uploaded'
        this.uploading = false
        this.uploadFiles = this.uploadFiles.filter((f) => f.state !== 'Uploaded')
        if (this.uploadFiles.length > 0) {
          this.upload()
        }
      }
      const catchHandler = (r) => {
        const uploadError = r.response.data?.message
        if (uploadError) {
          this.uploadError = uploadError
        }

        this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
        this.uploading = false
        if (this.uploadFiles.length > 0) {
          this.upload()
        }
      }
      await RolloutFileAssociation.uploadFile(formData, onUploadProgress).then(
        thenHandler,
        catchHandler
      )
    },
    resetSearch() {
      this.documentSearch = null
    },
  },
}
</script>

<template>
  <div
    @dragover.prevent="onDragover"
    @drop.prevent="onDrop"
  >
    <v-row class="mb-4 pt-2">
      <v-col cols="6">
        <PlanitAlert
          v-if="stateIndex === 2"
          color="warning"
          class="mb-4"
        >
          {{ $t('rollouts.file_edit_info') }}
        </PlanitAlert>
        <p class="mb-2">
          {{ $t('rollouts.add_docs') }}
        </p>
        <planitTextField
          v-model.trim.debounce="documentSearch"
          :label="$t('generic.documents')"
          @click:append="resetSearch"
          density="compact"
          variant="outlined"
          hide-details
          :disabled="rollout.state === RolloutState.COMPLETED"
          class="search-input-icon-fix"
          prepend-inner-icon="fal fa-search"
        ></planitTextField>
        <div id="search-field" class="mb-4">
          <v-menu
            v-if="documentSearch"
            :model-value="documentSearch"
            :close-on-content-click="false"
            :persistent="!(true)"
            activator="#search-field"
            min-width="48rem"
            max-width="48rem"
          >
            <div class="bg-white">
              <PlanitModelSearch
                :value="documentSearch"
                :placeholder="$t('generic.documents')"
                :company-id="rollout.company_id"
                :filter-model-type="['documents']"
                hide-switches
                hide-search-input
                @set-model="addDocument"
              >
              </PlanitModelSearch>
            </div>
          </v-menu>
        </div>
        <div
          style="max-height: 16rem; overflow-y: auto"
          class="mb-4"
        >
          <div
            v-for="(file, i) in uploadFiles"
            class="d-flex align-center mb-2 ml-2"
            :key="i + file.name"
          >
            <PlanitIcon
              class="mr-2"
              :icon="getIconByExtension(file.name)"
            />
            <div class="table-text flex-grow-1">
              <div class="mb-1">{{ file.name }}</div>
              <v-progress-linear
                :model-value="file.uploadProgress"
                class="mr-4"
              ></v-progress-linear>
            </div>
          </div>
          <div
            v-for="(association, i) in orderBy(rollout.file_associations, 'created', 'desc')"
            :key="i"
            class="d-flex align-center mb-2 ml-2"
          >
            <PlanitIcon
              class="mr-2"
              :icon="getIconByExtension(association.file.filename)"
            />
            <div class="table-text flex-grow-1">
              <div v-if="association.file.export_status !== FileExportStatus.FINISHED">
                <div class="mb-1">{{ association.file.document_name }}</div>
                <v-progress-linear
                  color="primary"
                  indeterminate
                  class="mr-4"
                ></v-progress-linear>
              </div>
              <div v-else>
                <div>
                  <a
                    :href="association.file.link"
                    target="_blank"
                    >{{ association.file.filename }}</a
                  >
                </div>
                <div>{{ association.file.getFormattedFileSize() }}</div>
              </div>
            </div>
            <PlanitButton
              variant="text"
              size="small"
              :disabled="rollout.state === RolloutState.COMPLETED"
              color="default"
              class="icon-btn px-2"
              @click.prevent="deleteAssociation(association)"
            >
              <PlanitIcon size="small" icon=" fal fa-trash-alt "></PlanitIcon>

            </PlanitButton>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <p class="mb-2">{{ $t('rollouts.add_files') }}</p>
        <div>
          <PlanitButton
            color="primary"
            :disabled="rollout.state === RolloutState.COMPLETED"
            tag="label"
            for="file"
            variant="outlined"
          >
            <PlanitIcon start icon=" far fa-file-upload "></PlanitIcon>

            Upload
          </PlanitButton>
          <input
            id="file"
            ref="file"
            type="file"
            class="uploadinput"
            multiple
            @change="onFileChange"
          />
        </div>
        <PlanitAlert
          v-if="uploadError"
          color="error"
          class="mt-4"
        >
          {{ uploadError }}
        </PlanitAlert>
      </v-col>
    </v-row>
    <v-fade-transition>
      <v-overlay
        v-if="showDragOverlay"
        absolute
        @mouseleave.prevent="cancelDragover"
        @dragleave.prevent="cancelDragover"
      >
        <div class="d-flex align-center">
          <PlanitIcon
            size="x-large"
            class="mr-1"
            >fas fa-file-upload</PlanitIcon
          >
          <label>{{ $t('components.rollout_card.drag_drop_here') }}</label>
        </div>
      </v-overlay>
    </v-fade-transition>
  </div>
</template>
