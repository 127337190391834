import PlanitModel from './planit-model'
import Company from './company'


export default class MailConfig extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      mail_server: this.attr(),
      mail_port: this.attr(),
      mail_use_tls: this.attr(),
      mail_use_ssl: this.attr(),
      mail_username: this.attr(),
      mail_password: this.attr(),
      mail_sender_name: this.attr(),
      mail_sender_email: this.attr(),

      company_id: this.attr(),
      company: this.belongsTo(Company, 'company_id'),

    }
  }

  url() {
    let url = Company.$url() + '/' + this.company_id + '/' + this.constructor.entity
    return url
  }

  testMailConnection() {
    return this.constructor.api().patch(this.url() + '/test-connection')
  }
}

MailConfig.entity = 'mail-config'
