import { Model } from '@vuex-orm/core'

import store from '@state/store'

import Section from './section'
import Answer from './answer'
import { Document } from './document'
import { DocumentVersion } from './document-version'


export default class RepositoryReferenceItem extends Model {
  /**
   * Wrapper class that holds all relevant information for a repository reference to make API retrival easier
   */

  // static primaryKey = ['answer_viid', 'document_id']

  static fields() {
    return {
      id: this.uid(),

      document_id: this.attr(),

      answer_viid: this.attr(),
      answer_backref_viid: this.attr(), // separate backref because answer_id is used as primary key
      answer: this.belongsTo(Answer, 'answer_backref_viid', 'viid'),

      repo_answer_viid: this.attr(),

      section_id: this.attr(),
      section: this.belongsTo(Section, 'section_id'),

      repository_document_version_id: this.attr(),
      repository_document_version: this.belongsTo(DocumentVersion, 'repository_document_version_id'),

      repo_document_id: this.attr(),
      repo_document: this.belongsTo(Document, 'repo_document_id'),

      select_items: this.attr(),

      children_repository_reference_item_viids: this.attr([]),
      children_repository_reference_items: this.hasManyBy(RepositoryReferenceItem, 'children_repository_reference_item_viids'),

      repo_create_optional_button_text_translations: this.attr(),
      new_item_creation_allowed: this.attr(),

      num_levels: this.attr(),

    }
  }

  static allFast() {
    return Object.keys(store.state.entities[this.entity].data).map(
      (k) => store.state.entities[this.entity].data[k]
    )
  }

  updateAttr(data) {
    return this.constructor.update({
      where: this.$id,
      data,
    })
  }

}
RepositoryReferenceItem.counter = -1

RepositoryReferenceItem.entity = 'repository-reference-items'
