import PlanitModel from './planit-model'
import TableColumn from './table-column'
import TableColumnSettingAssociation from './table-column-setting-association'
import TableSettingActivation from './table-setting-activation'

export default class TableSetting extends PlanitModel {
  static fields() {
    return {
      ...super.fields(),

      name: this.attr(),
      company_default: this.attr(),
      server_default: this.attr(),
      shared_with_company: this.attr(),
      menu_category_uuid: this.attr(),

      default_sort_column_id: this.attr(),
      default_sort_direction: this.attr(),

      table_column_setting_associations: this.hasMany(TableColumnSettingAssociation, 'table_setting_id'),
      table_setting_activations: this.hasMany(TableSettingActivation, 'table_setting_id'),
    }
  }
  static async loadTableSettings(company_id, user_id, folder_id=null) {
    const params = {
      company_id: company_id,
      user_id: user_id,
      folder_id: folder_id,
    }

    return TableSetting.$all(params)

  }

  static async createTableSetting(name ,company_id, user_id, folder_id=null) {
    const params = {
      name: name,
      company_id: company_id,
      user_id: user_id,
      folder_id: folder_id
    }
    return TableSetting.api().post('/table-settings', params)
  }
  async updateTableSetting(params) {
    return await TableSetting.api().patch(this.url(), params)
  }

  async addNewColumn(table_column_id, position) {
    const data = {
      table_setting_id: this.id,
      table_column_id: table_column_id,
      position: position
    }
    return this.createSubResource(TableColumnSettingAssociation, {
      ...data,
    })
  }
  async createTableSettingActivation(params) {
    return this.createSubResource(TableSettingActivation, {
      ...params,
      table_setting_id: this.id
    })
  }
}

TableSetting.entity = 'table-settings'